import {
  put, all, takeEvery, takeLatest, call, select,
} from 'redux-saga/effects';
import _isNil from 'lodash/isNil';
import _isEmpty from 'lodash/isEmpty';
import { ordersApiSelector, searchOrdersApiSelector } from 'app/api/selectors';
import { getErrorMessage } from 'utils/error';
import { authRequired } from 'services/auth/sagas';
import { serviceSaga } from 'services/sagaWorkers';
import { ACTIONS } from './constants';
import { fetchOrderFeedSuccess, fetchOrderFeedError } from './actions';

function* setUpdateFlagWatcher({
  guidOrderLine, params, successActionCallback, errorActionCallback,
}) {
  const api = yield select(ordersApiSelector);
  try {
    const isCancelling = !_isNil(params.cancelled) && params.cancelled;
    let cancelResponse = {};
    let othersResponse = {};

    if (isCancelling) {
      const { cancelled, ...restParams } = params;
      cancelResponse = yield call([api, api.updateFlag], guidOrderLine, { cancelled });
      if (!_isEmpty(restParams)) {
        othersResponse = yield call([api, api.updateFlag], guidOrderLine, restParams);
      }
    } else {
      othersResponse = yield call([api, api.updateFlag], guidOrderLine, params);
    }

    const response = {
      ...cancelResponse,
      ...othersResponse,
    };

    if (successActionCallback) {
      yield put(successActionCallback(response));
    }
    return response;
  } catch (error) {
    const errorMessage = getErrorMessage(error);

    if (errorActionCallback) {
      yield put(errorActionCallback(errorMessage, error));
    }
    return error;
  }
}

function* fetchSearchOrdersWatcher({ searchTerm, successActionCallback, errorActionCallback }) {
  const api = yield select(searchOrdersApiSelector);
  try {
    const { data } = yield call([api, api.searchOrders], searchTerm);
    const response = {
      searchTerm,
      ...data,
    };
    if (successActionCallback) {
      yield put(successActionCallback(response));
    }
    return response;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    if (errorActionCallback) {
      yield put(errorActionCallback(errorMessage, error));
    }
    return error;
  }
}

function* fetchOrderFeedSuccessWatcher() {
  yield put(fetchOrderFeedSuccess());
}

function* fetchOrderFeedErrorWatcher() {
  yield put(fetchOrderFeedError());
}

const fetchOrderFeedWatcher = action => serviceSaga(
  ordersApiSelector, 'orderFeedServerSide', action, {
    onSuccess: fetchOrderFeedSuccessWatcher,
    onError: fetchOrderFeedErrorWatcher,
  },
);
const fetchOrderFeedQueryWatcher = action => serviceSaga(ordersApiSelector, 'orderFeedServerSide', action);
const fetchOrderFeedCSVWatcher = action => serviceSaga(ordersApiSelector, 'orderFeedToCSV', action);
const fetchSweepOrderLineWatcher = action => serviceSaga(ordersApiSelector, 'sweepOrderLine', action);
const setAddOrderWatcher = action => serviceSaga(ordersApiSelector, 'addOrder', action);
const fetchGeneratePickTicketWatcher = action => serviceSaga(ordersApiSelector, 'generatePickTicket', action);
const fetchOrderExporterPastReportsWatcher = action => serviceSaga(ordersApiSelector, 'getOrderExporterPastReports', action);
const setOrderExporterCSVDownloadWatcher = action => serviceSaga(ordersApiSelector, 'getOrderExporterReportCSV', action);
const getFilteredOrderLineFeedWatcher = action => serviceSaga(ordersApiSelector, 'getFilteredOrderLineFeed', action);
const getFilteredOrderFeedWatcher = action => serviceSaga(ordersApiSelector, 'getFilteredOrderFeed', action);
const getOrderDetailsWatcher = action => serviceSaga(ordersApiSelector, 'getOrderDetails', action);
const postOrderNoteWatcher = action => serviceSaga(ordersApiSelector, 'postOrderNote', action);
const getOrderLineNotesWatcher = action => serviceSaga(ordersApiSelector, 'getOrderLineNotes', action);
const postAddOrderLineNotesWatcher = action => serviceSaga(ordersApiSelector, 'postAddOrderLineNotes', action);

export default function* ordersServiceSagas() {
  yield all([
    takeLatest(ACTIONS.FETCH_ORDER_FEED, authRequired, fetchOrderFeedWatcher),
    takeEvery(ACTIONS.FETCH_ORDER_FEED_QUERY, authRequired, fetchOrderFeedQueryWatcher),
    takeEvery(ACTIONS.FETCH_ORDER_FEED_CSV, authRequired, fetchOrderFeedCSVWatcher),
    takeEvery(ACTIONS.SET_UPDATE_FLAG, authRequired, setUpdateFlagWatcher),
    takeEvery(ACTIONS.FETCH_SWEEP_ORDER_LINE, authRequired, fetchSweepOrderLineWatcher),
    takeEvery(ACTIONS.FETCH_SEARCH_ORDERS, authRequired, fetchSearchOrdersWatcher),
    takeEvery(ACTIONS.SET_ADD_ORDER, authRequired, setAddOrderWatcher),
    takeEvery(ACTIONS.FETCH_GENERATE_PICK_TICKET, authRequired, fetchGeneratePickTicketWatcher),
    takeEvery(
      ACTIONS.FETCH_ORDER_EXPORTER_PAST_REPORTS, authRequired, fetchOrderExporterPastReportsWatcher,
    ),
    takeEvery(
      ACTIONS.SET_ORDER_EXPORTER_CSV_DOWNLOAD, authRequired, setOrderExporterCSVDownloadWatcher,
    ),
    takeEvery(ACTIONS.GET_FILTERED_ORDER_LINES, authRequired, getFilteredOrderLineFeedWatcher),
    takeEvery(ACTIONS.GET_FILTERED_ORDERS, authRequired, getFilteredOrderFeedWatcher),
    takeEvery(ACTIONS.GET_ORDER_DETAILS, authRequired, getOrderDetailsWatcher),
    takeEvery(ACTIONS.POST_ORDER_NOTE, authRequired, postOrderNoteWatcher),
    takeEvery(ACTIONS.GET_ORDER_LINE_NOTES, authRequired, getOrderLineNotesWatcher),
    takeEvery(ACTIONS.POST_ADD_ORDER_LINE_NOTES, authRequired, postAddOrderLineNotesWatcher),
  ]);
}