import _reduce from 'lodash/reduce';
import _pick from 'lodash/pick';
import { formatUserLocalStorageKey, UNIQ_LOCAL_STORAGE_KEYS } from 'app/persistedState';

export const ACTIONS = {
  INIT_CONTAINER: 'SHIPPING_RULES__INIT_CONTAINER',
  EXIT_CONTAINER: 'SHIPPING_RULES__EXIT_CONTAINER',

  LOAD_SHIPPING_RULES: 'SHIPPING_RULES__LOAD_SHIPPING_RULES',
  LOAD_SHIPPING_RULES_SUCCESS: 'SHIPPING_RULES__LOAD_SHIPPING_RULES_SUCCESS',
  LOAD_SHIPPING_RULES_ERROR: 'SHIPPING_RULES__LOAD_SHIPPING_RULES_ERROR',
  LOAD_FEED: 'SHIPPING_RULES__LOAD_FEED',

  LOAD_SHIP_SETUP: 'SHIPPING_RULES__LOAD_SHIP_SETUP',
  LOAD_SHIP_SETUP_SUCCESS: 'SHIPPING_RULES__LOAD_SHIP_SETUP_SUCCESS',
  LOAD_SHIP_SETUP_ERROR: 'SHIPPING_RULES__LOAD_SHIP_SETUP_ERROR',

  SHOW_OPTIONS_MODAL: 'SHIPPING_RULES__SHOW_OPTIONS_MODAL',
  REFRESH_FEED: 'SHIPPING_RULES__REFRESH_FEED',
  SET_FILTER_OPERAND: 'SHIPPING_RULES__SET_FILTER_OPERAND',
  UNSET_FILTER_OPERAND: 'SHIPPING_RULES__UNSET_FILTER_OPERAND',
  CLEAR_FILTERS: 'SHIPPING_RULES__CLEAR_FILTERS',
  SET_FILTERS: 'SHIPPING_RULES__SET_FILTERS',
  ARRANGE_FILTERS: 'SHIPPING_RULES__ARRANGE_FILTERS',

  SET_COLUMNS: 'SHIPPING_RULES__SET_COLUMNS',
  SET_COLUMNS_ORDER: 'SHIPPING_RULES__SET_COLUMNS_ORDER',
  SET_COLUMN_ENABLED: 'SHIPPING_RULES__SET_COLUMN_ENABLED',
  SET_ITEMS_PER_PAGE: 'SHIPPING_RULES__SET_ITEMS_PER_PAGE',
  SORT_FEED: 'SHIPPING_RULES__SORT_FEED',
  GO_TO_PAGE: 'SHIPPING_RULES__GO_TO_PAGE',

  EXPORT_TO_FILE: 'SHIPPING_RULES__EXPORT_TO_FILE',
  EXPORT_TO_FILE_SUCCESS: 'SHIPPING_RULES__EXPORT_TO_FILE_SUCCESS',
  EXPORT_TO_FILE_ERROR: 'SHIPPING_RULES__EXPORT_TO_FILE_ERROR',

  ADD_SHIPPING_RULE: 'SHIPPING_RULES__ADD_RULE',
  CANCEL_ADD_SHIPPING_RULE: 'SHIPPING_RULES__CANCEL_ADD_RULE',
  EDIT_SHIPPING_RULE: 'SHIPPING_RULES__EDIT_RULE',
  CANCEL_EDIT_SHIPPING_RULE: 'SHIPPING_RULES__CANCEL_EDIT_RULE',
  DELETE_SHIPPING_RULE: 'SHIPPING_RULES__DELETE_RULE',
  CANCEL_DELETE_SHIPPING_RULE: 'SHIPPING_RULES__CANCEL_DELETE_RULE',

  REMOVE_SHIPPING_RULE: 'SHIPPING_RULES__REMOVE_RULE',
  REMOVE_SHIPPING_RULE_SUCCESS: 'SHIPPING_RULES__REMOVE_RULE_SUCCESS',
  REMOVE_SHIPPING_RULE_ERROR: 'SHIPPING_RULES__REMOVE_RULE_ERROR',

  CREATE_SHIPPING_RULE: 'SHIPPING_RULES__CREATE_RULE',
  CREATE_SHIPPING_RULE_SUCCESS: 'SHIPPING_RULES__CREATE_RULE_SUCCESS',
  CREATE_SHIPPING_RULE_ERROR: 'SHIPPING_RULES__CREATE_RULE_ERROR',

  UPDATE_SHIPPING_RULE: 'SHIPPING_RULES__UPDATE_RULE',
  UPDATE_SHIPPING_RULE_SUCCESS: 'SHIPPING_RULES__UPDATE_RULE_SUCCESS',
  UPDATE_SHIPPING_RULE_ERROR: 'SHIPPING_RULES__UPDATE_RULE_ERROR',

  FETCH_BRANDS_SUPPLIERS: 'SHIPPING_RULES__FETCH_BRANDS_SUPPLIERS',
  FETCH_BRANDS_SUPPLIERS_SUCCESS: 'SHIPPING_RULES__FETCH_BRANDS_SUPPLIERS_SUCCESS',
  FETCH_BRANDS_SUPPLIERS_ERROR: 'SHIPPING_RULES__FETCH_BRANDS_SUPPLIERS_ERROR',
};

export const SHIPPING_RULES_LOCAL_STORAGE_KEY = username => formatUserLocalStorageKey(
  username, UNIQ_LOCAL_STORAGE_KEYS.SHIPPING_RULES_VIEW,
);

export const SHIPPING_RULE_FIELDS = {
  BRAND_SUPPLIER_ID: 'brand_supplier_id',
  SUPPLIER: 'supplier',
  BRAND: 'brand',
  DROPSHIP_FEE: 'dropship_fee',
  RESTOCKING_FEE: 'restocking_fee',
  LEADTIME: 'leadtime',
  PO_OVERRIDE: 'po_override',
  EDI: 'edi',
  APO: 'apo',
  NON_48: 'non_48',
  WAREHOUSE_SHIPMENT: 'warehouse_shipment',
  TRANSHIP_BRAND: 'tranship_brand',
  EMAIL_BRAND: 'email_brand',
  VALID_SALES_CHANNELS: 'valid_sales_channels',
  FULFILLMENT_WAREHOUSES: 'fulfillment_warehouses',
};

export const SHIPPING_RULES_COLUMNS = {
  [SHIPPING_RULE_FIELDS.SUPPLIER]: {
    id: SHIPPING_RULE_FIELDS.SUPPLIER,
    enabled: true,
    label: 'Supplier',
    sortable: true,
    filterable: true,
    filterableContextMenu: true,
    copyable: true,
    editable: false,
    cellProps: {
      align: 'left',
      minWidth: '250px',
    },
  },
  [SHIPPING_RULE_FIELDS.BRAND]: {
    id: SHIPPING_RULE_FIELDS.BRAND,
    enabled: true,
    label: 'Brand',
    sortable: true,
    filterable: true,
    filterableContextMenu: true,
    copyable: true,
    editable: false,
    cellProps: {
      align: 'left',
      minWidth: '250px',
    },
  },
  [SHIPPING_RULE_FIELDS.EDI]: {
    id: SHIPPING_RULE_FIELDS.EDI,
    enabled: true,
    label: 'EDI',
    sortable: true,
    filterable: true,
    filterableContextMenu: true,
    copyable: true,
    editable: false,
    cellProps: {
      align: 'left',
      minWidth: '110px',
    },
  },
  [SHIPPING_RULE_FIELDS.APO]: {
    id: SHIPPING_RULE_FIELDS.APO,
    enabled: true,
    label: 'APO/PO',
    sortable: true,
    filterable: true,
    filterableContextMenu: true,
    copyable: true,
    editable: false,
    cellProps: {
      align: 'left',
      minWidth: '110px',
    },
  },
  [SHIPPING_RULE_FIELDS.NON_48]: {
    id: SHIPPING_RULE_FIELDS.NON_48,
    enabled: true,
    label: 'Non 48',
    sortable: true,
    filterable: true,
    filterableContextMenu: true,
    copyable: true,
    editable: true,
    cellProps: {
      align: 'left',
      minWidth: '110px',
    },
  },
  [SHIPPING_RULE_FIELDS.DROPSHIP_FEE]: {
    id: SHIPPING_RULE_FIELDS.DROPSHIP_FEE,
    enabled: true,
    label: 'Dropship Fee',
    sortable: true,
    filterable: true,
    filterableContextMenu: true,
    copyable: true,
    editable: false,
    cellProps: {
      align: 'left',
      minWidth: '150px',
    },
  },
  [SHIPPING_RULE_FIELDS.RESTOCKING_FEE]: {
    id: SHIPPING_RULE_FIELDS.RESTOCKING_FEE,
    enabled: true,
    label: 'Restocking Fee',
    sortable: true,
    filterable: true,
    filterableContextMenu: true,
    copyable: true,
    editable: false,
    cellProps: {
      align: 'left',
      minWidth: '170px',
    },
  },
  [SHIPPING_RULE_FIELDS.LEADTIME]: {
    id: SHIPPING_RULE_FIELDS.LEADTIME,
    enabled: true,
    label: 'Leadtime',
    sortable: true,
    filterable: true,
    filterableContextMenu: true,
    copyable: true,
    editable: true,
    cellProps: {
      align: 'left',
      minWidth: '130px',
    },
  },
  [SHIPPING_RULE_FIELDS.WAREHOUSE_SHIPMENT]: {
    id: SHIPPING_RULE_FIELDS.WAREHOUSE_SHIPMENT,
    enabled: true,
    label: 'Warehouse Shipment',
    sortable: true,
    filterable: true,
    filterableContextMenu: true,
    copyable: true,
    editable: false,
    cellProps: {
      align: 'left',
      minWidth: '210px',
    },
  },
  [SHIPPING_RULE_FIELDS.TRANSHIP_BRAND]: {
    id: SHIPPING_RULE_FIELDS.TRANSHIP_BRAND,
    enabled: true,
    label: 'Tranship Brand',
    sortable: true,
    filterable: true,
    filterableContextMenu: true,
    copyable: true,
    editable: false,
    cellProps: {
      align: 'left',
      minWidth: '210px',
    },
  },
  [SHIPPING_RULE_FIELDS.EMAIL_BRAND]: {
    id: SHIPPING_RULE_FIELDS.EMAIL_BRAND,
    enabled: true,
    label: 'Email Brand',
    sortable: true,
    filterable: true,
    filterableContextMenu: true,
    copyable: true,
    editable: false,
    cellProps: {
      align: 'left',
      minWidth: '210px',
    },
  },
  [SHIPPING_RULE_FIELDS.PO_OVERRIDE]: {
    id: SHIPPING_RULE_FIELDS.PO_OVERRIDE,
    enabled: true,
    label: 'PO Override',
    sortable: true,
    filterable: true,
    filterableContextMenu: true,
    copyable: true,
    editable: false,
    cellProps: {
      align: 'left',
      minWidth: '130px',
    },
  },
  [SHIPPING_RULE_FIELDS.FULFILLMENT_WAREHOUSES]: {
    id: SHIPPING_RULE_FIELDS.FULFILLMENT_WAREHOUSES,
    enabled: true,
    label: 'Fulfillment Warehouses',
    sortable: false,
    filterable: true,
    filterableContextMenu: false,
    copyable: true,
    editable: false,
    cellProps: {
      align: 'left',
      minWidth: '220px',
    },
  },
  [SHIPPING_RULE_FIELDS.VALID_SALES_CHANNELS]: {
    id: SHIPPING_RULE_FIELDS.VALID_SALES_CHANNELS,
    enabled: true,
    label: 'Valid Sales Channels',
    sortable: false,
    filterable: true,
    filterableContextMenu: false,
    copyable: true,
    editable: false,
    cellProps: {
      align: 'left',
      minWidth: '220px',
    },
  },
};

export const SHIPPING_RULES_COLUMNS_STORAGE_SETTINGS = _reduce(
  SHIPPING_RULES_COLUMNS, (result, value, key) => {
    const newResult = result;
    newResult[key] = _pick(value, ['id', 'enabled']);
    return newResult;
  }, {},
);

export const SHIPPING_RULES_COLUMNS_DEFAULT_ORDER = [
  SHIPPING_RULE_FIELDS.SUPPLIER,
  SHIPPING_RULE_FIELDS.BRAND,
  SHIPPING_RULE_FIELDS.EDI,
  SHIPPING_RULE_FIELDS.APO,
  SHIPPING_RULE_FIELDS.NON_48,
  SHIPPING_RULE_FIELDS.DROPSHIP_FEE,
  SHIPPING_RULE_FIELDS.RESTOCKING_FEE,
  SHIPPING_RULE_FIELDS.LEADTIME,
  SHIPPING_RULE_FIELDS.WAREHOUSE_SHIPMENT,
  SHIPPING_RULE_FIELDS.TRANSHIP_BRAND,
  SHIPPING_RULE_FIELDS.EMAIL_BRAND,
  SHIPPING_RULE_FIELDS.PO_OVERRIDE,
  SHIPPING_RULE_FIELDS.FULFILLMENT_WAREHOUSES,
  SHIPPING_RULE_FIELDS.VALID_SALES_CHANNELS,
];