const ENDPOINTS = {
  GET_USER_PROFILE: '/get_user_profile',
  UPDATE_USER_PROFILE_INFO: '/update_user_profile_info',
  CHANGE_PASSWORD: '/change_password',
  GET_BUSINESS_INFO: '/get_business_info',
  UPLOAD_BUSINESS_LOGO: '/upload_business_logo',
  UPDATE_BUSINESS_INFO: '/update_business_info',
};

export default ENDPOINTS;
