import _get from 'lodash/get';
import _set from 'lodash/set';
import _includes from 'lodash/includes';
import { PROD_MODE } from 'app/config';
import { getFromLocalStorage, saveToLocalStorage } from 'utils/localStorage';
import config from './config';
import { FEATURE_FLAGS } from './constants';

const LOCAL_STORAGE_KEY = 'omni:app:dev:flags';

const getLocalStorageFlags = () => getFromLocalStorage(LOCAL_STORAGE_KEY) || {};

const getFeatureFlagValue = featureFlag => _get(getLocalStorageFlags(), featureFlag, null);

const setFeatureFlagValue = (featureFlag, value) => {
  const flags = getLocalStorageFlags();
  _set(flags, featureFlag, value);
  saveToLocalStorage(flags, LOCAL_STORAGE_KEY);
};

const resetFeatureFlags = () => saveToLocalStorage({}, LOCAL_STORAGE_KEY);

const isFeatureEnabled = featureFlag => {
  const featureFlagsList = Object.values(FEATURE_FLAGS);
  if (!_includes(featureFlagsList, featureFlag)) {
    throw new Error(`Usage of undefined feature flag ${featureFlag}`);
  }

  const configFeatureFlagValue = config[featureFlag];

  if (PROD_MODE) {
    return configFeatureFlagValue;
  }

  const featureFlagValue = getFeatureFlagValue(featureFlag);

  return featureFlagValue !== null ? featureFlagValue : configFeatureFlagValue;
};

export {
  config,
  setFeatureFlagValue,
  resetFeatureFlags,
  isFeatureEnabled,
};