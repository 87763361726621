import {
  all, takeEvery, put, fork,
} from 'redux-saga/effects';
import { APP_FEATURES } from 'constants/global';
import { setFeatureName } from 'containers/Dashboard/actions';
import { ACTIONS } from './constants';
import { resetFeatureReducers } from './actions';
import productsSagas from './Products/sagas';
import productCreatorSagas from './ProductCreator/sagas';
import brandsSuppliersSagas from './BrandsSuppliers/sagas';

function* initContainerWatcher() {
  yield put(setFeatureName(APP_FEATURES.PRODUCT_MANAGEMENT.title));
}

function* exitContainerWatcher() {
  yield put(setFeatureName());
  yield put(resetFeatureReducers());
}

export default function* productManagementSagas(...args) {
  yield all([
    takeEvery(ACTIONS.INIT_CONTAINER, initContainerWatcher),
    takeEvery(ACTIONS.EXIT_CONTAINER, exitContainerWatcher),
    fork(productsSagas, ...args),
    fork(productCreatorSagas, ...args),
    fork(brandsSuppliersSagas, ...args),
  ]);
}
