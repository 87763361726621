export const ACTIONS = {
  DOWNLOAD_TEMPLATE: 'BULK_MARK_SHIP__DOWNLOAD_TEMPLATE',
  DOWNLOAD_TEMPLATE_SUCCESS: 'BULK_MARK_SHIP__DOWNLOAD_TEMPLATE_SUCCESS',
  DOWNLOAD_TEMPLATE_ERROR: 'BULK_MARK_SHIP__DOWNLOAD_TEMPLATE_ERROR',

  UPLOAD_FILE: 'BULK_MARK_SHIP__UPLOAD_FILE',
  UPLOAD_FILE_SUCCESS: 'BULK_MARK_SHIP__UPLOAD_FILE_SUCCESS',
  UPLOAD_FILE_ERROR: 'BULK_MARK_SHIP__UPLOAD_FILE_ERROR',

  RESET_STATE: 'BULK_MARK_SHIP__RESET_STATE',
};