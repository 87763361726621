import { createReducer } from 'redux-create-reducer';
import { ACTIONS } from '../constants';

export const INITIAL_STATE = {
  isAddingOrderNote: false,
  isFetchingOrderDetails: false,
  data: null,
  orderNoteError: null,
  showOrderDetailsModal: false,
};

const setIsAddingOrderNote = (state, isAddingOrderNote) => ({
  ...state,
  isAddingOrderNote,
});

const updateAddOrder = state => ({
  ...setIsAddingOrderNote(state, true),
  orderNoteError: INITIAL_STATE.orderNoteError,
});

const updateAddOrderError = (state, { errorMessage }) => ({
  ...setIsAddingOrderNote(state, false),
  orderNoteError: errorMessage,
});

const setIsFetchingOrderDetails = (state, isFetchingOrderDetails) => ({
  ...state,
  isFetchingOrderDetails,
});

const updateData = (state, { payload }) => ({
  ...state,
  data: payload[0] || [],
  isFetchingOrderDetails: false,
});

const updateShowOrderDetailsModal = (state, { showOrderDetailsModal }) => ({
  ...state,
  showOrderDetailsModal,
});

const resetState = () => ({ ...INITIAL_STATE });

export const handlers = {
  [ACTIONS.ADD_ORDER_NOTE]: updateAddOrder,
  [ACTIONS.ADD_ORDER_NOTE_SUCCESS]: state => setIsAddingOrderNote(state, false),
  [ACTIONS.ADD_ORDER_NOTE_ERROR]: updateAddOrderError,
  [ACTIONS.LOAD_ORDER_DETAILS]: state => setIsFetchingOrderDetails(state, true),
  [ACTIONS.LOAD_ORDER_DETAILS_SUCCESS]: updateData,
  [ACTIONS.LOAD_ORDER_DETAILS_ERROR]: state => setIsFetchingOrderDetails(state, false),
  [ACTIONS.SHOW_ORDER_DETAILS_MODAL]: updateShowOrderDetailsModal,
  [ACTIONS.RESET_ORDER_DETAILS]: resetState,
};

export default createReducer(INITIAL_STATE, handlers);