import {
  all, fork, takeEvery, put, select,
} from 'redux-saga/effects';
import pickingZoneSagas from './pickingZoneSagas';
import shippingZoneSagas from './shippingZoneSagas';
import { ACTIONS } from '../constants';
import { getZoneName } from '../selectors/pickingZoneSelectors';
import { reloadZone } from '../actions/pickingZone';

function* initContainerWatcher() {
  const pickingZoneName = yield select(getZoneName);
  if (pickingZoneName) {
    yield put(reloadZone());
  }
}

export default function* pickingSagas(...args) {
  yield all([
    takeEvery(ACTIONS.INIT_CONTAINER, initContainerWatcher),
    fork(pickingZoneSagas, ...args),
    fork(shippingZoneSagas, ...args),
  ]);
}
