import {
  all, takeEvery,
} from 'redux-saga/effects';
import { suppliersApiSelector } from 'app/api/selectors';
import { serviceSaga } from 'services/sagaWorkers';
import { authRequired } from 'services/auth/sagas';
import { ACTIONS } from './constants';

const listSuppliersWatcher = action => serviceSaga(suppliersApiSelector, 'listSuppliers', action);
const addSupplierWatcher = action => serviceSaga(suppliersApiSelector, 'addSupplier', action);
const updateSupplierWatcher = action => serviceSaga(suppliersApiSelector, 'updateSupplier', action);
const deleteSupplierWatcher = action => serviceSaga(suppliersApiSelector, 'deleteSupplier', action);

export default function* suppliersSagas() {
  yield all([
    takeEvery(ACTIONS.LIST_SUPPLIERS, authRequired, listSuppliersWatcher),
    takeEvery(ACTIONS.ADD_SUPPLIER, authRequired, addSupplierWatcher),
    takeEvery(ACTIONS.UPDATE_SUPPLIER, authRequired, updateSupplierWatcher),
    takeEvery(ACTIONS.DELETE_SUPPLIER, authRequired, deleteSupplierWatcher),
  ]);
}