import { css } from 'styled-components';
import VocesRegular from '../fonts/Voces-Regular.ttf';
import RobotoRegular from '../fonts/Roboto-Regular.ttf';
import RobotoMedium from '../fonts/Roboto-Medium.ttf';
import MontserratRegular from '../fonts/Montserrat-Regular.ttf';
import MontserratBold from '../fonts/Montserrat-Bold.ttf';

const FONT_VOCES = 'Voces';
const FONT_ROBOTO = 'Roboto';
const FONT_ROBOTO_MEDIUM = 'Roboto Medium';
const FONT_MONTSERRAT = 'Montserrat';
const FONT_MONTSERRAT_BOLD = 'Montserrat Bold';

export const VOCES = `${FONT_VOCES}, Arial`;
export const ROBOTO = `${FONT_ROBOTO}, Arial`;
export const ROBOTO_MEDIUM = `${FONT_ROBOTO_MEDIUM}, Arial`;
export const MONTSERRAT = `${FONT_MONTSERRAT}, Helvetica`;
export const MONTSERRAT_BOLD = `${FONT_MONTSERRAT_BOLD}, Helvetica`;

// Any font face here needs to be added to index.html
const fonts = css`
  @font-face {
    font-family: ${FONT_VOCES};
    src: url(${VocesRegular}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: ${FONT_ROBOTO};
    src: url(${RobotoRegular}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: ${FONT_ROBOTO_MEDIUM};
    src: url(${RobotoMedium}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: ${FONT_MONTSERRAT};
    src: url(${MontserratRegular}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: ${FONT_MONTSERRAT_BOLD};
    src: url(${MontserratBold}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
`;
export default fonts;
