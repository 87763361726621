import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { usePrevious } from 'utils/react';
import {
  SwitchWrap,
  switchColors,
  switchSizes,
  SwitchGroup,
} from './styles';

const Switch = ({
  checked,
  onChange,
  size,
  color,
  disabled,
  isControlled,
  ...restProps
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const prevChecked = usePrevious(checked);

  useEffect(() => {
    if (prevChecked !== checked) {
      setIsChecked(checked);
    }
    if (isControlled) {
      setIsChecked(checked);
    }
  }, [checked, prevChecked, isControlled, isChecked]);

  const handleOnChange = value => {
    if (disabled) {
      return;
    }
    setIsChecked(value);
    onChange(value);
  };

  return (
    <SwitchWrap
      size={size}
      color={color}
      disabled={disabled}
      checked={isChecked}
      onClick={() => handleOnChange(!isChecked)}
      {...restProps}
    />
  );
};

Switch.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  size: PropTypes.oneOf(Object.keys(switchSizes)),
  color: PropTypes.oneOf(Object.keys(switchColors)),
  isControlled: PropTypes.bool,
};

Switch.defaultProps = {
  checked: false,
  disabled: false,
  onChange() {},
  size: switchSizes.medium,
  color: switchColors.default,
  isControlled: false,
};

export default Switch;

export {
  switchColors,
  switchSizes,
  SwitchGroup,
};