import { createAction } from 'redux-create-action';
import { ACTIONS } from './constants';

export const getSalesSummary = createAction(
  ACTIONS.GET_SALES_SUMMARY, 'params', 'successActionCallback', 'errorActionCallback',
);
export const getSalesChart = createAction(
  ACTIONS.GET_SALES_CHART, 'params', 'successActionCallback', 'errorActionCallback',
);
export const getInventoryLocationsChart = createAction(
  ACTIONS.GET_INVENTORY_LOCATIONS_CHART,
  'params',
  'successActionCallback',
  'errorActionCallback',
);
export const getInventoryLocationsSummary = createAction(
  ACTIONS.GET_INVENTORY_LOCATIONS_SUMMARY, 'successActionCallback', 'errorActionCallback',
);