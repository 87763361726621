import _reduce from 'lodash/reduce';
import _pick from 'lodash/pick';
import { formatUserLocalStorageKey, UNIQ_LOCAL_STORAGE_KEYS } from 'app/persistedState';
import formatMoney from 'utils/formatMoney';
import { numberWithCommas } from 'utils/formatNumber';

export const ACTIONS = {
  GENERATE_VISUALIZATION: 'SALES_REPORT__GENERATE_VISUALIZATION',
  GENERATE_VISUALIZATION_SUCCESS: 'SALES_REPORT__GENERATE_VISUALIZATION_SUCCESS',
  GENERATE_VISUALIZATION_ERROR: 'SALES_REPORT__GENERATE_VISUALIZATION_ERROR',

  FETCH_SALES_SUMMARY: 'SALES_REPORT__FETCH_SALES_SUMMARY',
  FETCH_SALES_SUMMARY_SUCCESS: 'SALES_REPORT__FETCH_SALES_SUMMARY_SUCCESS',
  FETCH_SALES_SUMMARY_ERROR: 'SALES_REPORT__FETCH_SALES_SUMMARY_ERROR',

  FETCH_SALES_CHART: 'SALES_REPORT__FETCH_SALES_CHART',
  FETCH_SALES_CHART_SUCCESS: 'SALES_REPORT__FETCH_SALES_CHART_SUCCESS',
  FETCH_SALES_CHART_ERROR: 'SALES_REPORT__FETCH_SALES_CHART_ERROR',

  SET_CHART_SETTINGS: 'SALES_REPORT__SET_CHART_SETTINGS',

  EXPORT_TO_FILE: 'SALES_REPORT__EXPORT_TO_FILE',
  EXPORT_TO_FILE_SUCCESS: 'SALES_REPORT__EXPORT_TO_FILE_SUCCESS',
  EXPORT_TO_FILE_ERROR: 'SALES_REPORT__EXPORT_TO_FILE_ERROR',

  SEARCH_AUTOCOMPLETE: 'SALES_REPORT__SEARCH_AUTOCOMPLETE',
  SEARCH_BRAND_SUCCESS: 'SALES_REPORT__SEARCH_BRAND_SUCCESS',
  SEARCH_VENDOR_SUCCESS: 'SALES_REPORT__SEARCH_VENDOR_SUCCESS',

  SET_COLUMNS: 'SALES_REPORT__SET_COLUMNS',
  SET_COLUMN_ENABLED: 'SALES_REPORT__SET_COLUMN_ENABLED',
  TOGGLE_COLUMNS_ENABLED: 'SALES_REPORT__TOGGLE_COLUMNS_ENABLED',
  SHOW_OPTIONS_MODAL: 'SALES_REPORT__SHOW_OPTIONS_MODAL',

  SET_START_DATE: 'SALES_REPORT__SET_START_DATE',
  SET_END_DATE: 'SALES_REPORT__SET_END_DATE',
  ADD_BRAND: 'SALES_REPORT__ADD_BRAND',
  REMOVE_BRAND: 'SALES_REPORT__REMOVE_BRAND',
  ADD_VENDOR: 'SALES_REPORT__ADD_VENDOR',
  REMOVE_VENDOR: 'SALES_REPORT__REMOVE_VENDOR',

  RESET_STATE: 'SALES_REPORT__RESET_STATE',
};

export const REPORT_COLUMNS = {
  order_id: {
    id: 'order_id',
    label: 'Order Id',
    required: false,
    enabled: true,
    cellProps: {
      align: 'left',
    },
  },
  so: {
    id: 'so',
    label: 'SO#',
    required: false,
    enabled: true,
    cellProps: {
      minWidth: '8rem',
      maxWidth: '11rem',
      align: 'center',
    },
  },
  po: {
    id: 'po',
    label: 'PO#',
    required: false,
    enabled: true,
    cellProps: {
      minWidth: '6rem',
      maxWidth: '9rem',
      align: 'center',
    },
  },
  brand: {
    id: 'brand',
    label: 'Brand',
    required: false,
    enabled: true,
    cellProps: {
      minWidth: '6rem',
      maxWidth: '9rem',
      align: 'center',
    },
  },
  vendor: {
    id: 'vendor',
    label: 'Vendor',
    required: false,
    enabled: true,
    cellProps: {
      minWidth: '6rem',
      maxWidth: '9rem',
      align: 'center',
    },
  },
  sku: {
    id: 'sku',
    label: 'SKU',
    required: false,
    enabled: true,
    cellProps: {
      minWidth: '6rem',
      maxWidth: '9rem',
      align: 'center',
    },
  },
  alt_sku: {
    id: 'alt_sku',
    label: 'Alt SKU',
    required: false,
    enabled: false,
    cellProps: {
      minWidth: '4rem',
      maxWidth: '7rem',
      align: 'center',
    },
  },
  product_name: {
    id: 'product_name',
    label: 'Product Name',
    required: false,
    enabled: true,
    cellProps: {
      minWidth: '3rem',
      maxWidth: '6rem',
      align: 'center',
    },
  },
  upc: {
    id: 'upc',
    label: 'UPC',
    required: false,
    enabled: true,
    cellProps: {
      minWidth: '3rem',
      maxWidth: '6rem',
      align: 'center',
    },
  },
  qty_purchased: {
    id: 'qty_purchased',
    label: 'Qty Purchased',
    required: false,
    enabled: true,
    cellProps: {
      minWidth: '3rem',
      maxWidth: '6rem',
      align: 'center',
    },
  },
  cost: {
    id: 'cost',
    label: 'Cost',
    required: false,
    enabled: true,
    cellProps: {
      minWidth: '3rem',
      maxWidth: '6rem',
      align: 'center',
    },
  },
  price: {
    id: 'price',
    label: 'Price',
    required: false,
    enabled: true,
    cellProps: {
      minWidth: '3rem',
      maxWidth: '6rem',
      align: 'center',
    },
  },
  markup: {
    id: 'markup',
    label: 'Markup',
    required: false,
    enabled: true,
    cellProps: {
      minWidth: '3rem',
      maxWidth: '6rem',
      align: 'center',
    },
  },
  dropship_fee: {
    id: 'dropship_fee',
    label: 'Dropship Fee',
    required: false,
    enabled: true,
    cellProps: {
      minWidth: '3rem',
      maxWidth: '6rem',
      align: 'center',
    },
  },
  edi: {
    id: 'edi',
    label: 'EDI',
    required: false,
    enabled: true,
    cellProps: {
      minWidth: '3rem',
      maxWidth: '6rem',
      align: 'center',
    },
  },
  type: {
    id: 'type',
    label: 'Type',
    required: false,
    enabled: true,
    cellProps: {
      minWidth: '3rem',
      maxWidth: '6rem',
      align: 'center',
    },
  },
  order_line_status: {
    id: 'order_line_status',
    label: 'Order Line Status',
    required: false,
    enabled: true,
    cellProps: {
      minWidth: '3rem',
      maxWidth: '6rem',
      align: 'center',
    },
  },
  track_number: {
    id: 'track_number',
    label: 'Track Number',
    required: false,
    enabled: true,
    cellProps: {
      minWidth: '3rem',
      maxWidth: '6rem',
      align: 'center',
    },
  },
  date_of_purchase: {
    id: 'date_of_purchase',
    label: 'Date of Purchase',
    required: false,
    enabled: true,
    cellProps: {
      minWidth: '3rem',
      maxWidth: '6rem',
      align: 'center',
    },
  },
  date_of_shipment: {
    id: 'date_of_shipment',
    label: 'Date of Shipment',
    required: false,
    enabled: true,
    cellProps: {
      minWidth: '3rem',
      maxWidth: '6rem',
      align: 'center',
    },
  },
  promised_ship_date: {
    id: 'promised_ship_date',
    label: 'Promised Ship Date',
    required: false,
    enabled: true,
    cellProps: {
      minWidth: '3rem',
      maxWidth: '6rem',
      align: 'center',
    },
  },
  sales_channel: {
    id: 'sales_channel',
    label: 'Sales Channel',
    required: false,
    enabled: true,
    cellProps: {
      minWidth: '3rem',
      maxWidth: '6rem',
      align: 'center',
    },
  },
  customer_name: {
    id: 'customer_name',
    label: 'Customer Name',
    required: false,
    enabled: true,
    cellProps: {
      minWidth: '3rem',
      maxWidth: '6rem',
      align: 'center',
    },
  },
  customer_address: {
    id: 'customer_address',
    label: 'Customer Address',
    required: false,
    enabled: true,
    cellProps: {
      minWidth: '3rem',
      maxWidth: '6rem',
      align: 'center',
    },
  },
  notes: {
    id: 'notes',
    label: 'Notes',
    required: false,
    enabled: true,
    cellProps: {
      minWidth: '3rem',
      maxWidth: '6rem',
      align: 'center',
    },
  },
  product_category: {
    id: 'product_category',
    label: 'Product Category',
    required: false,
    enabled: true,
    cellProps: {
      minWidth: '3rem',
      maxWidth: '6rem',
      align: 'center',
    },
  },
  product_gender: {
    id: 'product_gender',
    label: 'Product Gender',
    required: false,
    enabled: true,
    cellProps: {
      minWidth: '3rem',
      maxWidth: '6rem',
      align: 'center',
    },
  },
  warehouse: {
    id: 'warehouse',
    label: 'Warehouse',
    required: false,
    enabled: true,
    cellProps: {
      minWidth: '3rem',
      maxWidth: '6rem',
      align: 'center',
    },
  },
};

export const REPORT_COLUMNS_STORAGE_SETTINGS = _reduce(
  REPORT_COLUMNS, (result, value, key) => {
    const newResult = result;
    newResult[key] = _pick(value, ['id', 'enabled']);
    return newResult;
  }, {},
);

export const REPORT_LOCAL_STORAGE_KEY = username => formatUserLocalStorageKey(
  username, UNIQ_LOCAL_STORAGE_KEYS.SALES_REPORT,
);

export const REPORT_ERRORS = {
  VENDOR_NOT_FOUND: 'Vendor not found',
  BRAND_NOT_FOUND: 'Brand not found',
};

export const REPORT_FIELDS = {
  VENDOR: 'vendor',
  BRAND: 'brand',
};

export const SORT_BY_OPTIONS = [
  {
    label: 'Items Ordered',
    value: 'items_ordered',
    labelFormat: '',
    axisLeftFormat: '',
    valueFormatter: numberWithCommas,
  },
  {
    label: 'Revenue',
    value: 'revenue',
    labelFormat: '$,.2f',
    axisLeftFormat: '.2s',
    valueFormatter: formatMoney,
  },
];

export const LIMIT_OPTIONS = [
  {
    label: 'Top 5',
    value: 5,
  },
  {
    label: 'Top 10',
    value: 10,
  },
];

export const INDEX_BY_OPTIONS = [
  { label: 'Brands', value: 'brand' },
  { label: 'Vendors', value: 'vendor' },
  { label: 'SKU\'s', value: 'sku' },
  { label: 'Sales Channels', value: 'sales_channel' },
];

export const CHART_TYPE_OPTIONS = [
  { label: 'Bar Chart', value: 'bar' },
  { label: 'Line Chart', value: 'line' },
  { label: 'Pie Chart', value: 'pie' },
];

export const LINE_CHART_INTERVAL_OPTIONS = [
  { label: 'Daily', value: 'daily' },
  { label: 'Weekly', value: 'weekly' },
  { label: 'Monthly', value: 'monthly' },
  { label: 'Yearly', value: 'yearly' },
];