import { createSelector } from 'reselect';
import _get from 'lodash/get';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import { getSessionUserName } from 'app/session/selectors';
import { inventoryManagementState } from 'features/InventoryManagement/selectors';

const zoneId = 'shippingZone';
const targetZone = 'pickingZone';
const pickingState = state => _get(inventoryManagementState(state), 'picking');
const zoneState = (state, zone = zoneId) => _get(pickingState(state), zone);

export const getFieldUPCNumber = state => _get(zoneState(state), 'fieldUPCNumber');
export const getIsScanningUPC = state => _get(zoneState(state), 'isScanningUPC');
export const getIsMoving = state => _get(zoneState(state), 'isMoving');

export const getZoneItems = state => _get(zoneState(state), 'zoneItems', []);
export const findZoneItemByUPC = upc => state => _find(getZoneItems(state), { upc }) || {};

export const getChangedItems = state => _get(zoneState(state), 'changedItems', []);
export const getChangedItemsSelected = createSelector(
  getChangedItems,
  items => _filter(items, { selected: true }),
);

// Other Zone Selectors
export const getCanUserEditTheOtherZone = state => {
  const zoneLockOwner = _get(zoneState(state, targetZone), 'zone.lock.owner');
  return zoneLockOwner === getSessionUserName(state);
};
export const getOtherZone = state => _get(zoneState(state, targetZone), 'zone');
export const getOtherZoneName = state => _get(zoneState(state, targetZone), 'zone.location');
export const getIsFetchingOtherZone = state => _get(zoneState(state, targetZone), 'isFetchingZone');
export const selectSelectedItems = state => _get(zoneState(state), 'selectedItems', []);