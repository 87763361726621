import styled, { keyframes } from 'styled-components';
import { PORCELAIN } from 'constants/colors';

const shimmer = keyframes`
  100% {
    transform: translateX(100%);
  }
`;

const white = '255,255,255';

const Skeleton = styled.label`
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  background: #ddd;
  border-radius: 7px;
  background-color: ${PORCELAIN};
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(90deg, rgba(${white}, 0) 0, rgba(${white}, 0.2) 20%, rgba(${white}, 0.5) 60%, rgba(${white}, 0));
    animation: ${shimmer} 2s infinite;
  }
`;

export default Skeleton;