import { createAction } from 'redux-create-action';
import { ACTIONS } from './constants';

export const initContainer = createAction(ACTIONS.INIT_CONTAINER);
export const exitContainer = createAction(ACTIONS.EXIT_CONTAINER);

export const loadShippingRules = createAction(ACTIONS.LOAD_SHIPPING_RULES);
export const loadShippingRulesSuccess = createAction(ACTIONS.LOAD_SHIPPING_RULES_SUCCESS, 'payload');
export const loadShippingRulesError = createAction(ACTIONS.LOAD_SHIPPING_RULES_ERROR, 'errorMessage', 'error');
export const loadFeed = createAction(ACTIONS.LOAD_FEED);

export const loadShipSetup = createAction(ACTIONS.LOAD_SHIP_SETUP);
export const loadShipSetupSuccess = createAction(ACTIONS.LOAD_SHIP_SETUP_SUCCESS, 'payload');
export const loadShipSetupError = createAction(ACTIONS.LOAD_SHIP_SETUP_ERROR, 'errorMessage', 'error');

export const showOptionsModal = createAction(ACTIONS.SHOW_OPTIONS_MODAL, 'showOptionsModal');
export const refreshFeed = createAction(ACTIONS.REFRESH_FEED);
export const setFilterOperand = createAction(ACTIONS.SET_FILTER_OPERAND, 'operand');
export const unsetFilterOperand = createAction(ACTIONS.UNSET_FILTER_OPERAND, 'groupIndex', 'filterIndex');
export const clearFilters = createAction(ACTIONS.CLEAR_FILTERS);
export const setFilters = createAction(ACTIONS.SET_FILTERS, 'filters');
export const arrangeFilters = createAction(ACTIONS.ARRANGE_FILTERS, 'destination', 'source');

export const exportToFile = createAction(ACTIONS.EXPORT_TO_FILE);
export const exportToFileSuccess = createAction(ACTIONS.EXPORT_TO_FILE_SUCCESS, 'payload');
export const exportToFileError = createAction(ACTIONS.EXPORT_TO_FILE_ERROR, 'errorMessage', 'error');

export const setColumns = createAction(ACTIONS.SET_COLUMNS, 'columns');
export const setColumnsOrder = createAction(ACTIONS.SET_COLUMNS_ORDER, 'columnsOrder');
export const setColumnEnabled = createAction(ACTIONS.SET_COLUMN_ENABLED, 'columnId', 'enabled');
export const setItemsPerPage = createAction(ACTIONS.SET_ITEMS_PER_PAGE, 'itemsPerPage');
export const sortFeed = createAction(ACTIONS.SORT_FEED, 'columnId', 'direction');
export const goToPage = createAction(ACTIONS.GO_TO_PAGE, 'lastPage');

export const addShippingRule = createAction(ACTIONS.ADD_SHIPPING_RULE);
export const cancelAddShippingRule = createAction(ACTIONS.CANCEL_ADD_SHIPPING_RULE);
export const editShippingRule = createAction(ACTIONS.EDIT_SHIPPING_RULE, 'selectedShippingRule');
export const cancelEditShippingRule = createAction(ACTIONS.CANCEL_EDIT_SHIPPING_RULE);
export const deleteShippingRule = createAction(ACTIONS.DELETE_SHIPPING_RULE, 'selectedShippingRule');
export const cancelDeleteShippingRule = createAction(ACTIONS.CANCEL_DELETE_SHIPPING_RULE);

export const removeShippingRule = createAction(ACTIONS.REMOVE_SHIPPING_RULE);
export const removeShippingRuleSuccess = createAction(ACTIONS.REMOVE_SHIPPING_RULE_SUCCESS, 'payload');
export const removeShippingRuleError = createAction(ACTIONS.REMOVE_SHIPPING_RULE_ERROR, 'errorMessage', 'error');

export const createShippingRule = createAction(ACTIONS.CREATE_SHIPPING_RULE, 'shippingRuleValues');
export const createShippingRuleSuccess = createAction(ACTIONS.CREATE_SHIPPING_RULE_SUCCESS, 'payload');
export const createShippingRuleError = createAction(ACTIONS.CREATE_SHIPPING_RULE_ERROR, 'errorMessage', 'error');

export const updateShippingRule = createAction(ACTIONS.UPDATE_SHIPPING_RULE, 'shippingRuleValues');
export const updateShippingRuleSuccess = createAction(ACTIONS.UPDATE_SHIPPING_RULE_SUCCESS, 'payload');
export const updateShippingRuleError = createAction(ACTIONS.UPDATE_SHIPPING_RULE_ERROR, 'errorMessage', 'error');

export const fetchBrandsSuppliers = createAction(ACTIONS.FETCH_BRANDS_SUPPLIERS);
export const fetchBrandsSuppliersSuccess = createAction(ACTIONS.FETCH_BRANDS_SUPPLIERS_SUCCESS, 'payload');
export const fetchBrandsSuppliersError = createAction(ACTIONS.FETCH_BRANDS_SUPPLIERS_ERROR, 'errorMessage', 'error');