import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import dashboardReducers from 'containers/Dashboard/reducers';
import baseReducers from 'containers/Base/reducers';
import { ACTIONS } from 'containers/Base/Login/constants';
import featuresReducers from 'features/reducers';
import servicesReducers from 'services/reducers';
import sessionReducer from './session/reducers';
import globalReducers from './globalState/reducers';

const appReducers = browserHistory => combineReducers({
  router: connectRouter(browserHistory),
  global: globalReducers,
  session: sessionReducer,
  base: baseReducers,
  dashboard: dashboardReducers,
  features: featuresReducers,
  services: servicesReducers,
});

const reducers = browserHistory => (state, action) => {
  // to reset the entire store when logout
  let storeState = state;
  if (action.type === ACTIONS.SIGN_OFF_SUCCESS) {
    storeState = undefined;
  }
  return appReducers(browserHistory)(storeState, action);
};

export default reducers;
