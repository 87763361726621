import {
  put, all, takeEvery, call, select,
} from 'redux-saga/effects';
import _get from 'lodash/get';
import { toast } from 'react-toastify';
import { setReplaceQty } from 'services/inventory/actions';
import { INVENTORY_MANAGEMENT_ERRORS } from 'features/InventoryManagement/constants';
import { ACTIONS } from '../constants';
import {
  scanUPCSuccess,
  scanUPCError,
  reloadZone,
} from '../actions';
import {
  getZoneName,
  findZoneItemByUPC,
} from '../selectors';
import { buildZoneItem } from './sagaWorkers';

function* scanUPCWatcher({ upc }) {
  const zoneName = yield select(getZoneName);
  const existingItem = yield select(findZoneItemByUPC(upc));
  const existingItemQty = _get(existingItem, 'qty', 0);
  const qty = Number(existingItemQty) + 1;
  const itemInventory = yield call(buildZoneItem, upc, qty);
  const collection = [itemInventory];

  yield put(setReplaceQty(zoneName, collection, scanUPCSuccess, scanUPCError));
}

function* scanUPCSuccessWatcher() {
  yield call([toast, toast.success], 'Item successfully scanned');
}

function* scanUPCErrorWatcher({ errorMessage }) {
  yield put(reloadZone());
  yield call([toast, toast.error], errorMessage || INVENTORY_MANAGEMENT_ERRORS.SCANNING_UPC);
}

export default function* scanUPCSagas() {
  yield all([
    takeEvery(ACTIONS.SCAN_UPC, scanUPCWatcher),
    takeEvery(ACTIONS.SCAN_UPC_SUCCESS, scanUPCSuccessWatcher),
    takeEvery(ACTIONS.SCAN_UPC_ERROR, scanUPCErrorWatcher),
  ]);
}