import {
  all, takeEvery, put, call, select,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { getOrderDetails, postOrderNote } from 'services/orders/actions';
import { ACTIONS } from '../constants';
import {
  loadOrderDetailsSuccess,
  loadOrderDetailsError,
  addOrderNoteSuccess,
  addOrderNoteError,
  loadOrderDetails,
} from '../actions';
import {
  selectOrderDetailsOrderId,
} from '../selectors';

function* loadOrderDetailsWatcher({ orderId }) {
  yield put(getOrderDetails(orderId, loadOrderDetailsSuccess, loadOrderDetailsError));
}

function* loadOrderDetailsErrorWatcher({ errorMessage }) {
  yield call([toast, toast.error], errorMessage);
}

function* addOrderNoteWatcher({ orderNote }) {
  const orderId = yield select(selectOrderDetailsOrderId);
  yield put(postOrderNote(orderId, orderNote, addOrderNoteSuccess, addOrderNoteError));
}

function* addOrderNoteSuccessWatcher() {
  const orderId = yield select(selectOrderDetailsOrderId);

  if (orderId) {
    yield put(loadOrderDetails(orderId));
  }

  yield call([toast, toast.success], 'New order note added successfully.');
}

export default function* orderDetailsSagas() {
  yield all([
    takeEvery(ACTIONS.LOAD_ORDER_DETAILS, loadOrderDetailsWatcher),
    takeEvery(ACTIONS.LOAD_ORDER_DETAILS_ERROR, loadOrderDetailsErrorWatcher),
    takeEvery(ACTIONS.ADD_ORDER_NOTE, addOrderNoteWatcher),
    takeEvery(ACTIONS.ADD_ORDER_NOTE_SUCCESS, addOrderNoteSuccessWatcher),
  ]);
}