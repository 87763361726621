export const ACTIONS = {
  DOWNLOAD_SAMPLE: 'INVENTORY_LABEL_GENERATOR__DOWNLOAD_SAMPLE',
  DOWNLOAD_SAMPLE_SUCCESS: 'INVENTORY_LABEL_GENERATOR__DOWNLOAD_SAMPLE_SUCCESS',
  DOWNLOAD_SAMPLE_ERROR: 'INVENTORY_LABEL_GENERATOR__DOWNLOAD_SAMPLE_ERROR',

  UPLOAD_FILE: 'INVENTORY_LABEL_GENERATOR__UPLOAD_FILE',
  UPLOAD_FILE_SUCCESS: 'INVENTORY_LABEL_GENERATOR__UPLOAD_FILE_SUCCESS',
  UPLOAD_FILE_ERROR: 'INVENTORY_LABEL_GENERATOR__UPLOAD_FILE_ERROR',

  RESET_STATE: 'INVENTORY_LABEL_GENERATOR__RESET_STATE',
};