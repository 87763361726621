export const ACTIONS = {
  DOWNLOAD_TEMPLATE: 'PO_CREATOR__DOWNLOAD_TEMPLATE',
  DOWNLOAD_TEMPLATE_SUCCESS: 'PO_CREATOR__DOWNLOAD_TEMPLATE_SUCCESS',
  DOWNLOAD_TEMPLATE_ERROR: 'PO_CREATOR__DOWNLOAD_TEMPLATE_ERROR',

  VALIDATE_UPLOAD_FILE: 'PO_CREATOR__VALIDATE_UPLOAD_FILE',
  VALIDATE_UPLOAD_FILE_SUCCESS: 'PO_CREATOR__VALIDATE_UPLOAD_FILE_SUCCESS',
  VALIDATE_UPLOAD_FILE_ERROR: 'PO_CREATOR__VALIDATE_UPLOAD_FILE_ERROR',
  SET_UPLOAD_FILE_IS_VALID: 'PO_CREATOR__SET_UPLOAD_FILE_IS_VALID',
  SET_UPLOAD_FILE_VALIDATION_ERRORS: 'PO_CREATOR__SET_UPLOAD_FILE_VALIDATION_ERRORS',

  UPLOAD_FILE: 'PO_CREATOR__UPLOAD_FILE',
  UPLOAD_FILE_SUCCESS: 'PO_CREATOR__UPLOAD_FILE_SUCCESS',
  UPLOAD_FILE_ERROR: 'PO_CREATOR__UPLOAD_FILE_ERROR',

  RESET_STATE: 'PO_CREATOR__RESET_STATE',
};