import {
  all, takeEvery,
} from 'redux-saga/effects';
import { brandsApiSelector } from 'app/api/selectors';
import { serviceSaga } from 'services/sagaWorkers';
import { authRequired } from 'services/auth/sagas';
import { ACTIONS } from './constants';

const listBrandsWatcher = action => serviceSaga(brandsApiSelector, 'listBrands', action);
const addBrandWatcher = action => serviceSaga(brandsApiSelector, 'addBrand', action);
const updateBrandWatcher = action => serviceSaga(brandsApiSelector, 'updateBrand', action);
const deleteBrandWatcher = action => serviceSaga(brandsApiSelector, 'deleteBrand', action);

export default function* brandsSagas() {
  yield all([
    takeEvery(ACTIONS.LIST_BRANDS, authRequired, listBrandsWatcher),
    takeEvery(ACTIONS.ADD_BRAND, authRequired, addBrandWatcher),
    takeEvery(ACTIONS.UPDATE_BRAND, authRequired, updateBrandWatcher),
    takeEvery(ACTIONS.DELETE_BRAND, authRequired, deleteBrandWatcher),
  ]);
}