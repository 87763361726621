import _reduce from 'lodash/reduce';
import _pick from 'lodash/pick';
import { formatUserLocalStorageKey, UNIQ_LOCAL_STORAGE_KEYS } from 'app/persistedState';

export const ACTIONS = {
  INIT_CONTAINER: 'PRODUCTS__INIT_CONTAINER',
  EXIT_CONTAINER: 'PRODUCTS__EXIT_CONTAINER',

  LOAD_PRODUCTS: 'PRODUCTS__LOAD_PRODUCTS',
  LOAD_PRODUCTS_SUCCESS: 'PRODUCTS__LOAD_PRODUCTS_SUCCESS',
  LOAD_PRODUCTS_ERROR: 'PRODUCTS__LOAD_PRODUCTS_ERROR',
  LOAD_FEED: 'PRODUCTS__LOAD_FEED',

  SHOW_OPTIONS_MODAL: 'PRODUCTS__SHOW_OPTIONS_MODAL',
  SET_SHOW_DOWNLOAD_MODAL: 'PRODUCTS__SET_SHOW_DOWNLOAD_MODAL',
  REFRESH_FEED: 'PRODUCTS__REFRESH_FEED',
  SET_FILTER_OPERAND: 'PRODUCTS__SET_FILTER_OPERAND',
  UNSET_FILTER_OPERAND: 'PRODUCTS__UNSET_FILTER_OPERAND',
  CLEAR_FILTERS: 'PRODUCTS__CLEAR_FILTERS',
  SET_FILTERS: 'PRODUCTS__SET_FILTERS',
  ARRANGE_FILTERS: 'PRODUCTS__ARRANGE_FILTERS',

  SET_COLUMNS: 'PRODUCTS__SET_COLUMNS',
  SET_COLUMNS_ORDER: 'PRODUCTS__SET_COLUMNS_ORDER',
  SET_COLUMN_ENABLED: 'PRODUCTS__SET_COLUMN_ENABLED',
  SET_ITEMS_PER_PAGE: 'PRODUCTS__SET_ITEMS_PER_PAGE',
  SORT_FEED: 'PRODUCTS__SORT_FEED',
  GO_TO_PAGE: 'PRODUCTS__GO_TO_PAGE',

  EXPORT_TO_FILE: 'PRODUCTS__EXPORT_TO_FILE',
  EXPORT_TO_FILE_SUCCESS: 'PRODUCTS__EXPORT_TO_FILE_SUCCESS',
  EXPORT_TO_FILE_ERROR: 'PRODUCTS__EXPORT_TO_FILE_ERROR',

  ADD_PRODUCT: 'PRODUCTS__ADD_PRODUCT',
  CANCEL_ADD_PRODUCT: 'PRODUCTS__CANCEL_ADD_PRODUCT',
  EDIT_PRODUCT: 'PRODUCTS__EDIT_PRODUCT',
  CANCEL_EDIT_PRODUCT: 'PRODUCTS__CANCEL_EDIT_PRODUCT',
  DELETE_PRODUCT: 'PRODUCTS__DELETE_PRODUCT',
  CANCEL_DELETE_PRODUCT: 'PRODUCTS__CANCEL_DELETE_PRODUCT',

  REMOVE_PRODUCT: 'PRODUCTS__REMOVE_PRODUCT',
  REMOVE_PRODUCT_SUCCESS: 'PRODUCTS__REMOVE_PRODUCT_SUCCESS',
  REMOVE_PRODUCT_ERROR: 'PRODUCTS__REMOVE_PRODUCT_ERROR',

  CREATE_PRODUCT: 'PRODUCTS__CREATE_PRODUCT',
  CREATE_PRODUCT_SUCCESS: 'PRODUCTS__CREATE_PRODUCT_SUCCESS',
  CREATE_PRODUCT_ERROR: 'PRODUCTS__CREATE_PRODUCT_ERROR',

  UPDATE_PRODUCT: 'PRODUCTS__UPDATE_PRODUCT',
  UPDATE_PRODUCT_SUCCESS: 'PRODUCTS__UPDATE_PRODUCT_SUCCESS',
  UPDATE_PRODUCT_ERROR: 'PRODUCTS__UPDATE_PRODUCT_ERROR',

  FETCH_DOWNLOAD_LOGS: 'PRODUCTS__FETCH_DOWNLOAD_LOGS',
  FETCH_DOWNLOAD_LOGS_SUCCESS: 'PRODUCTS__FETCH_DOWNLOAD_LOGS_SUCCESS',
  FETCH_DOWNLOAD_LOGS_ERROR: 'PRODUCTS__FETCH_DOWNLOAD_LOGS_ERROR',

  REQUEST_DOWNLOAD_PRODUCTS: 'PRODUCTS__REQUEST_DOWNLOAD_PRODUCTS',
  REQUEST_DOWNLOAD_PRODUCTS_SUCCESS: 'PRODUCTS__REQUEST_DOWNLOAD_PRODUCTS_SUCCESS',
  REQUEST_DOWNLOAD_PRODUCTS_ERROR: 'PRODUCTS__REQUEST_DOWNLOAD_PRODUCTS_ERROR',

  DOWNLOAD_PRODUCTS_FILE: 'PRODUCTS__DOWNLOAD_PRODUCTS_FILE',
  DOWNLOAD_PRODUCTS_FILE_SUCCESS: 'PRODUCTS__DOWNLOAD_PRODUCTS_FILE_SUCCESS',
  DOWNLOAD_PRODUCTS_FILE_ERROR: 'PRODUCTS__DOWNLOAD_PRODUCTS_FILE_ERROR',

  CANCEL_DOWNLOAD_REQUEST: 'PRODUCTS__CANCEL_DOWNLOAD_REQUEST',
  CANCEL_DOWNLOAD_REQUEST_SUCCESS: 'PRODUCTS__CANCEL_DOWNLOAD_REQUEST_SUCCESS',
  CANCEL_DOWNLOAD_REQUEST_ERROR: 'PRODUCTS__CANCEL_DOWNLOAD_REQUEST_ERROR',
};

export const PRODUCTS_LOCAL_STORAGE_KEY = username => formatUserLocalStorageKey(
  username, UNIQ_LOCAL_STORAGE_KEYS.PRODUCTS_VIEW,
);

export const PRODUCT_FIELDS = {
  SKU: 'sku',
  UPC: 'upc',
  BRAND: 'brand',
  SUPPLIER: 'supplier',
  GENDER: 'gender',
  PRODUCT_TITLE: 'product_title',
  CATEGORY: 'category',
  DESCRIPTION: 'description',
  SIZE: 'size',
  COLOR: 'color',
  PRICE: 'price',
  COST: 'cost',
};

export const PRODUCTS_COLUMNS = {
  [PRODUCT_FIELDS.SKU]: {
    id: PRODUCT_FIELDS.SKU,
    enabled: true,
    label: 'SKU',
    sortable: true,
    filterable: true,
    filterableContextMenu: true,
    copyable: true,
    editable: false,
    cellProps: {
      align: 'left',
      minWidth: '300px',
    },
  },
  [PRODUCT_FIELDS.UPC]: {
    id: PRODUCT_FIELDS.UPC,
    enabled: true,
    label: 'UPC',
    sortable: true,
    filterable: true,
    filterableContextMenu: true,
    copyable: true,
    editable: false,
    cellProps: {
      align: 'left',
      minWidth: '180px',
    },
  },
  [PRODUCT_FIELDS.BRAND]: {
    id: PRODUCT_FIELDS.BRAND,
    enabled: true,
    label: 'Brand',
    sortable: true,
    filterable: true,
    filterableContextMenu: true,
    copyable: true,
    editable: false,
    cellProps: {
      align: 'left',
      minWidth: '250px',
    },
  },
  [PRODUCT_FIELDS.SUPPLIER]: {
    id: PRODUCT_FIELDS.SUPPLIER,
    enabled: true,
    label: 'Supplier',
    sortable: true,
    filterable: true,
    filterableContextMenu: true,
    copyable: true,
    editable: false,
    cellProps: {
      align: 'left',
      minWidth: '250px',
    },
  },
  [PRODUCT_FIELDS.GENDER]: {
    id: PRODUCT_FIELDS.GENDER,
    enabled: true,
    label: 'Gender',
    sortable: true,
    filterable: true,
    filterableContextMenu: true,
    copyable: true,
    editable: true,
    cellProps: {
      align: 'left',
      minWidth: '110px',
    },
  },
  [PRODUCT_FIELDS.PRODUCT_TITLE]: {
    id: PRODUCT_FIELDS.PRODUCT_TITLE,
    enabled: true,
    label: 'Product Title',
    sortable: false,
    filterable: false,
    filterableContextMenu: false,
    copyable: true,
    editable: false,
    cellProps: {
      align: 'left',
      minWidth: '300px',
    },
  },
  [PRODUCT_FIELDS.CATEGORY]: {
    id: PRODUCT_FIELDS.CATEGORY,
    enabled: true,
    label: 'Category',
    sortable: true,
    filterable: true,
    filterableContextMenu: true,
    copyable: true,
    editable: false,
    cellProps: {
      align: 'left',
      minWidth: '170px',
    },
  },
  [PRODUCT_FIELDS.DESCRIPTION]: {
    id: PRODUCT_FIELDS.DESCRIPTION,
    enabled: true,
    label: 'Description',
    sortable: false,
    filterable: false,
    filterableContextMenu: false,
    copyable: true,
    editable: false,
    cellProps: {
      align: 'left',
      minWidth: '325px',
    },
  },
  [PRODUCT_FIELDS.SIZE]: {
    id: PRODUCT_FIELDS.SIZE,
    enabled: true,
    label: 'Size',
    sortable: true,
    filterable: true,
    filterableContextMenu: true,
    copyable: true,
    editable: false,
    cellProps: {
      align: 'left',
      minWidth: '100px',
    },
  },
  [PRODUCT_FIELDS.COLOR]: {
    id: PRODUCT_FIELDS.COLOR,
    enabled: true,
    label: 'Color',
    sortable: true,
    filterable: true,
    filterableContextMenu: true,
    copyable: true,
    editable: false,
    cellProps: {
      align: 'left',
      minWidth: '100px',
    },
  },
  [PRODUCT_FIELDS.PRICE]: {
    id: PRODUCT_FIELDS.PRICE,
    enabled: true,
    label: 'Price',
    sortable: true,
    filterable: true,
    filterableContextMenu: true,
    copyable: true,
    editable: false,
    cellProps: {
      align: 'left',
      minWidth: '100px',
    },
  },
  [PRODUCT_FIELDS.COST]: {
    id: PRODUCT_FIELDS.COST,
    enabled: true,
    label: 'Cost',
    sortable: true,
    filterable: true,
    filterableContextMenu: true,
    copyable: true,
    editable: false,
    cellProps: {
      align: 'left',
      minWidth: '100px',
    },
  },
};

export const PRODUCTS_COLUMNS_STORAGE_SETTINGS = _reduce(
  PRODUCTS_COLUMNS, (result, value, key) => {
    const newResult = result;
    newResult[key] = _pick(value, ['id', 'enabled']);
    return newResult;
  }, {},
);

export const PRODUCTS_COLUMNS_DEFAULT_ORDER = [
  PRODUCT_FIELDS.BRAND,
  PRODUCT_FIELDS.SUPPLIER,
  PRODUCT_FIELDS.SKU,
  PRODUCT_FIELDS.UPC,
  PRODUCT_FIELDS.PRODUCT_TITLE,
  PRODUCT_FIELDS.DESCRIPTION,
  PRODUCT_FIELDS.CATEGORY,
  PRODUCT_FIELDS.GENDER,
  PRODUCT_FIELDS.SIZE,
  PRODUCT_FIELDS.COLOR,
  PRODUCT_FIELDS.PRICE,
  PRODUCT_FIELDS.COST,
];