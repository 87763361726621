import React, { useState } from 'react';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import { FaFlagCheckered } from 'react-icons/fa';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from 'components/Dialog/DialogContent';
import DialogTitle from 'components/Dialog/DialogTitle';
import Button, { buttonColors, ButtonGroup } from 'components/Button';
import IconButton, { circleButtonColors, circleButtonSizes } from 'components/CircleButton';
import Grid from '@material-ui/core/Grid';
import List, { ListItem } from 'components/List';
import Switch, { switchColors } from 'components/Switch';
import { isFeatureEnabled, resetFeatureFlags, setFeatureFlagValue } from '.';
import { FEATURE_FLAGS } from './constants';

const FeatureFlagControlWrap = styled.div`
  position: fixed;
  right: -2rem;
  bottom: -2rem;
  z-index: 101;

  transition: right 0.3s ease, bottom 0.3s ease;
  &:hover {
    right: 0;
    bottom: 0;
  }
  ${({ isActive }) => isActive && `
    right: 0;
    bottom: 0;
  `}
`;

const FeatureFlagsControl = () => {
  const [showControl, setShowControl] = useState(false);
  const featureFlags = Object.values(FEATURE_FLAGS);

  const onRefresh = () => window.location.reload();

  const onReset = () => {
    resetFeatureFlags();
    onRefresh();
  };

  return (
    <>
      <FeatureFlagControlWrap isActive={showControl}>
        <IconButton
          color={circleButtonColors.warning}
          size={circleButtonSizes.large}
          onClick={() => setShowControl(true)}
        >
          <FaFlagCheckered />
        </IconButton>
      </FeatureFlagControlWrap>
      {showControl && (
        <Dialog
          open
          onClose={() => setShowControl(false)}
          maxWidth="xs"
          fullWidth
        >
          <DialogTitle onClose={() => setShowControl(false)}>
            <Grid container>
              <h3>Feature Flags</h3>
            </Grid>
            <Grid container>
              <span>Choose the value for each flag and then REFRESH the page.</span>
            </Grid>
          </DialogTitle>
          <DialogContent padding="0 0 1rem 0">
            <List>
              {featureFlags.map(flag => (
                <ListItem key={flag}>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    style={{ padding: '0.2rem 1rem' }}
                  >
                    <span>{flag}</span>
                    <Switch
                      color={switchColors.info}
                      checked={isFeatureEnabled(flag)}
                      onChange={value => setFeatureFlagValue(flag, value)}
                    />
                  </Grid>
                </ListItem>
              ))}
            </List>
          </DialogContent>
          <DialogActions>
            <ButtonGroup contentAlign="right" padding="0 1rem">
              <Button
                color={buttonColors.danger}
                onClick={() => onReset()}
              >
                <span>Reset to Config</span>
              </Button>
              <Button
                color={buttonColors.success}
                onClick={() => onRefresh()}
              >
                <span>Reload Page</span>
              </Button>
            </ButtonGroup>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default FeatureFlagsControl;
