import { combineReducers } from 'redux';
import loginReducers from './Login/reducers';
import resetPasswordReducers from './ResetPassword/reducers';
import newPasswordReducers from './NewPassword/reducers';
import { ACTIONS } from './constants';

const reducers = combineReducers({
  login: loginReducers,
  resetPassword: resetPasswordReducers,
  newPassword: newPasswordReducers,
});

const baseReducers = (state, action) => {
  let reducerState = state;
  if (action.type === ACTIONS.RESET_STATE) {
    reducerState = undefined;
  }
  return reducers(reducerState, action);
};

export default baseReducers;
