export const TESTID_ADD_ORDER = {
  STEP_ORDER_TYPE: 'add_order_section_order_type',
  STEP_SHIPPING_ADDRESS: 'add_order_section_shipping_address',
  STEP_ADD_ITEMS: 'add_order_section_add_items',
  STEP_COMPLETE_ORDER: 'add_order_section_complete_order',
  BTN_CONTINUE: 'add_order_btn_continue',
  BTN_BACK: 'add_order_btn_back',
  BTN_SCAN_UPC: 'add_order_btn_scan_upc',
  BTN_CONFIRM_PAYMENT: 'add_order_btn_confirm_payment',
  BTN_ADD_TRANSACTION: 'add_order_btn_add_transaction',
  BTN_CONFIRM_TRANSACTION: 'add_order_btn_confirm_transaction',
  BTN_CONFIRM_ORDER: 'add_order_btn_confirm_order',
  BTN_CANCEL_ORDER: 'add_order_btn_cancel_order',
  FIELD_SHIP_NAME: 'add_order_field_ship_name',
  FIELD_SHIP_PHONE: 'add_order_field_ship_phone',
  FIELD_SHIP_ADDRESS_1: 'add_order_field_ship_address_1',
  FIELD_SHIP_ADDRESS_2: 'add_order_field_ship_address_2',
  FIELD_SHIP_ADDRESS_3: 'add_order_field_ship_address_3',
  FIELD_SHIP_COUNTRY: 'add_order_field_ship_country',
  FIELD_SHIP_POSTAL_CODE: 'add_order_field_ship_postal_code',
  FIELD_SHIP_CITY: 'add_order_field_ship_city',
  FIELD_SHIP_STATE: 'add_order_field_ship_state',
  FIELD_SCAN_UPC: 'add_order_field_scan_upc',
  FIELD_REF_ORDER: 'add_order_field_ref_order',
  FIELD_TRANSACTION_ID: 'add_order_field_transaction_id',
  FIELD_ORDER_ID: 'add_order_field_order_id',
  FIELD_ITEM_QTY: upc => `add_order_field_item_qty_${upc}`,
  FIELD_ITEM_DISCOUNT: upc => `add_order_field_item_discount_${upc}`,
  FIELD_ITEM_SALES_TAX: upc => `add_order_field_item_sales_tax_${upc}`,
  ITEM_ROW: upc => `add_order_item_${upc}_row`,
  ITEM_BTN_REMOVE: upc => `add_order_item_${upc}_btn_remove`,
  MODAL_CONFIRM_PAYMENT: 'add_order_modal_confirm_payment',
  CHIP_TRANSACTION_ID: id => `add_order_chip_transaction_id_${id}`,
  ORDER_TYPE_BUTTON: id => `order_type_${id}`,
};

export const PURCHASE_ORDER_CREATOR = {
  FIELD_CHOOSE_FILE: 'po_creator_field_choose_file',
  BTN_UPLOAD: 'po_creator_btn_upload',
  ERROR_MESSAGE: 'po_creator_error_message',
  SUCCESS_MESSAGE: 'po_creator_success_message',
};

export const RECEIVING = {
  BTN_SCAN_PO_NUMBER: 'receiving_btn_scan_po_number',
  BTN_SCAN_UPC: 'receiving_btn_scan_upc',
  BTN_COMMIT_ITEMS: 'receiving_btn_commit_items',
  FIELD_SCAN_PO_NUMBER: 'receiving_field_scan_po_number',
  FIELD_SCAN_UPC: 'receiving_field_scan_upc',
  FIELD_SHIPMENT_TOTAL_COST: 'receiving_field_shipment_total_cost',
  FIELD_ITEM_VOLUME: 'receiving_field_item_volume',
  LABEL_ITEM_SHIPMENT_COST: 'receiving_label_item_shipment_cost',
  LABEL_LINE_SHIPMENT_VOLUME: 'receiving_label_line_shipment_volume',
  LABEL_LINE_SHIPMENT_COST: 'receiving_label_line_shipment_cost',
  LABEL_SHIPMENT_TOTAL_VOLUME: 'receiving_label_shipment_total_volume',
  LABEL_SCANNED_PO_NUMBER: 'receiving_label_scanned_po_number',
  LINE_UPC: 'receiving_line_upc',
  LINE_PURCHASED_COUNT: 'receiving_line_purchased_count',
  LINE_SCANNED_COUNT: 'receiving_line_scanned_count',
  PO_ITEM_ROW: 'receiving_po_item_row',
};

export const LOGIN = {
  CONTENT_BOX: 'login_content_box',
  BTN_SIGN_IN: 'login_btn_sign_in',
  ERROR_MESSAGE: 'login_error_message',
  LINK_FORGOT_PASSWORD: 'link_forgot_password',
  FIELD_USERNAME: 'login_field_username',
  FIELD_PASSWORD: 'login_field_password',
};

export const LOGOUT = {
  MODAL: 'logout_modal',
};

export const APP_BAR = {
  NAVBAR: 'app_bar_navbar',
  BTN_ACCOUNT_SETTINGS: 'app_bar_btn_account_settings',
  BTN_AVATAR: 'app_bar_btn_avatar',
  BTN_LOGOUT: 'app_bar_btn_logout',
  BTN_DRAWER: 'app_bar_btn_drawer',
  DROPDOWN_MENU: 'app_bar_dropdown_menu',
};

export const DRAWER = {
  MENU: 'drawer_menu',
  MENU_ITEM: name => `drawer_menu_item_${name}`,
};

export const TESTID_COMPONENT = {
  SIDEBAR_CONTENT: 'ui_sidebar_content',
  FIELD_AUTOCOMPLETE_DROPDOWN: 'ui_field_autocomplete_dropdown',
  FIELD_AUTOCOMPLETE_OPTION: option => `ui_field_autocomplete_option_${option}`,
  BTN_LOCK: 'ui_btn_lock',
  BTN_LOCK_LABEL: 'ui_btn_lock_label',
  SELECT_MENU_ITEM: optionValue => `MuiSelect_MenuItem_${optionValue}`,
};

export const USERS_MANAGEMENT = {
  SECTION_TITLE: 'users_management_section_title',
  BTN_ADD_USER: 'users_management_btn_add_user',
  BTN_EDIT_USER: username => `users_management_btn_edit_user_${username}`,
  BTN_DELETE_USER: username => `users_management_btn_delete_user_${username}`,
  BTN_ADD_ROLE: 'users_management_btn_add_role',
  BTN_SUBMIT_USER: 'users_management_btn_submit_user',
  BTN_CONFIRM_DELETE_USER: 'users_management_btn_confirm_delete_user',
  LIST_ITEM_LABEL_FULL_NAME: username => `users_management_list_item_label_full_name_${username}`,
  LIST_ITEM_LABEL_USERNAME: username => `users_management_list_item_label_username_${username}`,
  LIST_ITEM_LABEL_ROLES: username => `users_management_list_item_label_roles_${username}`,
  LIST_ITEM_MENU_BTN: username => `users_management_list_item_menu_btn_${username}`,
  LIST_ITEM_VERIFIED: username => `users_management_list_item_verified_${username}`,
  SELECT_USER_ROLES: 'users_management_select_user_roles',
  FIELD_FIRST_NAME: 'users_management_field_first_name',
  FIELD_LAST_NAME: 'users_management_field_last_name',
  FIELD_EMAIL: 'users_management_field_email',
  FIELD_PHONE: 'users_management_field_phone',
  FIELD_USERNAME: 'users_management_field_username',
  SWITCH_USER_STATUS: 'users_management_switch_user_status',
  CHIP_USER_STATUS: 'users_management_chip_user_status',
  CHIP_ROLE: role => `users_management_chip_role_${role}`,
  MODAL_DELETE_USER: 'users_management_modal_delete_user',
};

export const ROLES_MANAGEMENT = {
  SECTION_TITLE: 'roles_management_section_title',
  BTN_ADD_ROLE: 'roles_management_btn_add_role',
  BTN_EDIT_ROLE: role => `roles_management_btn_edit_role_${role}`,
  BTN_DELETE_ROLE: role => `roles_management_btn_delete_role_${role}`,
  BTN_SUBMIT_ROLE: 'roles_management_btn_submit_role',
  BTN_CONFIRM_DELETE_ROLE: 'roles_management_btn_confirm_delete_role',
  LIST_ITEM_ROLE: role => `roles_management_list_item_role_${role}`,
  LIST_ITEM_ROLE_NAME: 'roles_management_list_item_role_name',
  LIST_ITEM_ROLE_DESCRIPTION: 'roles_management_list_item_role_description',
  LIST_ITEM_MENU_BTN: role => `roles_management_list_item_menu_btn_${role}`,
  ROW_PERMISSION_CATEGORY: (role, category) => `roles_management_row_permission_category_${role}_${category}`,
  PERMISSIONS_LIST: 'roles_management_permissions_list',
  FIELD_ROLE_NAME: 'roles_management_field_role_name',
  FIELD_ROLE_DESCRIPTION: 'roles_management_field_role_description',
  CHECKBOX_PERMISSION: permission => `roles_management_checkbox_permission_${permission}`,
};

export const RESET_PASSWORD = {
  FORGOT_PASSWORD_CONTAINER: 'reset_password_forgot_password_container',
  FIELD_USERNAME: 'reset_password_field_username',
  BTN_SEND_LINK: 'reset_password_btn_send_link',
  LINK_SIGN_IN: 'reset_password_link_sign_in',
  LINK_RESEND_EMAIL: 'reset_password_link_resend_email',
  CHECK_EMAIL_CONTAINER: 'reset_password_check_email_container',
  MESSAGE_ERROR: 'reset_password_message_error',
};

export const SALES_REPORT = {
  BTN_DOWNLOAD: 'sales_report_btn_download',
  BTN_GENERATE: 'sales_report_btn_generate',
  BTN_OPTIONS_CANCEL: 'sales_report_options_cancel',
  BTN_OPTIONS_DOWNLOAD: 'sales_report_options_download',
  CHIP_BRAND: brand => `sales_report_chip_brand_${brand}`,
  CHIP_VENDOR: vendor => `sales_report_chip_brand_${vendor}`,
  CHART_CONTAINER: 'sales_report_chart_container',
  FIELD_END_DATE: 'sales_report_field_end_date',
  FIELD_START_DATE: 'sales_report_field_start_date',
  FIELD_FILTER_BRANDS: 'sales_report_field_filter_brands',
  FIELD_FILTER_VENDORS: 'sales_report_field_filter_vendors',
  MODAL_DOWNLOAD_OPTIONS: 'sales_report_modal_download_options',
  SECTION_TITLE: 'sales_report_section_title',
  SELECT: selectId => `sales_report_select_${selectId}`,
  SELECT_OPTION: option => `sales_report_select_${option}`,
  SUMMARY: summaryId => `sales_report_summary_${summaryId}`,
  NO_DATA_FOUND: 'sales_report_no_data_found',
};

export const INVENTORY = {
  ZONE_CONTAINER: zone => `inventory_zone_container_${zone}`,
  SHIPPING_ZONE_CONTAINER: 'inventory_shipping_zone_container',
  BTN_ZONE_NAME_SCAN: 'inventory_btn_zone_name_scan',
  BTN_ZONE_UPC_SCAN: 'inventory_btn_zone_upc_scan',
  BTN_ZONE_LOCK: 'inventory_btn_zone_lock',
  FIELD_ZONE_NAME: 'inventory_field_zone_name',
  FIELD_ZONE_UPC: 'inventory_field_zone_upc',
};

export const SHIPPING = {
  BTN_SO_NUMBER_SCAN: 'shipping_btn_so_number_scan',
  BTN_UPC_SCAN: 'shipping_btn_upc_scan',
  BTN_CONFIRM_SHIPMENT: 'shipping_btn_confirm_shipment',
  BTN_MANUAL_CONFIRM: 'shipping_btn_manual_confirm',
  FIELD_SO_NUMBER: 'shipping_field_so_number',
  FIELD_UPC: 'shipping_field_upc',
  FIELD_TRACKING_NUMBER: 'shipping_field_tracking_number',
  SWITCH_PARTIAL_SHIPMENT: 'shipping_switch_partial_shipment',
  ROW_SO_ITEM: upc => `shipping_row_so_item_${upc}`,
  LABEL_QTY_SCANNED: 'shipping_label_qty_scanned',
  PANEL_EMPTY_SO: 'shipping_panel_empty_so',
};