import {
  put, all, takeEvery, call, select,
} from 'redux-saga/effects';
import _reduce from 'lodash/reduce';
import { tomApiSelector } from 'app/api/selectors';
import { getErrorMessage } from 'utils/error';
import { authRequired } from 'services/auth/sagas';
import { ACTIONS } from './constants';
import { formatPOScannedItem, formatShipment } from './helpers';

function* fetchPurchaseOrderWatcher({
  purchaseOrderNumber, successActionCallback, errorActionCallback,
}) {
  const api = yield select(tomApiSelector);
  try {
    const response = yield call([api, api.getPurchaseOrder], purchaseOrderNumber);
    const result = response.data ? response.data : response;

    if (successActionCallback) {
      yield put(successActionCallback(result));
    }
    return result;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    if (errorActionCallback) {
      yield put(errorActionCallback(errorMessage, error));
    }
    return error;
  }
}

const formatPOScannedItems = items => _reduce(items, (result, item) => {
  const formattedItem = formatPOScannedItem(item);
  const newResult = {
    ...result,
    ...formattedItem,
  };
  return newResult;
}, {});

function* setPurchaseOrderScannedItemsWatcher({
  params, successActionCallback, errorActionCallback,
}) {
  const { scannedItems, poShipment } = params;
  const api = yield select(tomApiSelector);
  try {
    const items = formatPOScannedItems(scannedItems);
    const shipment = formatShipment(poShipment);
    const response = yield call([api, api.submitPurchaseOrderScannedItems], items, shipment);
    const result = response.data ? response.data : response;

    if (successActionCallback) {
      yield put(successActionCallback(result));
    }
    return result;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    if (errorActionCallback) {
      yield put(errorActionCallback(errorMessage, error));
    }
    return error;
  }
}

export default function* tomSagas() {
  yield all([
    takeEvery(ACTIONS.FETCH_PURCHASE_ORDER, authRequired, fetchPurchaseOrderWatcher),
    takeEvery(
      ACTIONS.SET_PURCHASE_ORDER_SCANNED_ITEMS, authRequired, setPurchaseOrderScannedItemsWatcher,
    ),
  ]);
}