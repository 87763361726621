import { createReducer } from 'redux-create-reducer';
import { ACTIONS } from './constants';

const INITIAL_STATE = {
  isFetchingPermissions: false,
  globalPermissions: null,
  globalPermissionsError: null,
};

const setIsFetchingPermissions = (state, isFetchingPermissions) => ({
  ...state,
  isFetchingPermissions,
});

const updatePermissionsSuccess = (state, { payload }) => ({
  ...state,
  isFetchingPermissions: INITIAL_STATE.isFetchingPermissions,
  globalPermissions: payload.permissions,
});

const updatePermissionsError = (state, { errorMessage }) => ({
  ...state,
  isFetchingPermissions: INITIAL_STATE.isFetchingPermissions,
  globalPermissions: INITIAL_STATE.permissions,
  globalPermissionsError: errorMessage,
});

const handlers = {
  [ACTIONS.FETCH_PERMISSIONS]: state => setIsFetchingPermissions(state, true),
  [ACTIONS.FETCH_PERMISSIONS_SUCCESS]: updatePermissionsSuccess,
  [ACTIONS.FETCH_PERMISSIONS_ERROR]: updatePermissionsError,
};

export default createReducer(INITIAL_STATE, handlers);
