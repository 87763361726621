import _get from 'lodash/get';
import { productManagementState } from '../selectors';

const productCreatorState = state => _get(productManagementState(state), 'productCreator');
export const selectIsDownloadingTemplate = state => _get(productCreatorState(state), 'isDownloadingTemplate');
export const selectIsValidatingFile = state => _get(productCreatorState(state), 'isValidatingFile');
export const selectIsUploadingFile = state => _get(productCreatorState(state), 'isUploadingFile');

export const selectIsUploadFileValid = state => _get(productCreatorState(state), 'isUploadFileValid');
export const selectUploadFileErrors = state => _get(productCreatorState(state), 'uploadFileErrors');

export const selectUploadLogs = state => _get(productCreatorState(state), 'uploadLogs');
export const selectUploadLogsError = state => _get(productCreatorState(state), 'uploadLogsError');
export const selectUploadType = state => _get(productCreatorState(state), 'uploadType');
export const selectOriginalFileName = state => _get(productCreatorState(state), 'originalFileName');
export const selectIsFetchingLogs = state => _get(productCreatorState(state), 'isFetchingLogs');
export const selectIsDownloadingOriginalFile = state => _get(productCreatorState(state), 'isDownloadingOriginalFile');
export const selectIsCancellingRequest = state => _get(productCreatorState(state), 'isCancellingRequest');
export const selectIsDownloadingErrorFile = state => _get(productCreatorState(state), 'isDownloadingErrorFile');
