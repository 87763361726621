import _get from 'lodash/get';
import { accountSettingsState } from '../selectors';

export const shippingRulesState = state => _get(accountSettingsState(state), 'shippingRules');

// rulesFeed
const rulesFeedState = state => _get(shippingRulesState(state), 'rulesFeed');
export const getFeedData = state => _get(rulesFeedState(state), 'data');
export const getRules = state => _get(getFeedData(state), 'rules');
export const getFeedDataTotalRecords = state => _get(getFeedData(state), 'totalRecords');
export const getFeedDataPage = state => _get(getFeedData(state), 'page');
export const getFeedDataItemsPerPage = state => _get(getFeedData(state), 'itemsPerPage');
export const getLastPage = state => _get(rulesFeedState(state), 'lastPage');
export const getFilters = state => _get(rulesFeedState(state), 'filters');

export const getShowOptionsModal = state => _get(rulesFeedState(state), 'showOptionsModal');

export const getIsExporting = state => _get(rulesFeedState(state), 'isExporting');
export const getIsRefreshing = state => _get(rulesFeedState(state), 'isRefreshing');
export const getIsFetchingFeed = state => _get(rulesFeedState(state), 'isFetchingFeed');

// settings
const settingsState = state => _get(shippingRulesState(state), 'settings');
export const getSettingsColumnsOrder = state => _get(settingsState(state), 'columnsOrder');
export const getSettingsColumns = state => _get(settingsState(state), 'columns');
export const getSettingsItemsPerPage = state => _get(settingsState(state), 'itemsPerPage');
export const getSettingsSorting = state => _get(settingsState(state), 'sorting');

// rulesManagement
const rulesManagementState = state => _get(shippingRulesState(state), 'rulesManagement');
export const getSelectedShippingRule = state => _get(rulesManagementState(state), 'selectedShippingRule');
export const getShowDeleteShippingRuleModal = state => _get(rulesManagementState(state), 'showDeleteShippingRuleModal');
export const getShowEditShippingRuleModal = state => _get(rulesManagementState(state), 'showEditShippingRuleModal');
export const getShowAddShippingRuleModal = state => _get(rulesManagementState(state), 'showAddShippingRuleModal');
export const getIsRemovingShippingRule = state => _get(rulesManagementState(state), 'isRemovingShippingRule');
export const getIsUpdatingShippingRule = state => _get(rulesManagementState(state), 'isUpdatingShippingRule');
export const getIsCreatingShippingRule = state => _get(rulesManagementState(state), 'isCreatingShippingRule');

// setup
const setupState = state => _get(shippingRulesState(state), 'setup');
export const selectFulfillmentWarehouses = state => _get(setupState(state), 'fulfillment_warehouses');
export const selectValidSalesChannels = state => _get(setupState(state), 'valid_sales_channels');
export const selectBrandsSuppliers = state => _get(setupState(state), 'brands_suppliers');