import { createReducer } from 'redux-create-reducer';
import { ACTIONS } from './constants';

export const INITIAL_STATE = {
  isInitiatingContainer: false,
  isLoadingChannels: false,
  isLoadingIntegrations: false,
  channels: null,
  integrations: null,
  showAddIntegrationModal: false,
  showModifyIntegrationModal: false,
  showDeleteIntegrationModal: false,
  addIntegrationId: null,
  modifyIntegrationId: null,
  deleteIntegrationId: null,
};

const resetState = () => ({
  ...INITIAL_STATE,
});

const setIsInitContainer = (state, isInitiatingContainer) => ({
  ...state,
  isInitiatingContainer,
});

const setIsLoadingChannels = (state, isLoadingChannels) => ({
  ...state,
  isLoadingChannels,
});

const setIsLoadingIntegrations = (state, isLoadingIntegrations) => ({
  ...state,
  isLoadingIntegrations,
});

const updateChannels = (state, { payload }) => ({
  ...state,
  channels: payload.channels,
  isLoadingChannels: INITIAL_STATE.isLoadingChannels,
});

const updateIntegrations = (state, { payload }) => ({
  ...state,
  integrations: payload.integrations,
  isLoadingIntegrations: INITIAL_STATE.isLoadingIntegrations,
});

const updateShowAddIntegrationModal = (state, { showAddIntegrationModal }) => ({
  ...state,
  showAddIntegrationModal,
});

const updateShowModifyIntegrationModal = (state, { showModifyIntegrationModal }) => ({
  ...state,
  showModifyIntegrationModal,
});

const updateShowDeleteIntegrationModal = (state, { showDeleteIntegrationModal }) => ({
  ...state,
  showDeleteIntegrationModal,
});

const updateAddIntegrationId = (state, { addIntegrationId }) => ({
  ...state,
  addIntegrationId,
});

const updateModifyIntegrationId = (state, { modifyIntegrationId }) => ({
  ...state,
  modifyIntegrationId,
});

const updateDeleteIntegrationId = (state, { deleteIntegrationId }) => ({
  ...state,
  deleteIntegrationId,
});

export const handlers = {
  [ACTIONS.INIT_CONTAINER]: state => setIsInitContainer(state, true),
  [ACTIONS.INIT_CONTAINER_SUCCESS]: state => setIsInitContainer(state, false),
  [ACTIONS.INIT_CONTAINER_ERROR]: state => setIsInitContainer(state, false),

  [ACTIONS.LOAD_CHANNELS]: state => setIsLoadingChannels(state, true),
  [ACTIONS.LOAD_CHANNELS_SUCCESS]: updateChannels,
  [ACTIONS.LOAD_CHANNELS_ERROR]: state => setIsLoadingChannels(state, false),

  [ACTIONS.LOAD_INTEGRATIONS]: state => setIsLoadingIntegrations(state, true),
  [ACTIONS.LOAD_INTEGRATIONS_SUCCESS]: updateIntegrations,
  [ACTIONS.LOAD_INTEGRATIONS_ERROR]: state => setIsLoadingIntegrations(state, false),

  [ACTIONS.SET_SHOW_ADD_INTEGRATION_MODAL]: updateShowAddIntegrationModal,
  [ACTIONS.SET_SHOW_MODIFY_INTEGRATION_MODAL]: updateShowModifyIntegrationModal,
  [ACTIONS.SET_SHOW_DELETE_INTEGRATION_MODAL]: updateShowDeleteIntegrationModal,

  [ACTIONS.SET_ADD_INTEGRATION_ID]: updateAddIntegrationId,
  [ACTIONS.SET_MODIFY_INTEGRATION_ID]: updateModifyIntegrationId,
  [ACTIONS.SET_DELETE_INTEGRATION_ID]: updateDeleteIntegrationId,

  [ACTIONS.RESET_STATE]: resetState,
};

export default createReducer(INITIAL_STATE, handlers);