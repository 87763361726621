export const ACTIONS = {
  SET_IMPORT_FILE: 'SERVICE_PURCHASE_ORDERS__SET_IMPORT_FILE',
  SET_VALIDATE_FILE: 'SERVICE_PURCHASE_ORDERS__SET_VALIDATE_FILE',
  FETCH_IMPORT_TEMPLATE: 'SERVICE_PURCHASE_ORDERS__FETCH_IMPORT_TEMPLATE',

  FETCH_PURCHASE_ORDERS: 'SERVICE_PURCHASE_ORDERS__FETCH_PURCHASE_ORDERS',
  FETCH_PURCHASE_ORDERS_CSV: 'SERVICE_PURCHASE_ORDERS__FETCH_PURCHASE_ORDERS_CSV',

  SET_REMOVE_PURCHASE_ORDER: 'SERVICE_PURCHASE_ORDERS__SET_REMOVE_PURCHASE_ORDER',
  SET_UPDATE_PURCHASE_ORDER: 'SERVICE_PURCHASE_ORDERS__SET_UPDATE_PURCHASE_ORDER',
};
