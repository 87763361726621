export const ACTIONS = {
  SCAN_PURCHASE_ORDER: 'RECEIVING__SCAN_PURCHASE_ORDER',
  SCAN_PURCHASE_ORDER_SUCCESS: 'RECEIVING__SCAN_PURCHASE_ORDER_SUCCESS',
  SCAN_PURCHASE_ORDER_ERROR: 'RECEIVING__SCAN_PURCHASE_ORDER_ERROR',
  SET_SCANNED_ITEMS: 'RECEIVING__SET_SCANNED_ITEMS',
  SET_INITIAL_SCANNED_ITEMS_SNAPSHOT: 'RECEIVING__SET_INITIAL_SCANNED_ITEMS_SNAPSHOT',
  SET_PURCHASE_ORDER_NUMBER: 'RECEIVING__SET_PURCHASE_ORDER_NUMBER',
  SET_UPC_ERROR_LIST: 'RECEIVING__SET_UPC_ERROR_LIST',
  SET_SELECTED_SALES_ORDERS_LIST: 'RECEIVING__SET_SELECTED_SALES_ORDERS_LIST',
  RELOAD_PURCHASE_ORDER: 'RECEIVING__RELOAD_PURCHASE_ORDER',
  SET_SHOW_MANUAL_CONFIRM_MODAL: 'RECEIVING__SET_SHOW_MANUAL_CONFIRM_MODAL',
  SET_MANUAL_CONFIRM_ITEM: 'RECEIVING__SET_MANUAL_CONFIRM_ITEM',
  COMMIT_SCANNED_ITEMS: 'RECEIVING__COMMIT_SCANNED_ITEMS',
  COMMIT_SCANNED_ITEMS_SUCCESS: 'RECEIVING__COMMIT_SCANNED_ITEMS_SUCCESS',
  COMMIT_SCANNED_ITEMS_ERROR: 'RECEIVING__COMMIT_SCANNED_ITEMS_ERROR',
  SHOW_SALES_ORDERS_LIST_MODAL: 'RECEIVING__SHOW_SALES_ORDERS_LIST_MODAL',
  SET_SHIPMENT_VOLUME_UNIT: 'RECEIVING__SET_SHIPMENT_VOLUME_UNIT',
  SET_SHIPMENT_TOTAL_COST: 'RECEIVING__SET_SHIPMENT_TOTAL_COST',
  SET_SHIPMENT_TOTAL_VOLUME: 'RECEIVING__SET_SHIPMENT_TOTAL_VOLUME',
  SET_SCANNED_ITEMS_SHIPMENT_COST: 'RECEIVING__SET_SCANNED_ITEMS_SHIPMENT_COST',
  SET_INITIAL_SHIPMENT_SNAPSHOT: 'RECEIVING__SET_INITIAL_SHIPMENT_SNAPSHOT',
};

export const i18n = {
  scan_po_title: 'Received PO',
  scan_po_description: 'Enter the PO# to scan items for check-in.',
  scan_po_empty_title: 'Empty PO#',
  scan_po_empty_description: 'There are no items in this PO#.',
};

export const SHIPMENT_VOLUME_UNITS = [
  { label: 'Cubic Meters (m3)', value: 'm3' },
  { label: 'Cubic Feet (ft3)', value: 'ft3' },
];