import { isEmpty } from 'lodash';
import { createReducer } from 'redux-create-reducer';
import { removeFromArray } from 'utils/array';
import { ACTIONS } from '../constants';

const NOW = new Date();

export const INITIAL_STATE = {
  isExporting: false,
  brandResults: null,
  vendorResults: null,
  isSearching: {
    brand: false,
    vendor: false,
  },
  showOptionsModal: false,
  startDate: new Date(NOW.setDate(NOW.getDate() - 30)),
  endDate: new Date(),
  selectedBrands: [],
  selectedVendors: [],
};

const setIsExporting = (state, isExporting) => ({
  ...state,
  isExporting,
});

const setBrandResults = (state, { payload }) => ({
  ...state,
  brandResults: payload.search_results,
  isSearching: {
    ...state.isSearching,
    brand: false,
  },
});

const setVendorResults = (state, { payload }) => ({
  ...state,
  vendorResults: payload.search_results,
  isSearching: {
    ...state.isSearching,
    vendor: false,
  },
});

const setShowOptionsModal = (state, { showOptionsModal }) => ({
  ...state,
  showOptionsModal,
});

const updateStartDate = (state, { startDate }) => ({
  ...state,
  startDate,
});

const updateEndDate = (state, { endDate }) => ({
  ...state,
  endDate,
});

const addSelectedBrand = (state, { name }) => {
  const { selectedBrands } = state;
  const updatedSelected = selectedBrands.includes(name)
    ? selectedBrands
    : [...selectedBrands, name];

  return {
    ...state,
    selectedBrands: updatedSelected,
    brandResults: INITIAL_STATE.brandResults,
  };
};

const removeSelectedBrand = (state, { name }) => {
  const { selectedBrands } = state;
  const updatedSelected = removeFromArray(selectedBrands, name);
  return {
    ...state,
    selectedBrands: updatedSelected,
  };
};

const addSelectedVendor = (state, { name }) => {
  const { selectedVendors } = state;
  const updatedSelected = selectedVendors.includes(name)
    ? selectedVendors
    : [...selectedVendors, name];

  return {
    ...state,
    selectedVendors: updatedSelected,
    vendorResults: INITIAL_STATE.vendorResults,
  };
};

const removeSelectedVendor = (state, { name }) => {
  const { selectedVendors } = state;
  const updatedSelected = removeFromArray(selectedVendors, name);
  return {
    ...state,
    selectedVendors: updatedSelected,
  };
};

const setSearchingAutocomplete = (state, { fieldId, value }) => ({
  ...state,
  brandResults: INITIAL_STATE.brandResults,
  vendorResults: INITIAL_STATE.vendorResults,
  isSearching: {
    ...state.isSearching,
    [fieldId]: !isEmpty(value),
  },
});

const resetState = () => ({
  ...INITIAL_STATE,
});

export const handlers = {
  [ACTIONS.EXPORT_TO_FILE]: state => setIsExporting(state, true),
  [ACTIONS.EXPORT_TO_FILE_SUCCESS]: state => setIsExporting(state, false),
  [ACTIONS.EXPORT_TO_FILE_ERROR]: state => setIsExporting(state, false),

  [ACTIONS.SEARCH_AUTOCOMPLETE]: setSearchingAutocomplete,
  [ACTIONS.SEARCH_BRAND_SUCCESS]: setBrandResults,
  [ACTIONS.SEARCH_VENDOR_SUCCESS]: setVendorResults,
  [ACTIONS.SHOW_OPTIONS_MODAL]: setShowOptionsModal,

  [ACTIONS.SET_START_DATE]: updateStartDate,
  [ACTIONS.SET_END_DATE]: updateEndDate,
  [ACTIONS.ADD_BRAND]: addSelectedBrand,
  [ACTIONS.REMOVE_BRAND]: removeSelectedBrand,
  [ACTIONS.ADD_VENDOR]: addSelectedVendor,
  [ACTIONS.REMOVE_VENDOR]: removeSelectedVendor,

  [ACTIONS.RESET_STATE]: resetState,
};

export default createReducer(INITIAL_STATE, handlers);