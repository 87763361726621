import React from 'react';
import PropTypes from 'prop-types';
import Spinner, { spinnerSizes } from 'components/Spinner';
import {
  CircleButtonWrap, SpinnerWrap, circleButtonColors, circleButtonSizes,
} from './styles';

const CircleButton = ({
  children, color, size, loading, disabled, block, ...buttonProps
}) => (
  <CircleButtonWrap
    color={color}
    size={size}
    disabled={disabled}
    block={block}
    {...buttonProps}
  >
    {loading && (
      <SpinnerWrap color={color} size={size}>
        <Spinner size={spinnerSizes.small} />
      </SpinnerWrap>
    )}
    {!loading && <span>{children}</span>}
  </CircleButtonWrap>
);

CircleButton.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.oneOf(Object.keys(circleButtonColors)),
  size: PropTypes.oneOf(Object.keys(circleButtonSizes)),
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  block: PropTypes.bool,
};

CircleButton.defaultProps = {
  color: circleButtonColors.default,
  size: circleButtonSizes.medium,
  disabled: false,
  loading: false,
  block: false,
};

export default CircleButton;
export { circleButtonColors, circleButtonSizes };