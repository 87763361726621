import {
  put, all, takeEvery, call, select,
} from 'redux-saga/effects';
import { setReplaceQty } from 'services/inventory/actions';
import { errorToasterSaga, successToasterSaga } from 'services/sagaWorkers';
import { INVENTORY_MANAGEMENT_ERRORS, INVENTORY_MANAGEMENT_SUCCESS } from 'features/InventoryManagement/constants';
import { ACTIONS } from '../constants';
import {
  deleteSelectedItemsSuccess,
  deleteSelectedItemsError,
  toggleShowDeleteConfirmation,
  reloadZone,
  setSelectedItems,
} from '../actions';
import {
  getZoneName, selectSelectedItems,
} from '../selectors';
import { buildZoneItem } from './sagaWorkers';

function* deleteSelectedItemsWatcher() {
  const zoneName = yield select(getZoneName);
  const selectedItems = yield select(selectSelectedItems);

  const collection = yield all(selectedItems.map(selectedItemUPC => {
    const inventoryItem = call(buildZoneItem, selectedItemUPC, 0);
    return inventoryItem;
  }));

  yield put(
    setReplaceQty(zoneName, collection, deleteSelectedItemsSuccess, deleteSelectedItemsError),
  );
}

function* deleteSelectedItemsSuccessWatcher() {
  yield call(successToasterSaga, { message: INVENTORY_MANAGEMENT_SUCCESS.DELETING_ITEMS });
  yield put(setSelectedItems([]));
  yield put(toggleShowDeleteConfirmation(false));
}

function* deleteSelectedItemsErrorWatcher({ errorMessage }) {
  yield put(reloadZone());
  const errorDisplay = errorMessage || INVENTORY_MANAGEMENT_ERRORS.DELETING_ITEMS;
  yield call(errorToasterSaga, { errorMessage: errorDisplay });
  yield put(toggleShowDeleteConfirmation(false));
}

export default function* deleteZoneItemsSagas() {
  yield all([
    takeEvery(ACTIONS.DELETE_SELECTED_ITEMS, deleteSelectedItemsWatcher),
    takeEvery(ACTIONS.DELETE_SELECTED_ITEMS_SUCCESS, deleteSelectedItemsSuccessWatcher),
    takeEvery(ACTIONS.DELETE_SELECTED_ITEMS_ERROR, deleteSelectedItemsErrorWatcher),
  ]);
}