import _map from 'lodash/map';
import _get from 'lodash/get';

const htmlTemplate = content => `
  <html>
    <head>
      <style>
        body {
          font-family: Arial, sans-serif;
          color: rgba(0, 0, 0, 0.87);
        }
        p {
          margin: 0;
        }
        table {
          width: 100%;
          border-collapse: collapse;
          font-size: 14px;
        }
        table tr th, table tr td {
          text-align: left;
          padding: 1rem;
        }
        table thead tr th {
          background-color: #ebedef;
        }
        table thead > tr th:last-child {
          border-radius: 0 8px 0 0;
        }
        table thead > tr th:first-child {
          border-radius: 8px 0 0 0;
        }
        table tbody > tr {
          border-bottom: 1px solid #d5d9dc;
        }
        .grid {
          padding: 1.5rem 0;
          display: grid;
          grid-column-gap: 0.5rem;
          grid-row-gap: 1.25rem;
          grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        }
      </style>
    </head>
    <body>${content}</body>
  </html>
`;

const formatNote = note => {
  const newNote = note.replace(/\n/g, '<br />');
  return newNote;
};

const buildDataTable = (data = {}) => {
  const {
    noData,
    order_line_notes: orderLineNotes,
    order_id: orderId,
    sku,
    upc,
    sales_order_number: so,
  } = data;

  if (noData) {
    return '<h1>No Order Line Notes Available.</h1>';
  }

  const rows = _map(orderLineNotes, item => (`
    <tr>
      <td>${item.timestamp}</td>
      <td>${item.username || 'N/A'}</td>
      <td>${formatNote(item.note)}</td>
    </tr>
  `)).join('');

  const table = `
    <div class="grid">
      <div>
        <p><strong>Order ID: </strong>${orderId}</p>
      </div>
      <div>
        <p><strong>SKU: </strong>${sku}</p>
      </div>
      <div>
        <p><strong>UPC: </strong>${upc}</p>
      </div>
      <div>
        <p><strong>SO#: </strong>${so}</p>
      </div>
    </div>
    <table>
      <thead>
        <tr>
          <th width="25%">Timestamp</th>
          <th width="15%">Username</th>
          <th width="60%">Note</th>
        </tr>
      </thead>
      <tbody>${rows}</tbody>
    </table>
  `;
  return table;
};
export const openNotesPopup = ({
  data,
}) => {
  const guidOrderLine = _get(data, 'guid_order_line', '');
  const url = '';
  const name = '';
  const params = `
    height=600,
    left=100,
    resizable=yes,
    top=0,
    width=768,
    overflow=auto,
  `;
  const title = `Order Line ${guidOrderLine} - OMNI Warehouse`;
  const htmlMarkup = htmlTemplate(buildDataTable(data));

  const popup = window.open(url, name, params);
  popup.document.open();
  popup.document.write(htmlMarkup);
  popup.document.close();
  popup.document.title = title;
};