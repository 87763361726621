import { createReducer } from 'redux-create-reducer';
import { ACTIONS } from '../constants';

export const INITIAL_STATE = {
  items: [],
  isFetching: false,
};

const setIsFetching = (state, isFetching) => ({
  ...state,
  isFetching,
});

const updateItems = (state, { payload }) => ({
  ...state,
  items: payload,
  isFetching: false,
});

export const handlers = {
  [ACTIONS.FETCH_BRANDS]: state => setIsFetching(state, true),
  [ACTIONS.FETCH_BRANDS_ERROR]: state => setIsFetching(state, false),
  [ACTIONS.FETCH_BRANDS_SUCCESS]: updateItems,
};

export default createReducer(INITIAL_STATE, handlers);