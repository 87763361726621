// Tool for naming colors: http://chir.ag/projects/name-that-color/
// Tool for darkening colors: https://pinetools.com/darken-color
// Darken Naming convention {COLOR_NAME}_DARK_{AMOUNT}. Amount from the amount set in the tool
// Tool for lightning colors: https://pinetools.com/lighten-color
// Lightning Naming convention {COLOR_NAME}_LIGHT_{AMOUNT}. Amount from the amount set in the tool

// Gray
export const WHITE = '#ffffff';
export const ALABASTER = '#FAFAFA';
export const ATHENS_GRAY = '#f8f9fa';
export const WILD_SAND = '#F6F6F6';
export const PORCELAIN_LIGHT_35 = '#f2f3f4';
export const PORCELAIN = '#ebedef';
export const ATHENS_GRAY_DARK_7 = '#e3e7eb';
export const IRON = '#d5d9dc';
export const GALLERY = '#C0C0C0';
export const BOMBAY = '#aeb1b5';
export const OLSO_GRAY = '#929394';
export const TUNDORA_LIGHT_40 = '#918d8d';
export const BOULDER = '#7B7B7B';
export const ABBEY = '#54595F';
export const TUNDORA = '#464444';
export const BRIGHT_GRAY = '#353d48';
export const TUNDORA_DARK_20 = '#383636';
export const MINE_SHAFT = '#2e2c2c';
export const PALE_SKY = '#637381';
export const BLACK_LIGHT_60 = '#999999';
export const BLACK = '#000000';

// Yellow
export const OLD_LACE = '#FDF9EB';
export const OLD_LACE_DARK_5 = '#faf2d4';
export const AMBER_LIGHT_60 = '#ffe69b';
export const AMBER = '#ffc107';
export const AMBER_DARK_20 = '#d19d00';
export const TREE_POPPY = '#F7931E';

// Green
export const FETA = '#EBFDED';
export const FETA_DARK_5 = '#d4fad9';
export const EUCALYPTUS_LIGHT_60 = '#9de7ae';
export const EUCALYPTUS = '#28a745';
export const EUCALYPTUS_DARK_20 = '#208537';

// Turquoise
export const LINK_WATER = '#d4eaf5';
export const HAVELOCK_BLUE = '#53A3D9';
export const CORNFLOWER_BLUE = '#2996cc';
export const STEEL_BLUE_LIGHT_60 = '#b3d2e0';
export const STEEL_BLUE = '#468FAE';
export const STEEL_BLUE_DARK_20 = '#38728b';
export const TURQUOISE = '#45CFC2';
export const TURQUOISE_DARK_20 = '#2dafa3';

// Blue
export const SELAGO = '#DFE8FB';
export const SELAGO_DARK_5 = '#c9d8f8';
export const AZURE_LIGHT_60 = '#99caff';
export const AZURE = '#007bff';
export const AZURE_DARK_20 = '#0062cc';

// Purple
export const BLUE_VIOLET_LIGHT_60 = '#b7b3e0';
export const BLUE_VIOLET = '#5046AE';
export const BLUE_VIOLET_DARK_20 = '#3f388b';

// Red
export const WISP_PINK = '#FDEBED';
export const WISP_PINK_DARK_5 = '#fad4d9';
export const PUNCH_LIGHT_60 = '#f0aeb4';
export const PUNCH = '#dc3545';
export const TALL_POPPY = '#C1262C';
export const PUNCH_DARK_20 = '#ba202e';
export const TALL_POPPY_DARK_20 = '#9a1e23';

// Orange
export const CRUSTA = '#F77545';
export const CRUSTA_DARK_20 = '#f2480a';

// THEME
export const TEXT_SECONDARY = PALE_SKY;
export const TEXT_DANGER = PUNCH;
export const TEXT_SUCCESS = EUCALYPTUS_DARK_20;
