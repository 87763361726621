import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import ROUTES from 'constants/routes';
import { getSessionAuthorized } from './session/selectors';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => (rest.loggedIn
      ? <Component {...props} />
      : <Redirect to={{ pathname: ROUTES.LOGIN.BASE, state: { from: props.location } }} />
    )}
  />
);

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  location: PropTypes.shape().isRequired,
};

const mapStateToProps = state => ({
  loggedIn: getSessionAuthorized(state),
});

export default connect(mapStateToProps)(PrivateRoute);
