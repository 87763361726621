import { queryStringFromObj } from 'utils/urlParser';
import HttpRequest from '../HttpRequest';
import { delayResponse } from '../mocks/helpers';
import bulkShipTemplate from '../mocks/shipments/data/bulk_ship_template';
import ENDPOINTS, { SHIP_RULES_DEFAULT_PARAMS } from './constants';

export default class ShipmentsApi extends HttpRequest {
  mergeShipment({ trackingNumber, guidOrderLineList }) {
    const params = {
      tracking_number: trackingNumber,
      order_lines: guidOrderLineList,
    };

    const bodyFormData = new FormData();
    bodyFormData.set('shipment', JSON.stringify(params));

    return this.call(ENDPOINTS.MERGE_SHIPMENT, {
      method: 'POST',
      data: bodyFormData,
    });
  }

  getSetupShipRules() {
    return this.call(ENDPOINTS.SETUP_SHIP_RULES);
  }

  getShipRules({ params = {} }) {
    const requestParams = {
      ...SHIP_RULES_DEFAULT_PARAMS,
      ...params,
    };

    const queryString = queryStringFromObj(requestParams);
    return this.call(`${ENDPOINTS.SHIP_RULES}${queryString}`);
  }

  shipRulesToCSV() {
    const requestParams = {
      ...SHIP_RULES_DEFAULT_PARAMS,
    };
    const bodyFormData = new FormData();
    const { filter } = requestParams;
    bodyFormData.set('payload', JSON.stringify({
      ...requestParams,
      filter: JSON.stringify(filter),
    }));

    return this.call(ENDPOINTS.SHIP_RULES, {
      method: 'POST',
      data: bodyFormData,
    });
  }

  addShipRule({ params }) {
    const bodyFormData = new FormData();
    bodyFormData.set('fn', 'add');
    bodyFormData.set('addObject', JSON.stringify(params));

    return this.call(ENDPOINTS.SHIP_RULES, {
      method: 'POST',
      data: bodyFormData,
    });
  }

  updateShipRule({ ruleId, params }) {
    const bodyFormData = new FormData();
    bodyFormData.set('fn', 'update');
    bodyFormData.set('id', ruleId);
    bodyFormData.set('updateObject', JSON.stringify(params));

    return this.call(ENDPOINTS.SHIP_RULES, {
      method: 'POST',
      data: bodyFormData,
    });
  }

  removeShipRules({ ruleIdsArray }) {
    const bodyFormData = new FormData();
    bodyFormData.set('fn', 'delete');
    bodyFormData.set('removeObject', JSON.stringify(ruleIdsArray));

    return this.call(ENDPOINTS.SHIP_RULES, {
      method: 'POST',
      data: bodyFormData,
    });
  }

  bulkMarkShipGetOrders({ file }) {
    const bodyFormData = new FormData();
    bodyFormData.set('Filedata', file);

    return this.call(ENDPOINTS.BULK_MARK_SHIP_GET_ORDERS, {
      method: 'POST',
      data: bodyFormData,
    });
  }

  getBulkShipTemplate() {
    return delayResponse(bulkShipTemplate);
  }
}

export { ENDPOINTS };