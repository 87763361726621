import _reduce from 'lodash/reduce';
import _find from 'lodash/find';
import _isEmpty from 'lodash/isEmpty';

export const findSelectedItems = (selectedItemIds, items) => {
  const selectedItems = _reduce(selectedItemIds, (result, upc) => {
    const item = _find(items, { upc });
    if (item) {
      result.push(item);
    }
    return result;
  }, []);
  return !_isEmpty(selectedItems) && selectedItems;
};