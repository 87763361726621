import { createReducer } from 'redux-create-reducer';
import { ACTIONS } from './constants';

export const INITIAL_STATE = {
  isDownloadingTemplate: false,
  isUploadingFile: false,
};

const setIsDownloadingTemplate = (state, isDownloadingTemplate) => ({
  ...state,
  isDownloadingTemplate,
});

const setIsUploadingFile = (state, isUploadingFile) => ({
  ...state,
  isUploadingFile,
});

const resetState = () => ({
  ...INITIAL_STATE,
});

export const handlers = {
  [ACTIONS.DOWNLOAD_TEMPLATE]: state => setIsDownloadingTemplate(state, true),
  [ACTIONS.DOWNLOAD_TEMPLATE_SUCCESS]: state => setIsDownloadingTemplate(state, false),
  [ACTIONS.DOWNLOAD_TEMPLATE_ERROR]: state => setIsDownloadingTemplate(state, false),

  [ACTIONS.UPLOAD_FILE]: state => setIsUploadingFile(state, true),
  [ACTIONS.UPLOAD_FILE_SUCCESS]: state => setIsUploadingFile(state, false),
  [ACTIONS.UPLOAD_FILE_ERROR]: state => setIsUploadingFile(state, false),

  [ACTIONS.RESET_STATE]: resetState,
};

export default createReducer(INITIAL_STATE, handlers);