import { createReducer } from 'redux-create-reducer';
import { normalizeToCountryCode } from 'utils/country';
import { ACTIONS, SHIPPING_ADDRESS_FIELD } from '../constants';

const INITIAL_STATE = {
  [SHIPPING_ADDRESS_FIELD.NAME]: '',
  [SHIPPING_ADDRESS_FIELD.PHONE]: '',
  [SHIPPING_ADDRESS_FIELD.ADDRESS_1]: '',
  [SHIPPING_ADDRESS_FIELD.ADDRESS_2]: '',
  [SHIPPING_ADDRESS_FIELD.ADDRESS_3]: '',
  [SHIPPING_ADDRESS_FIELD.COUNTRY]: 'US',
  [SHIPPING_ADDRESS_FIELD.ZIP_CODE]: '',
  [SHIPPING_ADDRESS_FIELD.CITY]: '',
  [SHIPPING_ADDRESS_FIELD.STATE]: '',
  isSearchingZipCode: false,
};

const updateFieldValue = (state, { fieldId, value }) => ({
  ...state,
  [fieldId]: value,
});

const setIsSearchingZipCode = (state, isSearchingZipCode) => ({
  ...state,
  isSearchingZipCode,
});

const updateShippingInfo = (state, { shippingInfo }) => ({
  ...state,
  ...shippingInfo,
  [SHIPPING_ADDRESS_FIELD.COUNTRY]: normalizeToCountryCode(
    shippingInfo[SHIPPING_ADDRESS_FIELD.COUNTRY],
  ),
});

const handlers = {
  [ACTIONS.SET_SHIPPING_ADDRESS_FIELD_VALUE]: updateFieldValue,
  [ACTIONS.SEARCH_ZIP_CODE]: state => setIsSearchingZipCode(state, true),
  [ACTIONS.SEARCH_ZIP_CODE_SUCCESS]: state => setIsSearchingZipCode(state, false),
  [ACTIONS.SET_SHIPPING_INFO]: updateShippingInfo,
};

export default createReducer(INITIAL_STATE, handlers);