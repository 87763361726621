import { createReducer } from 'redux-create-reducer';
import { ACTIONS } from './constants';

export const INITIAL_STATE = {
  authorizationError: null,
  checkAvailabilityError: null,
  deleteSelectedUser: null,
  editSelectedUser: null,
  isAddingUser: false,
  isCheckingUsername: false,
  isDeletingUser: false,
  isEditingUser: false,
  isInitiatingContainer: false,
  isLoadingUsers: false,
  isResendingInvite: false,
  isUsernameAvailable: null,
  roles: null,
  showAddUserModal: false,
  showDeleteUserModal: false,
  showEditUserModal: false,
  users: null,
};

const resetState = () => ({
  ...INITIAL_STATE,
});

const setIsInitContainer = (state, isInitiatingContainer) => ({
  ...state,
  isInitiatingContainer,
});

const setIsLoadingUsers = (state, isLoadingUsers) => ({
  ...state,
  isLoadingUsers,
});

const setIsAddingUser = (state, isAddingUser) => ({
  ...state,
  isAddingUser,
});

const setIsEditingUser = (state, isEditingUser) => ({
  ...state,
  isEditingUser,
});

const setIsDeletingUser = (state, isDeletingUser) => ({
  ...state,
  isDeletingUser,
});

const setIsResendingInvite = (state, isResendingInvite) => ({
  ...state,
  isResendingInvite,
});

const updateUsers = (state, { payload }) => ({
  ...state,
  users: payload.users,
  isLoadingUsers: false,
});

const updateRoles = (state, { roles }) => ({
  ...state,
  roles,
});

const updateShowAddUserModal = (state, { showAddUserModal }) => ({
  ...state,
  showAddUserModal,
});

const updateShowDeleteUserModal = (state, { showDeleteUserModal }) => ({
  ...state,
  showDeleteUserModal,
});

const updateShowEditUserModal = (state, { showEditUserModal }) => ({
  ...state,
  showEditUserModal,
});

const updateDeleteSelectedUser = (state, { deleteSelectedUser }) => ({
  ...state,
  deleteSelectedUser,
});

const updateEditSelectedUser = (state, { editSelectedUser }) => ({
  ...state,
  editSelectedUser,
});

const updateAuthorizationError = (state, { authorizationError }) => ({
  ...state,
  authorizationError,
});

const updateUsernameAvailabilitySuccess = (state, { payload }) => ({
  ...state,
  isCheckingUsername: false,
  isUsernameAvailable: payload.username_available,
});

const updateCheckUsername = state => ({
  ...state,
  isCheckingUsername: true,
  isUsernameAvailable: null,
  checkAvailabilityError: null,
});

const updateCheckUsernameError = (state, { checkAvailabilityError }) => ({
  ...state,
  isCheckingUsername: false,
  isUsernameAvailable: false,
  checkAvailabilityError,
});

export const handlers = {
  [ACTIONS.INIT_USERS_MANAGEMENT_CONTAINER]: state => setIsInitContainer(state, true),
  [ACTIONS.INIT_USERS_MANAGEMENT_CONTAINER_SUCCESS]: state => setIsInitContainer(state, false),
  [ACTIONS.INIT_USERS_MANAGEMENT_CONTAINER_ERROR]: state => setIsInitContainer(state, false),
  [ACTIONS.LOAD_ACCOUNT_USERS]: state => setIsLoadingUsers(state, true),
  [ACTIONS.LOAD_ACCOUNT_USERS_SUCCESS]: updateUsers,
  [ACTIONS.LOAD_ACCOUNT_USERS_ERROR]: state => setIsLoadingUsers(state, false),
  [ACTIONS.SET_SHOW_ADD_USER_MODAL]: updateShowAddUserModal,
  [ACTIONS.SET_SHOW_DELETE_USER_MODAL]: updateShowDeleteUserModal,
  [ACTIONS.SET_SHOW_EDIT_USER_MODAL]: updateShowEditUserModal,
  [ACTIONS.SET_ROLES]: updateRoles,
  [ACTIONS.ADD_USER]: state => setIsAddingUser(state, true),
  [ACTIONS.ADD_USER_SUCCESS]: state => setIsAddingUser(state, false),
  [ACTIONS.ADD_USER_ERROR]: state => setIsAddingUser(state, false),
  [ACTIONS.EDIT_USER]: state => setIsEditingUser(state, true),
  [ACTIONS.EDIT_USER_SUCCESS]: state => setIsEditingUser(state, false),
  [ACTIONS.EDIT_USER_ERROR]: state => setIsEditingUser(state, false),
  [ACTIONS.DELETE_USER]: state => setIsDeletingUser(state, true),
  [ACTIONS.DELETE_USER_SUCCESS]: state => setIsDeletingUser(state, false),
  [ACTIONS.DELETE_USER_ERROR]: state => setIsDeletingUser(state, false),
  [ACTIONS.SET_DELETE_SELECTED_USER]: updateDeleteSelectedUser,
  [ACTIONS.SET_EDIT_SELECTED_USER]: updateEditSelectedUser,
  [ACTIONS.SET_AUTHORIZATION_ERROR]: updateAuthorizationError,
  [ACTIONS.RESEND_INVITE_EMAIL]: state => setIsResendingInvite(state, true),
  [ACTIONS.RESEND_INVITE_EMAIL_SUCCESS]: state => setIsResendingInvite(state, false),
  [ACTIONS.RESEND_INVITE_EMAIL_ERROR]: state => setIsResendingInvite(state, false),
  [ACTIONS.CHECK_USERNAME_AVAILABILITY]: updateCheckUsername,
  [ACTIONS.CHECK_USERNAME_AVAILABILITY_SUCCESS]: updateUsernameAvailabilitySuccess,
  [ACTIONS.SET_AVAILABILITY_ERRORS]: updateCheckUsernameError,
  [ACTIONS.RESET_STATE]: resetState,
};

export default createReducer(INITIAL_STATE, handlers);