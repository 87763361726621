import { VIEW_COLUMNS } from './columns';
import { QUICK_FILTERS, VIEW_FILTERS } from './filters';
import { formatColumnSettingsStorage } from './helpers';

export const ORDER_LIST_COLUMNS_DEFAULT_ORDER = [
  VIEW_COLUMNS.ORDER_ID.id,
  VIEW_COLUMNS.SALES_ORDER_NUMBER.id,
  VIEW_COLUMNS.PURCHASE_DATE.id,
  VIEW_COLUMNS.SALES_CHANNEL.id,
  VIEW_COLUMNS.ADDRESS.id,
  VIEW_COLUMNS.SHIPPING_PRIORITY.id,
];

export const ORDER_LIST_COLUMNS = {
  [VIEW_COLUMNS.ORDER_ID.id]: {
    ...VIEW_COLUMNS.ORDER_ID,
    enabled: true,
    sortable: true,
    filterableContextMenu: true,
    copyable: true,
    editable: false,
    cellProps: {
      align: 'left',
      minWidth: '250px',
    },
  },
  [VIEW_COLUMNS.SALES_ORDER_NUMBER.id]: {
    ...VIEW_COLUMNS.SALES_ORDER_NUMBER,
    enabled: true,
    sortable: true,
    filterableContextMenu: true,
    copyable: true,
    editable: false,
    cellProps: {
      align: 'left',
      minWidth: '180px',
    },
  },
  [VIEW_COLUMNS.PURCHASE_DATE.id]: {
    ...VIEW_COLUMNS.PURCHASE_DATE,
    enabled: true,
    sortable: true,
    filterableContextMenu: true,
    copyable: true,
    editable: false,
    cellProps: {
      align: 'left',
      minWidth: '180px',
    },
  },
  [VIEW_COLUMNS.SALES_CHANNEL.id]: {
    ...VIEW_COLUMNS.SALES_CHANNEL,
    enabled: true,
    sortable: true,
    filterableContextMenu: true,
    copyable: true,
    editable: false,
    cellProps: {
      align: 'left',
      minWidth: '180px',
    },
  },
  [VIEW_COLUMNS.ADDRESS.id]: {
    ...VIEW_COLUMNS.ADDRESS,
    enabled: true,
    sortable: false,
    filterableContextMenu: false,
    copyable: false,
    editable: false,
    cellProps: {
      align: 'left',
      minWidth: '250px',
    },
  },
  [VIEW_COLUMNS.SHIPPING_PRIORITY.id]: {
    ...VIEW_COLUMNS.SHIPPING_PRIORITY,
    enabled: true,
    sortable: true,
    filterableContextMenu: true,
    copyable: true,
    editable: false,
    cellProps: {
      align: 'left',
      minWidth: '180px',
    },
  },
};

export const ORDER_LIST_FILTERS = {
  ...VIEW_FILTERS,
};

export const ORDER_LIST_COLUMNS_STORAGE_SETTINGS = formatColumnSettingsStorage(
  ORDER_LIST_COLUMNS,
);

export const ORDER_LIST_QUICK_FILTERS = [
  ...QUICK_FILTERS,
];