import { createReducer } from 'redux-create-reducer';
import { ACTIONS } from '../constants';

export const INITIAL_STATE = {
  selectedItem: null,
  createdItem: null,
  errorMessage: null,
  isSubmitting: false,
  showDeleteModal: false,
  showManagementModal: false,
  isRemoving: false,
};

const updateSelectedItem = (state, { selectedItem }) => ({
  ...state,
  selectedItem,
});

const updateCreatedItem = (state, { payload }) => ({
  ...state,
  createdItem: payload,
  isSubmitting: false,
});

const updateErrorMessage = (state, { errorMessage }) => ({
  ...state,
  errorMessage,
  isSubmitting: false,
});

const setSubmitting = state => ({
  ...state,
  createdItem: null,
  errorMessage: null,
  isSubmitting: true,
});

const setIsSubmitting = (state, isSubmitting) => ({
  ...state,
  isSubmitting,
});

const updateShowDeleteModal = (state, { showDeleteModal }) => ({
  ...state,
  showDeleteModal,
});

const setIsRemoving = (state, isRemoving) => ({
  ...state,
  isRemoving,
});

const updateShowManagementModal = (state, { showManagementModal }) => ({
  ...state,
  showManagementModal,
});

export const resetState = () => ({
  ...INITIAL_STATE,
});

export const handlers = {
  [ACTIONS.SET_SELECTED_SUPPLIER]: updateSelectedItem,
  [ACTIONS.CREATE_SUPPLIER]: setSubmitting,
  [ACTIONS.CREATE_SUPPLIER_SUCCESS]: updateCreatedItem,
  [ACTIONS.CREATE_SUPPLIER_ERROR]: updateErrorMessage,
  [ACTIONS.REMOVE_SUPPLIER]: state => setIsRemoving(state, true),
  [ACTIONS.REMOVE_SUPPLIER_SUCCESS]: state => setIsRemoving(state, false),
  [ACTIONS.REMOVE_SUPPLIER_ERROR]: state => setIsRemoving(state, false),
  [ACTIONS.EDIT_SUPPLIER]: setSubmitting,
  [ACTIONS.EDIT_SUPPLIER_ERROR]: updateErrorMessage,
  [ACTIONS.EDIT_SUPPLIER_SUCCESS]: state => setIsSubmitting(state, false),
  [ACTIONS.SHOW_DELETE_SUPPLIER_MODAL]: updateShowDeleteModal,
  [ACTIONS.SHOW_SUPPLIER_MANAGEMENT_MODAL]: updateShowManagementModal,
  [ACTIONS.RESET_SUPPLIER_MANAGEMENT_STATE]: resetState,
};

export default createReducer(INITIAL_STATE, handlers);