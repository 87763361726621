import React from 'react';
import PropTypes from 'prop-types';
import {
  FiChevronLeft, FiChevronRight, FiChevronsLeft, FiChevronsRight,
} from 'react-icons/fi';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import TextField, { FIELD_VALUE_TYPES } from 'components/Mui/TextField';
import Form from 'components/Form';
import { PaginationWrap } from './styles';

const Pagination = ({
  pageNumber,
  disabled,
  onArrowClick,
  onFormSubmit,
  fieldValue,
  onFieldChange,
  totalPages,
  totalRecords,
}) => (
  <Box display="flex" alignItems="center">
    <Box mr={1}>
      <IconButton
        disabled={
            disabled ||
            pageNumber < 2
          }
        onClick={() => onArrowClick(1)}
      >
        <FiChevronsLeft size="1.2rem" />
      </IconButton>
    </Box>
    <Box mr={1}>
      <IconButton
        disabled={
            disabled ||
            pageNumber < 2
          }
        onClick={() => onArrowClick(pageNumber - 1)}
      >
        <FiChevronLeft size="1.2rem" />
      </IconButton>
    </Box>
    <Box mr={1} maxWidth="5rem">
      <Form onSubmit={onFormSubmit}>
        <TextField
          label="Page"
          variant="outlined"
          size="small"
          value={fieldValue}
          onChange={onFieldChange}
          valueType={FIELD_VALUE_TYPES.INTEGER}
          disabled={
              disabled ||
              totalPages === 1
            }
        />
      </Form>
    </Box>
    <Box mr={1}>
      <span>{`of ${totalPages >= 0 ? totalPages : 0}`}</span>
    </Box>
    <Box mr={1}>
      <IconButton
        disabled={
          disabled ||
          pageNumber >= totalPages
        }
        onClick={() => onArrowClick(pageNumber + 1)}
      >
        <FiChevronRight size="1.2rem" />
      </IconButton>
    </Box>
    <Box mr={1}>
      <IconButton
        disabled={
          disabled ||
          pageNumber >= totalPages
        }
        onClick={() => onArrowClick(totalPages)}
      >
        <FiChevronsRight size="1.2rem" />
      </IconButton>
    </Box>
    {String(totalRecords) && (
      <Box mr={1}>
        <span>
          {totalRecords > 0 ? `${totalRecords} Records` : 'No Records'}
        </span>
      </Box>
    )}
  </Box>
);

Pagination.propTypes = {
  pageNumber: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
  onArrowClick: PropTypes.func.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  fieldValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onFieldChange: PropTypes.func.isRequired,
  totalPages: PropTypes.number.isRequired,
  totalRecords: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Pagination.defaultProps = {
  totalRecords: null,
};

export default Pagination;

export { PaginationWrap };