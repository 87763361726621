import { queryStringFromObj } from 'utils/urlParser';
import _forEach from 'lodash/forEach';
import HttpRequest from '../HttpRequest';
import ENDPOINTS, { ORDER_FEED_DEFAULT_PARAMS, ORDER_LINE_FEED_DEFAULT_PARAMS } from './constants';

export default class OrdersApi extends HttpRequest {
  orderFeedServerSide({ params = {} }) {
    const defaultParams = {
      ...ORDER_FEED_DEFAULT_PARAMS,
    };

    const requestParams = {
      ...defaultParams,
      ...params,
    };

    const queryString = queryStringFromObj(requestParams);
    return this.call(`${ENDPOINTS.ORDER_FEED_SERVER_SIDE}${queryString}`);
  }

  orderFeedToCSV({ params = {} }) {
    const defaultParams = {
      ...ORDER_FEED_DEFAULT_PARAMS,
    };

    const requestParams = {
      ...defaultParams,
      ...params,
    };

    const bodyFormData = new FormData();
    const { filter } = requestParams;
    bodyFormData.set('payload', JSON.stringify({
      ...requestParams,
      filter: JSON.stringify(filter),
    }));

    return this.call(ENDPOINTS.ORDER_FEED_SERVER_SIDE, {
      method: 'POST',
      data: bodyFormData,
    });
  }

  updateFlag(guidOrderLine, params = {}) {
    const bodyFormData = new FormData();
    bodyFormData.set('guid_order_line', guidOrderLine);
    _forEach(params, (param, key) => {
      bodyFormData.set(key, param);
    });
    return this.call(ENDPOINTS.UPDATE_FLAG, {
      method: 'POST',
      data: bodyFormData,
    });
  }

  sweepOrderLine({ guidOrderLine, salesOrderNumber }) {
    const queryString = queryStringFromObj({
      guid_order_line: guidOrderLine,
      sales_order_number: salesOrderNumber,
    });
    return this.call(`${ENDPOINTS.SWEEP_ORDER_LINE}${queryString}`);
  }

  addOrder({ params = {} }) {
    const bodyFormData = [
      `orders=${encodeURIComponent(JSON.stringify(params))}`,
      'json',
    ].join('&');

    return this.call(ENDPOINTS.ADD_ORDER, {
      method: 'POST',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      },
      data: bodyFormData,
    });
  }

  generatePickTicket({ salesOrderNumbers }) {
    const queryString = queryStringFromObj({
      so: salesOrderNumbers.join(','),
    });
    return this.call(`${ENDPOINTS.GENERATE_PICK_TICKET}${queryString}`);
  }

  getOrderExporterPastReports({ date, orderType }) {
    // timestamp 2020-02-24 12:00:00
    // orderType b2b_email
    const queryString = queryStringFromObj({
      timestamp: date,
      orderType,
    });
    return this.call(`${ENDPOINTS.ORDER_EXPORTER}${queryString}`);
  }

  getOrderExporterReportCSV({ params = {} }) {
    const { timestamp, orderType, isPast } = params;
    const bodyFormData = new FormData();

    bodyFormData.set('timestamp', timestamp);
    bodyFormData.set('orderType', orderType);
    bodyFormData.set('isPast', isPast);

    return this.call(ENDPOINTS.ORDER_EXPORTER, {
      method: 'POST',
      data: bodyFormData,
    });
  }

  getFilteredOrderLineFeed({ params = {} }) {
    const defaultParams = {
      ...ORDER_LINE_FEED_DEFAULT_PARAMS,
    };

    const requestParams = {
      ...defaultParams,
      ...params,
    };

    const queryString = queryStringFromObj(requestParams);
    return this.call(`${ENDPOINTS.FILTERED_ORDER_LINES}${queryString}`);
  }

  getFilteredOrderFeed({ params = {} }) {
    const defaultParams = {
      ...ORDER_FEED_DEFAULT_PARAMS,
    };

    const requestParams = {
      ...defaultParams,
      ...params,
    };

    const queryString = queryStringFromObj(requestParams);
    return this.call(`${ENDPOINTS.FILTERED_ORDERS}${queryString}`);
  }

  getOrderDetails({ orderId }) {
    const requestParams = {
      order_id: orderId,
    };

    const queryString = queryStringFromObj(requestParams);
    return this.call(`${ENDPOINTS.ORDER_DETAILS}${queryString}`);
  }

  postOrderNote({ orderId, note }) {
    const bodyFormData = new FormData();

    bodyFormData.set('order_id', orderId);
    bodyFormData.set('note', note);

    return this.call(ENDPOINTS.ADD_ORDER_NOTE, {
      method: 'POST',
      data: bodyFormData,
    });
  }

  getOrderLineNotes({ guidOrderLineIds }) {
    const requestParams = {
      guid_order_line: guidOrderLineIds,
    };

    const queryString = queryStringFromObj(requestParams);
    return this.call(`${ENDPOINTS.ORDER_LINE_NOTES}${queryString}`);
  }

  postAddOrderLineNotes({ params = {} }) {
    const { guidOrderLine, note } = params;
    const bodyFormData = new FormData();

    bodyFormData.set('guid_order_line', guidOrderLine);
    bodyFormData.set('note', note);

    return this.call(ENDPOINTS.ADD_ORDER_LINE_NOTES, {
      method: 'POST',
      data: bodyFormData,
    });
  }
}

export { ENDPOINTS };
