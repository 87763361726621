import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import _includes from 'lodash/includes';
import _reduce from 'lodash/reduce';
import _get from 'lodash/get';

export const withMissingRequired = (requiredFields, formValues) => {
  if (!formValues) {
    return;
  }
  const missingValues = _map(requiredFields, key => {
    const value = formValues[key];
    return _isEmpty(value);
  });

  return _includes(missingValues, true);
};

export const applyDiscountToPrice = (price, qty = 1, discount) => {
  const priceNumber = Number(price);
  const discountNumber = Number(discount);
  if (!price || !discount) {
    return price;
  }

  return Number((priceNumber - ((priceNumber * discountNumber) / 100)) * qty).toFixed(2);
};

export const getDiscountOfPrice = (originalPrice, newPrice, qty) => {
  const originalPriceNumber = Number(originalPrice);
  const newPriceNumber = Number(newPrice);

  if (!originalPrice || !newPrice) {
    return;
  }

  const discount = (100 - ((newPriceNumber * 100) / (originalPriceNumber * qty))).toFixed(2);

  return discount < 0 || discount === '-0.00' ? '0.00' : discount;
};

export const getTaxValueOfPrice = (taxPercentage, price) => {
  if (!taxPercentage || !price) {
    return;
  }

  const taxPercentageNumber = Number(taxPercentage);
  const priceNumber = Number(price);

  return ((priceNumber * taxPercentageNumber) / 100).toFixed(2);
};

export const getPriceWithTax = (price, tax) => {
  if (!tax || !price) {
    return;
  }

  const taxNumber = Number(tax);
  const priceNumber = Number(price);

  return (priceNumber + taxNumber).toFixed(2);
};

export const getOrderGrandTotal = orderItems => {
  if (_isEmpty(orderItems)) {
    return '0.00';
  }

  const totalAmount = _reduce(orderItems, (sum, item) => {
    const price = _get(item, 'price', 0);
    const tax = _get(item, 'sales_tax_amount', 0);
    return sum + (Number(price) + Number(tax));
  }, 0);

  return totalAmount.toFixed(2);
};

export const getOrderTotalTax = orderItems => {
  if (_isEmpty(orderItems)) {
    return '0.00';
  }

  const totalTax = _reduce(orderItems, (sum, item) => {
    const tax = _get(item, 'sales_tax_amount');
    return sum + Number(tax);
  }, 0);

  return totalTax.toFixed(2);
};

export const getOrderSubtotal = orderItems => {
  if (_isEmpty(orderItems)) {
    return '0.00';
  }

  const subtotalAmount = _reduce(orderItems, (sum, item) => {
    const qtyOrdered = _get(item, 'qty_ordered');
    const originalPrice = (_get(item, 'original_price', 0) * Number(qtyOrdered)).toFixed(2);
    return sum + Number(originalPrice);
  }, 0);

  return subtotalAmount.toFixed(2);
};

export const getTotalDiscountAmount = orderItems => {
  if (_isEmpty(orderItems)) {
    return '0.00';
  }

  const totalDiscount = _reduce(orderItems, (sum, item) => {
    const qtyOrdered = _get(item, 'qty_ordered');
    const originalPrice = (_get(item, 'original_price', 0) * Number(qtyOrdered)).toFixed(2);
    const price = _get(item, 'price', 0);

    return sum + (Number(originalPrice) - Number(price));
  }, 0);

  return totalDiscount.toFixed(2);
};

export const isEmptyQty = qty => Number(qty) === 0;