import { combineReducers } from 'redux';
import managementReducers from './managementReducers';
import feedReducers from './feedReducers';
import settingsReducers from './settingsReducers';
import brandManagementReducers from './brandManagementReducers';
import brandsReducers from './brandsReducers';
import suppliersReducers from './suppliersReducers';
import supplierManagementReducers from './supplierManagementReducers';

export default combineReducers({
  brandManagement: brandManagementReducers,
  brands: brandsReducers,
  feed: feedReducers,
  management: managementReducers,
  settings: settingsReducers,
  supplierManagement: supplierManagementReducers,
  suppliers: suppliersReducers,
});
