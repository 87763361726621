import { createReducer } from 'redux-create-reducer';
import _get from 'lodash/get';
import { PICKING_ZONE_ACTIONS as ACTIONS } from '../constants';

export const INITIAL_STATE = {
  zone: null,
  changedItems: [],
  isFetchingZone: false,
  isReloadingZone: false,
  isScanningUPC: false,
  isMoving: false,
  isLockingZone: false,
  moveItemsError: null,
  fieldZoneName: '',
  fieldUPCNumber: '',
  selectedItems: [],
};

const updateFieldZoneName = (state, { fieldZoneName }) => ({
  ...state,
  fieldZoneName: fieldZoneName && fieldZoneName.toUpperCase(),
});

const updateFieldUPCNumber = (state, { fieldUPCNumber }) => ({
  ...state,
  fieldUPCNumber,
});

const setIsFetchingZone = (state, isFetchingZone = INITIAL_STATE.isFetchingZone) => ({
  ...state,
  isFetchingZone,
});

const setScanZoneName = (state, { zoneName }) => {
  const currentZoneName = _get(state, 'zone.location', null);
  const isReloadingZone = currentZoneName === zoneName;

  return {
    ...state,
    isFetchingZone: true,
    isReloadingZone,
    fieldZoneName: INITIAL_STATE.fieldZoneName,
    fieldUPCNumber: INITIAL_STATE.fieldUPCNumber,
  };
};

const setScanZoneNameSuccess = (state, { payload }) => ({
  ...state,
  isFetchingZone: INITIAL_STATE.isFetchingZone,
  isReloadingZone: INITIAL_STATE.isReloadingZone,
  fieldZoneName: INITIAL_STATE.fieldZoneName,
  zone: payload,
});

const updateChangedItems = (state, { list }) => ({
  ...state,
  changedItems: list,
});

const setIsLockingZone = (state, isLockingZone) => ({
  ...state,
  isLockingZone,
});

const setLockZoneSuccess = (state, { payload }) => {
  const nonce = _get(payload, 'nonce');
  const owner = _get(payload, 'owner');
  const lockAcquired = _get(payload, 'lock_acquired');

  return {
    ...state,
    isLockingZone: INITIAL_STATE.isLockingZone,
    zone: {
      ...state.zone,
      lock: {
        ...state.zone.lock,
        locked: lockAcquired,
        nonce,
        owner,
      },
    },
  };
};

const setUnlockZoneSuccess = (state, { payload }) => {
  const lockReleased = _get(payload, 'lock_released');
  if (!lockReleased) {
    return { ...state };
  }

  return {
    ...state,
    isLockingZone: INITIAL_STATE.isLockingZone,
    zone: {
      ...state.zone,
      lock: {
        ...state.zone.lock,
        locked: false,
        nonce: undefined,
        owner: null,
      },
    },
  };
};

const setReloadZoneLockSuccess = (state, { payload }) => ({
  ...state,
  isLockingZone: INITIAL_STATE.isLockingZone,
  fieldUPCNumber: INITIAL_STATE.fieldUPCNumber,
  fieldZoneName: INITIAL_STATE.fieldZoneName,
  zone: {
    ...state.zone,
    lock: {
      ...state.zone.lock,
      ...payload,
    },
  },
});

const setScanUPCSuccess = state => ({
  ...state,
  isScanningUPC: INITIAL_STATE.isScanningUPC,
  fieldUPCNumber: INITIAL_STATE.fieldUPCNumber,
  fieldZoneName: INITIAL_STATE.fieldZoneName,
});

const setIsScanningUPC = (state, isScanningUPC = INITIAL_STATE.isScanningUPC) => ({
  ...state,
  isScanningUPC,
});

const setIsMoving = (state, isMoving = INITIAL_STATE.isMoving) => ({
  ...state,
  isMoving,
});

const setMoveItems = state => ({
  ...state,
  moveItemsError: INITIAL_STATE.moveItemsError,
  isMoving: true,
});

const updateSelectedItems = (state, { selectedItems }) => ({
  ...state,
  selectedItems,
});

export const handlers = {
  [ACTIONS.SET_FIELD_ZONE_NAME]: updateFieldZoneName,
  [ACTIONS.SET_FIELD_UPC_NUMBER]: updateFieldUPCNumber,

  [ACTIONS.SCAN_ZONE_NAME]: setScanZoneName,
  [ACTIONS.SCAN_ZONE_NAME_SUCCESS]: setScanZoneNameSuccess,
  [ACTIONS.SCAN_ZONE_NAME_ERROR]: state => setIsFetchingZone(state, false),

  [ACTIONS.SCAN_UPC]: state => setIsScanningUPC(state, true),
  [ACTIONS.SCAN_UPC_SUCCESS]: setScanUPCSuccess,
  [ACTIONS.SCAN_UPC_ERROR]: state => setIsScanningUPC(state, false),

  [ACTIONS.SET_ZONE_CHANGED_ITEMS]: updateChangedItems,

  [ACTIONS.LOCK_ZONE]: state => setIsLockingZone(state, true),
  [ACTIONS.LOCK_ZONE_SUCCESS]: setLockZoneSuccess,
  [ACTIONS.LOCK_ZONE_ERROR]: state => setIsLockingZone(state, false),
  [ACTIONS.UNLOCK_ZONE]: state => setIsLockingZone(state, true),
  [ACTIONS.UNLOCK_ZONE_SUCCESS]: setUnlockZoneSuccess,
  [ACTIONS.UNLOCK_ZONE_ERROR]: state => setIsLockingZone(state, false),
  [ACTIONS.RELOAD_ZONE_LOCK]: state => setIsLockingZone(state, true),
  [ACTIONS.RELOAD_ZONE_LOCK_SUCCESS]: setReloadZoneLockSuccess,
  [ACTIONS.RELOAD_ZONE_LOCK_ERROR]: state => setIsLockingZone(state, false),

  [ACTIONS.MOVE_SELECTED_ITEMS]: setMoveItems,
  [ACTIONS.MOVE_SELECTED_ITEMS_SUCCESS]: state => setIsMoving(state, false),
  [ACTIONS.MOVE_SELECTED_ITEMS_ERROR]: state => setIsMoving(state, false),

  [ACTIONS.UPDATE_SELECTED_ITEMS]: updateChangedItems,
  [ACTIONS.SET_SELECTED_ITEMS]: updateSelectedItems,
};

export default createReducer(INITIAL_STATE, handlers);