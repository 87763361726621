export const ACTIONS = {
  INIT_CONTAINER: 'INTEGRATIONS__INIT_CONTAINER',
  INIT_CONTAINER_SUCCESS: 'INTEGRATIONS__INIT_CONTAINER_SUCCESS',
  INIT_CONTAINER_ERROR: 'INTEGRATIONS__INIT_CONTAINER_ERROR',
  EXIT_CONTAINER: 'INTEGRATIONS__EXIT_CONTAINER',

  LOAD_CHANNELS: 'INTEGRATIONS__LOAD_CHANNELS',
  LOAD_CHANNELS_SUCCESS: 'INTEGRATIONS__LOAD_CHANNELS_SUCCESS',
  LOAD_CHANNELS_ERROR: 'INTEGRATIONS__LOAD_CHANNELS_ERROR',

  LOAD_INTEGRATIONS: 'INTEGRATIONS__LOAD_INTEGRATIONS',
  LOAD_INTEGRATIONS_SUCCESS: 'INTEGRATIONS__LOAD_INTEGRATIONS_SUCCESS',
  LOAD_INTEGRATIONS_ERROR: 'INTEGRATIONS__LOAD_INTEGRATIONS_ERROR',

  SET_SHOW_ADD_INTEGRATION_MODAL: 'INTEGRATIONS__SET_SHOW_ADD_INTEGRATION_MODAL',
  SET_SHOW_MODIFY_INTEGRATION_MODAL: 'INTEGRATIONS__SET_SHOW_MODIFY_INTEGRATION_MODAL',
  SET_SHOW_DELETE_INTEGRATION_MODAL: 'INTEGRATIONS__SET_SHOW_DELETE_INTEGRATION_MODAL',

  SET_ADD_INTEGRATION_ID: 'INTEGRATIONS__SET_ADD_INTEGRATION_ID',
  SET_MODIFY_INTEGRATION_ID: 'INTEGRATIONS__SET_MODIFY_INTEGRATION_ID',
  SET_DELETE_INTEGRATION_ID: 'INTEGRATIONS__SET_DELETE_INTEGRATION_ID',

  RESET_STATE: 'INTEGRATIONS__RESET_STATE',
};