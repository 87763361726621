import {
  all, takeEvery, put, call, select,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';
import ROUTES from 'constants/routes';
import { formatSortByToObject, formatFilterToObject } from 'app/api/helpers';
import { hashFiltersToURL } from 'features/sagaHelpers';
import { getFilteredOrderFeed, getFilteredOrderLineFeed } from 'services/orders/actions';
import { getArrangedFilters } from 'features/helpers';
import { ACTIONS, ORDER_VIEW_TYPE } from '../constants';
import {
  loadOrderLines,
  loadOrderLinesError,
  loadOrderLinesSuccess,
  setFilters,
  setIsFetchingFeed,
} from '../actions';
import {
  getSettingsDays,
  getSettingsItemsPerPage,
  getSettingsSorting,
  getLastPage,
  getFilters,
  selectOrderViewType,
} from '../selectors';

export function* getOrderViewParams(otherParams = {}) {
  const days = yield select(getSettingsDays);
  const itemsPerPage = yield select(getSettingsItemsPerPage);
  const { columnId, direction } = yield select(getSettingsSorting);
  const page = yield select(getLastPage);
  const sortBy = yield call(formatSortByToObject, columnId, direction);
  const filters = yield select(getFilters);
  const filter = yield call(formatFilterToObject, filters);

  return {
    days,
    itemsPerPage,
    page,
    sortBy,
    filter,
    ...otherParams,
  };
}

function* requestOrderFeed(otherParams = {}) {
  const orderViewType = yield select(selectOrderViewType);
  const filters = yield select(getFilters);

  const fetchOrderViewData = orderViewType === ORDER_VIEW_TYPE.ORDER_LINE
    ? getFilteredOrderLineFeed
    : getFilteredOrderFeed;

  yield call(hashFiltersToURL, filters, ROUTES.ORDER_VIEW.BASE);

  const params = yield call(getOrderViewParams, otherParams);

  yield put(fetchOrderViewData(params, loadOrderLinesSuccess, loadOrderLinesError));
}

export function* loadOrderLinesWatcher() {
  yield put(setIsFetchingFeed(true));
  yield call(requestOrderFeed);
}

function* loadOrderLinesErrorWatcher({ errorMessage }) {
  yield call([toast, toast.error], errorMessage);
}

function* arrangeFiltersWatcher({ destination, source }) {
  const filters = yield select(getFilters);
  const updatedFilters = yield call(getArrangedFilters, destination, source, filters);
  yield put(setFilters(updatedFilters));
  if (JSON.stringify(filters) !== JSON.stringify(updatedFilters)) {
    yield put(loadOrderLines());
  }
}

export default function* loadOrderLinesSagas() {
  yield all([
    takeEvery([
      ACTIONS.LOAD_ORDER_LINES,
      ACTIONS.REFRESH_ORDER_FEED,
      ACTIONS.SORT_ORDER_FEED,
      ACTIONS.SET_FILTER_OPERAND,
      ACTIONS.UNSET_FILTER_OPERAND,
      ACTIONS.CLEAR_FILTERS,
      ACTIONS.GO_TO_PAGE,
      ACTIONS.SET_ITEMS_PER_PAGE,
      ACTIONS.SET_DAYS_RANGE,
      ACTIONS.SET_ORDER_VIEW_TYPE,
    ], loadOrderLinesWatcher),
    takeEvery(ACTIONS.LOAD_ORDER_LINES_ERROR, loadOrderLinesErrorWatcher),
    takeEvery(ACTIONS.ARRANGE_FILTERS, arrangeFiltersWatcher),
  ]);
}