import { all, fork } from 'redux-saga/effects';
import inventoryManagementSagas from './InventoryManagement/sagas';
import orderViewSagas from './OrderView/sagas';
import orderManagementSagas from './OrderManagement/sagas';
import toolsSagas from './Tools/sagas';
import accountSettingsSagas from './AccountSettings/sagas';
import reportsSagas from './Reports/sagas';
import productManagementSagas from './ProductManagement/sagas';
import purchaseOrderManagementSagas from './PurchaseOrderManagement/sagas';

export default function* featuresSagas(...args) {
  yield all([
    fork(accountSettingsSagas, ...args),
    fork(inventoryManagementSagas, ...args),
    fork(orderViewSagas, ...args),
    fork(orderManagementSagas, ...args),
    fork(toolsSagas, ...args),
    fork(reportsSagas, ...args),
    fork(productManagementSagas, ...args),
    fork(purchaseOrderManagementSagas, ...args),
  ]);
}
