import { combineReducers } from 'redux';
import downloadReducers from './downloadReducers';
import feedReducers from './feedReducers';
import settingsReducers from './settingsReducers';

export default combineReducers({
  feed: feedReducers,
  settings: settingsReducers,
  download: downloadReducers,
});
