import { formatSortByToObject, formatFilterToObject } from '../helpers';

const ENDPOINTS = {
  PRODUCTS: '/products',
  IMPORT_PRODUCTS: '/products/upload',
  VALIDATE_PRODUCTS: '/products/upload/validate',
  UPLOAD_PRODUCTS_LOGS: '/products/upload/logs',
  DELETE_PRODUCTS_TEMPLATE: '/products/omni_product_delete_template.xlsx',
  EDIT_PRODUCTS_TEMPLATE: '/products/omni_product_update_template.xlsx',
  ADD_PRODUCTS_TEMPLATE: '/products/omni_product_add_template.xlsx',
  DOWNLOAD_UPLOADED_FILE: (fileKey = '') => `/products/upload/${fileKey}`,
  DOWNLOAD_ERROR_FILE: (fileKey = '') => `/products/upload/upload_result/${fileKey}`,
  DOWNLOAD_PRODUCTS_LOGS: '/products/download/requests',
  DOWNLOAD_PRODUCTS: '/products/download',
  DOWNLOAD_PRODUCTS_FILE: (fileKey = '') => `/products/download/${fileKey}`,
  BRANDS_SUPPLIERS: '/brands_suppliers',
  BRANDS_SUPPLIERS_ADD: '/brands_suppliers/add',
  BRANDS_SUPPLIERS_DELETE: '/brands_suppliers/delete',
  BRANDS_SUPPLIERS_UPDATE: '/brands_suppliers/update',
  FILTERED_BRANDS_SUPPLIERS: '/brands_suppliers/filtered_brands_suppliers',
};

export const PRODUCTS_DEFAULT_PARAMS = {
  itemsPerPage: 25,
  days: '180',
  sortBy: formatSortByToObject('brand', 'asc'),
  filter: formatFilterToObject(),
  page: 1,
  parser: 'advanced',
};

export const BRANDS_SUPPLIERS_DEFAULT_PARAMS = {
  itemsPerPage: 25,
  days: '180',
  sortBy: formatSortByToObject('brand_name', 'asc'),
  filter: formatFilterToObject(),
  page: 1,
  parser: 'advanced',
};

export default ENDPOINTS;