import {
  all, call, select, put, takeEvery,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { INVENTORY_MANAGEMENT_ERRORS, INVENTORY_MANAGEMENT_SUCCESS } from 'features/InventoryManagement/constants';
import {
  sagas,
  toggleSelectItemWorker,
  toggleSelectAllItemsWorker,
  cancelChangedItemsWorker,
  scanUPCWorker,
  moveSelectedItemsWorker,
  removeFromShippingZone,
} from './sagaWorkers';
import {
  getChangedItems,
  getZoneItems,
  getOtherZoneName,
  findZoneItemByUPC,
  selectSelectedItems,
} from '../selectors/shippingZoneSelectors';
import {
  setChangedItems,
  updateSelectedItems,
  scanUPCSuccess,
  scanUPCError,
  moveSelectedItemsSuccess,
  moveSelectedItemsError,
  setSelectedItems,
} from '../actions/shippingZone';
import {
  reloadZone,
} from '../actions/pickingZone';
import { SHIPPING_ZONE_ACTIONS as ACTIONS } from '../constants';

function* toggleSelectItemWatcher({ upc, isSelected }) {
  yield call(toggleSelectItemWorker, {
    upc, isSelected, getChangedItems, updateSelectedItems,
  });
}

function* toggleSelectAllItemsWatcher({ selectAll }) {
  yield call(toggleSelectAllItemsWorker, { selectAll, getChangedItems, updateSelectedItems });
}

function* cancelChangedItemsWatcher() {
  yield call(cancelChangedItemsWorker, { getZoneItems, setChangedItems });
}

function* scanUPCWatcher({ upc }) {
  const targetZoneName = yield select(getOtherZoneName);
  yield call(scanUPCWorker, {
    upc,
    targetZoneName,
    findZoneItemByUPC,
    scanUPCSuccess,
    scanUPCError,
  });
}

function* scanUPCSuccessWatcher({ list }) {
  yield call(removeFromShippingZone, list);
}

function* scanUPCErrorWatcher({ errorMessage }) {
  yield call([toast, toast.error], errorMessage);
  yield put(reloadZone());
}

function* moveSelectedItemsWatcher() {
  const targetZoneName = yield select(getOtherZoneName);
  yield call(moveSelectedItemsWorker, {
    targetZoneName,
    getChangedItems,
    moveSelectedItemsSuccess,
    moveSelectedItemsError,
    selectSelectedItems,
  });
}

function* moveSelectedItemsSuccessWatcher({ list }) {
  yield call(removeFromShippingZone, list);
  yield put(setSelectedItems([]));
  yield call([toast, toast.success], INVENTORY_MANAGEMENT_SUCCESS.MOVING_ITEMS_FROM_SHIPPING_ZONE);
}

function* moveSelectedItemsErrorWatcher({ errorMessage }) {
  yield put(reloadZone());
  yield call([toast, toast.error], errorMessage || INVENTORY_MANAGEMENT_ERRORS.MOVING_ITEMS);
}

function* setZoneItemsWatcher({ list }) {
  yield put(setChangedItems(list));
}

export default function* shippingZoneSagas() {
  const sharedSagas = sagas(ACTIONS, {
    toggleSelectItemWatcher,
    toggleSelectAllItemsWatcher,
    cancelChangedItemsWatcher,
    scanUPCWatcher,
    moveSelectedItemsWatcher,
  });

  yield all([
    takeEvery(ACTIONS.SET_ZONE_ITEMS, setZoneItemsWatcher),
    takeEvery(ACTIONS.SCAN_UPC_SUCCESS, scanUPCSuccessWatcher),
    takeEvery(ACTIONS.SCAN_UPC_ERROR, scanUPCErrorWatcher),
    takeEvery(ACTIONS.MOVE_SELECTED_ITEMS_SUCCESS, moveSelectedItemsSuccessWatcher),
    takeEvery(ACTIONS.MOVE_SELECTED_ITEMS_ERROR, moveSelectedItemsErrorWatcher),
    ...sharedSagas,
  ]);
}