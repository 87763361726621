import { createReducer } from 'redux-create-reducer';
import _map from 'lodash/map';
import {
  ACTIONS,
  ORDER_LINES_FILTERS,
  ORDER_LINES_QUICK_FILTERS,
} from '../constants';

export const INITIAL_STATE = {
  columnFilterOptions: _map(ORDER_LINES_FILTERS),
  filterOptions: ORDER_LINES_FILTERS,
  quickFilterOptions: ORDER_LINES_QUICK_FILTERS,
};

const updateFilterOptions = (state, { filterOptions }) => ({
  ...state,
  filterOptions,
});

const updateColumnFilterOptions = (state, { columnFilterOptions }) => ({
  ...state,
  columnFilterOptions: _map(columnFilterOptions),
});

const updateQuickFilterOptions = (state, { quickFilterOptions }) => ({
  ...state,
  quickFilterOptions,
});

export const handlers = {
  [ACTIONS.SET_FILTER_OPTIONS]: updateFilterOptions,
  [ACTIONS.SET_COLUMN_FILTER_OPTIONS]: updateColumnFilterOptions,
  [ACTIONS.SET_QUICK_FILTER_OPTIONS]: updateQuickFilterOptions,
};

export default createReducer(INITIAL_STATE, handlers);