import { createAction } from 'redux-create-action';
import { ACTIONS } from './constants';

export const initContainer = createAction(ACTIONS.INIT_CONTAINER);

export const downloadEditTemplate = createAction(ACTIONS.DOWNLOAD_EDIT_TEMPLATE);
export const downloadEditTemplateSuccess = createAction(ACTIONS.DOWNLOAD_EDIT_TEMPLATE_SUCCESS, 'payload');
export const downloadEditTemplateError = createAction(ACTIONS.DOWNLOAD_EDIT_TEMPLATE_ERROR, 'errorMessage', 'error');

export const downloadAddTemplate = createAction(ACTIONS.DOWNLOAD_ADD_TEMPLATE);
export const downloadAddTemplateSuccess = createAction(ACTIONS.DOWNLOAD_ADD_TEMPLATE_SUCCESS, 'payload');
export const downloadAddTemplateError = createAction(ACTIONS.DOWNLOAD_ADD_TEMPLATE_ERROR, 'errorMessage', 'error');

export const downloadDeleteTemplate = createAction(ACTIONS.DOWNLOAD_DELETE_TEMPLATE);
export const downloadDeleteTemplateSuccess = createAction(ACTIONS.DOWNLOAD_DELETE_TEMPLATE_SUCCESS, 'payload');
export const downloadDeleteTemplateError = createAction(ACTIONS.DOWNLOAD_DELETE_TEMPLATE_ERROR, 'errorMessage', 'error');

export const validateUploadFile = createAction(ACTIONS.VALIDATE_UPLOAD_FILE, 'file');
export const validateUploadFileSuccess = createAction(ACTIONS.VALIDATE_UPLOAD_FILE_SUCCESS, 'payload');
export const validateUploadFileError = createAction(ACTIONS.VALIDATE_UPLOAD_FILE_ERROR, 'errorMessage', 'error');
export const setUploadFileIsValid = createAction(ACTIONS.SET_UPLOAD_FILE_IS_VALID, 'isUploadFileValid');
export const setUploadFileValidationErrors = createAction(ACTIONS.SET_UPLOAD_FILE_VALIDATION_ERRORS, 'uploadFileErrors');
export const resetValidateUpload = createAction(ACTIONS.RESET_VALIDATE_UPLOAD);

export const uploadFile = createAction(ACTIONS.UPLOAD_FILE, 'file');
export const uploadFileSuccess = createAction(ACTIONS.UPLOAD_FILE_SUCCESS, 'payload');
export const uploadFileError = createAction(ACTIONS.UPLOAD_FILE_ERROR, 'errorMessage', 'error');

export const loadUploadLogs = createAction(ACTIONS.LOAD_UPLOAD_LOGS);
export const loadUploadLogsSuccess = createAction(ACTIONS.LOAD_UPLOAD_LOGS_SUCCESS, 'payload');
export const loadUploadLogsError = createAction(ACTIONS.LOAD_UPLOAD_LOGS_ERROR, 'errorMessage', 'error');

export const setUploadLogs = createAction(ACTIONS.SET_UPLOAD_LOGS, 'uploadLogs');

export const setUploadType = createAction(ACTIONS.SET_UPLOAD_TYPE, 'uploadType');

export const downloadOriginalFile = createAction(ACTIONS.DOWNLOAD_ORIGINAL_FILE, 'fileKey', 'originalFileName');
export const downloadOriginalFileSuccess = createAction(ACTIONS.DOWNLOAD_ORIGINAL_FILE_SUCCESS, 'payload');
export const downloadOriginalFileError = createAction(ACTIONS.DOWNLOAD_ORIGINAL_FILE_ERROR, 'errorMessage', 'error');

export const cancelUploadRequest = createAction(ACTIONS.CANCEL_UPLOAD_REQUEST, 'fileKey');
export const cancelUploadRequestSuccess = createAction(ACTIONS.CANCEL_UPLOAD_REQUEST_SUCCESS, 'payload');
export const cancelUploadRequestError = createAction(ACTIONS.CANCEL_UPLOAD_REQUEST_ERROR, 'errorMessage', 'error');

export const downloadErrorFile = createAction(ACTIONS.DOWNLOAD_ERROR_FILE, 'fileKey', 'originalFileName');
export const downloadErrorFileSuccess = createAction(ACTIONS.DOWNLOAD_ERROR_FILE_SUCCESS, 'payload');
export const downloadErrorFileError = createAction(ACTIONS.DOWNLOAD_ERROR_FILE_ERROR, 'errorMessage', 'error');

export const resetProductCreatorState = createAction(ACTIONS.RESET_STATE);