import { FiShoppingBag } from 'react-icons/fi';
import { AiFillFilePpt } from 'react-icons/ai';
import { HiInboxIn } from 'react-icons/hi';
import ROUTES from 'constants/routes';
import { isFeatureEnabled } from 'app/featureFlags';
import { FEATURE_FLAGS } from 'app/featureFlags/constants';

export const ACTIONS = {
  INIT_CONTAINER: 'PURCHASE_ORDER_MANAGEMENT__INIT_CONTAINER',
  EXIT_CONTAINER: 'P0_MANAGEMENT__EXIT_CONTAINER',
  RESET_FEATURE_REDUCERS: 'P0_MANAGEMENT__RESET_FEATURE_REDUCERS',
};

export const PURCHASE_ORDER_MANAGEMENT_SECTIONS = {
  PURCHASE_ORDERS: {
    id: 'purchase_orders',
    title: 'Purchase Orders',
    route: ROUTES.PURCHASE_ORDER_MANAGEMENT.PURCHASE_ORDERS(),
    icon: FiShoppingBag,
    disabled: !isFeatureEnabled(FEATURE_FLAGS.PURCHASE_ORDER_MANAGEMENT_PURCHASE_ORDERS),
  },
  PURCHASE_ORDER_CREATOR: {
    id: 'purchase_order_creator',
    title: 'Purchase Order Creator',
    route: ROUTES.PURCHASE_ORDER_MANAGEMENT.PURCHASE_ORDER_CREATOR,
    icon: AiFillFilePpt,
    disabled: false,
  },
  RECEIVING: {
    id: 'receiving',
    title: 'Receiving',
    route: ROUTES.PURCHASE_ORDER_MANAGEMENT.RECEIVING,
    icon: HiInboxIn,
    disabled: false,
  },
};