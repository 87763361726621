import { createReducer } from 'redux-create-reducer';
import { ACTIONS } from './constants';

const INITIAL_STATE = {
  salesOrderNumbers: '',
  isGenerating: false,
  showPickTicketsPopup: false,
  generatedPickTickets: null,
  showPickTicketGeneratorModal: false,
};

const updateSalesOrderNumbers = (state, { salesOrderNumbers }) => ({
  ...state,
  salesOrderNumbers,
});

const resetState = () => ({
  ...INITIAL_STATE,
});

const setIsGenerating = (state, isGenerating) => ({
  ...state,
  isGenerating,
});

const showPopup = (state, { showPickTicketsPopup }) => ({
  ...state,
  showPickTicketsPopup,
});

const showGeneratorModal = (state, { showPickTicketGeneratorModal }) => ({
  ...state,
  showPickTicketGeneratorModal,
});

const setGeneratedPickTickets = (state, { payload }) => ({
  ...state,
  isGenerating: INITIAL_STATE.isGenerating,
  generatedPickTickets: payload,
});

const handlers = {
  [ACTIONS.GENERATE_TICKETS]: state => setIsGenerating(state, true),
  [ACTIONS.GENERATE_TICKETS_SUCCESS]: setGeneratedPickTickets,
  [ACTIONS.GENERATE_TICKETS_ERROR]: state => setIsGenerating(state, false),
  [ACTIONS.SET_SALES_ORDER_NUMBERS]: updateSalesOrderNumbers,
  [ACTIONS.SET_SHOW_POPUP]: showPopup,
  [ACTIONS.SET_SHOW_PICK_TICKET_GENERATOR_MODAL]: showGeneratorModal,
  [ACTIONS.RESET_STATE]: resetState,
};

export default createReducer(INITIAL_STATE, handlers);
