import { createReducer } from 'redux-create-reducer';
import { ACTIONS } from '../constants';

const INITIAL_STATE = {
  orderItems: [],
  isScanningItem: false,
};

const setIsScanningItem = (state, isScanningItem) => ({
  ...state,
  isScanningItem,
});

const addItem = (state, { item }) => {
  const newItem = {
    ...item,
    qty_ordered: '1',
    qty_shipped: '0',
    discount_percentage: '0.00',
    sales_tax_percentage: '0.00',
    sales_tax_amount: '0.00',
    original_price: String(item.price),
    price: String(item.price),
    price_with_tax: String(item.price + (item.tax || 0)),
    referring_replacement_id: '',
    referring_return_id: '',
  };

  const items = [
    ...state.orderItems,
    newItem,
  ];
  return {
    ...state,
    orderItems: items,
  };
};

const updateOrderItems = (state, { orderItems }) => ({
  ...state,
  orderItems,
});

const handlers = {
  [ACTIONS.SCAN_ITEM]: state => setIsScanningItem(state, true),
  [ACTIONS.SCAN_ITEM_SUCCESS]: state => setIsScanningItem(state, false),
  [ACTIONS.SCAN_ITEM_ERROR]: state => setIsScanningItem(state, false),
  [ACTIONS.SET_ITEM]: addItem,
  [ACTIONS.SET_ORDER_ITEMS]: updateOrderItems,
};

export default createReducer(INITIAL_STATE, handlers);