import {
  put, select, call,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';
import _omit from 'lodash/omit';
import { getErrorMessage } from 'utils/error';
import { createTempFile } from 'utils/file';
import { SERVICE_ERRORS } from './constants';

export function* errorToasterSaga({ errorMessage }) {
  yield call([toast, toast.error], errorMessage);
}

export function* successToasterSaga({ message }) {
  yield call([toast, toast.success], message);
}

export function* fileSaverSaga({
  fileName,
  fileData,
  blobType = 'text/plain',
  errorActionCallback,
}) {
  try {
    const fileURL = createTempFile({ fileName, fileData, blobType });
    const fakeLink = document.createElement('a');
    fakeLink.href = fileURL;
    fakeLink.setAttribute('download', fileName);
    fakeLink.click();
  } catch (err) {
    const error = new Error(SERVICE_ERRORS.CREATING_FILE(fileName));
    if (errorActionCallback) {
      yield put(errorActionCallback(SERVICE_ERRORS.CREATING_FILE(fileName), error));
    }
  }
}

export function* serviceSaga(apiSelector, endpoint, action = {}, options = {}) {
  const { onSuccess, onError } = options;
  const {
    successActionCallback, errorActionCallback, ...rest
  } = action;
  const params = _omit(rest, ['type']);

  const api = yield select(apiSelector);
  try {
    const response = yield call([api, api[endpoint]], params);
    const result = response.data ? response.data : response;

    if (successActionCallback) {
      yield put(successActionCallback(result));
    }

    if (onSuccess) {
      yield call(onSuccess, result);
    }

    return result;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    if (errorActionCallback) {
      yield put(errorActionCallback(errorMessage, error));
    }

    if (onError) {
      yield call(onError, error);
    }

    return error;
  }
}