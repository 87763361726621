import HttpRequest from '../HttpRequest';
import ENDPOINTS from './constants';

export default class AccountApi extends HttpRequest {
  getUser() {
    return this.call(`${ENDPOINTS.GET_USER_PROFILE}`);
  }

  updateUserProfileInfo(username, profileInfo) {
    /* { first_name, last_name, email_address, phone_number, } = profileInfo; */

    const bodyFormData = new FormData();
    bodyFormData.set('user', username);
    bodyFormData.set('info', JSON.stringify(profileInfo));

    return this.call(ENDPOINTS.UPDATE_USER_PROFILE_INFO, {
      method: 'POST',
      data: bodyFormData,
    });
  }

  changePassword(currentPassword, newPassword) {
    const bodyFormData = new FormData();
    bodyFormData.set('current_password', currentPassword);
    bodyFormData.set('new_password', newPassword);

    return this.call(ENDPOINTS.CHANGE_PASSWORD, {
      method: 'POST',
      data: bodyFormData,
    });
  }

  getBusinessInfo() {
    return this.call(ENDPOINTS.GET_BUSINESS_INFO);
  }

  updateBusinessInfo(updatedInfo) {
    const bodyFormData = new FormData();
    bodyFormData.set('info', JSON.stringify(updatedInfo));

    return this.call(ENDPOINTS.UPDATE_BUSINESS_INFO, {
      method: 'POST',
      data: bodyFormData,
    });
  }

  uploadBusinessLogo(file) {
    const bodyFormData = new FormData();
    bodyFormData.set('file', file);

    return this.call(ENDPOINTS.UPLOAD_BUSINESS_LOGO, {
      method: 'POST',
      data: bodyFormData,
    });
  }
}

export { ENDPOINTS };
