import {
  all,
  takeEvery,
  put,
  call,
  select,
  race,
  take,
} from 'redux-saga/effects';
import _filter from 'lodash/filter';
import { toast } from 'react-toastify';
import { convertDateToISO } from 'utils/date';
import { getSalesChart, getSalesSummary } from 'services/dataVisualization/actions';
import { ACTIONS } from '../constants';
import {
  fetchSalesSummary,
  fetchSalesChart,
  fetchSalesSummarySuccess,
  fetchSalesSummaryError,
  fetchSalesChartSuccess,
  fetchSalesChartError,
  generateVisualizationError,
  generateVisualizationSuccess,
} from '../actions';
import {
  getEndDate,
  getSelectedBrands,
  getSelectedVendors,
  getSettingsColumns,
  getStartDate,
  selectChartSettings,
} from '../selectors';

export function* getReportRequestParams() {
  const selectedStartDate = yield select(getStartDate);
  const selectedEndDate = yield select(getEndDate);
  const columns = yield select(getSettingsColumns);
  const selectedBrands = yield select(getSelectedBrands);
  const selectedVendors = yield select(getSelectedVendors);

  const startDate = convertDateToISO(selectedStartDate);
  const endDate = convertDateToISO(selectedEndDate);
  const selectedCols = _filter(columns, { enabled: true }).map(({ id }) => id);
  return {
    startDate,
    endDate,
    selectedCols,
    selectedBrands,
    selectedVendors,
  };
}

function* getVisualizationFilterParams() {
  const selectedStartDate = yield select(getStartDate);
  const selectedEndDate = yield select(getEndDate);
  const selectedBrands = yield select(getSelectedBrands);
  const selectedVendors = yield select(getSelectedVendors);

  const startDate = convertDateToISO(selectedStartDate);
  const endDate = convertDateToISO(selectedEndDate);

  return {
    startDate,
    endDate,
    selectedBrands,
    selectedVendors,
  };
}

function* generateVisualizationWatcher() {
  yield put(fetchSalesSummary());
  yield put(fetchSalesChart());

  const { success, error } = yield race({
    success: take([
      ACTIONS.FETCH_SALES_SUMMARY_SUCCESS,
      ACTIONS.FETCH_SALES_CHART_SUCCESS,
    ]),
    error: take([
      ACTIONS.FETCH_SALES_SUMMARY_ERROR,
      ACTIONS.FETCH_SALES_CHART_ERROR,
    ]),
  });

  if (success) {
    yield put(generateVisualizationSuccess());
  } else if (error) {
    yield put(generateVisualizationError());
  }
}

function* generateVisualizationErrorWatcher({ errorMessage }) {
  yield call([toast, toast.error], errorMessage);
}

function* fetchSalesSummaryWatcher() {
  const params = yield call(getVisualizationFilterParams);
  yield put(getSalesSummary(params, fetchSalesSummarySuccess, fetchSalesSummaryError));
}

function* fetchSalesChartWatcher() {
  const chartSettings = yield select(selectChartSettings);
  const filterParams = yield call(getVisualizationFilterParams);
  const params = {
    ...filterParams,
    ...chartSettings,
  };
  yield put(getSalesChart(params, fetchSalesChartSuccess, fetchSalesChartError));
}

function* setChartSettingsWatcher() {
  yield put(fetchSalesChart());
}

export default function* containerSagas() {
  yield all([
    takeEvery(ACTIONS.GENERATE_VISUALIZATION, generateVisualizationWatcher),
    takeEvery(ACTIONS.GENERATE_VISUALIZATION_ERROR, generateVisualizationErrorWatcher),

    takeEvery(ACTIONS.FETCH_SALES_SUMMARY, fetchSalesSummaryWatcher),
    takeEvery(ACTIONS.FETCH_SALES_CHART, fetchSalesChartWatcher),

    takeEvery(ACTIONS.SET_CHART_SETTINGS, setChartSettingsWatcher),
  ]);
}