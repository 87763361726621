import {
  all, takeEvery, call, put, select,
} from 'redux-saga/effects';
import { postAddOrderLineNotes } from 'services/orders/actions';
import { errorToasterSaga, successToasterSaga } from 'services/sagaWorkers';
import { submitOrderLineNoteError, submitOrderLineNoteSuccess } from '../actions';
import { ACTIONS } from '../constants';
import { selectNewNote, selectOrderLine } from '../selectors';

function* submitOrderLineNoteWatcher() {
  const newNote = yield select(selectNewNote);
  const orderLine = yield select(selectOrderLine);
  const params = {
    note: newNote,
    guidOrderLine: orderLine.guid_order_line,
  };
  yield put(postAddOrderLineNotes(params, submitOrderLineNoteSuccess, submitOrderLineNoteError));
}

function* submitOrderLineNoteSuccessWatcher() {
  yield call(successToasterSaga, { message: 'Order Line Note Successfully Added.' });
}

export default function* orderLineNoteSagas() {
  yield all([
    takeEvery(ACTIONS.SUBMIT_ORDER_LINE_NOTE, submitOrderLineNoteWatcher),
    takeEvery(ACTIONS.SUBMIT_ORDER_LINE_NOTE_SUCCESS, submitOrderLineNoteSuccessWatcher),
    takeEvery(ACTIONS.SUBMIT_ORDER_LINE_NOTE_ERROR, errorToasterSaga),
  ]);
}