import { createSelector } from 'reselect';
import _get from 'lodash/get';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import { getSessionUserName } from 'app/session/selectors';
import { inventoryManagementState } from 'features/InventoryManagement/selectors';

const zoneId = 'pickingZone';
const pickingState = state => _get(inventoryManagementState(state), 'picking');
const zoneState = (state, zone = zoneId) => _get(pickingState(state), zone);

export const getFieldZoneName = state => _get(zoneState(state), 'fieldZoneName');
export const getFieldUPCNumber = state => _get(zoneState(state), 'fieldUPCNumber');
export const getIsFetchingZone = state => _get(zoneState(state), 'isFetchingZone');
export const getIsReloadingZone = state => _get(zoneState(state), 'isReloadingZone');
export const getIsLockingZone = state => _get(zoneState(state), 'isLockingZone');
export const getIsScanningUPC = state => _get(zoneState(state), 'isScanningUPC');
export const getIsMoving = state => _get(zoneState(state), 'isMoving');

export const getZone = state => _get(zoneState(state), 'zone');
export const getZoneName = state => _get(getZone(state), 'location', '');
export const getZoneLevel = state => _get(getZone(state), 'level');
export const getZoneCol = state => _get(getZone(state), 'col');
export const getZoneRow = state => _get(getZone(state), 'row');
export const getZoneCoords = state => ({
  location: getZoneName(state),
  level: getZoneLevel(state),
  col: getZoneCol(state),
  row: getZoneRow(state),
});
export const getZoneItems = state => _get(getZone(state), 'items', []);
export const getZoneLock = state => _get(getZone(state), 'lock');
export const getZoneLocked = state => _get(getZoneLock(state), 'locked', false);
export const getZoneLockNonce = state => _get(getZoneLock(state), 'nonce');
export const getZoneLockOwner = state => _get(getZoneLock(state), 'owner');
export const findZoneItemByUPC = upc => state => _find(getZoneItems(state), { upc }) || {};

export const getCanUserEditZone = state => getZoneLockOwner(state) === getSessionUserName(state);
export const getCanUserForceUnlock = state => _get(getZoneLock(state), 'can_force_release', false);

export const getChangedItems = state => _get(zoneState(state), 'changedItems', []);
export const getChangedItemsSelected = createSelector(
  getChangedItems,
  items => _filter(items, { selected: true }),
);
export const selectSelectedItems = state => _get(zoneState(state), 'selectedItems', []);