import { createReducer } from 'redux-create-reducer';
import _reduce from 'lodash/reduce';
import _find from 'lodash/find';
import { getLocalStorageSessionUsername } from 'app/persistedState';
import { createLocalStorageReducer } from 'utils/redux';
import { PAGINATION_DAYS, PAGINATION_ITEMS_PER_PAGE } from 'features/components/FeedPagination';
import {
  ACTIONS,
  ORDER_LINES_COLUMNS,
  ORDER_LINES_COLUMNS_STORAGE_SETTINGS,
  ORDER_LINES_COLUMNS_DEFAULT_ORDER,
  ORDER_VIEW_LOCAL_STORAGE_KEY,
  ORDER_VIEW_TYPE,
  ORDER_LIST_COLUMNS_STORAGE_SETTINGS,
  ORDER_LIST_COLUMNS_DEFAULT_ORDER,
  ORDER_LIST_COLUMNS,
} from '../constants';

const viewTypeInitialState = ({
  columns,
  columnsOrder,
  defaultSortingColumnId,
}) => ({
  columns,
  columnsOrder,
  days: _find(PAGINATION_DAYS, { value: 'All' }).value,
  itemsPerPage: _find(PAGINATION_ITEMS_PER_PAGE, { value: 25 }).value,
  sorting: {
    columnId: defaultSortingColumnId,
    direction: 'desc',
  },
});

export const INITIAL_STATE = {
  orderViewType: ORDER_VIEW_TYPE.ORDER_LINE,
  [ORDER_VIEW_TYPE.ORDER_LINE]: viewTypeInitialState({
    columns: ORDER_LINES_COLUMNS_STORAGE_SETTINGS,
    columnsOrder: ORDER_LINES_COLUMNS_DEFAULT_ORDER,
    defaultSortingColumnId: ORDER_LINES_COLUMNS.purchase_date.id,
  }),
  [ORDER_VIEW_TYPE.ORDER_LIST]: viewTypeInitialState({
    columns: ORDER_LIST_COLUMNS_STORAGE_SETTINGS,
    columnsOrder: ORDER_LIST_COLUMNS_DEFAULT_ORDER,
    defaultSortingColumnId: ORDER_LIST_COLUMNS.purchase_date.id,
  }),
};

const viewType = (state, action) => handler => {
  const { orderViewType } = state;
  const viewState = state[orderViewType];
  const updatedViewState = handler(viewState, action);
  return {
    ...state,
    [orderViewType]: {
      ...viewState,
      ...updatedViewState,
    },
  };
};

const updateColumnEnabled = (state, { columnId, enabled }) => {
  const { columns } = state;

  const updatedColumns = _reduce(columns, (result, value, key) => {
    const newResult = result;
    if (value.id === columnId) {
      newResult[key] = {
        ...value,
        enabled,
      };
      return newResult;
    }
    newResult[key] = value;
    return newResult;
  }, {});

  return {
    ...state,
    columns: updatedColumns,
  };
};

const updateColumnsOrder = (state, { columnsOrder }) => ({
  ...state,
  columnsOrder,
});

const updateColumns = (state, { columns }) => ({
  ...state,
  columns,
});

const updateDays = (state, { days }) => ({
  ...state,
  days,
});

const updateItemsPerPage = (state, { itemsPerPage }) => ({
  ...state,
  itemsPerPage,
});

const updateSorting = (state, { columnId, direction }) => ({
  ...state,
  sorting: {
    columnId,
    direction,
  },
});

const updateOrderViewType = (state, { orderViewType }) => ({
  ...state,
  orderViewType,
});

export const handlers = {
  [ACTIONS.SET_COLUMN_ENABLED]: (...args) => viewType(...args)(updateColumnEnabled),
  [ACTIONS.SET_COLUMNS_ORDER]: (...args) => viewType(...args)(updateColumnsOrder),
  [ACTIONS.SET_COLUMNS]: (...args) => viewType(...args)(updateColumns),
  [ACTIONS.SET_DAYS_RANGE]: (...args) => viewType(...args)(updateDays),
  [ACTIONS.SET_ITEMS_PER_PAGE]: (...args) => viewType(...args)(updateItemsPerPage),
  [ACTIONS.SORT_ORDER_FEED]: (...args) => viewType(...args)(updateSorting),
  [ACTIONS.SET_ORDER_VIEW_TYPE]: updateOrderViewType,
};

const settingsReducers = (state, action) => {
  const storedSessionUserName = getLocalStorageSessionUsername();

  return createLocalStorageReducer(
    createReducer(INITIAL_STATE, handlers),
    ORDER_VIEW_LOCAL_STORAGE_KEY(storedSessionUserName),
  )(state, action);
};

export default settingsReducers;