import _reduce from 'lodash/reduce';
import _pick from 'lodash/pick';
import { formatUserLocalStorageKey, UNIQ_LOCAL_STORAGE_KEYS } from 'app/persistedState';

export const ACTIONS = {
  INIT_CONTAINER: 'BRANDS_SUPPLIERS__INIT_CONTAINER',
  EXIT_CONTAINER: 'BRANDS_SUPPLIERS__EXIT_CONTAINER',

  LOAD_BRANDS_SUPPLIERS: 'BRANDS_SUPPLIERS__LOAD_BRANDS_SUPPLIERS',
  LOAD_BRANDS_SUPPLIERS_SUCCESS: 'BRANDS_SUPPLIERS__LOAD_BRANDS_SUPPLIERS_SUCCESS',
  LOAD_BRANDS_SUPPLIERS_ERROR: 'BRANDS_SUPPLIERS__LOAD_BRANDS_SUPPLIERS_ERROR',
  LOAD_FEED: 'BRANDS_SUPPLIERS__LOAD_FEED',

  SHOW_OPTIONS_MODAL: 'BRANDS_SUPPLIERS__SHOW_OPTIONS_MODAL',
  SET_SHOW_DOWNLOAD_MODAL: 'BRANDS_SUPPLIERS__SET_SHOW_DOWNLOAD_MODAL',
  REFRESH_FEED: 'BRANDS_SUPPLIERS__REFRESH_FEED',
  SET_FILTER_OPERAND: 'BRANDS_SUPPLIERS__SET_FILTER_OPERAND',
  UNSET_FILTER_OPERAND: 'BRANDS_SUPPLIERS__UNSET_FILTER_OPERAND',
  CLEAR_FILTERS: 'BRANDS_SUPPLIERS__CLEAR_FILTERS',
  SET_FILTERS: 'BRANDS_SUPPLIERS__SET_FILTERS',
  ARRANGE_FILTERS: 'BRANDS_SUPPLIERS__ARRANGE_FILTERS',

  SET_COLUMNS: 'BRANDS_SUPPLIERS__SET_COLUMNS',
  SET_COLUMNS_ORDER: 'BRANDS_SUPPLIERS__SET_COLUMNS_ORDER',
  SET_COLUMN_ENABLED: 'BRANDS_SUPPLIERS__SET_COLUMN_ENABLED',
  SET_ITEMS_PER_PAGE: 'BRANDS_SUPPLIERS__SET_ITEMS_PER_PAGE',
  SORT_FEED: 'BRANDS_SUPPLIERS__SORT_FEED',
  GO_TO_PAGE: 'BRANDS_SUPPLIERS__GO_TO_PAGE',

  EXPORT_TO_FILE: 'BRANDS_SUPPLIERS__EXPORT_TO_FILE',
  EXPORT_TO_FILE_SUCCESS: 'BRANDS_SUPPLIERS__EXPORT_TO_FILE_SUCCESS',
  EXPORT_TO_FILE_ERROR: 'BRANDS_SUPPLIERS__EXPORT_TO_FILE_ERROR',

  SHOW_BRAND_SUPPLIER_MODAL: 'BRANDS_SUPPLIERS__SHOW_BRAND_SUPPLIER_MODAL',
  SHOW_DELETE_BRAND_SUPPLIER_MODAL: 'BRANDS_SUPPLIERS__SHOW_DELETE_BRAND_SUPPLIER_MODAL',
  SHOW_DELETE_BRAND_MODAL: 'BRANDS_SUPPLIERS__SHOW_DELETE_BRAND_MODAL',
  SHOW_BRAND_MANAGEMENT_MODAL: 'BRANDS_SUPPLIERS__SHOW_BRAND_MANAGEMENT_MODAL',
  SHOW_DELETE_SUPPLIER_MODAL: 'BRANDS_SUPPLIERS__SHOW_DELETE_SUPPLIER_MODAL',
  SHOW_SUPPLIER_MANAGEMENT_MODAL: 'BRANDS_SUPPLIERS__SHOW_SUPPLIER_MANAGEMENT_MODAL',

  SET_SELECTED_ITEM: 'BRANDS_SUPPLIERS__SET_SELECTED_ITEM',

  REMOVE_BRAND_SUPPLIER: 'BRANDS_SUPPLIERS__REMOVE_BRAND_SUPPLIER',
  REMOVE_BRAND_SUPPLIER_SUCCESS: 'BRANDS_SUPPLIERS__REMOVE_BRAND_SUPPLIER_SUCCESS',
  REMOVE_BRAND_SUPPLIER_ERROR: 'BRANDS_SUPPLIERS__REMOVE_BRAND_SUPPLIER_ERROR',

  CREATE_BRAND_SUPPLIER: 'BRANDS_SUPPLIERS__CREATE_BRAND_SUPPLIER',
  CREATE_BRAND_SUPPLIER_SUCCESS: 'BRANDS_SUPPLIERS__CREATE_BRAND_SUPPLIER_SUCCESS',
  CREATE_BRAND_SUPPLIER_ERROR: 'BRANDS_SUPPLIERS__CREATE_BRAND_SUPPLIER_ERROR',

  EDIT_BRAND_SUPPLIER: 'BRANDS_SUPPLIERS__EDIT_BRAND_SUPPLIER',
  EDIT_BRAND_SUPPLIER_SUCCESS: 'BRANDS_SUPPLIERS__EDIT_BRAND_SUPPLIER_SUCCESS',
  EDIT_BRAND_SUPPLIER_ERROR: 'BRANDS_SUPPLIERS__EDIT_BRAND_SUPPLIER_ERROR',

  FETCH_BRANDS: 'BRANDS_SUPPLIERS__FETCH_BRANDS',
  FETCH_BRANDS_SUCCESS: 'BRANDS_SUPPLIERS__FETCH_BRANDS_SUCCESS',
  FETCH_BRANDS_ERROR: 'BRANDS_SUPPLIERS__FETCH_BRANDS_ERROR',

  FETCH_SUPPLIERS: 'BRANDS_SUPPLIERS__FETCH_SUPPLIERS',
  FETCH_SUPPLIERS_SUCCESS: 'BRANDS_SUPPLIERS__FETCH_SUPPLIERS_SUCCESS',
  FETCH_SUPPLIERS_ERROR: 'BRANDS_SUPPLIERS__FETCH_SUPPLIERS_ERROR',

  CREATE_BRAND: 'BRANDS_SUPPLIERS__CREATE_BRAND',
  CREATE_BRAND_SUCCESS: 'BRANDS_SUPPLIERS__CREATE_BRAND_SUCCESS',
  CREATE_BRAND_ERROR: 'BRANDS_SUPPLIERS__CREATE_BRAND_ERROR',

  CREATE_SUPPLIER: 'BRANDS_SUPPLIERS__CREATE_SUPPLIER',
  CREATE_SUPPLIER_SUCCESS: 'BRANDS_SUPPLIERS__CREATE_SUPPLIER_SUCCESS',
  CREATE_SUPPLIER_ERROR: 'BRANDS_SUPPLIERS__CREATE_SUPPLIER_ERROR',

  REMOVE_BRAND: 'BRANDS_SUPPLIERS__REMOVE_BRAND',
  REMOVE_BRAND_SUCCESS: 'BRANDS_SUPPLIERS__REMOVE_BRAND_SUCCESS',
  REMOVE_BRAND_ERROR: 'BRANDS_SUPPLIERS__REMOVE_BRAND_ERROR',

  REMOVE_SUPPLIER: 'BRANDS_SUPPLIERS__REMOVE_SUPPLIER',
  REMOVE_SUPPLIER_SUCCESS: 'BRANDS_SUPPLIERS__REMOVE_SUPPLIER_SUCCESS',
  REMOVE_SUPPLIER_ERROR: 'BRANDS_SUPPLIERS__REMOVE_SUPPLIER_ERROR',

  EDIT_BRAND: 'BRANDS_SUPPLIERS__EDIT_BRAND',
  EDIT_BRAND_SUCCESS: 'BRANDS_SUPPLIERS__EDIT_BRAND_SUCCESS',
  EDIT_BRAND_ERROR: 'BRANDS_SUPPLIERS__EDIT_BRAND_ERROR',

  EDIT_SUPPLIER: 'BRANDS_SUPPLIERS__EDIT_SUPPLIER',
  EDIT_SUPPLIER_SUCCESS: 'BRANDS_SUPPLIERS__EDIT_SUPPLIER_SUCCESS',
  EDIT_SUPPLIER_ERROR: 'BRANDS_SUPPLIERS__EDIT_BSUPPLIER_ERROR',

  SET_SELECTED_BRAND: 'BRANDS_SUPPLIERS__SET_SELECTED_BRAND',
  SET_SELECTED_SUPPLIER: 'BRANDS_SUPPLIERS__SET_SELECTED_SUPPLIER',

  RESET_BRAND_SUPPLIER_MANAGEMENT_STATE: 'BRANDS_SUPPLIERS__RESET_BRAND_SUPPLIER_MANAGEMENT_STATE',
  RESET_BRAND_MANAGEMENT_STATE: 'BRANDS_SUPPLIERS__RESET_BRAND_MANAGEMENT_STATE',
  RESET_SUPPLIER_MANAGEMENT_STATE: 'BRANDS_SUPPLIERS__RESET_SUPPLIER_MANAGEMENT_STATE',
};

export const BRANDS_SUPPLIERS_LOCAL_STORAGE_KEY = username => formatUserLocalStorageKey(
  username, UNIQ_LOCAL_STORAGE_KEYS.BRANDS_SUPPLIERS_VIEW,
);

export const BRANDS_SUPPLIERS_FIELDS = {
  BRAND_NAME: 'brand_name',
  SUPPLIER_NAME: 'supplier_name',
};

export const BRANDS_SUPPLIERS_COLUMNS = {
  [BRANDS_SUPPLIERS_FIELDS.BRAND_NAME]: {
    id: BRANDS_SUPPLIERS_FIELDS.BRAND_NAME,
    enabled: true,
    label: 'Brand',
    sortable: true,
    filterable: true,
    filterableContextMenu: true,
    copyable: true,
    editable: false,
    cellProps: {
      align: 'left',
      minWidth: '250px',
    },
  },
  [BRANDS_SUPPLIERS_FIELDS.SUPPLIER_NAME]: {
    id: BRANDS_SUPPLIERS_FIELDS.SUPPLIER_NAME,
    enabled: true,
    label: 'Supplier',
    sortable: true,
    filterable: true,
    filterableContextMenu: true,
    copyable: true,
    editable: false,
    cellProps: {
      align: 'left',
      minWidth: '250px',
    },
  },
};

export const BRANDS_SUPPLIERS_COLUMNS_STORAGE_SETTINGS = _reduce(
  BRANDS_SUPPLIERS_COLUMNS, (result, value, key) => {
    const newResult = result;
    newResult[key] = _pick(value, ['id', 'enabled']);
    return newResult;
  }, {},
);

export const BRANDS_SUPPLIERS_COLUMNS_DEFAULT_ORDER = [
  BRANDS_SUPPLIERS_FIELDS.BRAND_NAME,
  BRANDS_SUPPLIERS_FIELDS.SUPPLIER_NAME,
];

export const BRAND_SUPPLIER_FIELDS = {
  BRAND_ID: 'brand_id',
  SUPPLIER_ID: 'supplier_id',
};

export const BRAND_FIELDS = {
  NAME: 'name',
};

export const SUPPLIER_FIELDS = {
  NAME: 'name',
};