import styled from 'styled-components';
import { genericBorder } from 'assets/styles/utils';
import { gridColors } from 'components/Grid';
import {
  OLD_LACE,
  ATHENS_GRAY,
  SELAGO,
  FETA,
  WISP_PINK,
  FETA_DARK_5,
  SELAGO_DARK_5,
  OLD_LACE_DARK_5,
  WISP_PINK_DARK_5,
} from 'constants/colors';

export const listColors = gridColors;

const highlightColors = {
  [listColors.default]: ATHENS_GRAY,
  [listColors.danger]: WISP_PINK,
  [listColors.danger_dark]: WISP_PINK_DARK_5,
  [listColors.success]: FETA,
  [listColors.success_dark]: FETA_DARK_5,
  [listColors.info]: SELAGO,
  [listColors.info_dark]: SELAGO_DARK_5,
  [listColors.warning]: OLD_LACE,
  [listColors.warning_dark]: OLD_LACE_DARK_5,
};
// padding 0.25rem 1.5rem
export const ListItem = styled.li`
  position: relative;
  display: flex;
  flex-direction: row;
  ${({ divider }) => divider && `
    border-top: ${genericBorder()};
    border-bottom: ${genericBorder()};
  `}
  padding: ${({ padding }) => padding || '0'};
  transition: background-color 0.3s ease;
  ${({ highlight, highlightColor }) => highlight && `
    background-color: ${highlightColors[highlightColor] || highlightColors.default};
  `}
  ${({ onClick }) => onClick && `
    cursor: pointer;
    &:hover {
      background-color: ${highlightColors.default};
    }
  `} 
`;

export const ListDivider = styled.span`
  display: block;
  height: 1px;
  width: ${({ width }) => (width || '90%')};
  margin: ${({ margin }) => (margin || '0.875rem auto')};
  border-top: ${genericBorder()};
`;

const List = styled.ul`
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: ${({ overflow }) => (overflow || 'auto')};
  max-height: ${({ maxHeight }) => (maxHeight || '100%')};
  width: ${({ width }) => (width || '100%')};
  min-width: ${({ minWidth }) => (minWidth || '0')};
  max-width: ${({ maxWidth }) => (maxWidth || '100%')};
  box-sizing: border-box;
  ${({ withOutline }) => withOutline && `
    border: ${genericBorder()};
  `}
  ${({ divider }) => divider && `
    border-top: ${genericBorder()};
    border-bottom: ${genericBorder()};
  `}
  ${ListItem} {
    ${({ itemsDivider }) => itemsDivider && `
      border-bottom: ${genericBorder()};

      &:last-child {
        border-bottom: none;
      }
    `}
  }
`;

export default List;
