export const ACTIONS = {
  FETCH_MERGE_SHIPMENT: 'SERVICE_SHIPMENTS__FETCH_MERGE_SHIPMENT',
  FETCH_SETUP_SHIP_RULES: 'SERVICE_SHIPMENTS__FETCH_SETUP_SHIP_RULES',
  FETCH_SHIP_RULES: 'SERVICE_SHIPMENTS__FETCH_SHIP_RULES',
  FETCH_SHIP_RULES_CSV: 'SERVICE_SHIPMENTS__FETCH_SHIP_RULES_CSV',
  SET_REMOVE_SHIP_RULES: 'SERVICE_SHIPMENTS__SET_REMOVE_SHIP_RULES',
  SET_ADD_SHIP_RULE: 'SERVICE_SHIPMENTS__SET_ADD_SHIP_RULE',
  SET_UPDATE_SHIP_RULE: 'SERVICE_SHIPMENTS__SET_UPDATE_SHIP_RULE',
  FETCH_BULK_SHIP_TEMPLATE: 'SERVICE_SHIPMENTS__FETCH_BULK_SHIP_TEMPLATE',
  SET_BULK_SHIP_GET_ORDERS: 'SERVICE_SHIPMENTS__SET_BULK_SHIP_GET_ORDERS',
};
