import { FiShoppingBag } from 'react-icons/fi';
import { FaProductHunt, FaLink } from 'react-icons/fa';
import ROUTES from 'constants/routes';
import { isFeatureEnabled } from 'app/featureFlags';
import { FEATURE_FLAGS } from 'app/featureFlags/constants';

export const ACTIONS = {
  INIT_CONTAINER: 'PRODUCT_MANAGEMENT__INIT_CONTAINER',
  EXIT_CONTAINER: 'PRODUCT_MANAGEMENT__EXIT_CONTAINER',
  RESET_FEATURE_REDUCERS: 'PRODUCT_MANAGEMENT__RESET_FEATURE_REDUCERS',
};

export const PRODUCT_MANAGEMENT_SECTIONS = {
  PRODUCTS: {
    id: 'products',
    title: 'Products',
    route: ROUTES.PRODUCT_MANAGEMENT.PRODUCTS(),
    icon: FiShoppingBag,
    disabled: !isFeatureEnabled(FEATURE_FLAGS.PRODUCT_MANAGEMENT_PRODUCTS),
  },
  PRODUCT_CREATOR: {
    id: 'product_creator',
    title: 'Product Creator',
    route: ROUTES.PRODUCT_MANAGEMENT.PRODUCT_CREATOR,
    icon: FaProductHunt,
    disabled: !isFeatureEnabled(FEATURE_FLAGS.PRODUCT_MANAGEMENT_PRODUCT_CREATOR),
  },
  BRANDS_SUPPLIERS: {
    id: 'brands_suppliers',
    title: 'Brands/Suppliers',
    route: ROUTES.PRODUCT_MANAGEMENT.BRANDS_SUPPLIERS(),
    icon: FaLink,
    disabled: !isFeatureEnabled(FEATURE_FLAGS.PRODUCT_MANAGEMENT_BRANDS_SUPPLIERS),
  },
};