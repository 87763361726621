import { formatSortByToObject, formatFilterToObject } from '../helpers';

const ENDPOINTS = {
  ORDER_FEED_SERVER_SIDE: '/order_feed_serverside',
  UPDATE_FLAG: '/update_flag',
  SWEEP_ORDER_LINE: '/sweep_order_line',
  ADD_ORDER: '/add',
  GENERATE_PICK_TICKET: '/generate_pickticket',
  ORDER_EXPORTER: '/order_exporter',
  FILTERED_ORDER_LINES: '/filtered_order_lines',
  FILTERED_ORDERS: '/filtered_orders',
  ORDER_DETAILS: '/order_details',
  ADD_ORDER_NOTE: '/order_notes/add',
  ORDER_LINE_NOTES: '/order_line_notes',
  ADD_ORDER_LINE_NOTES: '/order_line_notes/add',
};

export const ORDER_FEED_DEFAULT_PARAMS = {
  itemsPerPage: 100,
  days: 'All',
  sortBy: formatSortByToObject('purchase_date', 'desc'),
  filter: formatFilterToObject(),
  page: 1,
  parser: 'advanced',
};

export const ORDER_LINE_FEED_DEFAULT_PARAMS = {
  itemsPerPage: 100,
  days: 'All',
  sortBy: formatSortByToObject('purchase_date', 'desc'),
  filter: formatFilterToObject(),
  page: 1,
  parser: 'advanced',
};

export default ENDPOINTS;
