import React from 'react';
import PropTypes from 'prop-types';
import formatMoney from 'utils/formatMoney';
import { TextContentWrap } from 'features/components/FeedGrid/TextContent';

const MoneyContent = ({ item, columnId }) => (
  <TextContentWrap>{formatMoney(item[columnId])}</TextContentWrap>
);

MoneyContent.propTypes = {
  item: PropTypes.shape(),
  columnId: PropTypes.string,
};

MoneyContent.defaultProps = {
  item: {},
  columnId: null,
};

export default MoneyContent;