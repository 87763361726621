import { createReducer } from 'redux-create-reducer';
import { ACTIONS } from './constants';

const INITIAL_STATE = {
  isSubmitting: false,
  error: null,
  changeSuccess: false,
  token: null,
  code: null,
  oldPassword: null,
  changeRequired: false,
};

const updateTokenCode = (state, { token, code }) => ({
  ...state,
  token,
  code,
});

const updateOldPassword = (state, { oldPassword }) => ({
  ...state,
  oldPassword,
});

const updateChangeRequired = (state, { changeRequired }) => ({
  ...state,
  changeRequired,
});

const setChangePassword = state => ({
  ...state,
  isSubmitting: true,
  error: INITIAL_STATE.error,
});

const setChangePasswordSuccess = state => ({
  ...state,
  isSubmitting: INITIAL_STATE.isSubmitting,
  changeSuccess: true,
});

const setChangePasswordError = (state, { errorMessage }) => ({
  ...state,
  isSubmitting: INITIAL_STATE.isSubmitting,
  error: errorMessage,
});

const resetState = () => ({
  ...INITIAL_STATE,
});

const handlers = {
  [ACTIONS.SET_TOKEN_CODE]: updateTokenCode,
  [ACTIONS.SET_OLD_PASSWORD]: updateOldPassword,
  [ACTIONS.SET_CHANGE_REQUIRED]: updateChangeRequired,
  [ACTIONS.CHANGE_PASSWORD]: setChangePassword,
  [ACTIONS.CHANGE_PASSWORD_SUCCESS]: setChangePasswordSuccess,
  [ACTIONS.CHANGE_PASSWORD_ERROR]: setChangePasswordError,
  [ACTIONS.RESET_STATE]: resetState,
};

export default createReducer(INITIAL_STATE, handlers);
