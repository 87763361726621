export const ACTIONS = {
  FETCH_PRODUCTS: 'SERVICE_PRODUCT_MANAGEMENT__FETCH_PRODUCTS',
  FETCH_PRODUCTS_CSV: 'SERVICE_PRODUCT_MANAGEMENT__FETCH_PRODUCTS_CSV',

  FETCH_EDIT_PRODUCTS_TEMPLATE: 'SERVICE_PRODUCT_MANAGEMENT__FETCH_EDIT_PRODUCTS_TEMPLATE',
  FETCH_ADD_PRODUCTS_TEMPLATE: 'SERVICE_PRODUCT_MANAGEMENT__FETCH_ADD_PRODUCTS_TEMPLATE',
  FETCH_DELETE_PRODUCTS_TEMPLATE: 'SERVICE_PRODUCT_MANAGEMENT__FETCH_DELETE_PRODUCTS_TEMPLATE',

  FETCH_PRODUCTS_UPLOAD_LOGS: 'SERVICE_PRODUCT_MANAGEMENT__FETCH_PRODUCTS_UPLOAD_LOGS',

  SET_IMPORT_PRODUCTS_FILE: 'SERVICE_PRODUCT_MANAGEMENT__SET_IMPORT_PRODUCTS_FILE',
  SET_VALIDATE_PRODUCTS_FILE: 'SERVICE_PRODUCT_MANAGEMENT__SET_VALIDATE_PRODUCTS_FILE',

  GET_DOWNLOAD_UPLOADED_FILE: 'SERVICE_PRODUCT_MANAGEMENT__GET_DOWNLOAD_UPLOADED_FILE',
  POST_CANCEL_UPLOAD_REQUEST: 'SERVICE_PRODUCT_MANAGEMENT__POST_CANCEL_UPLOAD_REQUEST',
  GET_DOWNLOAD_ERROR_FILE: 'SERVICE_PRODUCT_MANAGEMENT__GET_DOWNLOAD_ERROR_FILE',

  GET_DOWNLOAD_PRODUCTS_LOGS: 'SERVICE_PRODUCT_MANAGEMENT__GET_DOWNLOAD_PRODUCTS_LOGS',
  POST_DOWNLOAD_PRODUCTS: 'SERVICE_PRODUCT_MANAGEMENT__POST_DOWNLOAD_PRODUCTS',
  GET_DOWNLOAD_PRODUCTS_FILE: 'SERVICE_PRODUCT_MANAGEMENT__GET_DOWNLOAD_PRODUCTS_FILE',
  POST_CANCEL_DOWNLOAD_REQUEST: 'SERVICE_PRODUCT_MANAGEMENT__POST_CANCEL_DOWNLOAD_REQUEST',

  GET_BRANDS_SUPPLIERS: 'SERVICE_PRODUCT_MANAGEMENT__GET_BRANDS_SUPPLIERS',
  GET_FILTERED_BRANDS_SUPPLIERS: 'SERVICE_PRODUCT_MANAGEMENT__GET_FILTERED_BRANDS_SUPPLIERS',
  ADD_BRAND_SUPPLIER: 'SERVICE_PRODUCT_MANAGEMENT__ADD_BRAND_SUPPLIER',
  UPDATE_BRAND_SUPPLIER: 'SERVICE_PRODUCT_MANAGEMENT__UPDATE_BRAND_SUPPLIER',
  DELETE_BRAND_SUPPLIER: 'SERVICE_PRODUCT_MANAGEMENT__DELETE_BRAND_SUPPLIER',
};
