import { createAction } from 'redux-create-action';
import { ACTIONS } from './constants';

export const getReports = createAction(ACTIONS.GET_REPORTS);
export const getReportsSuccess = createAction(ACTIONS.GET_REPORTS_SUCCESS, 'payload');
export const getReportsError = createAction(ACTIONS.GET_REPORTS_ERROR, 'errorMessage', 'error');

export const setSelectedDate = createAction(ACTIONS.SET_SELECTED_DATE, 'selectedDate');
export const setSelectedOrderType = createAction(ACTIONS.SET_SELECTED_ORDER_TYPE, 'selectedOrderType');
export const setSelectedTimestamp = createAction(ACTIONS.SET_SELECTED_TIMESTAMP, 'selectedTimestamp');

export const downloadFile = createAction(ACTIONS.DOWNLOAD_FILE);
export const downloadFileSuccess = createAction(ACTIONS.DOWNLOAD_FILE_SUCCESS, 'payload');
export const downloadFileError = createAction(ACTIONS.DOWNLOAD_FILE_ERROR, 'errorMessage', 'error');

export const exportDownload = createAction(ACTIONS.EXPORT_DOWNLOAD);
export const exportDownloadSuccess = createAction(ACTIONS.EXPORT_DOWNLOAD_SUCCESS, 'payload');
export const exportDownloadError = createAction(ACTIONS.EXPORT_DOWNLOAD_ERROR, 'errorMessage', 'error');

export const resetState = createAction(ACTIONS.RESET_STATE);