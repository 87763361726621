import { createReducer } from 'redux-create-reducer';
import { ACTIONS } from '../constants';

export const INITIAL_STATE = {
  orderLine: null,
  showAddOrderLineNoteModal: false,
  isSubmittingNote: false,
  newNote: '',
};

const setAddOrderLineNote = (state, { orderLine }) => ({
  ...state,
  showAddOrderLineNoteModal: true,
  orderLine,
});

const updateNewNote = (state, { newNote }) => ({
  ...state,
  newNote,
});

const setIsSubmittingNote = (state, isSubmittingNote) => ({
  ...state,
  isSubmittingNote,
});

const setInitialState = () => ({ ...INITIAL_STATE });

export const handlers = {
  [ACTIONS.ADD_ORDER_LINE_NOTE]: setAddOrderLineNote,
  [ACTIONS.CANCEL_ADD_ORDER_LINE_NOTE]: setInitialState,
  [ACTIONS.SET_NEW_NOTE]: updateNewNote,
  [ACTIONS.SUBMIT_ORDER_LINE_NOTE]: state => setIsSubmittingNote(state, true),
  [ACTIONS.SUBMIT_ORDER_LINE_NOTE_SUCCESS]: setInitialState,
  [ACTIONS.SUBMIT_ORDER_LINE_NOTE_ERROR]: state => setIsSubmittingNote(state, false),
};

export default createReducer(INITIAL_STATE, handlers);