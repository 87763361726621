import { createReducer } from 'redux-create-reducer';
import { ACTIONS } from '../constants';

const INITIAL_STATE = {
  selectedPurchaseOrder: null,
  showEditModal: false,
  showDeleteModal: false,
  isUpdating: false,
  isRemoving: false,
};

const setEditShippingRule = (state, { selectedPurchaseOrder }) => ({
  ...state,
  showEditModal: true,
  selectedPurchaseOrder,
});

const setDeleteShippingRule = (state, { selectedPurchaseOrder }) => ({
  ...state,
  showDeleteModal: true,
  selectedPurchaseOrder,
});

const setIsRemoving = (state, isRemoving) => ({
  ...state,
  isRemoving,
});

const setIsUpdating = (state, isUpdating) => ({
  ...state,
  isUpdating,
});

const setRemoveShippingRuleSuccess = state => ({
  ...state,
  isRemoving: INITIAL_STATE.isRemoving,
  showDeleteModal: INITIAL_STATE.showDeleteModal,
  selectedPurchaseOrder: INITIAL_STATE.selectedPurchaseOrder,
});

const setUpdateShippingRuleSuccess = state => ({
  ...state,
  isUpdating: INITIAL_STATE.isUpdating,
  showEditModal: INITIAL_STATE.showEditModal,
});

const resetState = () => ({
  ...INITIAL_STATE,
});

const handlers = {
  [ACTIONS.EDIT_PURCHASE_ORDER]: setEditShippingRule,
  [ACTIONS.DELETE_PURCHASE_ORDER]: setDeleteShippingRule,

  [ACTIONS.CANCEL_DELETE_PURCHASE_ORDER]: resetState,
  [ACTIONS.CANCEL_EDIT_PURCHASE_ORDER]: resetState,

  [ACTIONS.REMOVE_PURCHASE_ORDER]: state => setIsRemoving(state, true),
  [ACTIONS.REMOVE_PURCHASE_ORDER_ERROR]: state => setIsRemoving(state, false),
  [ACTIONS.REMOVE_PURCHASE_ORDER_SUCCESS]: setRemoveShippingRuleSuccess,

  [ACTIONS.UPDATE_PURCHASE_ORDER]: state => setIsUpdating(state, true),
  [ACTIONS.UPDATE_PURCHASE_ORDER_SUCCESS]: setUpdateShippingRuleSuccess,
  [ACTIONS.UPDATE_PURCHASE_ORDER_ERROR]: state => setIsUpdating(state, false),
};

export default createReducer(INITIAL_STATE, handlers);