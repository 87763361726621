import { createAction } from 'redux-create-action';
import { ACTIONS } from './constants';

export const initUsersManagementContainer = createAction(ACTIONS.INIT_USERS_MANAGEMENT_CONTAINER);
export const initUsersManagementContainerSuccess = createAction(
  ACTIONS.INIT_USERS_MANAGEMENT_CONTAINER_SUCCESS,
  'payload',
);
export const initUsersManagementContainerError = createAction(ACTIONS.INIT_USERS_MANAGEMENT_CONTAINER_ERROR, 'errorMessage', 'error');
export const exitUsersManagementContainer = createAction(ACTIONS.EXIT_USERS_MANAGEMENT_CONTAINER);

export const loadAccountUsers = createAction(ACTIONS.LOAD_ACCOUNT_USERS);
export const loadAccountUsersSuccess = createAction(ACTIONS.LOAD_ACCOUNT_USERS_SUCCESS, 'payload');
export const loadAccountUsersError = createAction(ACTIONS.LOAD_ACCOUNT_USERS_ERROR, 'errorMessage', 'error');

export const setRoles = createAction(ACTIONS.SET_ROLES, 'roles');

export const checkUsernameAvailability = createAction(ACTIONS.CHECK_USERNAME_AVAILABILITY, 'username', 'callback');
export const checkUsernameAvailabilitySuccess = createAction(ACTIONS.CHECK_USERNAME_AVAILABILITY_SUCCESS, 'payload');
export const checkUsernameAvailabilityError = createAction(ACTIONS.CHECK_USERNAME_AVAILABILITY_ERROR, 'errorMessage', 'error');
export const setAvailabilityError = createAction(ACTIONS.SET_AVAILABILITY_ERRORS, 'checkAvailabilityError');
export const addUser = createAction(ACTIONS.ADD_USER, 'userData');
export const addUserSuccess = createAction(ACTIONS.ADD_USER_SUCCESS, 'payload');
export const addUserError = createAction(ACTIONS.ADD_USER_ERROR, 'errorMessage', 'error');
export const editUser = createAction(ACTIONS.EDIT_USER, 'userData');
export const editUserSuccess = createAction(ACTIONS.EDIT_USER_SUCCESS, 'payload');
export const editUserError = createAction(ACTIONS.EDIT_USER_ERROR, 'errorMessage', 'error');
export const deleteUser = createAction(ACTIONS.DELETE_USER, 'username');
export const deleteUserSuccess = createAction(ACTIONS.DELETE_USER_SUCCESS, 'payload');
export const deleteUserError = createAction(ACTIONS.DELETE_USER_ERROR, 'errorMessage', 'error');
export const setDeleteSelectedUser = createAction(ACTIONS.SET_DELETE_SELECTED_USER, 'deleteSelectedUser');
export const setEditSelectedUser = createAction(ACTIONS.SET_EDIT_SELECTED_USER, 'editSelectedUser');
export const setAuthorizationError = createAction(ACTIONS.SET_AUTHORIZATION_ERROR, 'authorizationError');

export const setShowAddUserModal = createAction(ACTIONS.SET_SHOW_ADD_USER_MODAL, 'showAddUserModal');
export const setShowDeleteUserModal = createAction(ACTIONS.SET_SHOW_DELETE_USER_MODAL, 'showDeleteUserModal');
export const setShowEditUserModal = createAction(ACTIONS.SET_SHOW_EDIT_USER_MODAL, 'showEditUserModal');

export const resendInviteEmail = createAction(ACTIONS.RESEND_INVITE_EMAIL, 'username');
export const resendInviteEmailSuccess = createAction(ACTIONS.RESEND_INVITE_EMAIL_SUCCESS, 'payload');
export const resendInviteEmailError = createAction(ACTIONS.RESEND_INVITE_EMAIL_ERROR, 'errorMessage', 'error');

export const resetUsersManagementState = createAction(ACTIONS.RESET_STATE);