import {
  all, takeEvery, put, select, call,
} from 'redux-saga/effects';
import _map from 'lodash/map';
import _pick from 'lodash/pick';
import _compact from 'lodash/compact';
import _find from 'lodash/find';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { toast } from 'react-toastify';
import { setAddOrder } from 'services/orders/actions';
import { ACTIONS, PAYMENT_TYPES_OPTIONS, ORDER_TYPES } from '../constants';
import {
  getOrderItems, getOrderId, getShippingInfo, getReferringOrder, getOrderNotes, getSalesChannel,
  getOrderStatus, getOrderTotal, getTotalQty, getTotalQtyShipped, getShippingItem,
} from '../selectors';
import {
  confirmOrderSuccess,
  confirmOrderError,
  setShippingItem,
  setOrderNotes,
  confirmPaymentSuccess,
  setOrderId,
  resetAddOrderReducers,
  generateOrderId,
} from '../actions';

function* confirmOrderWatcher() {
  const orderItems = yield select(getOrderItems);
  const shippingItem = yield select(getShippingItem);
  const orderId = yield select(getOrderId);
  const shippingInfo = yield select(getShippingInfo);
  const referringOrder = yield select(getReferringOrder);
  const orderNotes = yield select(getOrderNotes);
  const salesChannel = yield select(getSalesChannel);
  const orderStatus = yield select(getOrderStatus);
  const orderTotal = yield select(getOrderTotal);
  const totalQty = yield select(getTotalQty);
  const totalQtyShipped = yield select(getTotalQtyShipped);
  const purchaseDate = new Date().toISOString().split('.')[0];
  const orderLines = _compact(_map([...orderItems, shippingItem], item => {
    if (_isEmpty(item)) {
      return null;
    }

    const acceptedProps = _pick(
      item,
      [
        'sku',
        'qty_ordered',
        'qty_shipped',
        'price',
        'referring_replacement_id',
        'referring_return_id',
        'original_price',
        'discount_percentage',
        'sales_tax_amount',
        'sales_tax_percentage',
      ],
    );
    return acceptedProps;
  }));

  const formatOrderLine = orderLines.map((orderLine, index) => ({
    ...orderLine,
    order_line_id: `${orderId}:0${index + 1}`,
  }));

  const params = {
    order_lines: formatOrderLine,
    order_id: orderId,
    purchase_date: purchaseDate,
    order_status: orderStatus,
    sales_channel: salesChannel,
    ...shippingInfo,
    order_total: orderTotal,
    total_qty: totalQty,
    total_qty_shipped: totalQtyShipped,
    referring_order: referringOrder ? String(referringOrder) : '',
    order_notes: orderNotes,
  };

  yield put(setAddOrder(params, confirmOrderSuccess, confirmOrderError));
}

function* confirmOrderSuccessWatcher() {
  yield call([toast, toast.success], 'Order confirmed!');
}

function* confirmOrderErrorWatcher({ errorMessage }) {
  yield call([toast, toast.error], errorMessage);
}

function* setShippingItemSubtotalWatcher({ subtotal }) {
  const shippingItem = yield select(getShippingItem);
  const updatedShippingItem = {
    ...shippingItem,
    price: String(subtotal),
  };

  yield put(setShippingItem(updatedShippingItem));
}

function* confirmPaymentWatcher({ paymentType, transactionIds }) {
  const { getLogText } = _find(PAYMENT_TYPES_OPTIONS, { value: paymentType });
  const logText = getLogText(transactionIds);
  const orderNotes = yield select(getOrderNotes);

  const updatedOrderNotes = _compact([
    orderNotes,
    logText,
  ]).join('\n');

  yield put(setOrderNotes(updatedOrderNotes));
  yield put(confirmPaymentSuccess(true));
}

function* generateOrderIdWatcher() {
  const salesChannel = yield select(getSalesChannel);
  const orderType = _get(_find(ORDER_TYPES, { value: salesChannel }), 'code', 'NA');
  const timestamp = Date.now();
  const pad = Math.floor(Math.random() * 10);
  const orderId = `OW-${orderType}-${timestamp}-${pad}`;
  yield put(setOrderId(orderId));
}

function* restartOrderWatcher() {
  yield put(resetAddOrderReducers());
  yield put(generateOrderId());
}

export default function* completeOrderSagas() {
  yield all([
    takeEvery(ACTIONS.CONFIRM_ORDER, confirmOrderWatcher),
    takeEvery(ACTIONS.CONFIRM_ORDER_SUCCESS, confirmOrderSuccessWatcher),
    takeEvery(ACTIONS.CONFIRM_ORDER_ERROR, confirmOrderErrorWatcher),
    takeEvery(ACTIONS.SET_SHIPPING_ITEM_SUBTOTAL, setShippingItemSubtotalWatcher),
    takeEvery(ACTIONS.CONFIRM_PAYMENT, confirmPaymentWatcher),
    takeEvery(ACTIONS.GENERATE_ORDER_ID, generateOrderIdWatcher),
    takeEvery([
      ACTIONS.CANCEL_ORDER,
      ACTIONS.RESTART_ORDER,
    ], restartOrderWatcher),
  ]);
}