import { createAction } from 'redux-create-action';
import { ACTIONS } from './constants';

export const generateVisualization = createAction(ACTIONS.GENERATE_VISUALIZATION);
export const generateVisualizationSuccess = createAction(ACTIONS.GENERATE_VISUALIZATION_SUCCESS);
export const generateVisualizationError = createAction(ACTIONS.GENERATE_VISUALIZATION_ERROR);

export const fetchSalesSummary = createAction(ACTIONS.FETCH_SALES_SUMMARY);
export const fetchSalesSummarySuccess = createAction(ACTIONS.FETCH_SALES_SUMMARY_SUCCESS, 'payload');
export const fetchSalesSummaryError = createAction(ACTIONS.FETCH_SALES_SUMMARY_ERROR, 'errorMessage', 'error');

export const fetchSalesChart = createAction(ACTIONS.FETCH_SALES_CHART);
export const fetchSalesChartSuccess = createAction(ACTIONS.FETCH_SALES_CHART_SUCCESS, 'payload');
export const fetchSalesChartError = createAction(ACTIONS.FETCH_SALES_CHART_ERROR, 'errorMessage', 'error');

export const setChartSettings = createAction(ACTIONS.SET_CHART_SETTINGS, 'updatedProps');

export const exportToFile = createAction(ACTIONS.EXPORT_TO_FILE);
export const exportToFileSuccess = createAction(ACTIONS.EXPORT_TO_FILE_SUCCESS, 'payload');
export const exportToFileError = createAction(ACTIONS.EXPORT_TO_FILE_ERROR, 'errorMessage', 'error');

export const searchAutocomplete = createAction(ACTIONS.SEARCH_AUTOCOMPLETE, 'fieldId', 'value');
export const searchBrandSuccess = createAction(ACTIONS.SEARCH_BRAND_SUCCESS, 'payload');
export const searchVendorSuccess = createAction(ACTIONS.SEARCH_VENDOR_SUCCESS, 'payload');

export const setColumns = createAction(ACTIONS.SET_COLUMNS, 'columns');
export const setColumnEnabled = createAction(ACTIONS.SET_COLUMN_ENABLED, 'columnId', 'enabled');
export const showOptionsModal = createAction(ACTIONS.SHOW_OPTIONS_MODAL, 'showOptionsModal');
export const toggleColumnsEnabled = createAction(ACTIONS.TOGGLE_COLUMNS_ENABLED, 'enabled');

export const setStartDate = createAction(ACTIONS.SET_START_DATE, 'startDate');
export const setEndDate = createAction(ACTIONS.SET_END_DATE, 'endDate');
export const addBrand = createAction(ACTIONS.ADD_BRAND, 'name');
export const removeBrand = createAction(ACTIONS.REMOVE_BRAND, 'name');
export const addVendor = createAction(ACTIONS.ADD_VENDOR, 'name');
export const removeVendor = createAction(ACTIONS.REMOVE_VENDOR, 'name');

export const resetReportState = createAction(ACTIONS.RESET_STATE);