const ENDPOINTS = {
  AUTH_TEST: '/auth_test',
  LOGIN: '/login',
  LOGOUT: '/logout',
  FORGOT_PASSWORD: '/forgot_password',
  VERIFY_PASSWORD_RESET: '/verify_password_reset',
  CHANGE_TEMPORARY_PASSWORD: '/change_temporary_password',
};

export default ENDPOINTS;
