export const ACTIONS = {
  INIT_CONTAINER: 'INVENTORY_VIEW__INIT_CONTAINER',
  EXIT_CONTAINER: 'INVENTORY_VIEW__EXIT_CONTAINER',
  SET_FIELD_ZONE_NAME: 'INVENTORY_VIEW__SET_FIELD_ZONE_NAME',
  SET_FIELD_UPC_NUMBER: 'INVENTORY_VIEW__SET_FIELD_UPC_NUMBER',
  SET_CHANGED_ITEMS: 'INVENTORY_VIEW__SET_CHANGED_ITEMS',
  SAVE_CHANGED_ITEMS: 'INVENTORY_VIEW__SAVE_CHANGED_ITEMS',
  SAVE_CHANGED_ITEMS_SUCCESS: 'INVENTORY_VIEW__SAVE_CHANGED_ITEMS_SUCCESS',
  SAVE_CHANGED_ITEMS_ERROR: 'INVENTORY_VIEW__SAVE_CHANGED_ITEMS_ERROR',
  SCAN_ZONE_NAME: 'INVENTORY_VIEW__SCAN_ZONE_NAME',
  SCAN_ZONE_NAME_SUCCESS: 'INVENTORY_VIEW__SCAN_ZONE_NAME_SUCCESS',
  SCAN_ZONE_NAME_ERROR: 'INVENTORY_VIEW__SCAN_ZONE_NAME_ERROR',
  SCAN_UPC: 'INVENTORY_VIEW__SCAN_UPC',
  SCAN_UPC_SUCCESS: 'INVENTORY_VIEW__SCAN_UPC_SUCCESS',
  SCAN_UPC_ERROR: 'INVENTORY_VIEW__SCAN_UPC_ERROR',
  TOGGLE_ZONE_LOCK: 'INVENTORY_VIEW__TOGGLE_ZONE_LOCK',
  LOCK_ZONE: 'INVENTORY_VIEW__LOCK_ZONE',
  LOCK_ZONE_SUCCESS: 'INVENTORY_VIEW__LOCK_ZONE_SUCCESS',
  LOCK_ZONE_ERROR: 'INVENTORY_VIEW__LOCK_ZONE_ERROR',
  UNLOCK_ZONE: 'INVENTORY_VIEW__UNLOCK_ZONE',
  UNLOCK_ZONE_SUCCESS: 'INVENTORY_VIEW__UNLOCK_ZONE_SUCCESS',
  UNLOCK_ZONE_ERROR: 'INVENTORY_VIEW__UNLOCK_ZONE_ERROR',
  RELOAD_ZONE_LOCK: 'INVENTORY_VIEW__RELOAD_ZONE_LOCK',
  RELOAD_ZONE_LOCK_SUCCESS: 'INVENTORY_VIEW__RELOAD_ZONE_LOCK_SUCCESS',
  RELOAD_ZONE_LOCK_ERROR: 'INVENTORY_VIEW__RELOAD_ZONE_LOCK_ERROR',
  RENAME_ZONE: 'INVENTORY_VIEW__RENAME_ZONE',
  RENAME_ZONE_SUCCESS: 'INVENTORY_VIEW__RENAME_ZONE_SUCCESS',
  RENAME_ZONE_ERROR: 'INVENTORY_VIEW__RENAME_ZONE_ERROR',
  TOGGLE_SELECT_ITEM: 'INVENTORY_VIEW__TOGGLE_SELECT_ITEM',
  TOGGLE_SELECT_ALL_ITEMS: 'INVENTORY_VIEW__TOGGLE_SELECT_ALL_ITEMS',
  SET_SELECTED_ITEMS: 'INVENTORY_VIEW__SET_SELECTED_ITEMS',
  UPDATE_SELECTED_ITEMS: 'INVENTORY_VIEW__UPDATE_SELECTED_ITEMS',
  DELETE_SELECTED_ITEMS: 'INVENTORY_VIEW__DELETE_SELECTED_ITEMS',
  DELETE_SELECTED_ITEMS_SUCCESS: 'INVENTORY_VIEW__DELETE_SELECTED_ITEMS_SUCCESS',
  DELETE_SELECTED_ITEMS_ERROR: 'INVENTORY_VIEW__DELETE_SELECTED_ITEMS_ERROR',
  MOVE_SELECTED_ITEMS: 'INVENTORY_VIEW__MOVE_SELECTED_ITEMS',
  MOVE_SELECTED_ITEMS_SUCCESS: 'INVENTORY_VIEW__MOVE_SELECTED_ITEMS_SUCCESS',
  MOVE_SELECTED_ITEMS_ERROR: 'INVENTORY_VIEW__MOVE_SELECTED_ITEMS_ERROR',
  TOGGLE_SHOW_DELETE_CONFIRMATION: 'INVENTORY_VIEW__TOGGLE_SHOW_DELETE_CONFIRMATION',
  TOGGLE_SHOW_MOVE_CONFIRMATION: 'INVENTORY_VIEW__TOGGLE_SHOW_MOVE_CONFIRMATION',
  TOGGLE_SHOW_SEARCH_MODAL: 'INVENTORY_VIEW__TOGGLE_SHOW_SEARCH_MODAL',
  SEARCH_ITEMS: 'INVENTORY_VIEW__SEARCH_ITEMS',
  SEARCH_ITEMS_SUCCESS: 'INVENTORY_VIEW__SEARCH_ITEMS_SUCCESS',
  SEARCH_ITEMS_ERROR: 'INVENTORY_VIEW__SEARCH_ITEMS_ERROR',
  SEARCH_ITEMS_RESET: 'INVENTORY_VIEW__SEARCH_ITEMS_RESET',
  SEARCH_ITEMS_LOAD_ZONE: 'INVENTORY_VIEW_SEARCH_ITEMS_LOAD_ZONE',
  SEARCH_ITEMS_LOAD_ZONE_SUCCESS: 'INVENTORY_VIEW_SEARCH_ITEMS_LOAD_ZONE_SUCCESS',
  SEARCH_ITEMS_LOAD_ZONE_ERROR: 'INVENTORY_VIEW_SEARCH_ITEMS_LOAD_ZONE_ERROR',
  RELOAD_ZONE: 'INVENTORY_VIEW__RELOAD_ZONE',
  RESET_STATE: 'INVENTORY_VIEW__RESET_STATE',
};