import { splitZoneName } from 'utils/zoneName';
import { queryStringFromObj } from 'utils/urlParser';
import HttpRequest from '../HttpRequest';
import ENDPOINTS from './constants';

export default class InventoryApi extends HttpRequest {
  getZone(zoneName) {
    const [x, y, z] = splitZoneName(zoneName);
    const queryString = queryStringFromObj({ x, y, z });
    return this.call(`${ENDPOINTS.GET_ZONE}${queryString}`);
  }

  hasLock(zoneName) {
    const bodyFormData = new FormData();
    bodyFormData.set('zone', zoneName);

    return this.call(ENDPOINTS.HAS_LOCK, {
      method: 'POST',
      data: bodyFormData,
    });
  }

  acquireLock(zoneName) {
    const bodyFormData = new FormData();
    bodyFormData.set('zone', zoneName);

    return this.call(ENDPOINTS.ACQUIRE_LOCK, {
      method: 'POST',
      data: bodyFormData,
    });
  }

  releaseLock(zoneName, nonce) {
    const bodyFormData = new FormData();
    bodyFormData.set('zone', zoneName);
    bodyFormData.set('nonce', nonce);

    return this.call(ENDPOINTS.RELEASE_LOCK, {
      method: 'POST',
      data: bodyFormData,
    });
  }

  replaceQty(arrayOfItemObjects) {
    const bodyFormData = new FormData();
    bodyFormData.set('jsVal', JSON.stringify(arrayOfItemObjects));

    return this.call(ENDPOINTS.REPLACE_QTY, {
      method: 'POST',
      data: bodyFormData,
    });
  }

  moveItems(arrayOfItemObjects, zoneName) {
    const bodyFormData = new FormData();
    bodyFormData.set('jsVal', JSON.stringify(arrayOfItemObjects));
    bodyFormData.set('dest_zone', zoneName);

    return this.call(ENDPOINTS.MOVE_ITEMS, {
      method: 'POST',
      data: bodyFormData,
    });
  }

  searchItem(searchTerm) {
    const queryString = queryStringFromObj({ 1: searchTerm });
    return this.call(`${ENDPOINTS.SEARCH_ITEM}${queryString}`);
  }

  moveReplaceDeltaQty(arrayOfItemObjectsSource, arrayOfItemObjectsTarget, zoneName) {
    const bodyFormData = new FormData();
    bodyFormData.set('jsVal', JSON.stringify(arrayOfItemObjectsSource));
    bodyFormData.set('jsValDest', JSON.stringify(arrayOfItemObjectsTarget));
    bodyFormData.set('dest_zone', zoneName);

    return this.call(ENDPOINTS.MOVE_REPLACE_DELTA_QTY, {
      method: 'POST',
      data: bodyFormData,
    });
  }

  getHoldingShippingZone() {
    return this.call(ENDPOINTS.GET_HOLDING_SHIPPING_ZONE);
  }

  moveToShipping(arrayOfItemObjects, shippingZoneName) {
    const bodyFormData = new FormData();
    bodyFormData.set('jsVal', JSON.stringify(arrayOfItemObjects));
    bodyFormData.set('shipping_zone', shippingZoneName);

    return this.call(ENDPOINTS.MOVE_TO_SHIPPING, {
      method: 'POST',
      data: bodyFormData,
    });
  }

  moveFromShipping(arrayOfItemObjects, shippingZoneName) {
    const bodyFormData = new FormData();
    bodyFormData.set('jsValDest', JSON.stringify(arrayOfItemObjects));
    bodyFormData.set('shipping_zone', shippingZoneName);

    return this.call(ENDPOINTS.MOVE_FROM_SHIPPING, {
      method: 'POST',
      data: bodyFormData,
    });
  }

  decrementQty(location, upc, qty) {
    const query = { location, upc, qty };
    const queryString = queryStringFromObj(query);
    return this.call(`${ENDPOINTS.DECREMENT_QTY}${queryString}`);
  }

  getZoneAvailability(sku) {
    const query = { sku };
    const queryString = queryStringFromObj(query);
    return this.call(`${ENDPOINTS.GET_ZONE_AVAILABILITY}${queryString}&ship_pull&json`);
  }
}

export { ENDPOINTS };
