import HttpRequest from '../HttpRequest';
import ENDPOINTS from './constants';

export default class FirebaseApi extends HttpRequest {
  getReadToken() {
    return this.call(ENDPOINTS.GET_READ_TOKEN);
  }

  getReadWriteToken() {
    return this.call(ENDPOINTS.GET_READ_WRITE_TOKEN);
  }
}

export { ENDPOINTS };