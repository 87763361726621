import { createReducer } from 'redux-create-reducer';
import { SHIPPING_ZONE_ACTIONS as ACTIONS } from '../constants';

export const INITIAL_STATE = {
  zoneItems: [],
  changedItems: [],
  isScanningUPC: false,
  isMoving: false,
  moveItemsError: null,
  fieldUPCNumber: '',
  selectedItems: [],
};

const updateFieldUPCNumber = (state, { fieldUPCNumber }) => ({
  ...state,
  fieldUPCNumber,
});

const updateZoneItems = (state, { list }) => ({
  ...state,
  zoneItems: list,
});

const updateChangedItems = (state, { list }) => ({
  ...state,
  changedItems: list,
});

const setScanUPCSuccess = state => ({
  ...state,
  isScanningUPC: INITIAL_STATE.isScanningUPC,
  fieldUPCNumber: INITIAL_STATE.fieldUPCNumber,
  fieldZoneName: INITIAL_STATE.fieldZoneName,
});

const setIsScanningUPC = (state, isScanningUPC = INITIAL_STATE.isScanningUPC) => ({
  ...state,
  isScanningUPC,
});

const setIsMoving = (state, isMoving = INITIAL_STATE.isMoving) => ({
  ...state,
  isMoving,
});

const setMoveItems = state => ({
  ...state,
  moveItemsError: INITIAL_STATE.moveItemsError,
  isMoving: true,
});

const updateSelectedItems = (state, { selectedItems }) => ({
  ...state,
  selectedItems,
});

export const handlers = {
  [ACTIONS.SET_FIELD_UPC_NUMBER]: updateFieldUPCNumber,

  [ACTIONS.SCAN_UPC]: state => setIsScanningUPC(state, true),
  [ACTIONS.SCAN_UPC_SUCCESS]: setScanUPCSuccess,
  [ACTIONS.SCAN_UPC_ERROR]: state => setIsScanningUPC(state, false),

  [ACTIONS.MOVE_SELECTED_ITEMS]: setMoveItems,
  [ACTIONS.MOVE_SELECTED_ITEMS_SUCCESS]: state => setIsMoving(state, false),
  [ACTIONS.MOVE_SELECTED_ITEMS_ERROR]: state => setIsMoving(state, false),

  [ACTIONS.SET_ZONE_ITEMS]: updateZoneItems,
  [ACTIONS.SET_ZONE_CHANGED_ITEMS]: updateChangedItems,
  [ACTIONS.UPDATE_SELECTED_ITEMS]: updateChangedItems,
  [ACTIONS.SET_SELECTED_ITEMS]: updateSelectedItems,
};

export default createReducer(INITIAL_STATE, handlers);