import { combineReducers } from 'redux';
import purchaseOrdersReducers from './PurchaseOrders/reducers';
import purchaseOrderCreatorReducers from './PurchaseOrderCreator/reducers';
import receivingReducers from './Receiving/reducers';
import { ACTIONS } from './constants';

const featureReducers = combineReducers({
  purchaseOrders: purchaseOrdersReducers,
  purchaseOrderCreator: purchaseOrderCreatorReducers,
  receiving: receivingReducers,
});

const purchaseOrderManagementReducers = (state, action) => {
  let featureState = state;
  if (action.type === ACTIONS.RESET_FEATURE_REDUCERS) {
    featureState = undefined;
  }
  return featureReducers(featureState, action);
};

export default purchaseOrderManagementReducers;
