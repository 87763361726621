import { queryStringFromObj } from 'utils/urlParser';
import HttpRequest from '../HttpRequest';
import ENDPOINTS from './constants';

export default class TomApi extends HttpRequest {
  getPurchaseOrder(purchaseOrderNumber) {
    const query = {
      po: purchaseOrderNumber,
    };
    const queryString = queryStringFromObj(query);
    return this.call(`${ENDPOINTS.SERVER_SIDE_TYR}${queryString}`);
  }

  submitPurchaseOrderScannedItems(items, shipment) {
    const bodyFormData = new FormData();
    bodyFormData.set('po', JSON.stringify(items));
    bodyFormData.set('shipment', JSON.stringify(shipment));

    return this.call(ENDPOINTS.SERVER_SIDE_2_TYR, {
      method: 'POST',
      data: bodyFormData,
    });
  }
}

export { ENDPOINTS };