export const ACTIONS = {
  INIT_CONTAINER: 'DASHBOARD__INIT_CONTAINER',
  INIT_CONTAINER_SUCCESS: 'DASHBOARD__INIT_CONTAINER_SUCCESS',
  INIT_CONTAINER_ERROR: 'DASHBOARD__INIT_CONTAINER_ERROR',
  TOGGLE_DRAWER: 'DASHBOARD__TOGGLE_DRAWER',
  SET_FEATURE_NAME: 'DASHBOARD__SET_FEATURE_NAME',
  SET_DOCUMENT_TITLE: 'DASHBOARD__SET_DOCUMENT_TITLE',
  TOGGLE_SIDEBAR: 'DASHBOARD__TOGGLE_SIDEBAR',
  SET_IS_MOBILE_DEVICE: 'DASHBOARD__SET_IS_MOBILE_DEVICE',
};
