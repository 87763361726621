import { createGlobalStyle, css } from 'styled-components';
import { GALLERY, IRON, PORCELAIN } from 'constants/colors';
import reset from './reset';
import fonts from './fonts';
import typography from './typography';
import { genericBorder } from './utils';

const globalStyles = css`
  ${reset};
  ${typography};
  * {
    box-sizing: border-box;
  }
  html, body, #root {
    height: 100%;
  }
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: ${GALLERY};
  }
  ::-ms-input-placeholder { /* Microsoft Edge */
    color: ${GALLERY};
  }
  ::placeholder { /* Most modern browsers support this now. */
  color: ${GALLERY};
  }
  table {
    width: 100%;
    thead, tbody {
      > tr {
        border-bottom: ${genericBorder(IRON)};
      }
    }
    thead {
      tr {
        th {
          background: ${PORCELAIN};
        }
      }
    }
  }
  .custom-scrollbar {
    /* ===== Scrollbar CSS ===== */
    /* Firefox */
    scrollbar-width: thin;
    scrollbar-color: #CCCCCC #f7f7f7;

    /* Chrome, Edge, and Safari */
    ::-webkit-scrollbar {
      width: 12px;
      height: 12px;
    }

    ::-webkit-scrollbar-track {
      background: #f7f7f7;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #CCCCCC;
      border-radius: 10px;
      border: 3px solid #f7f7f7;
    }
    ::-webkit-scrollbar-thumb:hover {
      background-color: #999;
    }
  }
  .truncate {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const GlobalStyles = createGlobalStyle`
  ${globalStyles}
`;

const GlobalStylesWithFonts = createGlobalStyle`
 ${globalStyles};
 ${fonts};
`;

export default GlobalStyles;
export {
  GlobalStylesWithFonts,
};
