import _get from 'lodash/get';
import { featuresState } from 'features/selectors';

export const orderViewState = state => _get(featuresState(state), 'orderView');

// Order Feed
export const orderFeedState = state => _get(orderViewState(state), 'orderFeed');
export const getOrderFeedData = state => _get(orderFeedState(state), 'data');
export const selectOrderFeedItems = state => _get(getOrderFeedData(state), 'items');
export const getOrderFeedDataTotalRecords = state => _get(getOrderFeedData(state), 'totalRecords');
export const getOrderFeedDataPage = state => _get(getOrderFeedData(state), 'page');
export const getOrderFeedDataItemsPerPage = state => _get(getOrderFeedData(state), 'itemsPerPage');
export const getLastPage = state => _get(orderFeedState(state), 'lastPage');
export const getFilters = state => _get(orderFeedState(state), 'filters');

export const getShowOptionsModal = state => _get(orderFeedState(state), 'showOptionsModal');
export const getIsRefreshing = state => _get(orderFeedState(state), 'isRefreshing');
export const getIsExporting = state => _get(orderFeedState(state), 'isExporting');
export const selectIsFetchingFeed = state => _get(orderFeedState(state), 'isFetchingFeed');

// Order Line Management
export const orderLineManagementState = state => _get(orderViewState(state), 'orderLineManagement');
export const getShowEditOrderLineModal = state => _get(orderLineManagementState(state), 'showEditOrderLineModal');
export const getSelectedOrderLine = state => _get(orderLineManagementState(state), 'selectedOrderLine');
export const getIsUpdatingOrderLine = state => _get(orderLineManagementState(state), 'isUpdatingOrderLine');
export const getIsSweepingOrderLine = state => _get(orderLineManagementState(state), 'isSweepingOrderLine');

// Order Line Note
export const orderLineNoteState = state => _get(orderViewState(state), 'orderLineNote');
export const selectShowAddOrderLineNoteModal = state => _get(orderLineNoteState(state), 'showAddOrderLineNoteModal');
export const selectOrderLine = state => _get(orderLineNoteState(state), 'orderLine');
export const selectIsSubmittingNote = state => _get(orderLineNoteState(state), 'isSubmittingNote');
export const selectNewNote = state => _get(orderLineNoteState(state), 'newNote');

// Order Lines
export const orderLinesState = state => _get(orderViewState(state), 'orderLines');
export const selectIsFetchingOrderLineNotes = state => _get(orderLinesState(state), 'isFetchingOrderLineNotes');

// Search Orders
export const searchOrdersState = state => _get(orderViewState(state), 'searchOrders');
export const getIsSearchingOrders = state => _get(searchOrdersState(state), 'isSearching');
export const getIsSortingResults = state => _get(searchOrdersState(state), 'isSorting');
export const getSearchOrdersResults = state => _get(searchOrdersState(state), 'results');
export const getShowSearchOrdersModal = state => _get(searchOrdersState(state), 'showSearchOrdersModal');
export const getSearchOrdersSearchTerm = state => _get(searchOrdersState(state), 'searchTerm');

// Create Order
export const createOrderState = state => _get(orderViewState(state), 'createOrder');
export const getShowCreateOrderModal = state => _get(createOrderState(state), 'showCreateOrderModal');
export const getIsConfirmingCreateOrder = state => _get(createOrderState(state), 'isConfirmingCreateOrder');
export const getSalesOrderNumber = state => _get(createOrderState(state), 'salesOrderNumber');
export const getGuidOrderLine = state => _get(createOrderState(state), 'guidOrderLine');

// Settings
export const settingsState = state => _get(orderViewState(state), 'settings');
export const selectOrderViewType = state => _get(settingsState(state), 'orderViewType');
export const selectOrderViewTypeSettings = state => _get(
  settingsState(state), selectOrderViewType(state),
);
export const getSettingsColumnsOrder = state => _get(selectOrderViewTypeSettings(state), 'columnsOrder');
export const getSettingsColumns = state => _get(selectOrderViewTypeSettings(state), 'columns');
export const getSettingsDays = state => _get(selectOrderViewTypeSettings(state), 'days');
export const getSettingsItemsPerPage = state => _get(selectOrderViewTypeSettings(state), 'itemsPerPage');
export const getSettingsSorting = state => _get(selectOrderViewTypeSettings(state), 'sorting');

// Config
export const configState = state => _get(orderViewState(state), 'config');
export const selectFilterOptions = state => _get(configState(state), 'filterOptions');
export const selectColumnFilterOptions = state => _get(configState(state), 'columnFilterOptions');
export const selectQuickFilterOptions = state => _get(configState(state), 'quickFilterOptions');

// Order Details
export const orderDetailsState = state => _get(orderViewState(state), 'orderDetails');
export const selectShowOrderDetailsModal = state => _get(orderDetailsState(state), 'showOrderDetailsModal');
export const selectIsFetchingOrderDetails = state => _get(orderDetailsState(state), 'isFetchingOrderDetails');
export const selectOrderDetailsData = state => _get(orderDetailsState(state), 'data');
export const selectOrderDetailsOrderId = state => _get(selectOrderDetailsData(state), 'order_id');
export const selectIsAddingOrderNote = state => _get(orderDetailsState(state), 'isAddingOrderNote');
export const selectOrderNoteError = state => _get(orderDetailsState(state), 'orderNoteError');