import React from 'react';
import PropTypes from 'prop-types';
import { MdClose } from 'react-icons/md';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from 'components/Mui/Tooltip';
import { DialogTitleWrap, TitleContent } from './styles';

const DialogTitle = ({ children, onClose, ...styleProps }) => (
  <DialogTitleWrap {...styleProps}>
    <TitleContent>
      {children}
    </TitleContent>

    {onClose && (
      <Tooltip title="Close Dialog" placement="left">
        <IconButton
          onClick={onClose}
        >
          <MdClose size="1.2rem" />
        </IconButton>
      </Tooltip>
    )}
  </DialogTitleWrap>
);

DialogTitle.propTypes = {
  onClose: PropTypes.func,
  children: PropTypes.node.isRequired,
};

DialogTitle.defaultProps = {
  onClose: null,
};

export default DialogTitle;