import { createReducer } from 'redux-create-reducer';
import _reduce from 'lodash/reduce';
import _find from 'lodash/find';
import { getLocalStorageSessionUsername } from 'app/persistedState';
import { createLocalStorageReducer } from 'utils/redux';
import { PAGINATION_ITEMS_PER_PAGE } from 'features/components/FeedPagination';
import {
  ACTIONS,
  BRANDS_SUPPLIERS_COLUMNS,
  BRANDS_SUPPLIERS_COLUMNS_STORAGE_SETTINGS,
  BRANDS_SUPPLIERS_COLUMNS_DEFAULT_ORDER,
  BRANDS_SUPPLIERS_LOCAL_STORAGE_KEY,
  BRANDS_SUPPLIERS_FIELDS,
} from '../constants';

export const INITIAL_STATE = {
  columns: BRANDS_SUPPLIERS_COLUMNS_STORAGE_SETTINGS,
  columnsOrder: BRANDS_SUPPLIERS_COLUMNS_DEFAULT_ORDER,
  itemsPerPage: _find(PAGINATION_ITEMS_PER_PAGE, { value: 25 }).value,
  sort: BRANDS_SUPPLIERS_COLUMNS[BRANDS_SUPPLIERS_FIELDS.BRAND_NAME].id,
  sorting: {
    columnId: BRANDS_SUPPLIERS_COLUMNS[BRANDS_SUPPLIERS_FIELDS.BRAND_NAME].id,
    direction: 'asc',
  },
};

const updateColumnEnabled = (state, { columnId, enabled }) => {
  const { columns } = state;

  const updatedColumns = _reduce(columns, (result, value, key) => {
    const newResult = result;
    if (value.id === columnId) {
      newResult[key] = {
        ...value,
        enabled,
      };
      return newResult;
    }
    newResult[key] = value;
    return newResult;
  }, {});

  return {
    ...state,
    columns: updatedColumns,
  };
};

const updateColumnsOrder = (state, { columnsOrder }) => ({
  ...state,
  columnsOrder,
});

const updateColumns = (state, { columns }) => ({
  ...state,
  columns,
});

const updateItemsPerPage = (state, { itemsPerPage }) => ({
  ...state,
  itemsPerPage,
});

const updateSorting = (state, { columnId, direction }) => ({
  ...state,
  sorting: {
    columnId,
    direction,
  },
});

export const handlers = {
  [ACTIONS.SET_COLUMN_ENABLED]: updateColumnEnabled,
  [ACTIONS.SET_COLUMNS_ORDER]: updateColumnsOrder,
  [ACTIONS.SET_COLUMNS]: updateColumns,
  [ACTIONS.SET_ITEMS_PER_PAGE]: updateItemsPerPage,
  [ACTIONS.SORT_FEED]: updateSorting,
};

const settingsReducers = (state, action) => {
  const storedSessionUserName = getLocalStorageSessionUsername();

  return createLocalStorageReducer(
    createReducer(INITIAL_STATE, handlers),
    BRANDS_SUPPLIERS_LOCAL_STORAGE_KEY(storedSessionUserName),
  )(state, action);
};

export default settingsReducers;