import { createAction } from 'redux-create-action';
import { ACTIONS } from './constants';

export const fetchAuthTest = createAction(ACTIONS.FETCH_AUTH_TEST, 'username', 'ref', 'successActionCallback', 'errorActionCallback');
export const fetchAuthTestSuccess = createAction(ACTIONS.FETCH_AUTH_TEST_SUCCESS, 'payload');
export const fetchAuthTestError = createAction(ACTIONS.FETCH_AUTH_TEST_ERROR, 'errorMessage', 'error');

export const setLogin = createAction(ACTIONS.SET_LOGIN, 'username', 'password', 'successActionCallback', 'errorActionCallback');
export const setLoginSuccess = createAction(ACTIONS.SET_LOGIN_SUCCESS);
export const setLoginError = createAction(ACTIONS.SET_LOGIN_ERROR);

export const setLogout = createAction(ACTIONS.SET_LOGOUT, 'successActionCallback', 'errorActionCallback');
export const setLogoutSuccess = createAction(ACTIONS.SET_LOGOUT_SUCCESS);
export const setLogoutError = createAction(ACTIONS.SET_LOGOUT_ERROR);

export const setForgotPassword = createAction(ACTIONS.SET_FORGOT_PASSWORD, 'username', 'successActionCallback', 'errorActionCallback');
export const setVerifyPasswordReset = createAction(ACTIONS.SET_VERIFY_PASSWORD_RESET, 'data', 'successActionCallback', 'errorActionCallback');
export const setChangeTemporaryPassword = createAction(ACTIONS.SET_CHANGE_TEMPORARY_PASSWORD, 'data', 'successActionCallback', 'errorActionCallback');