import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import _pick from 'lodash/pick';
import _omit from 'lodash/omit';
import MuiTooltip from '@material-ui/core/Tooltip';

export const tooltipPlacements = {
  bottom: 'bottom',
  bottomEnd: 'bottom-end',
  bottomStart: 'bottom-start',
  left: 'left',
  leftEnd: 'left-end',
  leftStart: 'left-start',
  right: 'right',
  rightEnd: 'right-end',
  rightStart: 'right-start',
  top: 'top',
  topEnd: 'top-end',
  topStart: 'top-start',
};

const Tooltip = ({
  children,
  multiline,
  ...props
}) => {
  const styleProps = _pick(props, ['margin', 'fontSize']);
  const whiteSpace = multiline ? 'pre-line' : 'normal';
  const rest = _omit(props, Object.keys(styleProps));
  const TooltipWrap = withStyles(() => ({
    tooltip: {
      ...styleProps,
      whiteSpace,
    },
  }))(MuiTooltip);

  return (
    <TooltipWrap {...rest}>
      {children}
    </TooltipWrap>
  );
};

Tooltip.propTypes = {
  placement: PropTypes.oneOf(Object.values(tooltipPlacements)),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  multiline: PropTypes.bool,
  fontSize: PropTypes.string,
  margin: PropTypes.string,
};

Tooltip.defaultProps = {
  placement: tooltipPlacements.bottom,
  multiline: false,
  fontSize: '12px',
  margin: '0',
};

export default Tooltip;