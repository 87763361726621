import { createReducer } from 'redux-create-reducer';
import { ACTIONS } from '../constants';

export const INITIAL_STATE = {
  logs: null,
  isFetchingLogs: false,
  isRequestingDownload: false,
  isDownloading: false,
  isCancelling: false,
};

const updateLogs = (state, { payload }) => ({
  ...state,
  logs: payload.logs,
  isFetchingLogs: INITIAL_STATE.isFetchingLogs,
});

const setIsFetchingLogs = (state, isFetchingLogs) => ({
  ...state,
  isFetchingLogs,
});

const setIsRequestingDownload = (state, isRequestingDownload) => ({
  ...state,
  isRequestingDownload,
});

const setIsDownloading = (state, isDownloading) => ({
  ...state,
  isDownloading,
});

const setIsCancelling = (state, isCancelling) => ({
  ...state,
  isCancelling,
});

const handlers = {
  [ACTIONS.FETCH_DOWNLOAD_LOGS]: state => setIsFetchingLogs(state, true),
  [ACTIONS.FETCH_DOWNLOAD_LOGS_SUCCESS]: updateLogs,
  [ACTIONS.FETCH_DOWNLOAD_LOGS_ERROR]: state => setIsFetchingLogs(state, false),
  [ACTIONS.REQUEST_DOWNLOAD_PRODUCTS]: state => setIsRequestingDownload(state, true),
  [ACTIONS.REQUEST_DOWNLOAD_PRODUCTS_SUCCESS]: state => setIsRequestingDownload(state, false),
  [ACTIONS.REQUEST_DOWNLOAD_PRODUCTS_ERROR]: state => setIsRequestingDownload(state, false),
  [ACTIONS.DOWNLOAD_PRODUCTS_FILE]: state => setIsDownloading(state, true),
  [ACTIONS.DOWNLOAD_PRODUCTS_FILE_SUCCESS]: state => setIsDownloading(state, false),
  [ACTIONS.DOWNLOAD_PRODUCTS_FILE_ERROR]: state => setIsDownloading(state, false),
  [ACTIONS.CANCEL_DOWNLOAD_REQUEST]: state => setIsCancelling(state, true),
  [ACTIONS.CANCEL_DOWNLOAD_REQUEST_SUCCESS]: state => setIsCancelling(state, false),
  [ACTIONS.CANCEL_DOWNLOAD_REQUEST_ERROR]: state => setIsCancelling(state, false),
};

export default createReducer(INITIAL_STATE, handlers);