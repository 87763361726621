import { all, fork } from 'redux-saga/effects';
import baseSagas from 'containers/Base/sagas';
import dashboardSagas from 'containers/Dashboard/sagas';
import featuresSagas from 'features/sagas';
import servicesSagas from 'services/sagas';

export default function* sagas(...args) {
  yield all([
    fork(servicesSagas, ...args),
    fork(baseSagas, ...args),
    fork(dashboardSagas, ...args),
    fork(featuresSagas, ...args),
  ]);
}
