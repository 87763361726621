import { createReducer } from 'redux-create-reducer';
import { addFilter, removeFilter } from 'features/helpers';
import { ACTIONS } from '../constants';

export const INITIAL_STATE = {
  data: null,
  showOptionsModal: false,
  lastPage: 1,
  isRefreshing: false,
  isFetchingFeed: false,
  filters: [],
};

const setLoadResultsSuccess = (state, { payload }) => ({
  ...state,
  data: payload,
  isRefreshing: false,
  isFetchingFeed: false,
});

const setLoadResultsError = state => ({
  ...state,
  isRefreshing: false,
  isFetchingFeed: false,
});

const updateFilters = (state, filters) => ({
  ...state,
  filters,
});

const setFiltersList = (state, { filters }) => updateFilters(state, filters);

const setIsFetchingFeed = (state, isFetchingFeed) => ({
  ...state,
  isFetchingFeed,
});

const updateLastPage = (state, { lastPage }) => ({
  ...state,
  lastPage,
});

const resetLastPage = state => ({
  ...state,
  lastPage: INITIAL_STATE.lastPage,
});

const setIsRefreshing = (state, isRefreshing) => ({
  ...state,
  isRefreshing,
});

const addFilterOperand = (state, { operand }) => {
  const updatedFilters = addFilter(operand, state.filters);
  return {
    ...updateFilters(state, updatedFilters),
    lastPage: INITIAL_STATE.lastPage,
  };
};

const removeFilterOperand = (state, { groupIndex, filterIndex }) => {
  const updatedFilters = removeFilter(groupIndex, filterIndex, state.filters);
  return {
    ...updateFilters(state, updatedFilters),
    lastPage: INITIAL_STATE.lastPage,
  };
};

const setShowOptionsModal = (state, { showOptionsModal }) => ({
  ...state,
  showOptionsModal,
});

export const handlers = {
  [ACTIONS.LOAD_BRANDS_SUPPLIERS]: state => setIsFetchingFeed(state, true),
  [ACTIONS.LOAD_BRANDS_SUPPLIERS_SUCCESS]: setLoadResultsSuccess,
  [ACTIONS.LOAD_BRANDS_SUPPLIERS_ERROR]: setLoadResultsError,
  [ACTIONS.LOAD_FEED]: state => setIsFetchingFeed(state, true),
  [ACTIONS.SHOW_OPTIONS_MODAL]: setShowOptionsModal,
  [ACTIONS.GO_TO_PAGE]: updateLastPage,
  [ACTIONS.REFRESH_FEED]: state => setIsRefreshing(state, true),

  [ACTIONS.SET_ITEMS_PER_PAGE]: resetLastPage,
  [ACTIONS.SET_FILTER_OPERAND]: addFilterOperand,
  [ACTIONS.UNSET_FILTER_OPERAND]: removeFilterOperand,
  [ACTIONS.CLEAR_FILTERS]: state => updateFilters(state, INITIAL_STATE.filters),
  [ACTIONS.SET_FILTERS]: setFiltersList,
};

export default createReducer(INITIAL_STATE, handlers);