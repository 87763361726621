import {
  all, fork, takeEvery, select, put,
} from 'redux-saga/effects';
import scanZoneNameSagas from './scanZoneNameSagas';
import scanUPCSagas from './scanUPCSagas';
import zoneLockSagas from './zoneLockSagas';
import changeZoneItemsSagas from './changeZoneItemsSagas';
import renameZoneSagas from './renameZoneSagas';
import deleteZoneItemsSagas from './deleteZoneItemsSagas';
import moveZoneItemsSagas from './moveZoneItemsSagas';
import searchItemsSagas from './searchItemsSagas';
import { ACTIONS } from '../constants';
import { getZoneName } from '../selectors';
import { reloadZone } from '../actions';

function* initContainerWatcher() {
  const zoneName = yield select(getZoneName);
  if (zoneName) {
    yield put(reloadZone());
  }
}

export default function* inventoryViewSagas(...args) {
  yield all([
    takeEvery(ACTIONS.INIT_CONTAINER, initContainerWatcher),
    fork(scanZoneNameSagas, ...args),
    fork(scanUPCSagas, ...args),
    fork(zoneLockSagas, ...args),
    fork(changeZoneItemsSagas, ...args),
    fork(renameZoneSagas, ...args),
    fork(deleteZoneItemsSagas, ...args),
    fork(moveZoneItemsSagas, ...args),
    fork(searchItemsSagas, ...args),
  ]);
}
