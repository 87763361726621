import HttpRequest from '../HttpRequest';
import ENDPOINTS from './constants';

export default class IntegrationsApi extends HttpRequest {
  getChannels() {
    return this.call(ENDPOINTS.GET_CHANNELS);
  }

  getIntegrations() {
    return this.call(ENDPOINTS.GET_INTEGRATIONS);
  }

  addIntegration(params) {
    const bodyFormData = new FormData();
    bodyFormData.set('params', params);

    return this.call(ENDPOINTS.ADD_INTEGRATION, {
      method: 'POST',
      data: bodyFormData,
    });
  }

  editIntegration(params) {
    const bodyFormData = new FormData();
    bodyFormData.set('params', params);

    return this.call(ENDPOINTS.EDIT_INTEGRATION, {
      method: 'POST',
      data: bodyFormData,
    });
  }

  deleteIntegration(id) {
    const bodyFormData = new FormData();
    bodyFormData.set('id', id);

    return this.call(ENDPOINTS.DELETE_INTEGRATION, {
      method: 'POST',
      data: bodyFormData,
    });
  }
}

export { ENDPOINTS };