import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import _isString from 'lodash/isString';
import InputLabel from '@material-ui/core/InputLabel';
import MuiSelect from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Spinner from 'components/Spinner';
import { TESTID_COMPONENT } from 'constants/data-testid';

const FormControlWrap = styled.div`
  ${({ fullWidth }) => fullWidth && `
    width: 100%;
  `}
  .MuiFormControl-root {
    ${({ maxWidth }) => maxWidth && `
      max-width: ${maxWidth};
    `}
    ${({ minWidth }) => minWidth && `
      min-width: ${minWidth};
    `}
    ${({ width }) => width && `
      width: ${width};
    `}
  }
  .MuiOutlinedInput-root {
    &.Mui-disabled {
      background-color: rgba(0, 0, 0, 0.02);
    }
  }
  .MuiSelect-root {
    > div {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`;

const DefaultOptionComponent = ({ option }) => (<div>{option.label}</div>);

DefaultOptionComponent.propTypes = {
  option: PropTypes.shape(),
};

DefaultOptionComponent.defaultProps = {
  option: {},
};

export const LoadingIconWrap = styled.i`
  position: absolute;
  right: 0;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  > svg {
    width: 1rem;
    height: 1rem;
  }
`;

const LoadingIcon = () => (
  <LoadingIconWrap><Spinner /></LoadingIconWrap>
);

const Select = ({
  label,
  options,
  value,
  onChange,
  variant,
  fullWidth,
  disabled,
  required,
  helperText,
  optionComponent,
  error,
  size,
  onSelectEnd,
  isLoading,
  ...rest
}) => {
  const OptionComponent = optionComponent || DefaultOptionComponent;
  const displayHelperText = error && _isString(error) ? error : helperText;
  const handleOnChange = e => {
    onChange(e.target.value);
  };
  return (
    <FormControlWrap {...rest} fullWidth={fullWidth}>
      <FormControl
        variant={variant}
        size={size}
        fullWidth={fullWidth}
        disabled={disabled}
        required={required}
        error={!!error}
      >
        {label && (
          <InputLabel>{label}</InputLabel>
        )}
        <MuiSelect
          label={label}
          value={!_isEmpty(options) ? value : ''}
          onChange={handleOnChange}
          defaultValue={value}
          IconComponent={isLoading ? LoadingIcon : undefined}
          MenuProps={{
            onExited: onSelectEnd,
          }}
        >
          {_map(options, option => (
            <MenuItem
              key={option.value}
              value={option.value}
              data-testid={TESTID_COMPONENT.SELECT_MENU_ITEM(option.value)}
            >
              <OptionComponent option={option} selected={value === option.value} />
            </MenuItem>
          ))}
        </MuiSelect>
        {displayHelperText && (
          <FormHelperText>{displayHelperText}</FormHelperText>
        )}
      </FormControl>
    </FormControlWrap>
  );
};

Select.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.elementType]),
  options: PropTypes.arrayOf(PropTypes.shape()),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onSelectEnd: PropTypes.func,
  variant: PropTypes.string,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  helperText: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  isLoading: PropTypes.bool,
  optionComponent: PropTypes.oneOfType([
    PropTypes.elementType,
    PropTypes.node,
    PropTypes.func,
  ]),
  size: PropTypes.string,
};

Select.defaultProps = {
  label: null,
  options: [],
  value: null,
  onChange() {},
  onSelectEnd() {},
  variant: 'standard',
  fullWidth: false,
  disabled: false,
  required: false,
  helperText: null,
  isLoading: false,
  optionComponent: null,
  error: null,
  size: null,
};

export default Select;
