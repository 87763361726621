import {
  all, takeEvery, put,
} from 'redux-saga/effects';
import { listBrands } from 'services/brands/actions';
import { errorToasterSaga } from 'services/sagaWorkers';
import { listSuppliers } from 'services/suppliers/actions';
import {
  fetchBrandsError,
  fetchBrandsSuccess,
  fetchSuppliersError,
  fetchSuppliersSuccess,
} from '../actions';
import { ACTIONS } from '../constants';

function* fetchBrandsWatcher() {
  yield put(listBrands(fetchBrandsSuccess, fetchBrandsError));
}

function* fetchSuppliersWatcher() {
  yield put(listSuppliers(fetchSuppliersSuccess, fetchSuppliersError));
}

export default function* fetchSagas() {
  yield all([
    takeEvery(ACTIONS.FETCH_BRANDS, fetchBrandsWatcher),
    takeEvery(ACTIONS.FETCH_BRANDS_ERROR, errorToasterSaga),
    takeEvery(ACTIONS.FETCH_SUPPLIERS, fetchSuppliersWatcher),
    takeEvery(ACTIONS.FETCH_SUPPLIERS_ERROR, errorToasterSaga),
  ]);
}