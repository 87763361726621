import { combineReducers } from 'redux';
import { omitResetReducers } from 'utils/redux';
import profileReducers from './Profile/reducers';
import usersManagementReducers from './UsersManagement/reducers';
import businessReducers from './Business/reducers';
import shippingRulesReducers from './ShippingRules/reducers';
import integrationsReducers from './Integrations/reducers';
import rolesManagementReducers from './RolesManagement/reducers';
import { ACTIONS } from './constants';

const featureReducers = combineReducers({
  profile: profileReducers,
  usersManagement: usersManagementReducers,
  rolesManagement: rolesManagementReducers,
  business: businessReducers,
  shippingRules: shippingRulesReducers,
  integrations: integrationsReducers,
});

const accountSettingsReducers = (state, action) => {
  let featureState = state;
  if (action.type === ACTIONS.RESET_FEATURE_REDUCERS) {
    const reducers = omitResetReducers(state, ['profile']);
    featureState = {
      ...state,
      ...reducers,
    };
  }
  return featureReducers(featureState, action);
};

export default accountSettingsReducers;
