import { combineReducers } from 'redux';
import feedReducers from './feedReducers';
import managementReducers from './managementReducers';
import settingsReducers from './settingsReducers';

export default combineReducers({
  feed: feedReducers,
  management: managementReducers,
  settings: settingsReducers,
});
