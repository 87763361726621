import { all, fork } from 'redux-saga/effects';
import containerSagas from './containerSagas';
import loadShippingRulesSagas from './loadShippingRulesSagas';
import exportToFileSagas from './exportToFileSagas';
import shipRulesManagementSagas from './shipRulesManagementSagas';

export default function* shippingRulesSagas(...args) {
  yield all([
    fork(containerSagas, ...args),
    fork(loadShippingRulesSagas, ...args),
    fork(exportToFileSagas, ...args),
    fork(shipRulesManagementSagas, ...args),
  ]);
}
