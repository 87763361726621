const ENDPOINTS = {
  ADD_USER: '/add_user',
  DELETE_USER: '/delete_user',
  USER: '/user',
  USERS: '/users',
  USERNAME_AVAILABLE: '/username_available',
  RESET_USER_PASSWORD: '/reset_user_password',
  RESEND_INVITE: '/resend_invite',
  GET_ROLES: '/get_roles',
  GET_PERMISSIONS: '/get_permissions',
  ADD_ROLE: '/add_role',
  EDIT_ROLE: '/edit_role',
  DELETE_ROLE: '/delete_role',
};

export default ENDPOINTS;
