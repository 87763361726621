import { createReducer } from 'redux-create-reducer';
import { ACTIONS } from './constants';

export const INITIAL_STATE = {
  isDownloadingSample: false,
  isUploadingFile: false,
};

const setIsDownloadingSample = (state, isDownloadingSample) => ({
  ...state,
  isDownloadingSample,
});

const setIsUploadingFile = (state, isUploadingFile) => ({
  ...state,
  isUploadingFile,
});

const resetState = () => ({
  ...INITIAL_STATE,
});

export const handlers = {
  [ACTIONS.DOWNLOAD_SAMPLE]: state => setIsDownloadingSample(state, true),
  [ACTIONS.DOWNLOAD_SAMPLE_SUCCESS]: state => setIsDownloadingSample(state, false),
  [ACTIONS.DOWNLOAD_SAMPLE_ERROR]: state => setIsDownloadingSample(state, false),

  [ACTIONS.UPLOAD_FILE]: state => setIsUploadingFile(state, true),
  [ACTIONS.UPLOAD_FILE_SUCCESS]: state => setIsUploadingFile(state, false),
  [ACTIONS.UPLOAD_FILE_ERROR]: state => setIsUploadingFile(state, false),

  [ACTIONS.RESET_STATE]: resetState,
};

export default createReducer(INITIAL_STATE, handlers);