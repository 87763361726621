import { createReducer } from 'redux-create-reducer';
import { ACTIONS } from '../constants';

export const INITIAL_STATE = {
  selectedShippingRule: null,
  showAddShippingRuleModal: false,
  showEditShippingRuleModal: false,
  showDeleteShippingRuleModal: false,
  isCreatingShippingRule: false,
  isUpdatingShippingRule: false,
  isRemovingShippingRule: false,
};

const setAddShippingRule = state => ({
  ...state,
  showAddShippingRuleModal: true,
});

const setEditShippingRule = (state, { selectedShippingRule }) => ({
  ...state,
  showEditShippingRuleModal: true,
  selectedShippingRule,
});

const setDeleteShippingRule = (state, { selectedShippingRule }) => ({
  ...state,
  showDeleteShippingRuleModal: true,
  selectedShippingRule,
});

const setIsRemovingShippingRule = (state, isRemovingShippingRule) => ({
  ...state,
  isRemovingShippingRule,
});

const setIsCreatingShippingRule = (state, isCreatingShippingRule) => ({
  ...state,
  isCreatingShippingRule,
});

const setIsUpdatingShippingRule = (state, isUpdatingShippingRule) => ({
  ...state,
  isUpdatingShippingRule,
});

const setRemoveShippingRuleSuccess = state => ({
  ...state,
  isRemovingShippingRule: INITIAL_STATE.isRemovingShippingRule,
  showDeleteShippingRuleModal: INITIAL_STATE.showDeleteShippingRuleModal,
  selectedShippingRule: INITIAL_STATE.selectedShippingRule,
});

const setCreateShippingRuleSuccess = state => ({
  ...state,
  isCreatingShippingRule: INITIAL_STATE.isCreatingShippingRule,
  showAddShippingRuleModal: INITIAL_STATE.showAddShippingRuleModal,
});

const setUpdateShippingRuleSuccess = state => ({
  ...state,
  isUpdatingShippingRule: INITIAL_STATE.isUpdatingShippingRule,
  showEditShippingRuleModal: INITIAL_STATE.showEditShippingRuleModal,
});

const resetState = () => ({
  ...INITIAL_STATE,
});

export const handlers = {
  [ACTIONS.ADD_SHIPPING_RULE]: setAddShippingRule,
  [ACTIONS.EDIT_SHIPPING_RULE]: setEditShippingRule,
  [ACTIONS.DELETE_SHIPPING_RULE]: setDeleteShippingRule,
  [ACTIONS.CANCEL_ADD_SHIPPING_RULE]: resetState,
  [ACTIONS.CANCEL_DELETE_SHIPPING_RULE]: resetState,
  [ACTIONS.CANCEL_EDIT_SHIPPING_RULE]: resetState,
  [ACTIONS.REMOVE_SHIPPING_RULE]: state => setIsRemovingShippingRule(state, true),
  [ACTIONS.REMOVE_SHIPPING_RULE_ERROR]: state => setIsRemovingShippingRule(state, false),
  [ACTIONS.REMOVE_SHIPPING_RULE_SUCCESS]: setRemoveShippingRuleSuccess,
  [ACTIONS.CREATE_SHIPPING_RULE]: state => setIsCreatingShippingRule(state, true),
  [ACTIONS.CREATE_SHIPPING_RULE_SUCCESS]: setCreateShippingRuleSuccess,
  [ACTIONS.CREATE_SHIPPING_RULE_ERROR]: state => setIsCreatingShippingRule(state, false),
  [ACTIONS.UPDATE_SHIPPING_RULE]: state => setIsUpdatingShippingRule(state, true),
  [ACTIONS.UPDATE_SHIPPING_RULE_SUCCESS]: setUpdateShippingRuleSuccess,
  [ACTIONS.UPDATE_SHIPPING_RULE_ERROR]: state => setIsUpdatingShippingRule(state, false),
};

export default createReducer(INITIAL_STATE, handlers);