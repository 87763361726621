import {
  all, call, select, takeEvery, put, takeLeading,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { unsubscribeFromZoneUpdates, subscribeToZoneUpdates } from 'features/InventoryManagement/sagaWorkers';
import { INVENTORY_MANAGEMENT_ERRORS, INVENTORY_MANAGEMENT_SUCCESS } from 'features/InventoryManagement/constants';
import {
  fetchZone,
  setZoneLock,
  setZoneUnlock,
  fetchHasLock,
} from 'services/inventory/actions';
import { getShippingZoneName } from 'services/inventory/selectors';
import {
  sagas,
  toggleSelectItemWorker,
  toggleSelectAllItemsWorker,
  cancelChangedItemsWorker,
  scanUPCWorker,
  moveSelectedItemsWorker,
  addToShippingZone,
} from './sagaWorkers';
import {
  getZoneLocked,
  getZoneName,
  getZoneLockNonce,
  getChangedItems,
  getZoneItems,
  findZoneItemByUPC,
  selectSelectedItems,
} from '../selectors/pickingZoneSelectors';
import {
  scanZoneNameSuccess,
  scanZoneNameError,
  setChangedItems,
  unlockZone,
  lockZone,
  lockZoneSuccess,
  lockZoneError,
  unlockZoneSuccess,
  unlockZoneError,
  reloadZoneLockSuccess,
  reloadZoneLockError,
  updateSelectedItems,
  scanUPCSuccess,
  scanUPCError,
  scanZoneName,
  reloadZone,
  moveSelectedItemsSuccess,
  moveSelectedItemsError,
  reloadZoneLock,
  setSelectedItems,
} from '../actions/pickingZone';
import { PICKING_ZONE_ACTIONS as ACTIONS } from '../constants';

function* scanZoneNameWatcher({ zoneName }) {
  const prevZoneName = yield select(getZoneName);
  yield call(unsubscribeFromZoneUpdates, zoneName, prevZoneName);
  yield put(fetchZone(zoneName, scanZoneNameSuccess, scanZoneNameError));
}

function* scanZoneNameSuccessWatcher({ payload }) {
  const { items, location } = payload;
  yield put(setChangedItems(items));

  yield call(subscribeToZoneUpdates, location, reloadZoneLock, reloadZone);
}

function* scanZoneNameErrorWatcher() {
  yield call([toast, toast.error], INVENTORY_MANAGEMENT_ERRORS.SCANNING_ZONE_NAME);
}

function* toggleZoneLockWatcher() {
  const isZoneLocked = yield select(getZoneLocked);

  if (isZoneLocked) {
    yield put(unlockZone());
    return;
  }

  yield put(lockZone());
}

function* lockZoneWatcher() {
  const zoneName = yield select(getZoneName);
  yield put(setZoneLock(zoneName, lockZoneSuccess, lockZoneError));
}

function* lockZoneErrorWatcher({ errorMessage }) {
  yield call([toast, toast.error], errorMessage);
  yield put(reloadZone());
}

function* unlockZoneWatcher() {
  const zoneName = yield select(getZoneName);
  const nonce = yield select(getZoneLockNonce);
  yield put(setZoneUnlock(zoneName, nonce, unlockZoneSuccess, unlockZoneError));
}

function* unlockZoneErrorWatcher({ errorMessage }) {
  yield call([toast, toast.error], errorMessage || INVENTORY_MANAGEMENT_ERRORS.UNLOCKING_ZONE);
  yield put(reloadZone());
}

function* reloadZoneLockWatcher() {
  const zoneName = yield select(getZoneName);
  yield put(fetchHasLock(zoneName, reloadZoneLockSuccess, reloadZoneLockError));
}

function* reloadZoneLockErrorWatcher({ errorMessage }) {
  yield call([toast, toast.error], errorMessage);
}

function* reloadZoneWatcher() {
  const zoneName = yield select(getZoneName);
  yield put(scanZoneName(zoneName));
}

function* scanUPCWatcher({ upc }) {
  const targetZoneName = yield select(getShippingZoneName);
  yield call(scanUPCWorker, {
    upc,
    targetZoneName,
    findZoneItemByUPC,
    scanUPCSuccess,
    scanUPCError,
  });
}

function* scanUPCSuccessWatcher({ list }) {
  yield put(reloadZone());
  yield call(addToShippingZone, list);
}

function* scanUPCErrorWatcher({ errorMessage }) {
  yield call([toast, toast.error], errorMessage);
  yield put(reloadZone());
}

function* moveSelectedItemsWatcher() {
  const targetZoneName = yield select(getShippingZoneName);
  yield call(moveSelectedItemsWorker, {
    targetZoneName,
    getChangedItems,
    moveSelectedItemsSuccess,
    moveSelectedItemsError,
    selectSelectedItems,
  });
}

function* moveSelectedItemsSuccessWatcher({ list }) {
  yield call(addToShippingZone, list);
  yield put(setSelectedItems([]));
  yield call([toast, toast.success], INVENTORY_MANAGEMENT_SUCCESS.MOVING_ITEMS_TO_SHIPPING_ZONE);
}

function* moveSelectedItemsErrorWatcher({ errorMessage }) {
  yield put(reloadZone());
  yield call([toast, toast.error], errorMessage || INVENTORY_MANAGEMENT_ERRORS.MOVING_ITEMS);
}

// SHARED SAGAS
function* toggleSelectItemWatcher({ upc, isSelected }) {
  yield call(toggleSelectItemWorker, {
    upc, isSelected, getChangedItems, updateSelectedItems,
  });
}

function* toggleSelectAllItemsWatcher({ selectAll }) {
  yield call(toggleSelectAllItemsWorker, { selectAll, getChangedItems, updateSelectedItems });
}

function* cancelChangedItemsWatcher() {
  yield call(cancelChangedItemsWorker, { getZoneItems, setChangedItems });
}

export default function* pickingZoneSagas() {
  const sharedSagas = sagas(ACTIONS, {
    toggleSelectItemWatcher,
    toggleSelectAllItemsWatcher,
    cancelChangedItemsWatcher,
    scanUPCWatcher,
    moveSelectedItemsWatcher,
  });

  yield all([
    takeLeading(ACTIONS.SCAN_ZONE_NAME, scanZoneNameWatcher),
    takeEvery(ACTIONS.SCAN_ZONE_NAME_SUCCESS, scanZoneNameSuccessWatcher),
    takeEvery(ACTIONS.SCAN_ZONE_NAME_ERROR, scanZoneNameErrorWatcher),
    takeEvery(ACTIONS.TOGGLE_ZONE_LOCK, toggleZoneLockWatcher),
    takeLeading(ACTIONS.LOCK_ZONE, lockZoneWatcher),
    takeEvery(ACTIONS.LOCK_ZONE_ERROR, lockZoneErrorWatcher),
    takeLeading(ACTIONS.UNLOCK_ZONE, unlockZoneWatcher),
    takeEvery(ACTIONS.UNLOCK_ZONE_ERROR, unlockZoneErrorWatcher),
    takeLeading(ACTIONS.RELOAD_ZONE_LOCK, reloadZoneLockWatcher),
    takeEvery(ACTIONS.RELOAD_ZONE_LOCK_ERROR, reloadZoneLockErrorWatcher),
    takeEvery(ACTIONS.RELOAD_ZONE, reloadZoneWatcher),
    takeEvery(ACTIONS.SCAN_UPC_SUCCESS, scanUPCSuccessWatcher),
    takeEvery(ACTIONS.SCAN_UPC_ERROR, scanUPCErrorWatcher),
    takeEvery(ACTIONS.MOVE_SELECTED_ITEMS_SUCCESS, moveSelectedItemsSuccessWatcher),
    takeEvery(ACTIONS.MOVE_SELECTED_ITEMS_ERROR, moveSelectedItemsErrorWatcher),
    ...sharedSagas,
  ]);
}