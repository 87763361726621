export const ACTIONS = {
  INIT_CONTAINER: 'PICKING__INIT_CONTAINER',
  EXIT_CONTAINER: 'PICKING__EXIT_CONTAINER',
};

export const PICKING_ZONE_ACTIONS = {
  SET_FIELD_ZONE_NAME: 'PICKING__PICKING_ZONE__SET_FIELD_ZONE_NAME',
  SET_FIELD_UPC_NUMBER: 'PICKING__PICKING_ZONE__SET_FIELD_UPC_NUMBER',
  SCAN_ZONE_NAME: 'PICKING__PICKING_ZONE__CAN_ZONE_NAME',
  SCAN_ZONE_NAME_SUCCESS: 'PICKING__PICKING_ZONE__SCAN_ZONE_NAME_SUCCESS',
  SCAN_ZONE_NAME_ERROR: 'PICKING__PICKING_ZONE__SCAN_ZONE_NAME_ERROR',
  SET_ZONE_CHANGED_ITEMS: 'PICKING__PICKING_ZONE__SET_ZONE_CHANGED_ITEMS',
  SCAN_UPC: 'PICKING__PICKING_ZONE__SCAN_UPC',
  SCAN_UPC_SUCCESS: 'PICKING__PICKING_ZONE__SCAN_UPC_SUCCESS',
  SCAN_UPC_ERROR: 'PICKING__PICKING_ZONE__SCAN_UPC_ERROR',
  TOGGLE_ZONE_LOCK: 'PICKING__PICKING_ZONE__TOGGLE_ZONE_LOCK',
  LOCK_ZONE: 'PICKING__PICKING_ZONE__LOCK_ZONE',
  LOCK_ZONE_SUCCESS: 'PICKING__PICKING_ZONE__LOCK_ZONE_SUCCESS',
  LOCK_ZONE_ERROR: 'PICKING__PICKING_ZONE__LOCK_ZONE_ERROR',
  UNLOCK_ZONE: 'PICKING__PICKING_ZONE__UNLOCK_ZONE',
  UNLOCK_ZONE_SUCCESS: 'PICKING__PICKING_ZONE__UNLOCK_ZONE_SUCCESS',
  UNLOCK_ZONE_ERROR: 'PICKING__PICKING_ZONE__UNLOCK_ZONE_ERROR',
  RELOAD_ZONE_LOCK: 'PICKING__PICKING_ZONE__RELOAD_ZONE_LOCK',
  RELOAD_ZONE_LOCK_SUCCESS: 'PICKING__PICKING_ZONE__RELOAD_ZONE_LOCK_SUCCESS',
  RELOAD_ZONE_LOCK_ERROR: 'PICKING__PICKING_ZONE__RELOAD_ZONE_LOCK_ERROR',
  TOGGLE_SELECT_ITEM: 'PICKING__PICKING_ZONE__TOGGLE_SELECT_ITEM',
  TOGGLE_SELECT_ALL_ITEMS: 'PICKING__PICKING_ZONE__TOGGLE_SELECT_ALL_ITEMS',
  UPDATE_SELECTED_ITEMS: 'PICKING__PICKING_ZONE__UPDATE_SELECTED_ITEMS',
  CANCEL_CHANGED_ITEMS: 'PICKING__PICKING_ZONE__CANCEL_CHANGED_ITEMS',
  MOVE_SELECTED_ITEMS: 'PICKING__PICKING_ZONE__MOVE_SELECTED_ITEMS',
  MOVE_SELECTED_ITEMS_SUCCESS: 'PICKING__PICKING_ZONE__MOVE_SELECTED_ITEMS_SUCCESS',
  MOVE_SELECTED_ITEMS_ERROR: 'PICKING__PICKING_ZONE__MOVE_SELECTED_ITEMS_ERROR',
  RELOAD_ZONE: 'PICKING__PICKING_ZONE__RELOAD_ZONE',
  SET_SELECTED_ITEMS: 'PICKING__PICKING_ZONE__SET_SELECTED_ITEMS',
};

export const SHIPPING_ZONE_ACTIONS = {
  SET_FIELD_UPC_NUMBER: 'PICKING__SHIPPING_ZONE__SET_FIELD_UPC_NUMBER',
  SET_ZONE_CHANGED_ITEMS: 'PICKING__SHIPPING_ZONE__SET_ZONE_CHANGED_ITEMS',
  SET_ZONE_ITEMS: 'PICKING__SHIPPING_ZONE__SET_ZONE_ITEMS',
  SCAN_UPC: 'PICKING__SHIPPING_ZONE__SCAN_UPC',
  SCAN_UPC_SUCCESS: 'PICKING__SHIPPING_ZONE__SCAN_UPC_SUCCESS',
  SCAN_UPC_ERROR: 'PICKING__SHIPPING_ZONE__SCAN_UPC_ERROR',
  TOGGLE_SELECT_ITEM: 'PICKING__SHIPPING_ZONE__TOGGLE_SELECT_ITEM',
  TOGGLE_SELECT_ALL_ITEMS: 'PICKING__SHIPPING_ZONE__TOGGLE_SELECT_ALL_ITEMS',
  UPDATE_SELECTED_ITEMS: 'PICKING__SHIPPING_ZONE__UPDATE_SELECTED_ITEMS',
  CANCEL_CHANGED_ITEMS: 'PICKING__SHIPPING_ZONE__CANCEL_CHANGED_ITEMS',
  MOVE_SELECTED_ITEMS: 'PICKING__SHIPPING_ZONE__MOVE_SELECTED_ITEMS',
  MOVE_SELECTED_ITEMS_SUCCESS: 'PICKING__SHIPPING_ZONE__MOVE_SELECTED_ITEMS_SUCCESS',
  MOVE_SELECTED_ITEMS_ERROR: 'PICKING__SHIPPING_ZONE__MOVE_SELECTED_ITEMS_ERROR',
  SET_SELECTED_ITEMS: 'PICKING__SHIPPING_ZONE__SET_SELECTED_ITEMS',
};
