import { createAction } from 'redux-create-action';
import { ACTIONS } from './constants';

export const fetchOrderFeed = createAction(ACTIONS.FETCH_ORDER_FEED, 'params', 'successActionCallback', 'errorActionCallback');
export const fetchOrderFeedSuccess = createAction(ACTIONS.FETCH_ORDER_FEED_SUCCESS);
export const fetchOrderFeedError = createAction(ACTIONS.FETCH_ORDER_FEED_ERROR);

export const fetchOrderFeedQuery = createAction(ACTIONS.FETCH_ORDER_FEED_QUERY, 'params', 'successActionCallback', 'errorActionCallback');

export const fetchOrderFeedCSV = createAction(ACTIONS.FETCH_ORDER_FEED_CSV, 'params', 'successActionCallback', 'errorActionCallback');

export const setUpdateFlag = createAction(
  ACTIONS.SET_UPDATE_FLAG, 'guidOrderLine', 'params', 'successActionCallback', 'errorActionCallback',
);
export const fetchSweepOrderLine = createAction(
  ACTIONS.FETCH_SWEEP_ORDER_LINE, 'guidOrderLine', 'salesOrderNumber', 'successActionCallback', 'errorActionCallback',
);
export const fetchSearchOrders = createAction(ACTIONS.FETCH_SEARCH_ORDERS, 'searchTerm', 'successActionCallback', 'errorActionCallback');
export const setAddOrder = createAction(ACTIONS.SET_ADD_ORDER, 'params', 'successActionCallback', 'errorActionCallback');
export const fetchGeneratePickTicket = createAction(ACTIONS.FETCH_GENERATE_PICK_TICKET, 'salesOrderNumbers', 'successActionCallback', 'errorActionCallback');
export const fetchOrderExporterPastReports = createAction(ACTIONS.FETCH_ORDER_EXPORTER_PAST_REPORTS, 'date', 'orderType', 'successActionCallback', 'errorActionCallback');
export const setOrderExporterCSVDownload = createAction(ACTIONS.SET_ORDER_EXPORTER_CSV_DOWNLOAD, 'params', 'successActionCallback', 'errorActionCallback');

export const getFilteredOrderLineFeed = createAction(ACTIONS.GET_FILTERED_ORDER_LINES, 'params', 'successActionCallback', 'errorActionCallback');
export const getFilteredOrderFeed = createAction(ACTIONS.GET_FILTERED_ORDERS, 'params', 'successActionCallback', 'errorActionCallback');
export const getOrderDetails = createAction(ACTIONS.GET_ORDER_DETAILS, 'orderId', 'successActionCallback', 'errorActionCallback');
export const postOrderNote = createAction(ACTIONS.POST_ORDER_NOTE, 'orderId', 'note', 'successActionCallback', 'errorActionCallback');
export const getOrderLineNotes = createAction(ACTIONS.GET_ORDER_LINE_NOTES, 'guidOrderLineIds', 'successActionCallback', 'errorActionCallback');
export const postAddOrderLineNotes = createAction(ACTIONS.POST_ADD_ORDER_LINE_NOTES, 'params', 'successActionCallback', 'errorActionCallback');