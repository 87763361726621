export const APP_LOCAL_STORAGE_KEY = 'omni:app';
export const UNIQ_LOCAL_STORAGE_KEYS = {
  LINES_VIEW: 'lines_view',
  ORDER_VIEW: 'order_view', // Order View
  SALES_REPORT: 'sales_report',
  SHIPPING_RULES_VIEW: 'shipping_rules_view',
  PRODUCTS_VIEW: 'products_view',
  PURCHASE_ORDERS_VIEW: 'purchase_orders_view',
  BRANDS_SUPPLIERS_VIEW: 'brands_suppliers_view',
  GLOBAL: 'global',
};