export const ACTIONS = {
  LOAD_BUSINESS_INFO: 'BUSINESS__LOAD_INFO',
  LOAD_BUSINESS_INFO_SUCCESS: 'BUSINESS__LOAD_INFO_SUCCESS',
  LOAD_BUSINESS_INFO_ERROR: 'BUSINESS__LOAD_INFO_ERROR',
  SEARCH_ZIP_CODE: 'BUSINESS__SEARCH_ZIP_CODE',
  SEARCH_ZIP_CODE_SUCCESS: 'BUSINESS__SEARCH_ZIP_CODE_SUCCESS',
  SET_BUSINESS_INFO_FIELD_VALUE: 'BUSINESS__SET_BUSINESS_INFO_FIELD_VALUE',
  UPLOAD_BUSINESS_LOGO: 'BUSINESS__UPLOAD_LOGO',
  UPLOAD_BUSINESS_LOGO_SUCCESS: 'BUSINESS__UPLOAD_LOGO_SUCCESS',
  UPLOAD_BUSINESS_LOGO_ERROR: 'BUSINESS__UPLOAD_LOGO_ERROR',
  SAVE_BUSINESS_INFO: 'BUSINESS__SAVE_BUSINESS_INFO',
  SAVE_BUSINESS_INFO_SUCCESS: 'BUSINESS__SAVE_BUSINESS_INFO_SUCCESS',
  SAVE_BUSINESS_INFO_ERROR: 'BUSINESS__SAVE_BUSINESS_INFO_ERROR',
  RESET_STATE: 'BUSINESS__RESET_STATE',
};

export const BUSINESS_FIELDS = {
  BUSINESS_NAME: 'business_name',
  EMAIL_ADDRESS: 'email_address',
  PHONE_NUMBER: 'phone_number',
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  ADDRESS_1: 'address_1',
  ADDRESS_2: 'address_2',
  ADDRESS_3: 'address_3',
  CITY: 'city',
  STATE: 'state',
  ZIP_CODE: 'postal_code',
  COUNTRY: 'country',
};