import { createReducer } from 'redux-create-reducer';
import { createLocalStorageReducer } from 'utils/redux';
import { APP_VERSION } from '../config';
import { APP_LOCAL_STORAGE_KEY, UNIQ_LOCAL_STORAGE_KEYS } from '../persistedState';
import { ACTIONS } from './constants';

export const INITIAL_STATE = {
  appVersion: APP_VERSION,
};

const updateAppVersion = (state, { appVersion = APP_VERSION }) => ({
  ...state,
  appVersion,
});

export const handlers = {
  [ACTIONS.SET_APP_VERSION]: updateAppVersion,
};

const globalReducers = (state, action) => createLocalStorageReducer(
  createReducer(INITIAL_STATE, handlers),
  `${APP_LOCAL_STORAGE_KEY}:${UNIQ_LOCAL_STORAGE_KEYS.GLOBAL}`,
)(state, action);

export default globalReducers;