import {
  all, takeEvery, takeLatest,
} from 'redux-saga/effects';
import { shipmentsApiSelector } from 'app/api/selectors';
import { serviceSaga } from 'services/sagaWorkers';
import { authRequired } from 'services/auth/sagas';
import { ACTIONS } from './constants';

const fetchMergeShipmentWatcher = action => serviceSaga(shipmentsApiSelector, 'mergeShipment', action);
const fetchSetupShipRulesWatcher = action => serviceSaga(shipmentsApiSelector, 'getSetupShipRules', action);
const fetchShipRulesWatcher = action => serviceSaga(shipmentsApiSelector, 'getShipRules', action);
const fetchShipRulesCSVWatcher = action => serviceSaga(shipmentsApiSelector, 'shipRulesToCSV', action);
const fetchBulkShipTemplateWatcher = action => serviceSaga(shipmentsApiSelector, 'getBulkShipTemplate', action);
const setRemoveShipRulesWatcher = action => serviceSaga(shipmentsApiSelector, 'removeShipRules', action);
const setAddShipRuleWatcher = action => serviceSaga(shipmentsApiSelector, 'addShipRule', action);
const setUpdateShipRuleWatcher = action => serviceSaga(shipmentsApiSelector, 'updateShipRule', action);
const setBulkShipGetOrdersWatcher = action => serviceSaga(shipmentsApiSelector, 'bulkMarkShipGetOrders', action);

export default function* shipmentsSagas() {
  yield all([
    takeLatest(ACTIONS.FETCH_MERGE_SHIPMENT, authRequired, fetchMergeShipmentWatcher),
    takeEvery(ACTIONS.FETCH_SETUP_SHIP_RULES, authRequired, fetchSetupShipRulesWatcher),
    takeEvery(ACTIONS.FETCH_SHIP_RULES, authRequired, fetchShipRulesWatcher),
    takeEvery(ACTIONS.FETCH_SHIP_RULES_CSV, authRequired, fetchShipRulesCSVWatcher),
    takeEvery(ACTIONS.FETCH_BULK_SHIP_TEMPLATE, authRequired, fetchBulkShipTemplateWatcher),
    takeEvery(ACTIONS.SET_REMOVE_SHIP_RULES, authRequired, setRemoveShipRulesWatcher),
    takeEvery(ACTIONS.SET_ADD_SHIP_RULE, authRequired, setAddShipRuleWatcher),
    takeEvery(ACTIONS.SET_UPDATE_SHIP_RULE, authRequired, setUpdateShipRuleWatcher),
    takeEvery(ACTIONS.SET_BULK_SHIP_GET_ORDERS, authRequired, setBulkShipGetOrdersWatcher),
  ]);
}