import { createAction } from 'redux-create-action';
import { ACTIONS } from './constants';

export const loadBusinessInfo = createAction(ACTIONS.LOAD_BUSINESS_INFO);
export const loadBusinessInfoSuccess = createAction(ACTIONS.LOAD_BUSINESS_INFO_SUCCESS, 'payload');
export const loadBusinessInfoError = createAction(ACTIONS.LOAD_BUSINESS_INFO_ERROR, 'errorMessage', 'error');

export const searchZipCode = createAction(ACTIONS.SEARCH_ZIP_CODE, 'zipCode');
export const searchZipCodeSuccess = createAction(ACTIONS.SEARCH_ZIP_CODE_SUCCESS, 'payload');

export const setBusinessInfoFieldValue = createAction(ACTIONS.SET_BUSINESS_INFO_FIELD_VALUE, 'fieldId', 'value');

export const uploadBusinessLogo = createAction(ACTIONS.UPLOAD_BUSINESS_LOGO, 'file');
export const uploadBusinessLogoSuccess = createAction(ACTIONS.UPLOAD_BUSINESS_LOGO_SUCCESS, 'payload');
export const uploadBusinessLogoError = createAction(ACTIONS.UPLOAD_BUSINESS_LOGO_ERROR, 'errorMessage', 'error');

export const saveBusinessInfo = createAction(ACTIONS.SAVE_BUSINESS_INFO, 'updatedInfo');
export const saveBusinessInfoSuccess = createAction(ACTIONS.SAVE_BUSINESS_INFO_SUCCESS, 'payload');
export const saveBusinessInfoError = createAction(ACTIONS.SAVE_BUSINESS_INFO_ERROR, 'errorMessage', 'error');