import React from 'react';
import PropTypes from 'prop-types';

const FallbackComponent = ({ CustomComponent, pastDelay }) => {
  if (CustomComponent) {
    return <CustomComponent pastDelay={pastDelay} />;
  }

  return (<div>Loading...</div>);
};

FallbackComponent.propTypes = {
  CustomComponent: PropTypes.elementType,
  pastDelay: PropTypes.bool.isRequired,
};

FallbackComponent.defaultProps = {
  CustomComponent: null,
};

export default FallbackComponent;