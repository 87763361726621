export const ACTIONS = {
  INIT_CONTAINER: 'RESET_PASSWORD__INIT_CONTAINER',
  EXIT_CONTAINER: 'RESET_PASSWORD__EXIT_CONTAINER',
  SEND_RECOVERY_LINK: 'RESET_PASSWORD__SEND_RECOVERY_LINK',
  SEND_RECOVERY_LINK_SUCCESS: 'RESET_PASSWORD__SEND_RECOVERY_LINK_SUCCESS',
  SEND_RECOVERY_LINK_ERROR: 'RESET_PASSWORD__SEND_RECOVERY_LINK_ERROR',

  SET_EXPIRED_LINK: 'RESET_PASSWORD__SET_EXPIRED_LINK',

  RESET_STATE: 'RESET_PASSWORD__RESET_STATE',
};