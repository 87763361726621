export const buildInventoryItem = ({
  upc = null,
  qty = 1,
  row = null,
  col = null,
  lvl = null,
  lid = null,
  sku = null,
  rev = null,
  imageURL = null,
  modified = null,
}) => ({
  UPC: upc,
  Qty: qty,
  Row: row,
  Col: col,
  Lvl: lvl,
  lID: lid,
  SKU: sku,
  Rev: rev,
  imageURL,
  modified,
});