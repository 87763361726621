import _get from 'lodash/get';
import { productManagementState } from '../selectors';

export const brandsSuppliersState = state => _get(productManagementState(state), 'brandsSuppliers');

// feedReducers
const feedState = state => _get(brandsSuppliersState(state), 'feed');
export const getFeedData = state => _get(feedState(state), 'data');
export const getItems = state => _get(getFeedData(state), 'brands_suppliers');
export const getFeedDataTotalRecords = state => _get(getFeedData(state), 'totalRecords');
export const getFeedDataPage = state => _get(getFeedData(state), 'page');
export const getFeedDataItemsPerPage = state => _get(getFeedData(state), 'itemsPerPage');
export const getLastPage = state => _get(feedState(state), 'lastPage');
export const getFilters = state => _get(feedState(state), 'filters');

export const getShowOptionsModal = state => _get(feedState(state), 'showOptionsModal');

export const getIsRefreshing = state => _get(feedState(state), 'isRefreshing');
export const getIsFetchingFeed = state => _get(feedState(state), 'isFetchingFeed');

// settings
const settingsState = state => _get(brandsSuppliersState(state), 'settings');
export const getSettingsColumnsOrder = state => _get(settingsState(state), 'columnsOrder');
export const getSettingsColumns = state => _get(settingsState(state), 'columns');
export const getSettingsItemsPerPage = state => _get(settingsState(state), 'itemsPerPage');
export const getSettingsSorting = state => _get(settingsState(state), 'sorting');

// management
const managementState = state => _get(brandsSuppliersState(state), 'management');
export const selectSelectedItem = state => _get(managementState(state), 'selectedItem');
export const selectShowModal = state => _get(managementState(state), 'showModal');
export const selectShowDeleteModal = state => _get(managementState(state), 'showDeleteModal');
export const selectIsSubmitting = state => _get(managementState(state), 'isSubmitting');
export const selectIsRemoving = state => _get(managementState(state), 'isRemoving');
export const selectErrorMessage = state => _get(managementState(state), 'errorMessage');

// brands
const brandsState = state => _get(brandsSuppliersState(state), 'brands');
export const selectBrands = state => _get(brandsState(state), 'items');
export const selectIsFetchingBrands = state => _get(brandsState(state), 'isFetching');

// brandManagement
const brandManagementState = state => _get(brandsSuppliersState(state), 'brandManagement');
export const selectSelectedBrand = state => _get(brandManagementState(state), 'selectedItem');
export const selectCreatedBrand = state => _get(brandManagementState(state), 'createdItem');
export const selectBrandErrorMessage = state => _get(brandManagementState(state), 'errorMessage');
export const selectIsSubmittingBrand = state => _get(brandManagementState(state), 'isSubmitting');
export const selectShowDeleteBrandModal = state => _get(brandManagementState(state), 'showDeleteModal');
export const selectIsRemovingBrand = state => _get(brandManagementState(state), 'isRemoving');
export const selectBrandManagementModal = state => _get(brandManagementState(state), 'showManagementModal');

// suppliers
const suppliersState = state => _get(brandsSuppliersState(state), 'suppliers');
export const selectSuppliers = state => _get(suppliersState(state), 'items');
export const selectIsFetchingSuppliers = state => _get(suppliersState(state), 'isFetching');

// supplierManagement
const supplierManagementState = state => _get(brandsSuppliersState(state), 'supplierManagement');
export const selectSelectedSupplier = state => _get(supplierManagementState(state), 'selectedItem');
export const selectCreatedSupplier = state => _get(supplierManagementState(state), 'createdItem');
export const selectSupplierErrorMessage = state => _get(supplierManagementState(state), 'errorMessage');
export const selectIsSubmittingSupplier = state => _get(supplierManagementState(state), 'isSubmitting');
export const selectShowDeleteSupplierModal = state => _get(supplierManagementState(state), 'showDeleteModal');
export const selectIsRemovingSupplier = state => _get(supplierManagementState(state), 'isRemoving');
export const selectSupplierManagementModal = state => _get(supplierManagementState(state), 'showManagementModal');