import _map from 'lodash/map';
import { ORDER_EXPORTER_ORDER_TYPES } from 'services/orders/constants';

export const ACTIONS = {
  GET_REPORTS: 'ORDER_EXPORTER__GET_REPORTS',
  GET_REPORTS_SUCCESS: 'ORDER_EXPORTER__GET_REPORTS_SUCCESS',
  GET_REPORTS_ERROR: 'ORDER_EXPORTER__GET_REPORTS_ERROR',

  SET_SELECTED_DATE: 'ORDER_EXPORTER__SET_SELECTED_DATE',
  SET_SELECTED_ORDER_TYPE: 'ORDER_EXPORTER__SET_SELECTED_ORDER_TYPE',
  SET_SELECTED_TIMESTAMP: 'ORDER_EXPORTER__SET_SELECTED_TIMESTAMP',

  DOWNLOAD_FILE: 'ORDER_EXPORTER__DOWNLOAD_FILE',
  DOWNLOAD_FILE_SUCCESS: 'ORDER_EXPORTER__DOWNLOAD_FILE_SUCCESS',
  DOWNLOAD_FILE_ERROR: 'ORDER_EXPORTER__DOWNLOAD_FILE_ERROR',

  EXPORT_DOWNLOAD: 'ORDER_EXPORTER__EXPORT_DOWNLOAD',
  EXPORT_DOWNLOAD_SUCCESS: 'ORDER_EXPORTER__EXPORT_DOWNLOAD_SUCCESS',
  EXPORT_DOWNLOAD_ERROR: 'ORDER_EXPORTER__EXPORT_DOWNLOAD_ERROR',

  RESET_STATE: 'ORDER_EXPORTER__RESET_STATE',
};

export const ORDER_TYPE_OPTIONS = _map(ORDER_EXPORTER_ORDER_TYPES, orderType => ({
  value: orderType.id,
  label: orderType.label,
}));