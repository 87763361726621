export const ACTIONS = {
  FIREBASE_SIGN_IN: 'FIREBASE__SIGN_IN',
  FIREBASE_SIGN_OFF: 'FIREBASE__SIGN_OFF',
  FIREBASE_SUBSCRIBE: 'FIREBASE__SUBSCRIBE',
  FIREBASE_UNSUBSCRIBE: 'FIREBASE__UNSUBSCRIBE',
  FIREBASE_WRITE: 'FIREBASE__WRITE',
  FIREBASE_READ: 'FIREBASE__READ',
  FIREBASE_SET_SESSION: 'FIREBASE__SET_SESSION',
};

export const FIREBASE_PATHS = {
  ZONE_LOCK: ({ zoneName }) => `zones/${zoneName}/lock`,
  ZONE_LAST_CHANGE: ({ zoneName }) => `zones/${zoneName}/last_change`,
};