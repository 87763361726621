import styled from 'styled-components';
// import { APP_MIN_WIDTH } from 'constants/global';

// min-width: ${APP_MIN_WIDTH};

export const AppWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  ${({ blockScroll }) => blockScroll && `
    position: fixed;
  `}
`;
