import _get from 'lodash/get';
import { baseState } from '../selectors';

const getState = state => _get(baseState(state), 'newPassword');
export const getIsSubmitting = state => _get(getState(state), 'isSubmitting');
export const getChangeSuccess = state => _get(getState(state), 'changeSuccess');
export const getError = state => _get(getState(state), 'error');
export const getOldPassword = state => _get(getState(state), 'oldPassword');
export const getChangeRequired = state => _get(getState(state), 'changeRequired');
export const getToken = state => _get(getState(state), 'token');
export const getCode = state => _get(getState(state), 'code');