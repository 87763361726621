import {
  all, takeEvery, put, call, select, race, take,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
  getInventoryLocationsChart,
  getInventoryLocationsSummary,
} from 'services/dataVisualization/actions';
import { ACTIONS } from '../constants';
import {
  fetchChartDataSuccess,
  fetchChartDataError,
  fetchChartData,
  generateVisualizationSuccess,
  generateVisualizationError,
  fetchSummaryData,
  fetchSummaryDataSuccess,
  fetchSummaryDataError,
} from '../actions';
import { selectChartSettings } from '../selectors';

function* generateVisualizationWatcher() {
  yield put(fetchChartData());
  yield put(fetchSummaryData());

  const { success, error } = yield race({
    success: take([
      ACTIONS.FETCH_CHART_DATA_SUCCESS,
      ACTIONS.FETCH_SUMMARY_DATA_SUCCESS,
    ]),
    error: take([
      ACTIONS.FETCH_CHART_DATA_ERROR,
      ACTIONS.FETCH_SUMMARY_DATA_ERROR,
    ]),
  });

  if (success) {
    yield put(generateVisualizationSuccess());
  } else if (error) {
    yield put(generateVisualizationError());
  }
}

function* generateVisualizationErrorWatcher({ errorMessage }) {
  yield call([toast, toast.error], errorMessage);
}

function* fetchChartDataWatcher() {
  const chartSettings = yield select(selectChartSettings);

  const params = {
    ...chartSettings,
  };
  yield put(getInventoryLocationsChart(params, fetchChartDataSuccess, fetchChartDataError));
}

function* fetchSummaryDataWatcher() {
  yield put(getInventoryLocationsSummary(fetchSummaryDataSuccess, fetchSummaryDataError));
}

function* setChartSettingsWatcher() {
  yield put(fetchChartData());
}

export default function* containerSagas() {
  yield all([
    takeEvery(ACTIONS.GENERATE_VISUALIZATION, generateVisualizationWatcher),
    takeEvery(ACTIONS.GENERATE_VISUALIZATION_ERROR, generateVisualizationErrorWatcher),
    takeEvery(ACTIONS.FETCH_CHART_DATA, fetchChartDataWatcher),
    takeEvery(ACTIONS.FETCH_SUMMARY_DATA, fetchSummaryDataWatcher),
    takeEvery(ACTIONS.SET_CHART_SETTINGS, setChartSettingsWatcher),
  ]);
}