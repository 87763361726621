import _isError from 'lodash/isError';
import _isString from 'lodash/isString';
import _get from 'lodash/get';

export const isError = data => data.isAxiosError || _isError(data);

export const getErrorMessage = error => {
  if (_isString(error)) {
    return error;
  }

  const responseData = _get(error, 'response.data');
  const errorMessage = _get(error, 'message');
  const errorResponse = responseData || errorMessage;
  return _isString(errorResponse) ? errorResponse : JSON.stringify(errorResponse);
};

export const getErrorStatus = error => {
  if (_isString(error)) {
    return error;
  }
  const responseStatus = _get(error, 'response.status');
  const errorStatus = _get(error, 'status');
  return responseStatus || errorStatus;
};