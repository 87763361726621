import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class ErrorBoundary extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(error) {
    // eslint-disable-next-line no-console
    console.log(error);
    return { hasError: true };
  }

  render() {
    const { hasError } = this.state;
    const { CustomComponent, children } = this.props;

    if (hasError) {
      if (CustomComponent) {
        return <CustomComponent />;
      }

      return <div>Loading failed! Please reload.</div>;
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  CustomComponent: PropTypes.elementType,
  children: PropTypes.oneOfType([
    PropTypes.elementType,
    PropTypes.shape(),
  ]).isRequired,
};

ErrorBoundary.defaultProps = {
  CustomComponent: null,
};

export default ErrorBoundary;