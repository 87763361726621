import { queryStringFromObj } from 'utils/urlParser';
import HttpRequest from '../HttpRequest';
import ENDPOINTS from './constants';

export default class UserManagementApi extends HttpRequest {
  getUsers() {
    return this.call(ENDPOINTS.USERS);
  }

  deleteUser(username) {
    const bodyFormData = new FormData();
    bodyFormData.set('username', username);

    return this.call(ENDPOINTS.DELETE_USER, {
      method: 'POST',
      data: bodyFormData,
    });
  }

  addUser(userData) {
    /*
      {
        first_name,
        last_name,
        email_address,
        phone_number,
        username,
        roles: [],
        active,
      } = userData;
    */
    const bodyFormData = new FormData();
    bodyFormData.set('info', JSON.stringify(userData));

    return this.call(ENDPOINTS.ADD_USER, {
      method: 'POST',
      data: bodyFormData,
    });
  }

  editUser(username, userData) {
    /*
      {
        first_name,
        last_name,
        email_address,
        phone_number,
        roles: [],
        active,
      } = userData;
    */
    const bodyFormData = new FormData();
    bodyFormData.set('username', username);
    bodyFormData.set('info', JSON.stringify(userData));

    return this.call(ENDPOINTS.USER, {
      method: 'POST',
      data: bodyFormData,
    });
  }

  checkUsernameAvailability(username) {
    const queryString = queryStringFromObj({ username });
    return this.call(`${ENDPOINTS.USERNAME_AVAILABLE}${queryString}`);
  }

  resetUserPassword(username) {
    const bodyFormData = new FormData();
    bodyFormData.set('username', username);

    return this.call(ENDPOINTS.RESET_USER_PASSWORD, {
      method: 'POST',
      data: bodyFormData,
    });
  }

  resendInvite(username) {
    const bodyFormData = new FormData();
    bodyFormData.set('username', username);

    return this.call(ENDPOINTS.RESEND_INVITE, {
      method: 'POST',
      data: bodyFormData,
    });
  }

  getRoles() {
    return this.call(ENDPOINTS.GET_ROLES);
  }

  getPermissions() {
    return this.call(ENDPOINTS.GET_PERMISSIONS);
  }

  addRole(params) {
    const { role_name: roleName, description, permissions } = params;
    const bodyFormData = new FormData();
    bodyFormData.set('role_name', roleName);
    bodyFormData.set('description', description);
    bodyFormData.set('permissions', JSON.stringify(permissions));

    return this.call(ENDPOINTS.ADD_ROLE, {
      method: 'POST',
      data: bodyFormData,
    });
  }

  deleteRole(params) {
    const { role_name: roleName } = params;
    const bodyFormData = new FormData();
    bodyFormData.set('role_name', roleName);

    return this.call(ENDPOINTS.DELETE_ROLE, {
      method: 'POST',
      data: bodyFormData,
    });
  }

  editRole(params) {
    const { role_name: roleName, description, permissions } = params;
    const bodyFormData = new FormData();
    bodyFormData.set('role_name', roleName);
    bodyFormData.set('description', description);
    bodyFormData.set('permissions', JSON.stringify(permissions));

    return this.call(ENDPOINTS.EDIT_ROLE, {
      method: 'POST',
      data: bodyFormData,
    });
  }
}

export { ENDPOINTS };
