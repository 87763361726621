import { createReducer } from 'redux-create-reducer';
import { ACTIONS } from './constants';

const INITIAL_STATE = {
  isSubmitting: false,
  logInError: null,
  isSigningOut: false,
};

const setLogIn = state => ({
  ...state,
  isSubmitting: true,
  logInError: INITIAL_STATE.logInError,
});

const setLogInSuccess = state => ({
  ...state,
  isSubmitting: INITIAL_STATE.isSubmitting,
});

const setLogInError = (state, { errorMessage }) => ({
  ...state,
  isSubmitting: INITIAL_STATE.isSubmitting,
  logInError: errorMessage,
});

const setIsSigningOut = (state, isSigningOut = INITIAL_STATE.isSigningOut) => ({
  ...state,
  isSigningOut,
});

const setLogOutSuccess = () => ({
  ...INITIAL_STATE,
});

const setResetState = () => ({
  ...INITIAL_STATE,
});

const handlers = {
  [ACTIONS.SIGN_IN]: setLogIn,
  [ACTIONS.SIGN_IN_SUCCESS]: setLogInSuccess,
  [ACTIONS.SIGN_IN_ERROR]: setLogInError,
  [ACTIONS.SIGN_OFF]: state => setIsSigningOut(state, true),
  [ACTIONS.SIGN_OFF_SUCCESS]: setLogOutSuccess,
  [ACTIONS.SIGN_OFF_ERROR]: state => setIsSigningOut(state, false),
  [ACTIONS.LOGIN_ERROR]: setLogInError,
  [ACTIONS.RESET_STATE]: setResetState,
};

export default createReducer(INITIAL_STATE, handlers);
