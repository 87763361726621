import { formatSortByToObject, formatFilterToObject } from '../helpers';

const ENDPOINTS = {
  MERGE_SHIPMENT: '/merge_shipment',
  SETUP_SHIP_RULES: '/setup_ship_rules',
  SHIP_RULES: '/ship_rules',
  BULK_MARK_SHIP_GET_ORDERS: '/bulk_mark_ship/GetOrders',
};

export const SHIP_RULES_DEFAULT_PARAMS = {
  itemsPerPage: 25,
  days: '180',
  sortBy: formatSortByToObject('brand', 'asc'),
  filter: formatFilterToObject(),
  page: 1,
  parser: 'advanced',
};

export default ENDPOINTS;
