// import { queryStringFromObj } from 'utils/urlParser';
import HttpRequest from '../HttpRequest';
import ENDPOINTS from './constants';

export default class AuthApi extends HttpRequest {
  authTest() {
    return this.call(ENDPOINTS.AUTH_TEST);
  }

  login(username, password) {
    const bodyFormData = new FormData();
    bodyFormData.set('username', username);
    bodyFormData.set('password', password);

    return this.call(ENDPOINTS.LOGIN, {
      method: 'POST',
      data: bodyFormData,
    });
  }

  logout() {
    return this.call(ENDPOINTS.LOGOUT);
  }

  forgotPassword(username) {
    const bodyFormData = new FormData();
    bodyFormData.set('username', username);

    return this.call(ENDPOINTS.FORGOT_PASSWORD, {
      method: 'POST',
      data: bodyFormData,
    });
  }

  verifyPasswordReset(data) {
    const { token, code, newPassword } = data;
    const bodyFormData = new FormData();
    bodyFormData.set('token', token);
    bodyFormData.set('code', code);
    bodyFormData.set('password', newPassword);

    return this.call(ENDPOINTS.VERIFY_PASSWORD_RESET, {
      method: 'POST',
      data: bodyFormData,
    });
  }

  changeTemporaryPassword(data) {
    const { oldPassword, newPassword } = data;
    const bodyFormData = new FormData();
    bodyFormData.set('old_password', oldPassword);
    bodyFormData.set('new_password', newPassword);

    return this.call(ENDPOINTS.CHANGE_TEMPORARY_PASSWORD, {
      method: 'POST',
      data: bodyFormData,
    });
  }
}

export { ENDPOINTS };
