export const ACTIONS = {
  LOAD_USER_PROFILE: 'PROFILE__LOAD_USER_PROFILE',
  LOAD_USER_PROFILE_SUCCESS: 'PROFILE__LOAD_USER_PROFILE_SUCCESS',
  LOAD_USER_PROFILE_ERROR: 'PROFILE__LOAD_USER_PROFILE_ERROR',
  UPDATE_PROFILE: 'PROFILE__UPDATE_PROFILE',
  UPDATE_PROFILE_SUCCESS: 'PROFILE__UPDATE_PROFILE_SUCCESS',
  UPDATE_PROFILE_ERROR: 'PROFILE__UPDATE_PROFILE_ERROR',
  CHANGE_PASSWORD: 'PROFILE__CHANGE_PASSWORD',
  CHANGE_PASSWORD_SUCCESS: 'PROFILE__CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_ERROR: 'PROFILE__CHANGE_PASSWORD_ERROR',
  RESET_STATE: 'PROFILE__RESET_STATE',
};

export const TEXT = {
  CHANGE_PASSWORD_DESCRIPTION: `
    After a successful password update, you will be redirected to the login page where you can log in with your new password.
  `,
};