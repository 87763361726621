import { combineReducers } from 'redux';
import setupReducers from './setupReducers';
import settingsReducers from './settingsReducers';
import visualizationReducers from './visualizationReducers';

export default combineReducers({
  setup: setupReducers,
  settings: settingsReducers,
  visualization: visualizationReducers,
});
