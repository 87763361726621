import { createReducer } from 'redux-create-reducer';
import { ACTIONS } from '../constants';

export const INITIAL_STATE = {
  isLoadingData: false,
  isExporting: false,
  data: [],
};

const setIsExporting = (state, isExporting) => ({
  ...state,
  isExporting,
});

const resetState = () => ({
  ...INITIAL_STATE,
});

export const handlers = {
  [ACTIONS.EXPORT_TO_FILE]: state => setIsExporting(state, true),
  [ACTIONS.EXPORT_TO_FILE_SUCCESS]: state => setIsExporting(state, false),
  [ACTIONS.EXPORT_TO_FILE_ERROR]: state => setIsExporting(state, false),
  [ACTIONS.RESET_STATE]: resetState,
};

export default createReducer(INITIAL_STATE, handlers);