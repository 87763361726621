import { createReducer } from 'redux-create-reducer';
import _get from 'lodash/get';
import { ACTIONS } from './constants';

export const INITIAL_STATE = {
  isFetchingZone: false,
  isReloadingZone: false,
  isScanningUPC: false,
  isLockingZone: false,
  isDeleting: false,
  isMoving: false,
  isSaving: false,
  isRenamingZone: false,
  isSearching: false,
  isLoadingZone: false,
  showDeleteConfirmation: false,
  showMoveConfirmation: false,
  showSearchModal: false,
  zone: null,
  changedItems: [],
  selectedItems: [],
  fieldZoneName: '',
  fieldUPCNumber: '',
  lastScannedUPC: null,
  moveItemsError: null,
  renameZoneError: null,
  searchItemsError: null,
  searchItemsResultsForTerm: null,
  searchItemsResults: null,
};

const updateFieldZoneName = (state, { fieldZoneName }) => ({
  ...state,
  fieldZoneName: fieldZoneName && fieldZoneName.toUpperCase(),
});

const updateFieldUPCNumber = (state, { fieldUPCNumber }) => ({
  ...state,
  fieldUPCNumber,
});

const updateChangedItems = (state, { list }) => ({
  ...state,
  changedItems: list,
});

const setScanZoneName = (state, { zoneName }) => {
  const currentZoneName = _get(state, 'zone.location', null);
  const lastScannedUPC = currentZoneName === zoneName
    ? state.lastScannedUPC
    : INITIAL_STATE.lastScannedUPC;
  const isReloadingZone = currentZoneName === zoneName;

  return {
    ...state,
    isFetchingZone: true,
    isReloadingZone,
    lastScannedUPC,
  };
};

const setScanZoneNameSuccess = (state, { payload }) => ({
  ...state,
  isFetchingZone: INITIAL_STATE.isFetchingZone,
  isReloadingZone: INITIAL_STATE.isReloadingZone,
  fieldZoneName: INITIAL_STATE.fieldZoneName,
  zone: payload,
});

const setScanZoneNameError = state => ({
  ...state,
  isFetchingZone: INITIAL_STATE.isFetchingZone,
  isReloadingZone: INITIAL_STATE.isReloadingZone,
});

const setScanUPCSuccess = state => ({
  ...state,
  isScanningUPC: INITIAL_STATE.isScanningUPC,
  fieldUPCNumber: INITIAL_STATE.fieldUPCNumber,
  lastScannedUPC: state.fieldUPCNumber,
});

const setIsScanningUPC = (state, isScanningUPC = INITIAL_STATE.isScanningUPC) => ({
  ...state,
  isScanningUPC,
});

const setIsLockingZone = (state, isLockingZone) => ({
  ...state,
  isLockingZone,
});

const setIsSaving = (state, isSaving = INITIAL_STATE.isSaving) => ({
  ...state,
  isSaving,
});

const setIsRenamingZone = (state, isRenamingZone = INITIAL_STATE.isRenamingZone) => ({
  ...state,
  isRenamingZone,
});

const setIsDeleting = (state, isDeleting = INITIAL_STATE.isDeleting) => ({
  ...state,
  isDeleting,
});

const setIsMoving = (state, isMoving = INITIAL_STATE.isMoving) => ({
  ...state,
  isMoving,
});

const setShowDeleteConfirmation = (state, { show }) => ({
  ...state,
  showDeleteConfirmation: show,
});

const setShowMoveConfirmation = (state, { show }) => ({
  ...state,
  showMoveConfirmation: show,
});

const setShowSearchModal = (state, { show }) => ({
  ...state,
  showSearchModal: show,
});

const setSaveChangedItems = state => ({
  ...state,
  isSaving: true,
  lastScannedUPC: INITIAL_STATE.lastScannedUPC,
});

const setSaveChangedItemsSuccess = state => ({
  ...state,
  isSaving: INITIAL_STATE.isSaving,
  changedItems: [
    ...state.zone.items,
  ],
});

const setLockZoneSuccess = (state, { payload }) => {
  const nonce = _get(payload, 'nonce');
  const owner = _get(payload, 'owner');
  const lockAcquired = _get(payload, 'lock_acquired');

  return {
    ...state,
    isLockingZone: INITIAL_STATE.isLockingZone,
    zone: {
      ...state.zone,
      lock: {
        ...state.zone.lock,
        locked: lockAcquired,
        nonce,
        owner,
      },
    },
  };
};

const setUnlockZoneSuccess = (state, { payload }) => {
  const lockReleased = _get(payload, 'lock_released');
  if (!lockReleased) {
    return { ...state };
  }

  return {
    ...state,
    isLockingZone: INITIAL_STATE.isLockingZone,
    zone: {
      ...state.zone,
      lock: {
        ...state.zone.lock,
        locked: false,
        nonce: undefined,
        owner: null,
      },
    },
  };
};

const setReloadZoneLockSuccess = (state, { payload }) => ({
  ...state,
  isLockingZone: INITIAL_STATE.isLockingZone,
  fieldUPCNumber: INITIAL_STATE.fieldUPCNumber,
  fieldZoneName: INITIAL_STATE.fieldZoneName,
  zone: {
    ...state.zone,
    lock: {
      ...state.zone.lock,
      ...payload,
    },
  },
});

const setDeleteSelectedItems = state => ({
  ...state,
  isDeleting: true,
  lastScannedUPC: INITIAL_STATE.lastScannedUPC,
});

const setMoveItems = state => ({
  ...state,
  moveItemsError: INITIAL_STATE.moveItemsError,
  isMoving: true,
});

const setMoveItemsError = (state, { errorMessage }) => ({
  ...state,
  moveItemsError: errorMessage,
  isMoving: false,
});

const setRenameZone = state => ({
  ...state,
  renameZoneError: INITIAL_STATE.renameZoneError,
  isRenamingZone: true,
});

const setRenameZoneError = (state, { errorMessage }) => ({
  ...state,
  renameZoneError: errorMessage,
  isRenamingZone: false,
});

const setSearchItems = state => ({
  ...state,
  isSearching: true,
  searchItemsError: INITIAL_STATE.searchItemsError,
  searchItemsResults: INITIAL_STATE.searchItemsResults,
});

const setSearchItemsSuccess = (state, { payload }) => {
  const { search_results: searchItemsResults, searchTerm: searchItemsResultsForTerm } = payload;

  return {
    ...state,
    isSearching: INITIAL_STATE.isSearching,
    searchItemsError: INITIAL_STATE.searchItemsError,
    searchItemsResultsForTerm,
    searchItemsResults,
  };
};

const setSearchItemsError = (state, { errorMessage }) => ({
  ...state,
  isSearching: INITIAL_STATE.isSearching,
  searchItemsError: errorMessage,
});

const setSearchItemsReset = state => ({
  ...state,
  searchItemsResults: INITIAL_STATE.searchItemsResults,
  isSearching: INITIAL_STATE.isSearching,
  searchItemsError: INITIAL_STATE.searchItemsError,
  searchItemsResultsForTerm: INITIAL_STATE.searchItemsResultsForTerm,
});

const setSearchItemsLoadZoneSuccess = (state, { payload }) => ({
  ...state,
  zone: payload,
  isLoadingZone: false,
});

const setIsLoadingZone = (state, isLoadingZone = INITIAL_STATE.isLoadingZone) => ({
  ...state,
  isLoadingZone,
});

const updateSelectedItems = (state, { selectedItems }) => ({
  ...state,
  selectedItems,
});

const resetState = () => ({
  ...INITIAL_STATE,
});

export const handlers = {
  [ACTIONS.SET_FIELD_ZONE_NAME]: updateFieldZoneName,
  [ACTIONS.SET_FIELD_UPC_NUMBER]: updateFieldUPCNumber,
  [ACTIONS.SET_CHANGED_ITEMS]: updateChangedItems,

  [ACTIONS.SCAN_ZONE_NAME]: setScanZoneName,
  [ACTIONS.SCAN_ZONE_NAME_SUCCESS]: setScanZoneNameSuccess,
  [ACTIONS.SCAN_ZONE_NAME_ERROR]: setScanZoneNameError,

  [ACTIONS.SCAN_UPC]: state => setIsScanningUPC(state, true),
  [ACTIONS.SCAN_UPC_SUCCESS]: setScanUPCSuccess,
  [ACTIONS.SCAN_UPC_ERROR]: state => setIsScanningUPC(state, false),

  [ACTIONS.SAVE_CHANGED_ITEMS]: setSaveChangedItems,
  [ACTIONS.SAVE_CHANGED_ITEMS_SUCCESS]: setSaveChangedItemsSuccess,
  [ACTIONS.SAVE_CHANGED_ITEMS_ERROR]: state => setIsSaving(state, false),

  [ACTIONS.LOCK_ZONE]: state => setIsLockingZone(state, true),
  [ACTIONS.LOCK_ZONE_SUCCESS]: setLockZoneSuccess,
  [ACTIONS.LOCK_ZONE_ERROR]: state => setIsLockingZone(state, false),
  [ACTIONS.UNLOCK_ZONE]: state => setIsLockingZone(state, true),
  [ACTIONS.UNLOCK_ZONE_SUCCESS]: setUnlockZoneSuccess,
  [ACTIONS.UNLOCK_ZONE_ERROR]: state => setIsLockingZone(state, false),
  [ACTIONS.RELOAD_ZONE_LOCK]: state => setIsLockingZone(state, true),
  [ACTIONS.RELOAD_ZONE_LOCK_SUCCESS]: setReloadZoneLockSuccess,
  [ACTIONS.RELOAD_ZONE_LOCK_ERROR]: state => setIsLockingZone(state, false),

  [ACTIONS.RENAME_ZONE]: setRenameZone,
  [ACTIONS.RENAME_ZONE_SUCCESS]: state => setIsRenamingZone(state, false),
  [ACTIONS.RENAME_ZONE_ERROR]: setRenameZoneError,

  [ACTIONS.UPDATE_SELECTED_ITEMS]: updateChangedItems,

  [ACTIONS.TOGGLE_SHOW_DELETE_CONFIRMATION]: setShowDeleteConfirmation,
  [ACTIONS.TOGGLE_SHOW_MOVE_CONFIRMATION]: setShowMoveConfirmation,
  [ACTIONS.TOGGLE_SHOW_SEARCH_MODAL]: setShowSearchModal,

  [ACTIONS.DELETE_SELECTED_ITEMS]: setDeleteSelectedItems,
  [ACTIONS.DELETE_SELECTED_ITEMS_SUCCESS]: state => setIsDeleting(state, false),
  [ACTIONS.DELETE_SELECTED_ITEMS_ERROR]: state => setIsDeleting(state, false),

  [ACTIONS.MOVE_SELECTED_ITEMS]: setMoveItems,
  [ACTIONS.MOVE_SELECTED_ITEMS_SUCCESS]: state => setIsMoving(state, false),
  [ACTIONS.MOVE_SELECTED_ITEMS_ERROR]: setMoveItemsError,

  [ACTIONS.SEARCH_ITEMS]: setSearchItems,
  [ACTIONS.SEARCH_ITEMS_SUCCESS]: setSearchItemsSuccess,
  [ACTIONS.SEARCH_ITEMS_ERROR]: setSearchItemsError,
  [ACTIONS.SEARCH_ITEMS_RESET]: setSearchItemsReset,
  [ACTIONS.SEARCH_ITEMS_LOAD_ZONE]: state => setIsLoadingZone(state, true),
  [ACTIONS.SEARCH_ITEMS_LOAD_ZONE_SUCCESS]: setSearchItemsLoadZoneSuccess,
  [ACTIONS.SEARCH_ITEMS_LOAD_ZONE_ERROR]: state => setIsLoadingZone(state, false),

  [ACTIONS.SET_SELECTED_ITEMS]: updateSelectedItems,

  [ACTIONS.RESET_STATE]: resetState,
};

export default createReducer(INITIAL_STATE, handlers);
