import _get from 'lodash/get';
import { reportsState } from '../selectors';

export const reportState = state => _get(reportsState(state), 'inventoryLocations');

// setup reducers
export const getSetup = state => _get(reportState(state), 'setup');
export const getIsExporting = state => _get(getSetup(state), 'isExporting');
export const getIsLoadingData = state => _get(getSetup(state), 'isLoadingData');
export const getData = state => _get(getSetup(state), 'data');

// visualization reducers
const visualizationState = state => _get(reportState(state), 'visualization');
export const selectIsGenerating = state => _get(visualizationState(state), 'isGenerating');
export const selectIsFetchingChartData = state => _get(visualizationState(state), 'isFetchingChartData');
export const selectIsFetchingSummaryData = state => _get(visualizationState(state), 'isFetchingSummaryData');
export const selectChartData = state => _get(visualizationState(state), 'chartData');
export const selectChartSettings = state => _get(visualizationState(state), 'chartSettings');
export const selectSummaryData = state => _get(visualizationState(state), 'summaryData');