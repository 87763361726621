import { createReducer } from 'redux-create-reducer';
import _isEqual from 'lodash/isEqual';
import { ACTIONS, SHIPMENT_VOLUME_UNITS } from './constants';
import { shipmentHasChanges } from './helpers';

const INITIAL_STATE = {
  purchaseOrderItems: null,
  isScanningPurchaseOrder: false,
  isCommittingItems: false,
  isReloadingPurchaseOrder: false,
  initialScannedItemsSnapshot: null,
  scannedItems: null,
  purchaseOrderNumber: null,
  upcErrorList: {},
  showManualConfirmModal: false,
  showSalesOrdersListModal: false,
  manualConfirmItem: null,
  hasChanges: false,
  selectedSalesOrdersList: [],
  shipmentVolumeUnit: SHIPMENT_VOLUME_UNITS[0].value,
  shipmentTotalCost: 0,
  shipmentTotalVolume: 0,
  initialShipmentSnapshot: null,
};

const setCommittingItems = state => ({
  ...state,
  isCommittingItems: true,
  upcErrorList: INITIAL_STATE.upcErrorList,
});

const setIsCommittingItems = (state, isCommittingItems) => ({
  ...state,
  isCommittingItems,
});

const setIsScanningPurchaseOrder = (state, isScanningPurchaseOrder) => ({
  ...state,
  isScanningPurchaseOrder,
});

const setIsReloadingPurchaseOrder = (state, isReloadingPurchaseOrder) => ({
  ...state,
  isReloadingPurchaseOrder,
});

const setScanPurchaseOrderSuccess = (state, { payload }) => ({
  ...state,
  isScanningPurchaseOrder: INITIAL_STATE.isScanningPurchaseOrder,
  isReloadingPurchaseOrder: INITIAL_STATE.isReloadingPurchaseOrder,
  purchaseOrderItems: payload.search_results,
});

const updateScannedItems = (state, { scannedItems }) => ({
  ...state,
  scannedItems,
  hasChanges: !_isEqual(state.initialScannedItemsSnapshot, scannedItems) ||
    shipmentHasChanges(state),
});

const updateScannedItemsSnapshot = (state, { initialScannedItemsSnapshot }) => ({
  ...state,
  initialScannedItemsSnapshot,
});

const updatePurchaseOrderNumber = (state, { purchaseOrderNumber }) => ({
  ...state,
  purchaseOrderNumber,
});

const updateUPCErrorList = (state, { upcErrorList }) => ({
  ...state,
  upcErrorList,
});

const updateShowManualConfirmModal = (state, { showManualConfirmModal }) => ({
  ...state,
  showManualConfirmModal,
});

const updateShowSalesOrdersListModal = (state, { showSalesOrdersListModal }) => ({
  ...state,
  showSalesOrdersListModal,
});

const updateManualConfirmItem = (state, { scannedItemRefId }) => ({
  ...state,
  manualConfirmItem: scannedItemRefId,
});

const updateSelectedSalesOrdersList = (state, { selectedSalesOrdersList }) => ({
  ...state,
  selectedSalesOrdersList,
});

const updateShipmentVolumeUnit = (state, { shipmentVolumeUnit }) => ({
  ...state,
  shipmentVolumeUnit: shipmentVolumeUnit || INITIAL_STATE.shipmentVolumeUnit,
});

const updateShipmentTotalCost = (state, { shipmentTotalCost }) => ({
  ...state,
  shipmentTotalCost: shipmentTotalCost || INITIAL_STATE.shipmentTotalCost,
});

const updateShipmentTotalVolume = (state, { shipmentTotalVolume }) => ({
  ...state,
  shipmentTotalVolume: Number(shipmentTotalVolume) || INITIAL_STATE.shipmentTotalVolume,
});

const updateShipmentSnapshot = (state, { initialShipmentSnapshot }) => ({
  ...state,
  initialShipmentSnapshot,
});

const handlers = {
  [ACTIONS.SCAN_PURCHASE_ORDER]: state => setIsScanningPurchaseOrder(state, true),
  [ACTIONS.SCAN_PURCHASE_ORDER_ERROR]: state => setIsScanningPurchaseOrder(state, false),
  [ACTIONS.SCAN_PURCHASE_ORDER_SUCCESS]: setScanPurchaseOrderSuccess,
  [ACTIONS.SET_SCANNED_ITEMS]: updateScannedItems,
  [ACTIONS.SET_INITIAL_SCANNED_ITEMS_SNAPSHOT]: updateScannedItemsSnapshot,
  [ACTIONS.SET_PURCHASE_ORDER_NUMBER]: updatePurchaseOrderNumber,
  [ACTIONS.COMMIT_SCANNED_ITEMS]: setCommittingItems,
  [ACTIONS.COMMIT_SCANNED_ITEMS_SUCCESS]: state => setIsCommittingItems(state, false),
  [ACTIONS.COMMIT_SCANNED_ITEMS_ERROR]: state => setIsCommittingItems(state, false),
  [ACTIONS.SET_UPC_ERROR_LIST]: updateUPCErrorList,
  [ACTIONS.RELOAD_PURCHASE_ORDER]: state => setIsReloadingPurchaseOrder(state, true),
  [ACTIONS.SET_SHOW_MANUAL_CONFIRM_MODAL]: updateShowManualConfirmModal,
  [ACTIONS.SHOW_SALES_ORDERS_LIST_MODAL]: updateShowSalesOrdersListModal,
  [ACTIONS.SET_MANUAL_CONFIRM_ITEM]: updateManualConfirmItem,
  [ACTIONS.SET_SELECTED_SALES_ORDERS_LIST]: updateSelectedSalesOrdersList,
  [ACTIONS.SET_SHIPMENT_VOLUME_UNIT]: updateShipmentVolumeUnit,
  [ACTIONS.SET_SHIPMENT_TOTAL_COST]: updateShipmentTotalCost,
  [ACTIONS.SET_SHIPMENT_TOTAL_VOLUME]: updateShipmentTotalVolume,
  [ACTIONS.SET_SCANNED_ITEMS_SHIPMENT_COST]: updateScannedItems,
  [ACTIONS.SET_INITIAL_SHIPMENT_SNAPSHOT]: updateShipmentSnapshot,
};

export default createReducer(INITIAL_STATE, handlers);
