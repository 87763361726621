import {
  all, takeEvery, put, select,
} from 'redux-saga/effects';
import _isEmpty from 'lodash/isEmpty';
import { fetchShipCities } from 'services/feeds/actions';
import { ACTIONS, SHIPPING_ADDRESS_FIELD } from '../constants';
import { getShippingAddressField } from '../selectors';
import { searchZipCode, searchZipCodeSuccess, setShippingAddressFieldValue } from '../actions';

function* searchZipCodeWatcher({ zipCode }) {
  yield put(fetchShipCities(zipCode, searchZipCodeSuccess));
}

function* setShippingAddressFieldValueWatcher({ fieldId, value }) {
  const shipCountry = yield select(getShippingAddressField(SHIPPING_ADDRESS_FIELD.COUNTRY));
  if (fieldId === SHIPPING_ADDRESS_FIELD.ZIP_CODE && shipCountry === 'US' && value.length >= 5) {
    yield put(searchZipCode(value));
  }
}

function* searchZipCodeSuccessWatcher({ payload }) {
  const { search_results: results } = payload;
  if (_isEmpty(results)) {
    return;
  }

  const { ship_city: shipCity, state: shipState } = results[0];
  yield put(setShippingAddressFieldValue(SHIPPING_ADDRESS_FIELD.CITY, shipCity));
  yield put(setShippingAddressFieldValue(SHIPPING_ADDRESS_FIELD.STATE, shipState));
}

export default function* shippingAddressSagas() {
  yield all([
    takeEvery(ACTIONS.SET_SHIPPING_ADDRESS_FIELD_VALUE, setShippingAddressFieldValueWatcher),
    takeEvery(ACTIONS.SEARCH_ZIP_CODE, searchZipCodeWatcher),
    takeEvery(ACTIONS.SEARCH_ZIP_CODE_SUCCESS, searchZipCodeSuccessWatcher),
  ]);
}