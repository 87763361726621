import { createAction } from 'redux-create-action';
import { ACTIONS } from './constants';

export const initContainer = createAction(ACTIONS.INIT_CONTAINER);
export const exitContainer = createAction(ACTIONS.EXIT_CONTAINER);

export const sendRecoveryLink = createAction(ACTIONS.SEND_RECOVERY_LINK, 'username');
export const sendRecoveryLinkSuccess = createAction(ACTIONS.SEND_RECOVERY_LINK_SUCCESS, 'payload');
export const sendRecoveryLinkError = createAction(ACTIONS.SEND_RECOVERY_LINK_ERROR, 'errorMessage', 'error');

export const setExpiredLink = createAction(ACTIONS.SET_EXPIRED_LINK, 'expiredLink');

export const resetState = createAction(ACTIONS.RESET_STATE);