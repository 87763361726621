import styled from 'styled-components';
import { FieldWrap, FieldGroup } from 'components/Field/styles';
import { CircleButtonWrap } from 'components/CircleButton/styles';

export const PaginationWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-right: 2rem;
  &:last-child {
    margin-right: 0;
  }
  > * {
    display: flex;
    align-items: center;
  }
  > span, > label {
    white-space: nowrap;
    margin: 0 0.5rem;
    font-size: 0.9rem;
  }
  ${FieldGroup} {
    flex: none;
    width: auto;
    ${CircleButtonWrap} {
      margin: 0;
    }
  }
  form {
    ${FieldWrap} {
      max-width: 5rem;
      margin: 0 0.5rem;
      > input {
        text-align: center;
      }
    }
  }
`;