import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SpinnerComponent from 'react-svg-spinner';
import {
  AMBER, ATHENS_GRAY, AZURE, BLACK, BLUE_VIOLET, BOMBAY, CRUSTA, EUCALYPTUS, PUNCH, WHITE,
} from 'constants/colors';

const spinnerSpeeds = {
  fast: 'fast',
  slow: 'slow',
};

const spinnerSizes = {
  small: 'small',
  medium: 'medium',
  large: 'large',
};

const sizes = {
  [spinnerSizes.small]: '1rem',
  [spinnerSizes.medium]: '3rem',
  [spinnerSizes.large]: '5rem',
};

const spinnerThickness = {
  thin: 'thin',
  medium: 'medium',
  thick: 'thick',
};

const thicknesses = {
  [spinnerThickness.thin]: 1,
  [spinnerThickness.medium]: 3,
  [spinnerThickness.thick]: 5,
};

const spinnerGaps = {
  single: 'single',
  double: 'double',
  triple: 'triple',
};

const gaps = {
  [spinnerGaps.single]: 3,
  [spinnerGaps.double]: 8,
  [spinnerGaps.triple]: 9,
};

const spinnerColors = {
  default: 'default',
  primary: 'primary',
  secondary: 'secondary',
  danger: 'danger',
  success: 'success',
  info: 'info',
  warning: 'warning',
  dark: 'dark',
  light: 'light',
  ghost: 'ghost',
};

const colors = {
  [spinnerColors.default]: BOMBAY,
  [spinnerColors.primary]: BLUE_VIOLET,
  [spinnerColors.secondary]: CRUSTA,
  [spinnerColors.danger]: PUNCH,
  [spinnerColors.success]: EUCALYPTUS,
  [spinnerColors.info]: AZURE,
  [spinnerColors.warning]: AMBER,
  [spinnerColors.dark]: BLACK,
  [spinnerColors.light]: ATHENS_GRAY,
  [spinnerColors.ghost]: WHITE,
};

export const SpinnerCenteredWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  justify-content: center;
  margin: auto;
  > span {
    padding: 0.5rem;
  }
  ${({ overlay, overlayColor }) => overlay && `
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: ${overlayColor || 'rgba(255, 255, 255, 0.6)'};
  `}
`;

const SpinnerCentered = ({
  text,
  overlay,
  overlayColor,
  ...rest
}) => (
  <SpinnerCenteredWrap overlay={overlay}>
    <Spinner {...rest} />
    {text && <span>{text}</span>}
  </SpinnerCenteredWrap>
);

SpinnerCentered.propTypes = {
  text: PropTypes.string,
  overlay: PropTypes.bool,
  overlayColor: PropTypes.string,
};

SpinnerCentered.defaultProps = {
  text: null,
  overlay: false,
  overlayColor: null,
};

const Spinner = ({
  color, thickness, gap, speed, size,
}) => (
  <SpinnerComponent
    color={colors[color] || colors.default}
    thickness={thicknesses[thickness] || thickness.thin}
    gap={gaps[gap] || gaps.single}
    speed={spinnerSpeeds[speed] || spinnerSpeeds.fast}
    size={sizes[size] || sizes.medium}
  />
);

Spinner.propTypes = {
  color: PropTypes.oneOf(Object.values(spinnerColors)),
  thickness: PropTypes.oneOf(Object.values(spinnerThickness)),
  size: PropTypes.oneOf(Object.values(spinnerSizes)),
  gap: PropTypes.oneOf(Object.values(spinnerGaps)),
  speed: PropTypes.oneOf(Object.values(spinnerSpeeds)),
};

Spinner.defaultProps = {
  color: spinnerColors.default,
  thickness: spinnerThickness.thin,
  size: spinnerSizes.medium,
  gap: spinnerGaps.single,
  speed: spinnerSpeeds.fast,
};

export default Spinner;

export {
  SpinnerCentered,
  spinnerSizes,
  spinnerSpeeds,
  spinnerColors,
  spinnerThickness,
  spinnerGaps,
};
