import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';

export const DescriptionCell = styled.div`
  width: 100%;
  h4 {
    display: block;
    word-break: break-all;
  }
  > span {
    word-break: break-all;
  }
`;

const DescriptionContent = ({ item }) => (
  <DescriptionCell>
    <Grid container justifyContent="space-between" style={{ padding: '0 0 0.4rem 0' }}>
      {item.brand && (
        <span>{item.brand}</span>
      )}
      {item.supplier && (
        <span><em>{`by ${item.supplier}`}</em></span>
      )}
    </Grid>
    {item.sku && (
      <h4>{item.sku}</h4>
    )}
    {item.product_name && (
      <span><em>{item.product_name}</em></span>
    )}
  </DescriptionCell>
);

DescriptionContent.propTypes = {
  item: PropTypes.shape(),
};

DescriptionContent.defaultProps = {
  item: {},
};

export default DescriptionContent;
