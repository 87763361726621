export const ACTIONS = {
  INIT_USERS_MANAGEMENT_CONTAINER: 'USERS_MANAGEMENT__INIT_CONTAINER',
  INIT_USERS_MANAGEMENT_CONTAINER_SUCCESS: 'USERS_MANAGEMENT__INIT_CONTAINER_SUCCESS',
  INIT_USERS_MANAGEMENT_CONTAINER_ERROR: 'USERS_MANAGEMENT__INIT_CONTAINER_ERROR',
  EXIT_USERS_MANAGEMENT_CONTAINER: 'USERS_MANAGEMENT__EXIT_CONTAINER',
  LOAD_ACCOUNT_USERS: 'USERS_MANAGEMENT__LOAD_ACCOUNT_USERS',
  LOAD_ACCOUNT_USERS_SUCCESS: 'USERS_MANAGEMENT__LOAD_ACCOUNT_USERS_SUCCESS',
  LOAD_ACCOUNT_USERS_ERROR: 'USERS_MANAGEMENT__LOAD_ACCOUNT_USERS_ERROR',
  CHECK_USERNAME_AVAILABILITY: 'USERS_MANAGEMENT__CHECK_USERNAME_AVAILABILITY',
  CHECK_USERNAME_AVAILABILITY_SUCCESS: 'USERS_MANAGEMENT__CHECK_USERNAME_AVAILABILITY_SUCCESS',
  CHECK_USERNAME_AVAILABILITY_ERROR: 'USERS_MANAGEMENT__CHECK_USERNAME_AVAILABILITY_ERROR',
  SET_AVAILABILITY_ERRORS: 'USERS_MANAGEMENT__SET_AVAILABILITY_ERRORS',
  SET_SHOW_ADD_USER_MODAL: 'USERS_MANAGEMENT__SET_SHOW_ADD_USER_MODAL',
  SET_SHOW_DELETE_USER_MODAL: 'USERS_MANAGEMENT__SET_SHOW_DELETE_USER_MODAL',
  SET_SHOW_EDIT_USER_MODAL: 'USERS_MANAGEMENT__SET_SHOW_EDIT_USER_MODAL',
  SET_ROLES: 'USERS_MANAGEMENT__SET_ROLES',
  ADD_USER: 'USERS_MANAGEMENT__ADD_USER',
  ADD_USER_SUCCESS: 'USERS_MANAGEMENT__ADD_USER_SUCCESS',
  ADD_USER_ERROR: 'USERS_MANAGEMENT__ADD_USER_ERROR',
  EDIT_USER: 'USERS_MANAGEMENT__EDIT_USER',
  EDIT_USER_SUCCESS: 'USERS_MANAGEMENT__EDIT_USER_SUCCESS',
  EDIT_USER_ERROR: 'USERS_MANAGEMENT__EDIT_USER_ERROR',
  DELETE_USER: 'USERS_MANAGEMENT__DELETE_USER',
  DELETE_USER_SUCCESS: 'USERS_MANAGEMENT__DELETE_USER_SUCCESS',
  DELETE_USER_ERROR: 'USERS_MANAGEMENT__DELETE_USER_ERROR',
  SET_DELETE_SELECTED_USER: 'USERS_MANAGEMENT__SET_DELETE_SELECTED_USER',
  SET_EDIT_SELECTED_USER: 'USERS_MANAGEMENT__SET_EDIT_SELECTED_USER',
  SET_AUTHORIZATION_ERROR: 'USERS_MANAGEMENT__SET_AUTHORIZATION_ERROR',
  RESEND_INVITE_EMAIL: 'USERS_MANAGEMENT__RESEND_INVITE_EMAIL',
  RESEND_INVITE_EMAIL_SUCCESS: 'USERS_MANAGEMENT__RESEND_INVITE_EMAIL_SUCCESS',
  RESEND_INVITE_EMAIL_ERROR: 'USERS_MANAGEMENT__RESEND_INVITE_EMAIL_ERROR',
  RESET_STATE: 'USERS_MANAGEMENT__RESET_STATE',
};

export const USER_FIELDS = {
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  EMAIL_ADDRESS: 'email_address',
  PHONE_NUMBER: 'phone_number',
  ROLES: 'roles',
  ACTIVE: 'active',
  USERNAME: 'username',
};