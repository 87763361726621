import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { ListDivider } from 'components/List';
import { DescriptionCell } from 'features/Reports/components/ReportTable/DescriptionContent';

const DescriptionContent = ({ item }) => (
  <DescriptionCell>
    <Grid container justifyContent="space-between" style={{ padding: '0 0 0.4rem 0' }}>
      {item.brand && (
        <span>{item.brand}</span>
      )}
    </Grid>
    {item.sku && (
      <h4>{item.sku}</h4>
    )}
    <ListDivider margin="0.5rem 0 0.2rem 0" width="100%" />
    <Grid container justifyContent="space-between">
      {item.lead_time && (
        <span>
          <span>Leadtime: </span>
          {` ${item.lead_time}`}
        </span>
      )}
    </Grid>
  </DescriptionCell>
);

DescriptionContent.propTypes = {
  item: PropTypes.shape(),
};

DescriptionContent.defaultProps = {
  item: {},
};

export default DescriptionContent;
