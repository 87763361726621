import { all, fork } from 'redux-saga/effects';
import exportToFileSagas from './exportToFileSagas';
import loadReportSagas from './loadReportSagas';
import searchSagas from './searchSagas';
import settingsSagas from './settingsSagas';

export default function* salesReportSagas(...args) {
  yield all([
    fork(loadReportSagas, ...args),
    fork(exportToFileSagas, ...args),
    fork(searchSagas, ...args),
    fork(settingsSagas, ...args),
  ]);
}
