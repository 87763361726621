import { combineReducers } from 'redux';
import orderFeedReducers from './orderFeedReducers';
import settingsReducers from './settingsReducers';
import orderLineManagementReducers from './orderLineManagementReducers';
import searchOrdersReducers from './searchOrdersReducers';
import createOrderReducers from './createOrderReducers';
import configReducers from './configReducers';
import orderDetailsReducers from './orderDetailsReducers';
import orderLinesReducers from './orderLinesReducers';
import orderLineNoteReducers from './orderLineNoteReducers';

export default combineReducers({
  config: configReducers,
  createOrder: createOrderReducers,
  orderDetails: orderDetailsReducers,
  orderFeed: orderFeedReducers,
  orderLineManagement: orderLineManagementReducers,
  orderLineNote: orderLineNoteReducers,
  orderLines: orderLinesReducers,
  searchOrders: searchOrdersReducers,
  settings: settingsReducers,
});
