import { combineReducers } from 'redux';
import rulesFeedReducers from './rulesFeedReducers';
import settingsReducers from './settingsReducers';
import rulesManagementReducers from './rulesManagementReducers';
import setupReducers from './setupReducers';

export default combineReducers({
  rulesFeed: rulesFeedReducers,
  settings: settingsReducers,
  rulesManagement: rulesManagementReducers,
  setup: setupReducers,
});
