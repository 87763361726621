import {
  all, fork, takeEvery, select, put,
} from 'redux-saga/effects';
import zoneASagas from './zoneASagas';
import zoneBSagas from './zoneBSagas';
import { VIEW_ACTIONS as ACTIONS } from '../constants';
import { getZoneName as getZoneNameA } from '../selectors/zoneASelectors';
import { getZoneName as getZoneNameB } from '../selectors/zoneBSelectors';
import { reloadZone as reloadZoneA } from '../actions/zoneA';
import { reloadZone as reloadZoneB } from '../actions/zoneB';

function* initContainerWatcher() {
  const zoneNameA = yield select(getZoneNameA);
  if (zoneNameA) {
    yield put(reloadZoneA());
  }

  const zoneNameB = yield select(getZoneNameB);
  if (zoneNameB) {
    yield put(reloadZoneB());
  }
}

export default function* inventoryMoveSagas(...args) {
  yield all([
    takeEvery(ACTIONS.INIT_CONTAINER, initContainerWatcher),
    fork(zoneASagas, ...args),
    fork(zoneBSagas, ...args),
  ]);
}
