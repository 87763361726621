import _uniq from 'lodash/uniq';
import _map from 'lodash/map';
import _includes from 'lodash/includes';
import _reduce from 'lodash/reduce';
import _isEmpty from 'lodash/isEmpty';
import _pick from 'lodash/pick';
import _compact from 'lodash/compact';

export const formatScanSalesOrderFilter = salesOrderNumber => {
  const filter = [{
    col: 'tracking_number',
    label: 'tracking',
    action: '=',
    value: 'null',
    com: '',
  }, {
    col: 'line_type',
    label: 'type',
    action: '<>',
    value: 'd',
    com: '',
  }, {
    col: 'sales_order_number',
    label: 'so',
    action: '=',
    value: salesOrderNumber,
    com: '',
  }];
  return JSON.stringify(filter);
};

export const validateAllItemsConfirmed = scannedItems => {
  const foo = _uniq(_map(scannedItems, item => item.count === item.total));
  return !_includes(foo, false);
};

export const getLinesConfirmedCounter = scannedItems => {
  const total = Object.values(scannedItems).length;
  const count = _reduce(scannedItems, (sum, item) => {
    const number = item.count === item.total ? 1 : 0;
    return sum + number;
  }, 0);
  return {
    total,
    count,
  };
};

export const getLinesConfirmedGuidOrderLine = scannedItems => {
  const guidList = _reduce(scannedItems, (list, item) => {
    if (item.count === item.total) {
      list.push(item.guid_order_line);
    }
    return list;
  }, []);

  return guidList;
};

export const getLinesConfirmed = scannedItems => {
  const skuList = _reduce(scannedItems, (list, item) => {
    if (item.count === item.total) {
      list.push(item);
    }
    return list;
  }, []);

  return skuList;
};

export const getOrderInfoDetails = orderLines => {
  if (_isEmpty(orderLines)) {
    return null;
  }

  const line = orderLines[0];

  const addressInfo = _pick(line, [
    'ship_name',
    'ship_address_1',
    'ship_address_2',
    'ship_address_3',
    'ship_city',
    'ship_state',
    'ship_country',
    'ship_postal_code',
    'ship_phone',
  ]);

  const address = _compact([
    addressInfo.ship_name,
    `${addressInfo.ship_address_1} ${addressInfo.ship_address_2} ${addressInfo.ship_address_3}`,
    `${addressInfo.ship_city}, ${addressInfo.ship_state} ${addressInfo.ship_postal_code}`,
    addressInfo.ship_country,
  ]).join('\n');

  const totalPrice = _reduce(orderLines, (sum, item) => sum + (item.price * item.qty_ordered), 0);

  return {
    address,
    phone: addressInfo.ship_phone,
    totalPrice,
    shippingType: line.shipping_priority,
    flags: {
      prime: line.is_prime,
      premium: line.is_premium,
      sameDay: line.shipping_priority === 'sameday',
    },
  };
};