import {
  all, takeEvery, put, select, call,
} from 'redux-saga/effects';
import _get from 'lodash/get';
import { addBrand, deleteBrand, updateBrand } from 'services/brands/actions';
import { errorToasterSaga, successToasterSaga } from 'services/sagaWorkers';
import {
  createBrandError,
  createBrandSuccess,
  editBrandError,
  editBrandSuccess,
  refreshFeed,
  removeBrandError,
  removeBrandSuccess,
  showBrandManagementModal,
  showDeleteBrandModal,
} from '../actions';
import { ACTIONS } from '../constants';
import { selectSelectedBrand } from '../selectors';

function* createBrandWatcher({ formValues }) {
  yield put(addBrand(formValues, createBrandSuccess, createBrandError));
}

function* removeBrandWatcher({ brandId }) {
  yield put(deleteBrand(brandId, removeBrandSuccess, removeBrandError));
}

function* removeBrandSuccessWatcher() {
  yield call(successToasterSaga, { message: 'Brand successfully deleted.' });
  yield put(refreshFeed());
  yield put(showDeleteBrandModal());
}

function* editBrandWatcher({ formValues }) {
  const selectedBrand = yield select(selectSelectedBrand);
  const brandId = _get(selectedBrand, 'brand_id');
  yield put(updateBrand(brandId, formValues, editBrandSuccess, editBrandError));
}

function* editBrandSuccessWatcher() {
  yield call(successToasterSaga, { message: 'Brand successfully updated.' });
  yield put(refreshFeed());
  yield put(showBrandManagementModal(false));
}

export default function* brandManagementSagas() {
  yield all([
    takeEvery(ACTIONS.CREATE_BRAND, createBrandWatcher),
    takeEvery(ACTIONS.CREATE_BRAND_ERROR, errorToasterSaga),

    takeEvery(ACTIONS.REMOVE_BRAND, removeBrandWatcher),
    takeEvery(ACTIONS.REMOVE_BRAND_ERROR, errorToasterSaga),
    takeEvery(ACTIONS.REMOVE_BRAND_SUCCESS, removeBrandSuccessWatcher),

    takeEvery(ACTIONS.EDIT_BRAND, editBrandWatcher),
    takeEvery(ACTIONS.EDIT_BRAND_ERROR, errorToasterSaga),
    takeEvery(ACTIONS.EDIT_BRAND_SUCCESS, editBrandSuccessWatcher),
  ]);
}