import {
  all, takeEvery, put,
} from 'redux-saga/effects';
import { fetchInstalled, fetchChannels } from 'services/integrations/actions';
import {
  loadChannels,
  loadChannelsError,
  loadChannelsSuccess,
  loadIntegrations,
  loadIntegrationsError,
  loadIntegrationsSuccess,
  setAddIntegrationId,
  setModifyIntegrationId,
  setDeleteIntegrationId,
} from './actions';
import { ACTIONS } from './constants';

function* initContainerWatcher() {
  yield put(loadChannels());
  yield put(loadIntegrations());
}

function* loadChannelsWatcher() {
  yield put(fetchChannels(loadChannelsSuccess, loadChannelsError));
}

function* loadIntegrationsWatcher() {
  yield put(fetchInstalled(loadIntegrationsSuccess, loadIntegrationsError));
}

function* setShowAddIntegrationModalWatcher({ showAddIntegrationModal }) {
  if (!showAddIntegrationModal) {
    yield put(setAddIntegrationId(null));
  }
}

function* setShowModifyIntegrationModalWatcher({ showModifyIntegrationModal }) {
  if (!showModifyIntegrationModal) {
    yield put(setModifyIntegrationId(null));
  }
}

function* setShowDeleteIntegrationModalWatcher({ showDeleteIntegrationModal }) {
  if (!showDeleteIntegrationModal) {
    yield put(setDeleteIntegrationId(null));
  }
}

export default function* integrationsSagas(/* ...args */) {
  yield all([
    takeEvery(ACTIONS.INIT_CONTAINER, initContainerWatcher),
    takeEvery(ACTIONS.LOAD_CHANNELS, loadChannelsWatcher),
    takeEvery(ACTIONS.LOAD_INTEGRATIONS, loadIntegrationsWatcher),
    takeEvery(ACTIONS.SET_SHOW_ADD_INTEGRATION_MODAL, setShowAddIntegrationModalWatcher),
    takeEvery(ACTIONS.SET_SHOW_MODIFY_INTEGRATION_MODAL, setShowModifyIntegrationModalWatcher),
    takeEvery(ACTIONS.SET_SHOW_DELETE_INTEGRATION_MODAL, setShowDeleteIntegrationModalWatcher),
  ]);
}
