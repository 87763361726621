/* eslint-disable max-len */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable no-console */
import _forEach from 'lodash/forEach';
import _chunk from 'lodash/chunk';

export const MOCK_API_LATENCY = 200;
export const LONG_DELAY = 3000;

export const delayResponse = (response, time = MOCK_API_LATENCY) => new Promise(resolve => {
  setTimeout(() => {
    resolve(response);
  }, time);
});

export const rejectRequest = (error, time = MOCK_API_LATENCY) => new Promise((resolve, rejected) => {
  setTimeout(() => {
    rejected(new Error(error));
  }, time);
});

export const logRequest = (...args) => {
  console.group('Request');
  _forEach(args, arg => {
    if (typeof arg === 'string') {
      console.info(`%c${arg}`, 'background: #eaeaea; color: #007bff; padding: 8px; border-radius: 8px;');
    } else {
      console.info(arg);
    }
  });
  console.groupEnd('Request');
};

export const buildFeedResponse = (dataJson, params, dataKey = 'results') => {
  const { page, itemsPerPage } = params;
  const pageNum = page || 1;
  const totalRecords = dataJson.length;
  const dataChunks = _chunk(dataJson, itemsPerPage);
  return {
    data: {
      [dataKey]: dataChunks[pageNum - 1],
      page: pageNum,
      itemsPerPage,
      totalRecords,
    },
  };
};