import {
  all, takeEvery, put, select, call,
} from 'redux-saga/effects';
import _get from 'lodash/get';
import { addSupplier, deleteSupplier, updateSupplier } from 'services/suppliers/actions';
import { errorToasterSaga, successToasterSaga } from 'services/sagaWorkers';
import {
  createSupplierError,
  createSupplierSuccess,
  editSupplierError,
  editSupplierSuccess,
  refreshFeed,
  removeSupplierError,
  removeSupplierSuccess,
  showSupplierManagementModal,
  showDeleteSupplierModal,
} from '../actions';
import { ACTIONS } from '../constants';
import { selectSelectedSupplier } from '../selectors';

function* createSupplierWatcher({ formValues }) {
  yield put(addSupplier(formValues, createSupplierSuccess, createSupplierError));
}

function* removeSupplierWatcher({ supplierId }) {
  yield put(deleteSupplier(supplierId, removeSupplierSuccess, removeSupplierError));
}

function* removeSupplierSuccessWatcher() {
  yield call(successToasterSaga, { message: 'Supplier successfully deleted.' });
  yield put(refreshFeed());
  yield put(showDeleteSupplierModal());
}

function* editSupplierWatcher({ formValues }) {
  const selectedSupplier = yield select(selectSelectedSupplier);
  const supplierId = _get(selectedSupplier, 'supplier_id');
  yield put(updateSupplier(supplierId, formValues, editSupplierSuccess, editSupplierError));
}

function* editSupplierSuccessWatcher() {
  yield call(successToasterSaga, { message: 'Supplier successfully updated.' });
  yield put(refreshFeed());
  yield put(showSupplierManagementModal(false));
}

export default function* supplierManagementSagas() {
  yield all([
    takeEvery(ACTIONS.CREATE_SUPPLIER, createSupplierWatcher),
    takeEvery(ACTIONS.CREATE_SUPPLIER_ERROR, errorToasterSaga),

    takeEvery(ACTIONS.REMOVE_SUPPLIER, removeSupplierWatcher),
    takeEvery(ACTIONS.REMOVE_SUPPLIER_ERROR, errorToasterSaga),
    takeEvery(ACTIONS.REMOVE_SUPPLIER_SUCCESS, removeSupplierSuccessWatcher),

    takeEvery(ACTIONS.EDIT_SUPPLIER, editSupplierWatcher),
    takeEvery(ACTIONS.EDIT_SUPPLIER_ERROR, errorToasterSaga),
    takeEvery(ACTIONS.EDIT_SUPPLIER_SUCCESS, editSupplierSuccessWatcher),
  ]);
}