import { combineReducers } from 'redux';
import pickTicketGeneratorReducers from './PickTicketGenerator/reducers';
import inventoryLabelGeneratorReducers from './InventoryLabelGenerator/reducers';
import UPCLabelGeneratorReducers from './UPCLabelGenerator/reducers';
import bulkMarkShipReducers from './BulkMarkShip/reducers';
import { ACTIONS } from './constants';

const featureReducers = combineReducers({
  pickTicketGenerator: pickTicketGeneratorReducers,
  inventoryLabelGenerator: inventoryLabelGeneratorReducers,
  UPCLabelGenerator: UPCLabelGeneratorReducers,
  bulkMarkShip: bulkMarkShipReducers,
});

const toolsReducers = (state, action) => {
  let featureState = state;
  if (action.type === ACTIONS.RESET_FEATURE_REDUCERS) {
    featureState = undefined;
  }
  return featureReducers(featureState, action);
};

export default toolsReducers;
