import {
  put, all, takeEvery, call, select,
} from 'redux-saga/effects';
import _reduce from 'lodash/reduce';
import { errorToasterSaga, successToasterSaga } from 'services/sagaWorkers';
import { setReplaceQty } from 'services/inventory/actions';
import { buildInventoryItem } from 'services/inventory/helpers';
import { INVENTORY_MANAGEMENT_ERRORS, INVENTORY_MANAGEMENT_SUCCESS } from 'features/InventoryManagement/constants';
import { ACTIONS } from '../constants';
import {
  saveChangedItemsSuccess,
  saveChangedItemsError,
  reloadZone,
} from '../actions';
import {
  getZoneName,
  getZoneLevel,
  getZoneRow,
  getZoneCol,
} from '../selectors';

function* saveChangedItemsSuccessWatcher() {
  yield put(reloadZone());
  yield call(successToasterSaga, { message: INVENTORY_MANAGEMENT_SUCCESS.CHANGING_ITEMS });
}

function* saveChangedItemsWatcher({ changedItems }) {
  const zoneName = yield select(getZoneName);
  const zoneLevel = yield select(getZoneLevel);
  const zoneRow = yield select(getZoneRow);
  const zoneCol = yield select(getZoneCol);
  const collection = _reduce(changedItems, (result, item) => {
    const inventoryItem = buildInventoryItem({
      row: zoneRow,
      col: zoneCol,
      lvl: zoneLevel,
      ...item,
    });
    result.push(inventoryItem);
    return result;
  }, []);

  yield put(setReplaceQty(zoneName, collection, saveChangedItemsSuccess, saveChangedItemsError));
}

function* saveChangedItemsErrorWatcher({ errorMessage }) {
  yield put(reloadZone());
  const errorDisplay = errorMessage || INVENTORY_MANAGEMENT_ERRORS.CHANGING_ITEMS;
  yield call(errorToasterSaga, { errorMessage: errorDisplay });
}

export default function* changeZoneItemsSagas() {
  yield all([
    takeEvery(ACTIONS.SAVE_CHANGED_ITEMS, saveChangedItemsWatcher),
    takeEvery(ACTIONS.SAVE_CHANGED_ITEMS_SUCCESS, saveChangedItemsSuccessWatcher),
    takeEvery(ACTIONS.SAVE_CHANGED_ITEMS_ERROR, saveChangedItemsErrorWatcher),
  ]);
}