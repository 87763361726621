import { all, put } from 'redux-saga/effects';
import { firebaseSubscribe, firebaseUnsubscribe } from 'services/firebase/actions';
import { FIREBASE_PATHS } from 'services/firebase/constants';

export function* unsubscribeFromZoneUpdates(zoneName, prevZoneName) {
  if (!prevZoneName) {
    return;
  }
  // unsubscribe from firebase paths
  const zoneLockPath = FIREBASE_PATHS.ZONE_LOCK({ zoneName: prevZoneName });
  const zoneInventoryChangePath = FIREBASE_PATHS.ZONE_LAST_CHANGE({
    zoneName: prevZoneName,
  });

  yield all([
    put(firebaseUnsubscribe(zoneLockPath)),
    put(firebaseUnsubscribe(zoneInventoryChangePath)),
  ]);
}

export function* subscribeToZoneUpdates(zoneName, reloadZoneLockAction, reloadZoneAction) {
  if (!zoneName) {
    return;
  }
  // subscribe to firebase path
  const zoneLockPath = FIREBASE_PATHS.ZONE_LOCK({ zoneName });
  const zoneInventoryChangePath = FIREBASE_PATHS.ZONE_LAST_CHANGE({ zoneName });

  yield all([
    put(firebaseSubscribe(zoneLockPath, reloadZoneLockAction)),
    put(firebaseSubscribe(zoneInventoryChangePath, reloadZoneAction)),
  ]);
}