import {
  all, takeEvery, put, call, select,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { getFilteredOrderFeed, getFilteredOrderLineFeed } from 'services/orders/actions';
import { fileSaverSaga } from 'services/sagaWorkers';
import { ACTIONS, ORDER_VIEW_TYPE } from '../constants';
import {
  exportToFileSuccess,
  exportToFileError,
} from '../actions';
import {
  selectOrderViewType,
} from '../selectors';
import { getOrderViewParams } from './loadOrderLinesSagas';

const ORDERS_FILE_NAME = 'filtered_orders.csv';
const ORDER_LINES_FILE_NAME = 'filtered_order_lines.csv';

function* exportToFileWatcher() {
  const orderViewType = yield select(selectOrderViewType);

  const fetchOrderViewData = orderViewType === ORDER_VIEW_TYPE.ORDER_LINE
    ? getFilteredOrderLineFeed
    : getFilteredOrderFeed;

  const params = yield call(getOrderViewParams, {
    format: 'csv',
  });
  yield put(fetchOrderViewData(params, exportToFileSuccess, exportToFileError));
}

function* exportToFileSuccessWatcher({ payload }) {
  const orderViewType = yield select(selectOrderViewType);
  const fileName = orderViewType === ORDER_VIEW_TYPE.ORDER_LINE
    ? ORDER_LINES_FILE_NAME
    : ORDERS_FILE_NAME;

  yield call(fileSaverSaga, {
    fileName,
    fileData: payload,
    blobType: 'text/csv;charset=utf-8;',
    errorActionCallback: exportToFileError,
  });
}

function* exportToFileErrorWatcher({ errorMessage }) {
  yield call([toast, toast.error], errorMessage);
}

export default function* exportToFileSagas() {
  yield all([
    takeEvery(ACTIONS.EXPORT_TO_FILE, exportToFileWatcher),
    takeEvery(ACTIONS.EXPORT_TO_FILE_SUCCESS, exportToFileSuccessWatcher),
    takeEvery(ACTIONS.EXPORT_TO_FILE_ERROR, exportToFileErrorWatcher),
  ]);
}