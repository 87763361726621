import { createReducer } from 'redux-create-reducer';
import { ACTIONS } from './constants';

const INITIAL_STATE = {
  isSubmitting: false,
  error: null,
  expiredLink: false,
};

const setSendRecoveryLink = state => ({
  ...state,
  isSubmitting: true,
  error: INITIAL_STATE.error,
});

const setSendRecoveryLinkSuccess = (state, { payload }) => ({
  ...state,
  isSubmitting: INITIAL_STATE.isSubmitting,
  emailWasSent: payload === 'Success',
});

const setSendRecoveryLinkError = (state, { errorMessage }) => ({
  ...state,
  isSubmitting: INITIAL_STATE.isSubmitting,
  error: errorMessage,
});

const updateExpiredLink = (state, { expiredLink }) => ({
  ...state,
  expiredLink,
});

const resetState = () => ({
  ...INITIAL_STATE,
});

const handlers = {
  [ACTIONS.SEND_RECOVERY_LINK]: setSendRecoveryLink,
  [ACTIONS.SEND_RECOVERY_LINK_SUCCESS]: setSendRecoveryLinkSuccess,
  [ACTIONS.SEND_RECOVERY_LINK_ERROR]: setSendRecoveryLinkError,
  [ACTIONS.SET_EXPIRED_LINK]: updateExpiredLink,
  [ACTIONS.RESET_STATE]: resetState,
};

export default createReducer(INITIAL_STATE, handlers);
