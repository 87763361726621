import _get from 'lodash/get';
import { orderManagementState } from 'features/OrderManagement/selectors';

const getState = state => _get(orderManagementState(state), 'orderExporter');
export const getIsGettingReports = state => _get(getState(state), 'isGettingReports');
export const getAvailableTimestamps = state => _get(getState(state), 'availableTimestamps');
export const getCurrentDate = state => _get(getState(state), 'currentDate');
export const getSelectedDate = state => _get(getState(state), 'selectedDate');
export const getSelectedOrderType = state => _get(getState(state), 'selectedOrderType');
export const getSelectedTimestamp = state => _get(getState(state), 'selectedTimestamp');
export const getIsDownloading = state => _get(getState(state), 'isDownloading');
export const getIsExporting = state => _get(getState(state), 'isExporting');
export const getPendingExportedCount = state => _get(getState(state), 'pendingExportedCount');