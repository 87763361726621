import styled from 'styled-components';

const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  ${({ centered }) => centered && `
    align-items: center;
  `}
  box-sizing: border-box;
  padding: ${({ padding }) => padding || '2rem'};
  width: ${({ width }) => width || '100%'};
  min-width: ${({ minWidth }) => minWidth || '0'};
  max-width: ${({ maxWidth }) => maxWidth || '100%'};
  > h1, > h2, > h3 {
    margin-bottom: 1.2rem;
  }
  > p {
    margin-bottom: 1rem;
  }
`;

export default DialogContent;