import { createAction } from 'redux-create-action';
import { ACTIONS } from './constants';

export const exitContainer = createAction(ACTIONS.EXIT_CONTAINER);
export const signIn = createAction(ACTIONS.SIGN_IN, 'data');
export const signInSuccess = createAction(ACTIONS.SIGN_IN_SUCCESS, 'payload');
export const signInError = createAction(ACTIONS.SIGN_IN_ERROR, 'errorMessage', 'error');
export const signOff = createAction(ACTIONS.SIGN_OFF);
export const signOffSuccess = createAction(ACTIONS.SIGN_OFF_SUCCESS);
export const signOffError = createAction(ACTIONS.SIGN_OFF_ERROR, 'errorMessage', 'error');
export const loginSuccess = createAction(ACTIONS.LOGIN_SUCCESS, 'payload');
export const loginError = createAction(ACTIONS.LOGIN_ERROR, 'errorMessage', 'error');
export const logoutSuccess = createAction(ACTIONS.LOGOUT_SUCCESS, 'payload');
export const logoutError = createAction(ACTIONS.LOGOUT_ERROR, 'errorMessage', 'error');

export const resetState = createAction(ACTIONS.RESET_STATE);
