import {
  all, takeEvery, put, call, select,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { fetchShipCities } from 'services/feeds/actions';
import { fetchBusinessInfo, setUploadBusinessLogo } from 'services/account/actions';
import { ACTIONS, BUSINESS_FIELDS } from './constants';
import {
  loadBusinessInfoError,
  loadBusinessInfoSuccess,
  searchZipCode,
  searchZipCodeSuccess,
  uploadBusinessLogoError,
  uploadBusinessLogoSuccess,
} from './actions';
import { getBusinessInfoField } from './selectors';

function* loadBusinessInfoWatcher() {
  yield put(fetchBusinessInfo(loadBusinessInfoSuccess, loadBusinessInfoError));
}

function* searchZipCodeWatcher({ zipCode }) {
  yield put(fetchShipCities(zipCode, searchZipCodeSuccess));
}

function* loadBusinessInfoErrorWatcher({ errorMessage }) {
  yield call([toast, toast.error], errorMessage);
}

function* setBusinessInfoFieldValueWatcher({ fieldId, value }) {
  const country = yield select(getBusinessInfoField(BUSINESS_FIELDS.COUNTRY));
  if (fieldId === BUSINESS_FIELDS.ZIP_CODE && country === 'US' && value.length >= 5) {
    yield put(searchZipCode(value));
  }
}

function* uploadBusinessLogoWatcher({ file }) {
  yield put(setUploadBusinessLogo(file, uploadBusinessLogoSuccess, uploadBusinessLogoError));
}

function* uploadBusinessLogoSuccessWatcher() {
  yield call([toast, toast.success], 'Business Logo Successfully Uploaded.');
}

function* uploadBusinessLogoErrorWatcher({ errorMessage }) {
  yield call([toast, toast.error], errorMessage);
}

export default function* businessSagas() {
  yield all([
    takeEvery(ACTIONS.LOAD_BUSINESS_INFO, loadBusinessInfoWatcher),
    takeEvery(ACTIONS.LOAD_BUSINESS_INFO_ERROR, loadBusinessInfoErrorWatcher),
    takeEvery(ACTIONS.SEARCH_ZIP_CODE, searchZipCodeWatcher),
    takeEvery(ACTIONS.SET_BUSINESS_INFO_FIELD_VALUE, setBusinessInfoFieldValueWatcher),
    takeEvery(ACTIONS.UPLOAD_BUSINESS_LOGO, uploadBusinessLogoWatcher),
    takeEvery(ACTIONS.UPLOAD_BUSINESS_LOGO_SUCCESS, uploadBusinessLogoSuccessWatcher),
    takeEvery(ACTIONS.UPLOAD_BUSINESS_LOGO_ERROR, uploadBusinessLogoErrorWatcher),
  ]);
}