import {
  all, takeEvery, put, select, call,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';
import _reduce from 'lodash/reduce';
import _isEmpty from 'lodash/isEmpty';
import {
  fetchPurchaseOrder,
  setPurchaseOrderScannedItems,
} from 'services/tom/actions';
import { numberWithCommas } from 'utils/formatNumber';
import { generateTickets, setSalesOrderNumbers, setShowPickTicketGeneratorModal } from 'features/Tools/PickTicketGenerator/actions';
import { ACTIONS, SHIPMENT_VOLUME_UNITS } from './constants';
import {
  setPurchaseOrderNumber,
  scanPurchaseOrderSuccess,
  scanPurchaseOrderError,
  setScannedItems,
  setInitialScannedItemsSnapshot,
  commitScannedItemsSuccess,
  commitScannedItemsError,
  reloadPurchaseOrder,
  setShipmentTotalVolume,
  setScannedItemsShipmentCost,
  setShipmentTotalCost,
  setShipmentVolumeUnit,
  setInitialShipmentSnapshot,
} from './actions';
import {
  getCommitItems,
  getPurchaseOrderNumber,
  getScannedItems,
  getShipmentTotalCost,
  getShipmentVolumeUnit,
} from './selectors';
import { formatScannedItem, formatScannedItemRefId } from './helpers';

function* scanPurchaseOrderWatcher({ purchaseOrderNumber }) {
  yield put(
    fetchPurchaseOrder(purchaseOrderNumber, scanPurchaseOrderSuccess, scanPurchaseOrderError),
  );
  yield put(setPurchaseOrderNumber(purchaseOrderNumber));
}

function* scanPurchaseOrderSuccessWatcher({ payload }) {
  const { shipment, search_results: results } = payload;

  const scannedItems = _reduce(results, (result, item) => {
    const scannedItemRefId = formatScannedItemRefId(item);
    const updatedResult = {
      ...result,
      [scannedItemRefId]: formatScannedItem(item),
    };
    return updatedResult;
  }, {});

  if (!_isEmpty(results)) {
    yield put(setShipmentTotalCost(shipment.total_cost));
    yield put(setShipmentVolumeUnit(shipment.volume_unit));
    yield put(setInitialShipmentSnapshot({
      shipmentTotalCost: shipment.total_cost || 0,
      shipmentVolumeUnit: shipment.volume_unit || SHIPMENT_VOLUME_UNITS[0].value,
    }));
    yield put(setScannedItems(scannedItems));
  }

  yield put(setInitialScannedItemsSnapshot(scannedItems));
}

function* scanPurchaseOrderErrorWatcher({ errorMessage }) {
  yield call([toast, toast.error], errorMessage);
}

function* reloadPurchaseOrderWatcher() {
  const purchaseOrderNumber = yield select(getPurchaseOrderNumber);
  yield call(scanPurchaseOrderWatcher, { purchaseOrderNumber });
}

function* commitScannedItemsWatcher() {
  const commitItems = yield select(getCommitItems);
  const shipmentTotalCost = yield select(getShipmentTotalCost);
  const shipmentVolumeUnit = yield select(getShipmentVolumeUnit);
  const poNumber = yield select(getPurchaseOrderNumber);
  const params = {
    scannedItems: commitItems,
    poShipment: {
      poNumber,
      shipmentTotalCost,
      shipmentVolumeUnit,
    },
  };
  yield put(
    setPurchaseOrderScannedItems(params, commitScannedItemsSuccess, commitScannedItemsError),
  );
}

function* commitScannedItemsSuccessWatcher({ payload }) {
  const { sales_order_numbers: salesOrderNumbers, moved_to_shipping: movedToShipping } = payload;

  if (!_isEmpty(salesOrderNumbers)) {
    yield put(setShowPickTicketGeneratorModal(true));
    yield put(setSalesOrderNumbers(salesOrderNumbers.join('\n')));
    yield put(generateTickets());
  }

  yield call([toast, toast.success], 'Scanned Items Successfully Committed');

  if (!_isEmpty(movedToShipping)) {
    yield call([toast, toast.success], 'Items moved to Shipping Zone');
  }

  yield put(reloadPurchaseOrder());
}

function* commitScannedItemsErrorWatcher({ errorMessage }) {
  yield call([toast, toast.error], errorMessage);
}

function* updateShipmentData() {
  const scannedItems = yield select(getScannedItems);
  const shipmentTotalCost = yield select(getShipmentTotalCost);

  const shipmentTotalVolume = _reduce(scannedItems, (sum, item) => {
    const newSum = sum + (item.item_shipment_volume * item.total);
    return newSum;
  }, 0);

  const updatedScannedItems = _reduce(scannedItems, (result, item, key) => {
    const shipmentTotalVolumePercentage = (
      (item.item_shipment_volume * 100) / shipmentTotalVolume || 0
    );
    const shipmentCost = shipmentTotalCost === 0
      ? 0
      : (shipmentTotalCost / 100) * shipmentTotalVolumePercentage;

    const newResult = result;
    newResult[key] = { ...item };
    newResult[key].item_shipment_cost = Number(numberWithCommas(shipmentCost, 2));

    return newResult;
  }, {});

  yield put(setShipmentTotalVolume(shipmentTotalVolume));
  yield put(setScannedItemsShipmentCost(updatedScannedItems));
}

export default function* receivingSagas() {
  yield all([
    takeEvery(ACTIONS.SCAN_PURCHASE_ORDER, scanPurchaseOrderWatcher),
    takeEvery(ACTIONS.SCAN_PURCHASE_ORDER_SUCCESS, scanPurchaseOrderSuccessWatcher),
    takeEvery(ACTIONS.SCAN_PURCHASE_ORDER_ERROR, scanPurchaseOrderErrorWatcher),
    takeEvery(ACTIONS.RELOAD_PURCHASE_ORDER, reloadPurchaseOrderWatcher),
    takeEvery(ACTIONS.COMMIT_SCANNED_ITEMS, commitScannedItemsWatcher),
    takeEvery(ACTIONS.COMMIT_SCANNED_ITEMS_SUCCESS, commitScannedItemsSuccessWatcher),
    takeEvery(ACTIONS.COMMIT_SCANNED_ITEMS_ERROR, commitScannedItemsErrorWatcher),
    takeEvery([
      ACTIONS.SET_SCANNED_ITEMS,
      ACTIONS.SET_SHIPMENT_TOTAL_COST,
      ACTIONS.SET_SHIPMENT_VOLUME_UNIT,
    ], updateShipmentData),
  ]);
}