import {
  all, fork, takeEvery, put,
} from 'redux-saga/effects';
import loginSagas from './Login/sagas';
import resetPasswordSagas from './ResetPassword/sagas';
import newPasswordSagas from './NewPassword/sagas';
import { ACTIONS } from './constants';
import { resetBaseReducers } from './actions';

function* exitContainerWatcher() {
  yield put(resetBaseReducers());
}

export default function* baseSagas(...args) {
  yield all([
    takeEvery(ACTIONS.EXIT_CONTAINER, exitContainerWatcher),
    fork(loginSagas, ...args),
    fork(resetPasswordSagas, ...args),
    fork(newPasswordSagas, ...args),
  ]);
}
