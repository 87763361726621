import { createAction } from 'redux-create-action';
import { ACTIONS } from './constants';

export const setImportInventoryLabelGeneratorFile = createAction(
  ACTIONS.SET_IMPORT_INVENTORY_LABEL_GENERATOR_FILE,
  'file', 'successActionCallback', 'errorActionCallback',
);
export const fetchInventoryLabelGeneratorSample = createAction(
  ACTIONS.FETCH_INVENTORY_LABEL_GENERATOR_SAMPLE,
  'successActionCallback', 'errorActionCallback',
);

export const setImportUPCLabelGeneratorFile = createAction(
  ACTIONS.SET_IMPORT_UPC_LABEL_GENERATOR_FILE,
  'file', 'options', 'successActionCallback', 'errorActionCallback',
);
export const fetchUPCLabelGeneratorSample = createAction(
  ACTIONS.FETCH_UPC_LABEL_GENERATOR_SAMPLE,
  'successActionCallback', 'errorActionCallback',
);