import { createReducer } from 'redux-create-reducer';
import _reduce from 'lodash/reduce';
import { getLocalStorageSessionUsername } from 'app/persistedState';
import { createLocalStorageReducer } from 'utils/redux';
import {
  ACTIONS,
  REPORT_COLUMNS_STORAGE_SETTINGS,
  REPORT_LOCAL_STORAGE_KEY,
} from '../constants';

export const INITIAL_STATE = {
  columns: REPORT_COLUMNS_STORAGE_SETTINGS,
};

const updateColumnEnabled = (state, { columnId, enabled }) => {
  const { columns } = state;

  const updatedColumns = _reduce(columns, (result, value, key) => {
    const newResult = result;
    if (value.id === columnId) {
      newResult[key] = {
        ...value,
        enabled,
      };
      return newResult;
    }
    newResult[key] = value;
    return newResult;
  }, {});

  return {
    ...state,
    columns: updatedColumns,
  };
};

const updateColumns = (state, { columns }) => ({
  ...state,
  columns,
});

export const handlers = {
  [ACTIONS.SET_COLUMN_ENABLED]: updateColumnEnabled,
  [ACTIONS.SET_COLUMNS]: updateColumns,
};

const settingsReducers = (state, action) => {
  const storedSessionUserName = getLocalStorageSessionUsername();

  return createLocalStorageReducer(
    createReducer(INITIAL_STATE, handlers),
    REPORT_LOCAL_STORAGE_KEY(storedSessionUserName),
  )(state, action);
};

export default settingsReducers;