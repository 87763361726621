import { createAction } from 'redux-create-action';
import { ACTIONS } from './constants';

export const fetchMergeShipment = createAction(ACTIONS.FETCH_MERGE_SHIPMENT, 'trackingNumber', 'guidOrderLineList', 'successActionCallback', 'errorActionCallback');
export const fetchSetupShipRules = createAction(ACTIONS.FETCH_SETUP_SHIP_RULES, 'successActionCallback', 'errorActionCallback');
export const fetchShipRules = createAction(ACTIONS.FETCH_SHIP_RULES, 'params', 'successActionCallback', 'errorActionCallback');
export const fetchShipRulesCSV = createAction(ACTIONS.FETCH_SHIP_RULES_CSV, 'successActionCallback', 'errorActionCallback');
export const fetchBulkShipTemplate = createAction(ACTIONS.FETCH_BULK_SHIP_TEMPLATE, 'successActionCallback', 'errorActionCallback');
export const setRemoveShipRules = createAction(ACTIONS.SET_REMOVE_SHIP_RULES, 'ruleIdsArray', 'successActionCallback', 'errorActionCallback');
export const setAddShipRule = createAction(ACTIONS.SET_ADD_SHIP_RULE, 'params', 'successActionCallback', 'errorActionCallback');
export const setUpdateShipRule = createAction(ACTIONS.SET_UPDATE_SHIP_RULE, 'ruleId', 'params', 'successActionCallback', 'errorActionCallback');
export const setBulkShipGetOrders = createAction(ACTIONS.SET_BULK_SHIP_GET_ORDERS, 'file', 'successActionCallback', 'errorActionCallback');