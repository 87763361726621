import { createReducer } from 'redux-create-reducer';
import { ACTIONS } from './constants';

const INITIAL_STATE = {
  isFetching: false,
  authError: null,
  data: null,
};

const setFetchAuthTest = state => ({
  ...state,
  isFetching: true,
  authError: INITIAL_STATE.authError,
});

const setFetchAuthTestSuccess = (state, { payload }) => ({
  ...state,
  isFetching: false,
  data: payload,
});

const setFetchAuthTestError = (state, { errorMessage }) => ({
  ...state,
  isFetching: false,
  data: INITIAL_STATE.data,
  authError: errorMessage,
});

const handlers = {
  [ACTIONS.FETCH_AUTH_TEST]: setFetchAuthTest,
  [ACTIONS.FETCH_AUTH_TEST_SUCCESS]: setFetchAuthTestSuccess,
  [ACTIONS.FETCH_AUTH_TEST_ERROR]: setFetchAuthTestError,
};

export default createReducer(INITIAL_STATE, handlers);
