export const VIEW_ACTIONS = {
  INIT_CONTAINER: 'INVENTORY_MOVE__INIT_CONTAINER',
  EXIT_CONTAINER: 'INVENTORY_MOVE__EXIT_CONTAINER',
};

export const ACTIONS = ZONE => ({
  SET_FIELD_ZONE_NAME: `INVENTORY_MOVE__${ZONE}__SET_FIELD_ZONE_NAME`,
  SET_FIELD_UPC_NUMBER: `INVENTORY_MOVE__${ZONE}__SET_FIELD_UPC_NUMBER`,
  SCAN_ZONE_NAME: `INVENTORY_MOVE__${ZONE}__SCAN_ZONE_NAME`,
  SCAN_ZONE_NAME_SUCCESS: `INVENTORY_MOVE__${ZONE}__SCAN_ZONE_NAME_SUCCESS`,
  SCAN_ZONE_NAME_ERROR: `INVENTORY_MOVE__${ZONE}__SCAN_ZONE_NAME_ERROR`,
  SET_ZONE_CHANGED_ITEMS: `INVENTORY_MOVE__${ZONE}__SET_ZONE_CHANGED_ITEMS`,
  SCAN_UPC: `INVENTORY_MOVE__${ZONE}__SCAN_UPC`,
  SCAN_UPC_SUCCESS: `INVENTORY_MOVE__${ZONE}__SCAN_UPC_SUCCESS`,
  SCAN_UPC_ERROR: `INVENTORY_MOVE__${ZONE}__SCAN_UPC_ERROR`,
  TOGGLE_ZONE_LOCK: `INVENTORY_MOVE__${ZONE}__TOGGLE_ZONE_LOCK`,
  LOCK_ZONE: `INVENTORY_MOVE__${ZONE}__LOCK_ZONE`,
  LOCK_ZONE_SUCCESS: `INVENTORY_MOVE__${ZONE}__LOCK_ZONE_SUCCESS`,
  LOCK_ZONE_ERROR: `INVENTORY_MOVE__${ZONE}__LOCK_ZONE_ERROR`,
  UNLOCK_ZONE: `INVENTORY_MOVE__${ZONE}__UNLOCK_ZONE`,
  UNLOCK_ZONE_SUCCESS: `INVENTORY_MOVE__${ZONE}__UNLOCK_ZONE_SUCCESS`,
  UNLOCK_ZONE_ERROR: `INVENTORY_MOVE__${ZONE}__UNLOCK_ZONE_ERROR`,
  RELOAD_ZONE_LOCK: `INVENTORY_MOVE__${ZONE}__RELOAD_ZONE_LOCK`,
  RELOAD_ZONE_LOCK_SUCCESS: `INVENTORY_MOVE__${ZONE}__RELOAD_ZONE_LOCK_SUCCESS`,
  RELOAD_ZONE_LOCK_ERROR: `INVENTORY_MOVE__${ZONE}__RELOAD_ZONE_LOCK_ERROR`,
  TOGGLE_SELECT_ITEM: `INVENTORY_MOVE__${ZONE}__TOGGLE_SELECT_ITEM`,
  TOGGLE_SELECT_ALL_ITEMS: `INVENTORY_MOVE__${ZONE}__TOGGLE_SELECT_ALL_ITEMS`,
  UPDATE_SELECTED_ITEMS: `INVENTORY_MOVE__${ZONE}__UPDATE_SELECTED_ITEMS`,
  SET_SELECTED_ITEMS: `INVENTORY_MOVE__${ZONE}__SET_SELECTED_ITEMS`,
  MOVE_SELECTED_ITEMS: `INVENTORY_MOVE__${ZONE}__MOVE_SELECTED_ITEMS`,
  MOVE_SELECTED_ITEMS_SUCCESS: `INVENTORY_MOVE__${ZONE}__MOVE_SELECTED_ITEMS_SUCCESS`,
  MOVE_SELECTED_ITEMS_ERROR: `INVENTORY_MOVE__${ZONE}__MOVE_SELECTED_ITEMS_ERROR`,
  RELOAD_ZONE: `INVENTORY_MOVE__${ZONE}__RELOAD_ZONE`,
});

export const ZONE_A_ACTIONS = ACTIONS('ZONE_A');
export const ZONE_B_ACTIONS = ACTIONS('ZONE_B');