import { createReducer } from 'redux-create-reducer';
import { ACTIONS } from '../constants';

export const INITIAL_STATE = {
  fulfillment_warehouses: {},
  valid_sales_channels: {},
  brands_suppliers: {},
};

const setLoadShipSetupSuccess = (state, { payload }) => ({
  ...state,
  ...payload,
});

const setBrandsSuppliers = (state, { payload }) => ({
  ...state,
  brands_suppliers: payload,
});

export const handlers = {
  [ACTIONS.LOAD_SHIP_SETUP_SUCCESS]: setLoadShipSetupSuccess,
  [ACTIONS.FETCH_BRANDS_SUPPLIERS_SUCCESS]: setBrandsSuppliers,
};

export default createReducer(INITIAL_STATE, handlers);