import _get from 'lodash/get';
import _find from 'lodash/find';
import { inventoryManagementState } from 'features/InventoryManagement/selectors';
import { getSessionUserName } from 'app/session/selectors';

const inventoryViewState = state => _get(inventoryManagementState(state), 'inventoryView');
export const getFieldZoneName = state => _get(inventoryViewState(state), 'fieldZoneName');
export const getFieldUPCNumber = state => _get(inventoryViewState(state), 'fieldUPCNumber');
export const getLastScannedUPC = state => _get(inventoryViewState(state), 'lastScannedUPC');

export const getIsFetchingZone = state => _get(inventoryViewState(state), 'isFetchingZone');
export const getIsReloadingZone = state => _get(inventoryViewState(state), 'isReloadingZone');
export const getIsLockingZone = state => _get(inventoryViewState(state), 'isLockingZone');
export const getIsScanningUPC = state => _get(inventoryViewState(state), 'isScanningUPC');
export const getIsSaving = state => _get(inventoryViewState(state), 'isSaving');
export const getIsRenamingZone = state => _get(inventoryViewState(state), 'isRenamingZone');
export const getIsDeleting = state => _get(inventoryViewState(state), 'isDeleting');
export const getIsMoving = state => _get(inventoryViewState(state), 'isMoving');
export const getIsSearching = state => _get(inventoryViewState(state), 'isSearching');
export const getIsLoadingZone = state => _get(inventoryViewState(state), 'isLoadingZone');
export const getShowDeleteConfirmation = state => _get(inventoryViewState(state), 'showDeleteConfirmation');
export const getShowMoveConfirmation = state => _get(inventoryViewState(state), 'showMoveConfirmation');
export const getShowSearchModal = state => _get(inventoryViewState(state), 'showSearchModal');

export const getMoveItemsError = state => _get(inventoryViewState(state), 'moveItemsError');
export const getRenameZoneError = state => _get(inventoryViewState(state), 'renameZoneError');

export const getChangedItems = state => _get(inventoryViewState(state), 'changedItems', []);

export const getSearchItemsResultsForTerm = state => _get(inventoryViewState(state), 'searchItemsResultsForTerm');
export const getSearchItemsResults = state => _get(inventoryViewState(state), 'searchItemsResults');
export const getSearchItemsError = state => _get(inventoryViewState(state), 'searchItemsError');

export const getZone = state => _get(inventoryViewState(state), 'zone');
export const getZoneItems = state => _get(getZone(state), 'items', []);
export const findZoneItemByUPC = upc => state => _find(getZoneItems(state), { upc }) || {};
export const getZoneName = state => _get(getZone(state), 'location', '');
export const getZoneLevel = state => _get(getZone(state), 'level');
export const getZoneCol = state => _get(getZone(state), 'col');
export const getZoneRow = state => _get(getZone(state), 'row');

export const getZoneLock = state => _get(getZone(state), 'lock');
export const getZoneLocked = state => _get(getZoneLock(state), 'locked', false);
export const getZoneLockNonce = state => _get(getZoneLock(state), 'nonce');
export const getZoneLockOwner = state => _get(getZoneLock(state), 'owner');

export const getCanUserEditZone = state => getZoneLockOwner(state) === getSessionUserName(state);
export const getCanUserForceUnlock = state => _get(getZoneLock(state), 'can_force_release', false);

export const selectSelectedItems = state => _get(inventoryViewState(state), 'selectedItems');