import { all, fork } from 'redux-saga/effects';
import containerSagas from './containerSagas';
import loadBrandsSuppliersSagas from './loadBrandsSuppliersSagas';
import exportToFileSagas from './exportToFileSagas';
import fetchSagas from './fetchSagas';
import managementSagas from './managementSagas';
import brandManagementSagas from './brandManagementSagas';
import supplierManagementSagas from './supplierManagementSagas';

export default function* brandsSuppliersSagas(...args) {
  yield all([
    fork(containerSagas, ...args),
    fork(loadBrandsSuppliersSagas, ...args),
    fork(exportToFileSagas, ...args),
    fork(fetchSagas, ...args),
    fork(managementSagas, ...args),
    fork(brandManagementSagas, ...args),
    fork(supplierManagementSagas, ...args),
  ]);
}
