import { createReducer } from 'redux-create-reducer';
import { ACTIONS } from './constants';

export const INITIAL_STATE = {
  isCancellingRequest: false,
  isDownloadingTemplate: false,
  isDownloadingErrorFile: false,
  isDownloadingOriginalFile: false,
  isFetchingLogs: false,
  isUploadFileValid: false,
  isUploadingFile: false,
  isValidatingFile: false,
  originalFileName: null,
  uploadFileErrors: null,
  uploadLogs: null,
  uploadLogsError: null,
  uploadType: null,
};

const setIsDownloadingTemplate = (state, isDownloadingTemplate) => ({
  ...state,
  isDownloadingTemplate,
});

const setIsValidatingFile = (state, isValidatingFile) => ({
  ...state,
  isValidatingFile,
});

const setIsUploadingFile = (state, isUploadingFile) => ({
  ...state,
  isUploadingFile,
});

const updateIsUploadFileValid = (state, { isUploadFileValid }) => ({
  ...state,
  isUploadFileValid,
});

const updateUploadFileErrors = (state, { uploadFileErrors }) => ({
  ...state,
  uploadFileErrors,
});

const updateUploadLogs = (state, { uploadLogs }) => ({
  ...state,
  uploadLogs,
});

const updateUploadType = (state, { uploadType }) => ({
  ...state,
  uploadType,
});

const setIsFetchingLogs = (state, isFetchingLogs) => ({
  ...state,
  isFetchingLogs,
  uploadLogsError: INITIAL_STATE.uploadLogsError,
});

const updateUploadLogsError = (state, { errorMessage }) => ({
  ...state,
  uploadLogsError: errorMessage,
  isFetchingLogs: false,
});

const resetUploadValidation = state => ({
  ...state,
  isUploadFileValid: INITIAL_STATE.isUploadFileValid,
  uploadFileErrors: INITIAL_STATE.uploadFileErrors,
});

const setIsDownloadingOriginalFile = (state, isDownloadingOriginalFile) => ({
  ...state,
  isDownloadingOriginalFile,
});

const updateOriginalFileName = (state, { originalFileName }) => ({
  ...state,
  originalFileName,
  isDownloadingOriginalFile: true,
});

const setIsCancellingRequest = (state, isCancellingRequest) => ({
  ...state,
  isCancellingRequest,
});

const setIsDownloadingErrorFile = (state, isDownloadingErrorFile) => ({
  ...state,
  isDownloadingErrorFile,
});

const updateErrorFileName = (state, { originalFileName }) => ({
  ...state,
  originalFileName,
  isDownloadingErrorFile: true,
});

const resetState = () => ({
  ...INITIAL_STATE,
});

export const handlers = {
  [ACTIONS.DOWNLOAD_EDIT_TEMPLATE]: state => setIsDownloadingTemplate(state, true),
  [ACTIONS.DOWNLOAD_EDIT_TEMPLATE_SUCCESS]: state => setIsDownloadingTemplate(state, false),
  [ACTIONS.DOWNLOAD_EDIT_TEMPLATE_ERROR]: state => setIsDownloadingTemplate(state, false),

  [ACTIONS.DOWNLOAD_ADD_TEMPLATE]: state => setIsDownloadingTemplate(state, true),
  [ACTIONS.DOWNLOAD_ADD_TEMPLATE_SUCCESS]: state => setIsDownloadingTemplate(state, false),
  [ACTIONS.DOWNLOAD_ADD_TEMPLATE_ERROR]: state => setIsDownloadingTemplate(state, false),

  [ACTIONS.DOWNLOAD_DELETE_TEMPLATE]: state => setIsDownloadingTemplate(state, true),
  [ACTIONS.DOWNLOAD_DELETE_TEMPLATE_SUCCESS]: state => setIsDownloadingTemplate(state, false),
  [ACTIONS.DOWNLOAD_DELETE_TEMPLATE_ERROR]: state => setIsDownloadingTemplate(state, false),

  [ACTIONS.VALIDATE_UPLOAD_FILE]: state => setIsValidatingFile(state, true),
  [ACTIONS.VALIDATE_UPLOAD_FILE_SUCCESS]: state => setIsValidatingFile(state, false),
  [ACTIONS.VALIDATE_UPLOAD_FILE_ERROR]: state => setIsValidatingFile(state, false),
  [ACTIONS.SET_UPLOAD_FILE_IS_VALID]: updateIsUploadFileValid,
  [ACTIONS.SET_UPLOAD_FILE_VALIDATION_ERRORS]: updateUploadFileErrors,
  [ACTIONS.RESET_VALIDATE_UPLOAD]: resetUploadValidation,

  [ACTIONS.UPLOAD_FILE]: state => setIsUploadingFile(state, true),
  [ACTIONS.UPLOAD_FILE_SUCCESS]: state => setIsUploadingFile(state, false),
  [ACTIONS.UPLOAD_FILE_ERROR]: state => setIsUploadingFile(state, false),

  [ACTIONS.SET_UPLOAD_LOGS]: updateUploadLogs,
  [ACTIONS.SET_UPLOAD_TYPE]: updateUploadType,

  [ACTIONS.LOAD_UPLOAD_LOGS]: state => setIsFetchingLogs(state, true),
  [ACTIONS.LOAD_UPLOAD_LOGS_SUCCESS]: state => setIsFetchingLogs(state, false),
  [ACTIONS.LOAD_UPLOAD_LOGS_ERROR]: updateUploadLogsError,

  [ACTIONS.DOWNLOAD_ORIGINAL_FILE]: updateOriginalFileName,
  [ACTIONS.DOWNLOAD_ORIGINAL_FILE_SUCCESS]: state => setIsDownloadingOriginalFile(state, false),
  [ACTIONS.DOWNLOAD_ORIGINAL_FILE_ERROR]: state => setIsDownloadingOriginalFile(state, false),

  [ACTIONS.CANCEL_UPLOAD_REQUEST]: state => setIsCancellingRequest(state, true),
  [ACTIONS.CANCEL_UPLOAD_REQUEST_SUCCESS]: state => setIsCancellingRequest(state, false),
  [ACTIONS.CANCEL_UPLOAD_REQUEST_ERROR]: state => setIsCancellingRequest(state, false),

  [ACTIONS.DOWNLOAD_ERROR_FILE]: updateErrorFileName,
  [ACTIONS.DOWNLOAD_ERROR_FILE_SUCCESS]: state => setIsDownloadingErrorFile(state, false),
  [ACTIONS.DOWNLOAD_ERROR_FILE_ERROR]: state => setIsDownloadingErrorFile(state, false),

  [ACTIONS.RESET_STATE]: resetState,
};

export default createReducer(INITIAL_STATE, handlers);