import {
  put, call, select,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import { getRouterQuery } from 'app/router/selectors';
import { encrypt, decrypt } from 'utils/crypt';

export function* handleURLFilters(actionCallback) {
  const urlQueryObj = yield select(getRouterQuery);
  const filtersHash = _get(urlQueryObj, 'filters');
  if (_isEmpty(filtersHash)) {
    return;
  }

  const filters = yield call(decrypt, filtersHash);
  yield put(actionCallback(JSON.parse(filters)));
}

export function* hashFiltersToURL(filters = [], functionRoute) {
  if (_isEmpty(filters)) {
    yield put(push(functionRoute()));
    return;
  }

  const urlQueryObj = {};
  const hash = yield call(encrypt, JSON.stringify(filters));
  urlQueryObj.filters = hash;
  yield put(push(functionRoute(urlQueryObj)));
}