import {
  all, takeEvery, put, call,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { fetchAdjustedInventory } from 'services/feeds/actions';
import { ACTIONS } from '../constants';
import {
  loadReportSuccess,
  loadReportError,
} from '../actions';

function* loadReportWatcher() {
  yield put(fetchAdjustedInventory(loadReportSuccess, loadReportError));
}

function* loadReportErrorWatcher({ errorMessage }) {
  yield call([toast, toast.error], errorMessage);
}

export default function* containerSagas() {
  yield all([
    takeEvery(ACTIONS.LOAD_REPORT, loadReportWatcher),
    takeEvery(ACTIONS.LOAD_REPORT_ERROR, loadReportErrorWatcher),
  ]);
}