import { combineReducers } from 'redux';
import inventoryManagementReducers from './InventoryManagement/reducers';
import orderViewReducers from './OrderView/reducers';
import orderManagementReducers from './OrderManagement/reducers';
import toolsReducers from './Tools/reducers';
import accountSettingsReducers from './AccountSettings/reducers';
import reportsReducers from './Reports/reducers';
import productManagementReducers from './ProductManagement/reducers';
import purchaseOrderManagementReducers from './PurchaseOrderManagement/reducers';

export default combineReducers({
  inventoryManagement: inventoryManagementReducers,
  orderView: orderViewReducers,
  orderManagement: orderManagementReducers,
  tools: toolsReducers,
  accountSettings: accountSettingsReducers,
  reports: reportsReducers,
  productManagement: productManagementReducers,
  purchaseOrderManagement: purchaseOrderManagementReducers,
});
