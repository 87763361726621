import _isEmpty from 'lodash/isEmpty';
import _reduce from 'lodash/reduce';
import _omit from 'lodash/omit';
import _isEqual from 'lodash/isEqual';
import _merge from 'lodash/fp/merge';
import { getFromLocalStorage, saveToLocalStorage } from 'utils/localStorage';

export const omitResetReducers = (state, omitPropsArray) => _reduce(
  _omit(state, omitPropsArray), (result, value, key) => ({
    ...result,
    [key]: undefined,
  }), {},
);

export const createLocalStorageReducer = (reducer, key) => {
  if (typeof localStorage !== 'object' || localStorage === null) {
    return reducer;
  }

  return (state, action) => {
    const storedState = getFromLocalStorage(key);
    // const expectedState = { ...state, ...storedState };
    const expectedState = _merge(state, storedState);

    let reducerState = _isEqual(state, expectedState) ? state : expectedState;

    if (typeof state === 'undefined') {
      const defaultState = reducer(undefined, {});
      // reducerState = { ...defaultState, ...storedState };
      reducerState = _merge(defaultState, storedState);
    }

    const newState = reducer(reducerState, action);

    if ((typeof storeState === 'undefined' || reducerState !== newState) && !_isEmpty(key)) {
      saveToLocalStorage(newState, key);
    }

    return newState;
  };
};