import { MdNoteAdd, MdLocalShipping } from 'react-icons/md';
import { FaFileDownload } from 'react-icons/fa';
import ROUTES from 'constants/routes';

export const ACTIONS = {
  INIT_CONTAINER: 'ORDER_MANAGEMENT__INIT_CONTAINER',
  EXIT_CONTAINER: 'ORDER_MANAGEMENT__EXIT_CONTAINER',
  RESET_FEATURE_REDUCERS: 'ORDER_MANAGEMENT__RESET_FEATURE_REDUCERS',
};

export const ORDER_MANAGEMENT_SECTIONS = {
  ADD_ORDER: {
    id: 'add_order',
    title: 'Add Order',
    route: ROUTES.ORDER_MANAGEMENT.ADD_ORDER,
    icon: MdNoteAdd,
    disabled: false,
  },
  SHIPPING: {
    id: 'shipping',
    title: 'Shipping',
    route: ROUTES.ORDER_MANAGEMENT.SHIPPING,
    icon: MdLocalShipping,
    disabled: false,
  },
  ORDER_EXPORTER: {
    id: 'order_exporter',
    title: 'Order Exporter',
    route: ROUTES.ORDER_MANAGEMENT.ORDER_EXPORTER,
    icon: FaFileDownload,
    iconSize: '1.3rem',
    disabled: false,
  },
};