export const ACTIONS = {
  INIT_CONTAINER: 'NEW_PASSWORD__INIT_CONTAINER',
  EXIT_CONTAINER: 'NEW_PASSWORD__EXIT_CONTAINER',

  SET_TOKEN_CODE: 'NEW_PASSWORD__SET_TOKEN_CODE',
  SET_OLD_PASSWORD: 'NEW_PASSWORD__SET_OLD_PASSWORD',
  SET_CHANGE_REQUIRED: 'NEW_PASSWORD__SET_CHANGE_REQUIRED',

  CHANGE_PASSWORD: 'NEW_PASSWORD__CHANGE_PASSWORD',
  CHANGE_PASSWORD_SUCCESS: 'NEW_PASSWORD__CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_ERROR: 'NEW_PASSWORD__CHANGE_PASSWORD_ERROR',

  RESET_STATE: 'NEW_PASSWORD__RESET_STATE',
};