import {
  all, takeEvery, put, call,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { getCurrentDateISO } from 'utils/date';
import { fetchAdjustedInventoryCostCSV } from 'services/feeds/actions';
import { fileSaverSaga } from 'services/sagaWorkers';
import { ACTIONS } from '../constants';
import {
  exportToFileSuccess,
  exportToFileError,
} from '../actions';

const EXPORT_FILE_NAME = (date = '') => `adjusted_inventory_cost_${date}.csv`;

function* exportToFileWatcher() {
  yield put(fetchAdjustedInventoryCostCSV(exportToFileSuccess, exportToFileError));
}

function* exportToFileSuccessWatcher({ payload }) {
  const date = getCurrentDateISO();

  yield call(fileSaverSaga, {
    fileName: EXPORT_FILE_NAME(date),
    fileData: payload,
    blobType: 'text/csv;charset=utf-8;',
    errorActionCallback: exportToFileError,
  });
}

function* exportToFileErrorWatcher({ errorMessage }) {
  yield call([toast, toast.error], errorMessage);
}

export default function* exportToFileSagas() {
  yield all([
    takeEvery(ACTIONS.EXPORT_TO_FILE, exportToFileWatcher),
    takeEvery(ACTIONS.EXPORT_TO_FILE_SUCCESS, exportToFileSuccessWatcher),
    takeEvery(ACTIONS.EXPORT_TO_FILE_ERROR, exportToFileErrorWatcher),
  ]);
}