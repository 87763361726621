export const ACTIONS = {
  EXIT_CONTAINER: 'LOGIN__EXIT_CONTAINER',
  SIGN_IN: 'LOGIN__SIGN_IN',
  SIGN_IN_SUCCESS: 'LOGIN__SIGN_IN_SUCCESS',
  SIGN_IN_ERROR: 'LOGIN__SIGN_IN_ERROR',
  SIGN_OFF: 'LOGIN__SIGN_OFF',
  SIGN_OFF_SUCCESS: 'LOGIN__SIGN_OFF_SUCCESS',
  SIGN_OFF_ERROR: 'LOGIN__SIGN_OFF_ERROR',
  LOGIN_SUCCESS: 'LOGIN__SUCCESS',
  LOGIN_ERROR: 'LOGIN__ERROR',
  LOGOUT_SUCCESS: 'LOGOUT__SUCCESS',
  LOGOUT_ERROR: 'LOGOUT__ERROR',
  RESET_STATE: 'LOGIN__RESET_STATE',
};

export const LOGIN_ERRORS = {
  INVALID_USERNAME_PASSWORD: 'Invalid username and/or password!',
  AT_LOGIN: 'Something went wrong with login!',
  AT_LOGOUT: 'Something went wrong with logout!',
  AUTH_LOGIN: 'Something went wrong with authentication at login!',
  AUTH_LOGOUT: 'Something went wrong with authentication at logout!',
  SIGN_IN: 'Something went wrong trying to login!',
  SIGN_OFF: 'Something went wrong trying to logout!',
};
