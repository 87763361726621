import styled from 'styled-components';
import MuiTextField from '@material-ui/core/TextField';
import { EUCALYPTUS, TEXT_SUCCESS } from 'constants/colors';

export const TextFieldWrap = styled(MuiTextField)`
  .MuiOutlinedInput-root {
    &.Mui-disabled {
      background-color: rgba(0, 0, 0, 0.02);
    }
  }
  ${({ success }) => success && `
    .MuiOutlinedInput-notchedOutline {
      border-color: ${EUCALYPTUS} !important;
    }
    .MuiFormLabel-root, .MuiFormHelperText-root {
      color: ${TEXT_SUCCESS} !important;
    }
  `}
`;