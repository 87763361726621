import { combineReducers } from 'redux';
import { ACTIONS } from './constants';
import AdjustedInventoryReducers from './AdjustedInventory/reducers';
import AdjustedInventoryCostReducers from './AdjustedInventoryCost/reducers';
import InventoryLocationsReducers from './InventoryLocations/reducers';
import InventoryQtysReducers from './InventoryQtys/reducers';
import SalesReportReducers from './SalesReport/reducers';

const featureReducers = combineReducers({
  adjustedInventory: AdjustedInventoryReducers,
  adjustedInventoryCost: AdjustedInventoryCostReducers,
  inventoryLocations: InventoryLocationsReducers,
  inventoryQtys: InventoryQtysReducers,
  salesReport: SalesReportReducers,
});

const reportsReducers = (state, action) => {
  let featureState = state;
  if (action.type === ACTIONS.RESET_FEATURE_REDUCERS) {
    featureState = undefined;
  }
  return featureReducers(featureState, action);
};

export default reportsReducers;
