import { all, fork } from 'redux-saga/effects';
import containerSagas from './containerSagas';
import loadProductsSagas from './loadProductsSagas';
import exportToFileSagas from './exportToFileSagas';
import downloadSagas from './downloadSagas';

export default function* productManagementSagas(...args) {
  yield all([
    fork(containerSagas, ...args),
    fork(loadProductsSagas, ...args),
    fork(exportToFileSagas, ...args),
    fork(downloadSagas, ...args),
  ]);
}
