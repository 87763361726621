import React from 'react';
import ErrorBoundary from './ErrorBoundary';
import FallbackComponent from './FallbackComponent';

const LoadableSuspense = ({
  loader,
  loadingComponent,
  errorComponent,
  delay = 200,
}) => {
  const LazyLoadedComponent = React.lazy(loader);

  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        pastDelay: false,
      };
      this.timeout = null;
    }

    componentDidMount() {
      this.timeout = setTimeout(() => this.setPastDelay(true), delay);
    }

    componentWillUnmount() {
      clearTimeout(this.timeout);
    }

    setPastDelay = pastDelay => {
      this.setState({ pastDelay });
    }

    render() {
      const { pastDelay } = this.state;

      return (
        <ErrorBoundary CustomComponent={errorComponent}>
          <React.Suspense
            fallback={
              <FallbackComponent CustomComponent={loadingComponent} pastDelay={pastDelay} />
            }
          >
            <LazyLoadedComponent {...this.props} />
          </React.Suspense>
        </ErrorBoundary>
      );
    }
  };
};

export default LoadableSuspense;