const ENDPOINTS = {
  GET_ZONE: '/get_zone',
  HAS_LOCK: '/has_lock',
  ACQUIRE_LOCK: '/acquire_lock',
  RELEASE_LOCK: '/release_lock',
  REPLACE_QTY: '/replace_qty',
  MOVE_ITEMS: '/move_items',
  SEARCH_ITEM: '/search_item',
  MOVE_REPLACE_DELTA_QTY: '/move_replace_delta_qty',
  GET_HOLDING_SHIPPING_ZONE: '/get_holding_shipping_zone',
  MOVE_TO_SHIPPING: '/move_to_shipping',
  MOVE_FROM_SHIPPING: '/move_from_shipping',
  DECREMENT_QTY: '/decrement_qty',
  GET_ZONE_AVAILABILITY: '/get_zone_availability',
};

export default ENDPOINTS;
