import { createAction } from 'redux-create-action';
import { ACTIONS } from './constants';

export const initContainer = createAction(ACTIONS.INIT_CONTAINER);
export const exitContainer = createAction(ACTIONS.EXIT_CONTAINER);

export const setTokenCode = createAction(ACTIONS.SET_TOKEN_CODE, 'token', 'code');
export const setOldPassword = createAction(ACTIONS.SET_OLD_PASSWORD, 'oldPassword');
export const setChangeRequired = createAction(ACTIONS.SET_CHANGE_REQUIRED, 'changeRequired');

export const changePassword = createAction(ACTIONS.CHANGE_PASSWORD, 'newPassword');
export const changePasswordSuccess = createAction(ACTIONS.CHANGE_PASSWORD_SUCCESS, 'payload');
export const changePasswordError = createAction(ACTIONS.CHANGE_PASSWORD_ERROR, 'errorMessage', 'error');

export const resetState = createAction(ACTIONS.RESET_STATE);