import { createAction } from 'redux-create-action';
import { ACTIONS } from './constants';

export const exportToFile = createAction(ACTIONS.EXPORT_TO_FILE);
export const exportToFileSuccess = createAction(ACTIONS.EXPORT_TO_FILE_SUCCESS, 'payload');
export const exportToFileError = createAction(ACTIONS.EXPORT_TO_FILE_ERROR, 'errorMessage', 'error');

export const generateVisualization = createAction(ACTIONS.GENERATE_VISUALIZATION);
export const generateVisualizationSuccess = createAction(ACTIONS.GENERATE_VISUALIZATION_SUCCESS);
export const generateVisualizationError = createAction(ACTIONS.GENERATE_VISUALIZATION_ERROR);

export const fetchSummaryData = createAction(ACTIONS.FETCH_SUMMARY_DATA);
export const fetchSummaryDataSuccess = createAction(ACTIONS.FETCH_SUMMARY_DATA_SUCCESS, 'payload');
export const fetchSummaryDataError = createAction(ACTIONS.FETCH_SUMMARY_DATA_ERROR, 'errorMessage', 'error');

export const fetchChartData = createAction(ACTIONS.FETCH_CHART_DATA);
export const fetchChartDataSuccess = createAction(ACTIONS.FETCH_CHART_DATA_SUCCESS, 'payload');
export const fetchChartDataError = createAction(ACTIONS.FETCH_CHART_DATA_ERROR, 'errorMessage', 'error');
export const setChartSettings = createAction(ACTIONS.SET_CHART_SETTINGS, 'updatedProps');

export const resetReportState = createAction(ACTIONS.RESET_STATE);