export const ACTIONS = {
  FETCH_PRODUCT_INFO: 'SERVICE_FEEDS__FETCH_PRODUCT_INFO',
  FETCH_SHIP_CITIES: 'SERVICE_FEEDS__FETCH_SHIP_CITIES',
  FETCH_SEARCH_ORDER_LINE_IDS: 'SERVICE_FEEDS__FETCH_SEARCH_ORDER_LINE_IDS',
  FETCH_ADJUSTED_INVENTORY: 'SERVICE_FEEDS__FETCH_ADJUSTED_INVENTORY',
  FETCH_ADJUSTED_INVENTORY_CSV: 'SERVICE_FEEDS__FETCH_ADJUSTED_INVENTORY_CSV',
  FETCH_ADJUSTED_INVENTORY_COST: 'SERVICE_FEEDS__FETCH_ADJUSTED_INVENTORY_COST',
  FETCH_ADJUSTED_INVENTORY_COST_CSV: 'SERVICE_FEEDS__FETCH_ADJUSTED_INVENTORY_COST_CSV',
  FETCH_INVENTORY_LOCATIONS: 'SERVICE_FEEDS__FETCH_INVENTORY_LOCATIONS',
  FETCH_INVENTORY_LOCATIONS_CSV: 'SERVICE_FEEDS__FETCH_INVENTORY_LOCATIONS_CSV',
  FETCH_INVENTORY_QTYS: 'SERVICE_FEEDS__FETCH_INVENTORY_QTYS',
  FETCH_INVENTORY_QTYS_CSV: 'SERVICE_FEEDS__FETCH_INVENTORY_QTYS_CSV',
  FETCH_SALES_REPORT: 'SERVICE_FEEDS__FETCH_SALES_REPORT',
  FETCH_SALES_REPORT_CSV: 'SERVICE_FEEDS__FETCH_SALES_REPORT_CSV',
  FETCH_SALES_REPORT_SEARCH: 'SERVICE_FEEDS__FETCH_SALES_REPORT_SEARCH',
};
