export const ACTIONS = {
  FETCH_PERMISSIONS: 'SERVICE_USER_MANAGEMENT__FETCH_PERMISSIONS',
  FETCH_PERMISSIONS_SUCCESS: 'SERVICE_USER_MANAGEMENT__FETCH_PERMISSIONS_SUCCESS',
  FETCH_PERMISSIONS_ERROR: 'SERVICE_USER_MANAGEMENT__FETCH_PERMISSIONS_ERROR',

  FETCH_USERS: 'SERVICE_USER_MANAGEMENT__FETCH_USERS',
  FETCH_CHECK_USERNAME_AVAILABILITY: 'SERVICE_USER_MANAGEMENT__FETCH_CHECK_USERNAME_AVAILABILITY',
  FETCH_ROLES: 'SERVICE_USER_MANAGEMENT__FETCH_ROLES',

  SET_ADD_USER: 'SERVICE_USER_MANAGEMENT__SET_ADD_USER',
  SET_DELETE_USER: 'SERVICE_USER_MANAGEMENT__SET_DELETE_USER',
  SET_EDIT_USER: 'SERVICE_USER_MANAGEMENT__SET_EDIT_USER',
  SET_RESET_USER_PASSWORD: 'SERVICE_USER_MANAGEMENT__SET_USER_PASSWORD',
  SET_RESEND_INVITE: 'SERVICE_USER_MANAGEMENT__SET_RESEND_INVITE',
  SET_ADD_ROLE: 'SERVICE_USER_MANAGEMENT__SET_ADD_ROLE',
  SET_EDIT_ROLE: 'SERVICE_USER_MANAGEMENT__SET_EDIT_ROLE',
  SET_DELETE_ROLE: 'SERVICE_USER_MANAGEMENT__SET_DELETE_ROLE',
};

// User Status from Cognito
export const USER_STATUSES = {
  UNCONFIRMED: 'UNCONFIRMED', // User has been created but not confirmed.
  CONFIRMED: 'CONFIRMED', // User has been confirmed.
  ARCHIVED: 'ARCHIVED', // User is no longer active.
  COMPROMISED: 'COMPROMISED', // User is disabled due to a potential security threat.
  UNKNOWN: 'UNKNOWN', // User status is not known.
  RESET_REQUIRED: 'RESET_REQUIRED', // User is confirmed, but the user must request a code and reset the password before can sign in.
  FORCE_CHANGE_PASSWORD: 'FORCE_CHANGE_PASSWORD', // User is confirmed and can sign in with temporary password, but on first sign-in, the user must change password before doing anything else.
};