import ROUTES from 'constants/routes';
import { BsClipboard, BsClipboardData } from 'react-icons/bs';
import {
  FaBoxes,
  FaRegChartBar,
} from 'react-icons/fa';
import { CgListTree } from 'react-icons/cg';

export const ACTIONS = {
  INIT_CONTAINER: 'REPORTS__INIT_CONTAINER',
  EXIT_CONTAINER: 'REPORTS__EXIT_CONTAINER',
  RESET_FEATURE_REDUCERS: 'REPORTS__RESET_FEATURE_REDUCERS',
};

export const REPORTS_SECTIONS = {
  ADJUSTED_INVENTORY: {
    id: 'adjusted-inventory',
    title: 'Adjusted Inventory',
    route: ROUTES.REPORTS.ADJUSTED_INVENTORY,
    icon: BsClipboard,
    disabled: false,
  },
  ADJUSTED_INVENTORY_COST: {
    id: 'adjusted-inventory-cost',
    title: 'Adjusted Inventory Cost',
    route: ROUTES.REPORTS.ADJUSTED_INVENTORY_COST,
    icon: BsClipboardData,
    disabled: false,
  },
  INVENTORY_LOCATIONS: {
    id: 'inventory-locations',
    title: 'Inventory Locations',
    route: ROUTES.REPORTS.INVENTORY_LOCATIONS,
    icon: CgListTree,
    disabled: false,
  },
  INVENTORY_QTYS: {
    id: 'inventory-qtys',
    title: 'Inventory Qtys',
    route: ROUTES.REPORTS.INVENTORY_QTYS,
    icon: FaBoxes,
    disabled: false,
  },
  SALES: {
    id: 'sales-report',
    title: 'Sales Report',
    route: ROUTES.REPORTS.SALES,
    icon: FaRegChartBar,
    disabled: false,
  },
};