import {
  all, takeEvery, put, call, select,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { errorToasterSaga } from 'services/sagaWorkers';
import {
  fetchSetupShipRules, setAddShipRule, setRemoveShipRules, setUpdateShipRule,
} from 'services/shipments/actions';
import { getBrandsSuppliers } from 'services/productManagement/actions';
import { ACTIONS } from '../constants';
import {
  removeShippingRuleError,
  removeShippingRuleSuccess,
  refreshFeed,
  loadShipSetupSuccess,
  loadShipSetupError,
  createShippingRuleSuccess,
  createShippingRuleError,
  updateShippingRuleSuccess,
  updateShippingRuleError,
  fetchBrandsSuppliersSuccess,
  fetchBrandsSuppliersError,
} from '../actions';
import { getSelectedShippingRule } from '../selectors';

function* removeShippingRuleWatcher() {
  const { id } = yield select(getSelectedShippingRule);
  yield put(setRemoveShipRules([id], removeShippingRuleSuccess, removeShippingRuleError));
}

function* removeShippingRuleSuccessWatcher({ payload }) {
  const { result } = payload;
  if (result === 'success') {
    yield call([toast, toast.success], 'Shipping Rule successfully deleted.');
    yield put(refreshFeed());
  }
}

function* loadShipSetupWatcher() {
  yield put(fetchSetupShipRules(loadShipSetupSuccess, loadShipSetupError));
}

function* fetchBrandsSuppliersWatcher() {
  yield put(getBrandsSuppliers(fetchBrandsSuppliersSuccess, fetchBrandsSuppliersError));
}

function* createShippingRuleWatcher({ shippingRuleValues }) {
  yield put(
    setAddShipRule(shippingRuleValues, createShippingRuleSuccess, createShippingRuleError),
  );
}

function* createShippingRuleSuccessWatcher({ payload }) {
  const { result } = payload;
  if (result === 'success') {
    yield call([toast, toast.success], 'Shipping Rule successfully added.');
    yield put(refreshFeed());
  }
}

function* updateShippingRuleWatcher({ shippingRuleValues }) {
  const { id: ruleId } = yield select(getSelectedShippingRule);
  yield put(setUpdateShipRule(
    ruleId, shippingRuleValues, updateShippingRuleSuccess, updateShippingRuleError,
  ));
}

function* updateShippingRuleSuccessWatcher({ payload }) {
  const { result } = payload;
  if (result === 'success') {
    yield call([toast, toast.success], 'Shipping Rule successfully updated.');
    yield put(refreshFeed());
  }
}

export default function* containerSagas() {
  yield all([
    takeEvery(ACTIONS.REMOVE_SHIPPING_RULE, removeShippingRuleWatcher),
    takeEvery(ACTIONS.REMOVE_SHIPPING_RULE_SUCCESS, removeShippingRuleSuccessWatcher),
    takeEvery(ACTIONS.REMOVE_SHIPPING_RULE_ERROR, errorToasterSaga),
    takeEvery(ACTIONS.LOAD_SHIP_SETUP, loadShipSetupWatcher),
    takeEvery(ACTIONS.LOAD_SHIP_SETUP_ERROR, errorToasterSaga),
    takeEvery(ACTIONS.CREATE_SHIPPING_RULE, createShippingRuleWatcher),
    takeEvery(ACTIONS.CREATE_SHIPPING_RULE_SUCCESS, createShippingRuleSuccessWatcher),
    takeEvery(ACTIONS.CREATE_SHIPPING_RULE_ERROR, errorToasterSaga),
    takeEvery(ACTIONS.UPDATE_SHIPPING_RULE, updateShippingRuleWatcher),
    takeEvery(ACTIONS.UPDATE_SHIPPING_RULE_SUCCESS, updateShippingRuleSuccessWatcher),
    takeEvery(ACTIONS.UPDATE_SHIPPING_RULE_ERROR, errorToasterSaga),
    takeEvery(ACTIONS.FETCH_BRANDS_SUPPLIERS, fetchBrandsSuppliersWatcher),
    takeEvery(ACTIONS.FETCH_BRANDS_SUPPLIERS_ERROR, errorToasterSaga),
  ]);
}