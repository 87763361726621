import { createReducer } from 'redux-create-reducer';
import { ACTIONS } from '../constants';

export const INITIAL_STATE = {
  isFetchingOrderLineNotes: false,
};

const setIsFetchingOrderLineNotes = (state, isFetchingOrderLineNotes) => ({
  ...state,
  isFetchingOrderLineNotes,
});

export const handlers = {
  [ACTIONS.FETCH_ORDER_LINE_NOTES]: state => setIsFetchingOrderLineNotes(state, true),
  [ACTIONS.FETCH_ORDER_LINE_NOTES_SUCCESS]: state => setIsFetchingOrderLineNotes(state, false),
  [ACTIONS.FETCH_ORDER_LINE_NOTES_ERROR]: state => setIsFetchingOrderLineNotes(state, false),
};

export default createReducer(INITIAL_STATE, handlers);