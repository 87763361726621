import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import _compact from 'lodash/compact';

export const getFromLocalStorage = key => {
  if (_isEmpty(key)) {
    return undefined;
  }
  try {
    const serializedState = localStorage.getItem(key);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveToLocalStorage = (state, key) => {
  if (_isEmpty(key)) {
    return;
  }
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(key, serializedState);
  } catch (err) {
    // ignore write errors
  }
};

export const removeFromLocalStorage = key => localStorage.removeItem(key);

export const getStorageUsagePercent = async storage => {
  if (!storage) {
    return;
  }
  try {
    const estimate = await storage.estimate();
    return ((estimate.usage / estimate.quota) * 100).toFixed(2);
  } catch (error) {
    return error;
  }
};

export const logEstimateStorageUsagePercent = async navigator => {
  if (!navigator.storage) {
    return;
  }
  const percent = await getStorageUsagePercent(navigator.storage);
  // eslint-disable-next-line no-console
  console.info(`%c OMNI Warehouse App currently using about ${percent}% of the available localStorage. `, 'background: #222; color: #bada55');
};

// returns an array of localStorage items in key/value pairs based on a query parameter
// returns all localStorage items if query isn't specified
// query can be a string or a RegExp object

export const filterLocalStorageItems = query => {
  const results = _compact(_map(Object.keys(localStorage), key => {
    if (key.match(query) || (!query && typeof key === 'string')) {
      return {
        key,
        value: JSON.parse(localStorage.getItem(key)),
      };
    }
  }));
  return results;
};
