import _reduce from 'lodash/reduce';
import _pick from 'lodash/pick';
import { formatUserLocalStorageKey, UNIQ_LOCAL_STORAGE_KEYS } from 'app/persistedState';

export const ACTIONS = {
  INIT_CONTAINER: 'PURCHASE_ORDERS__INIT_CONTAINER',
  EXIT_CONTAINER: 'PURCHASE_ORDERS__EXIT_CONTAINER',

  LOAD_PURCHASE_ORDERS: 'PURCHASE_ORDERS__LOAD_PURCHASE_ORDERS',
  LOAD_PURCHASE_ORDERS_SUCCESS: 'PURCHASE_ORDERS__LOAD_PURCHASE_ORDERS_SUCCESS',
  LOAD_PURCHASE_ORDERS_ERROR: 'PURCHASE_ORDERS__LOAD_PURCHASE_ORDERS_ERROR',
  LOAD_FEED: 'PURCHASE_ORDERS__LOAD_FEED',

  SHOW_OPTIONS_MODAL: 'PURCHASE_ORDERS__SHOW_OPTIONS_MODAL',
  REFRESH_FEED: 'PURCHASE_ORDERS__REFRESH_FEED',
  SET_FILTER_OPERAND: 'PURCHASE_ORDERS__SET_FILTER_OPERAND',
  UNSET_FILTER_OPERAND: 'PURCHASE_ORDERS__UNSET_FILTER_OPERAND',
  CLEAR_FILTERS: 'PURCHASE_ORDERS__CLEAR_FILTERS',
  SET_FILTERS: 'PURCHASE_ORDERS__SET_FILTERS',
  ARRANGE_FILTERS: 'PURCHASE_ORDERS__ARRANGE_FILTERS',

  SET_COLUMNS: 'PURCHASE_ORDERS__SET_COLUMNS',
  SET_COLUMNS_ORDER: 'PURCHASE_ORDERS__SET_COLUMNS_ORDER',
  SET_COLUMN_ENABLED: 'PURCHASE_ORDERS__SET_COLUMN_ENABLED',
  SET_ITEMS_PER_PAGE: 'PURCHASE_ORDERS__SET_ITEMS_PER_PAGE',
  SORT_FEED: 'PURCHASE_ORDERS__SORT_FEED',
  GO_TO_PAGE: 'PURCHASE_ORDERS__GO_TO_PAGE',

  EXPORT_TO_FILE: 'PURCHASE_ORDERS__EXPORT_TO_FILE',
  EXPORT_TO_FILE_SUCCESS: 'PURCHASE_ORDERS__EXPORT_TO_FILE_SUCCESS',
  EXPORT_TO_FILE_ERROR: 'PURCHASE_ORDERS__EXPORT_TO_FILE_ERROR',

  DOWNLOAD_LINE: 'PURCHASE_ORDERS__DOWNLOAD_LINE',
  DOWNLOAD_LINE_SUCCESS: 'PURCHASE_ORDERS__DOWNLOAD_LINE_SUCCESS',
  DOWNLOAD_LINE_ERROR: 'PURCHASE_ORDERS__DOWNLOAD_LINE_ERROR',

  EDIT_PURCHASE_ORDER: 'PURCHASE_ORDERS__EDIT_PURCHASE_ORDER',
  CANCEL_EDIT_PURCHASE_ORDER: 'PURCHASE_ORDERS__CANCEL_EDIT_PURCHASE_ORDER',

  DELETE_PURCHASE_ORDER: 'PURCHASE_ORDERS__DELETE_PURCHASE_ORDER',
  CANCEL_DELETE_PURCHASE_ORDER: 'PURCHASE_ORDERS__CANCEL_DELETE_PURCHASE_ORDER',

  REMOVE_PURCHASE_ORDER: 'PURCHASE_ORDERS__REMOVE_PURCHASE_ORDER',
  REMOVE_PURCHASE_ORDER_SUCCESS: 'PURCHASE_ORDERS__REMOVE_PURCHASE_ORDER_SUCCESS',
  REMOVE_PURCHASE_ORDER_ERROR: 'PURCHASE_ORDERS__REMOVE_PURCHASE_ORDER_ERROR',

  UPDATE_PURCHASE_ORDER: 'PURCHASE_ORDERS__UPDATE_PURCHASE_ORDER',
  UPDATE_PURCHASE_ORDER_SUCCESS: 'PURCHASE_ORDERS__UPDATE_PURCHASE_ORDER_SUCCESS',
  UPDATE_PURCHASE_ORDER_ERROR: 'PURCHASE_ORDERS__UPDATE_PURCHASE_ORDER_ERROR',
};

export const PURCHASE_ORDERS_LOCAL_STORAGE_KEY = username => formatUserLocalStorageKey(
  username, UNIQ_LOCAL_STORAGE_KEYS.PURCHASE_ORDERS_VIEW,
);

export const PURCHASE_ORDER_FIELDS = {
  PO: 'po',
  BRAND: 'brand',
  SUPPLIER: 'supplier',
  PURCHASE_DATE: 'purchase_date',
  TOTAL_ITEMS_COST: 'total_items_cost',
  TOTAL_SHIPMENT_COST: 'total_shipment_cost',
  NOTES: 'notes',
};

export const PURCHASE_ORDERS_COLUMNS = {
  [PURCHASE_ORDER_FIELDS.PO]: {
    id: PURCHASE_ORDER_FIELDS.PO,
    enabled: true,
    label: 'PO#',
    sortable: true,
    filterable: true,
    filterableContextMenu: true,
    copyable: true,
    editable: false,
    cellProps: {
      align: 'left',
      minWidth: '200px',
      maxWidth: '200px',
    },
  },
  [PURCHASE_ORDER_FIELDS.BRAND]: {
    id: PURCHASE_ORDER_FIELDS.BRAND,
    enabled: true,
    label: 'Brand',
    sortable: true,
    filterable: true,
    filterableContextMenu: true,
    copyable: true,
    editable: false,
    cellProps: {
      align: 'left',
      minWidth: '250px',
      maxWidth: '250px',
    },
  },
  [PURCHASE_ORDER_FIELDS.SUPPLIER]: {
    id: PURCHASE_ORDER_FIELDS.SUPPLIER,
    enabled: true,
    label: 'Supplier',
    sortable: true,
    filterable: true,
    filterableContextMenu: true,
    copyable: true,
    editable: false,
    cellProps: {
      align: 'left',
      minWidth: '250px',
      maxWidth: '250px',
    },
  },
  [PURCHASE_ORDER_FIELDS.PURCHASE_DATE]: {
    id: PURCHASE_ORDER_FIELDS.PURCHASE_DATE,
    enabled: true,
    label: 'Purchase Date',
    sortable: true,
    filterable: true,
    filterableContextMenu: true,
    copyable: true,
    editable: true,
    cellProps: {
      align: 'left',
      minWidth: '180px',
      maxWidth: '180px',
    },
  },
  [PURCHASE_ORDER_FIELDS.TOTAL_ITEMS_COST]: {
    id: PURCHASE_ORDER_FIELDS.TOTAL_ITEMS_COST,
    enabled: true,
    label: 'Total Cost of Items',
    sortable: true,
    filterable: true,
    filterableContextMenu: true,
    copyable: true,
    editable: false,
    cellProps: {
      align: 'left',
      minWidth: '200px',
      maxWidth: '200px',
    },
  },
  [PURCHASE_ORDER_FIELDS.TOTAL_SHIPMENT_COST]: {
    id: PURCHASE_ORDER_FIELDS.TOTAL_SHIPMENT_COST,
    enabled: true,
    label: 'Shipment Total Cost',
    sortable: true,
    filterable: true,
    filterableContextMenu: true,
    copyable: true,
    editable: false,
    cellProps: {
      align: 'left',
      minWidth: '200px',
      maxWidth: '200px',
    },
  },
  [PURCHASE_ORDER_FIELDS.NOTES]: {
    id: PURCHASE_ORDER_FIELDS.NOTES,
    enabled: true,
    label: 'Notes',
    sortable: true,
    filterable: true,
    filterableContextMenu: true,
    copyable: true,
    editable: true,
    cellProps: {
      align: 'left',
      minWidth: '400px',
    },
  },
};

export const PURCHASE_ORDERS_COLUMNS_STORAGE_SETTINGS = _reduce(
  PURCHASE_ORDERS_COLUMNS, (result, value, key) => {
    const newResult = result;
    newResult[key] = _pick(value, ['id', 'enabled']);
    return newResult;
  }, {},
);

export const PURCHASE_ORDERS_COLUMNS_DEFAULT_ORDER = [
  PURCHASE_ORDER_FIELDS.PO,
  PURCHASE_ORDER_FIELDS.BRAND,
  PURCHASE_ORDER_FIELDS.SUPPLIER,
  PURCHASE_ORDER_FIELDS.PURCHASE_DATE,
  PURCHASE_ORDER_FIELDS.TOTAL_ITEMS_COST,
  PURCHASE_ORDER_FIELDS.TOTAL_SHIPMENT_COST,
  PURCHASE_ORDER_FIELDS.NOTES,
];