import styled from 'styled-components';
import { genericBorder } from 'assets/styles/utils';
import { CircleButtonWrap } from 'components/CircleButton/styles';
import { PORCELAIN } from 'constants/colors';

export const DialogTitleWrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  border-bottom: ${genericBorder(PORCELAIN)};
  padding: ${({ padding }) => padding || '1rem'};
  ${CircleButtonWrap} {
    margin: 0;
  }
`;

export const TitleContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;