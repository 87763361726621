import { createReducer } from 'redux-create-reducer';
import { ACTIONS } from './constants';

export const INITIAL_STATE = {
  authorizationError: null,
  roles: null,
  isInitContainer: false,
  isLoadingRoles: false,
  isAddingRole: false,
  isEditingRole: false,
  isDeletingRole: false,
  showAddRoleModal: false,
  showDeleteRoleModal: false,
  showEditRoleModal: false,
  deleteSelectedRole: null,
  editSelectedRole: null,
};

const setIsInitContainer = (state, isInitContainer) => ({
  ...state,
  isInitContainer,
});

const updateAuthorizationError = (state, { authorizationError }) => ({
  ...state,
  authorizationError,
});

const setIsLoadingRoles = (state, isLoadingRoles) => ({
  ...state,
  isLoadingRoles,
});

const updateRoles = (state, { payload }) => ({
  ...state,
  roles: payload.roles,
  isLoadingRoles: INITIAL_STATE.isLoadingRoles,
});

const updateShowAddRoleModal = (state, { showAddRoleModal }) => ({
  ...state,
  showAddRoleModal,
});

const updateShowDeleteRoleModal = (state, { showDeleteRoleModal }) => ({
  ...state,
  showDeleteRoleModal,
});

const updateShowEditRoleModal = (state, { showEditRoleModal }) => ({
  ...state,
  showEditRoleModal,
});

const setIsAddingRole = (state, isAddingRole) => ({
  ...state,
  isAddingRole,
});

const setIsEditingRole = (state, isEditingRole) => ({
  ...state,
  isEditingRole,
});

const setIsDeletingRole = (state, isDeletingRole) => ({
  ...state,
  isDeletingRole,
});

const updateDeleteSelectedRole = (state, { deleteSelectedRole }) => ({
  ...state,
  deleteSelectedRole,
});

const updateEditSelectedRole = (state, { editSelectedRole }) => ({
  ...state,
  editSelectedRole,
});

export const handlers = {
  [ACTIONS.INIT_CONTAINER]: state => setIsInitContainer(state, true),
  [ACTIONS.INIT_CONTAINER_SUCCESS]: state => setIsInitContainer(state, false),
  [ACTIONS.INIT_CONTAINER_ERROR]: state => setIsInitContainer(state, false),

  [ACTIONS.SET_AUTHORIZATION_ERROR]: updateAuthorizationError,

  [ACTIONS.LOAD_ROLES]: state => setIsLoadingRoles(state, true),
  [ACTIONS.LOAD_ROLES_SUCCESS]: updateRoles,
  [ACTIONS.LOAD_ROLES_ERROR]: state => setIsLoadingRoles(state, false),

  [ACTIONS.SET_SHOW_ADD_ROLE_MODAL]: updateShowAddRoleModal,
  [ACTIONS.SET_SHOW_DELETE_ROLE_MODAL]: updateShowDeleteRoleModal,
  [ACTIONS.SET_SHOW_EDIT_ROLE_MODAL]: updateShowEditRoleModal,

  [ACTIONS.SET_DELETE_SELECTED_ROLE]: updateDeleteSelectedRole,
  [ACTIONS.SET_EDIT_SELECTED_ROLE]: updateEditSelectedRole,

  [ACTIONS.ADD_ROLE]: state => setIsAddingRole(state, true),
  [ACTIONS.ADD_ROLE_SUCCESS]: state => setIsAddingRole(state, false),
  [ACTIONS.ADD_ROLE_ERROR]: state => setIsAddingRole(state, false),

  [ACTIONS.EDIT_ROLE]: state => setIsEditingRole(state, true),
  [ACTIONS.EDIT_ROLE_SUCCESS]: state => setIsEditingRole(state, false),
  [ACTIONS.EDIT_ROLE_ERROR]: state => setIsEditingRole(state, false),

  [ACTIONS.DELETE_ROLE]: state => setIsDeletingRole(state, true),
  [ACTIONS.DELETE_ROLE_SUCCESS]: state => setIsDeletingRole(state, false),
  [ACTIONS.DELETE_ROLE_ERROR]: state => setIsDeletingRole(state, false),

  [ACTIONS.RESET_STATE]: () => ({ ...INITIAL_STATE }),
};

export default createReducer(INITIAL_STATE, handlers);