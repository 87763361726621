import { createReducer } from 'redux-create-reducer';
import { ACTIONS } from './constants';

export const INITIAL_STATE = {
  userProfile: {
    first_name: '',
    last_name: '',
    email_address: '',
    phone_number: '',
    avatar_url: '',
    roles: [],
  },
  isLoadingProfile: false,
  isChangingProfile: false,
  isChangingPassword: false,
};

const updateUserProfile = (state, { payload }) => ({
  ...state,
  userProfile: payload,
  isLoadingProfile: false,
});

const resetState = () => ({
  ...INITIAL_STATE,
});

const setIsLoadingProfile = (state, isLoadingProfile) => ({
  ...state,
  isLoadingProfile,
});

const setIsChangingProfile = (state, isChangingProfile) => ({
  ...state,
  isChangingProfile,
});

const setIsChangingPassword = (state, isChangingPassword) => ({
  ...state,
  isChangingPassword,
});

export const handlers = {
  [ACTIONS.LOAD_USER_PROFILE]: state => setIsLoadingProfile(state, true),
  [ACTIONS.LOAD_USER_PROFILE_SUCCESS]: updateUserProfile,
  [ACTIONS.LOAD_USER_PROFILE_ERROR]: state => setIsLoadingProfile(state, false),
  [ACTIONS.UPDATE_PROFILE]: state => setIsChangingProfile(state, true),
  [ACTIONS.UPDATE_PROFILE_SUCCESS]: state => setIsChangingProfile(state, false),
  [ACTIONS.UPDATE_PROFILE_ERROR]: state => setIsChangingProfile(state, false),
  [ACTIONS.CHANGE_PASSWORD]: state => setIsChangingPassword(state, true),
  [ACTIONS.CHANGE_PASSWORD_SUCCESS]: state => setIsChangingPassword(state, false),
  [ACTIONS.CHANGE_PASSWORD_ERROR]: state => setIsChangingPassword(state, false),
  [ACTIONS.RESET_STATE]: resetState,
};

export default createReducer(INITIAL_STATE, handlers);
