import { combineReducers } from 'redux';
import addOrderReducers from './AddOrder/reducers';
import shippingReducers from './Shipping/reducers';
import orderExporterReducers from './OrderExporter/reducers';
import { ACTIONS } from './constants';

const featureReducers = combineReducers({
  addOrder: addOrderReducers,
  shipping: shippingReducers,
  orderExporter: orderExporterReducers,
});

const orderManagementReducers = (state, action) => {
  let featureState = state;
  if (action.type === ACTIONS.RESET_FEATURE_REDUCERS) {
    featureState = undefined;
  }
  return featureReducers(featureState, action);
};

export default orderManagementReducers;
