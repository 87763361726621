import { createReducer } from 'redux-create-reducer';
import { ACTIONS } from './constants';

const INITIAL_STATE = {
  documentTitle: '',
  featureName: '',
  initError: false,
  initSuccess: false,
  initProcess: false,
  isMobileDevice: false,
  showDrawer: false,
  sidebarOpen: false,
};

const setShowDrawer = state => ({
  ...state,
  showDrawer: !state.showDrawer,
});

const updateFeatureName = (state, { featureName = INITIAL_STATE.featureName }) => ({
  ...state,
  featureName,
});

const updateDocumentTitle = (state, { documentTitle = INITIAL_STATE.documentTitle }) => ({
  ...state,
  documentTitle,
});

const setShowSidebar = (state, { open }) => ({
  ...state,
  sidebarOpen: open,
});

const updateIsMobileDevice = (state, { isMobileDevice }) => ({
  ...state,
  isMobileDevice,
});

const setInitStart = state => ({
  ...state,
  initProcess: true,
});

const setInitSuccess = state => ({
  ...state,
  initProcess: false,
  initSuccess: true,
});

const setInitError = state => ({
  ...state,
  initProcess: false,
  initError: true,
});

const handlers = {
  [ACTIONS.INIT_CONTAINER]: setInitStart,
  [ACTIONS.INIT_CONTAINER_SUCCESS]: setInitSuccess,
  [ACTIONS.INIT_CONTAINER_ERROR]: setInitError,
  [ACTIONS.TOGGLE_DRAWER]: setShowDrawer,
  [ACTIONS.SET_FEATURE_NAME]: updateFeatureName,
  [ACTIONS.SET_DOCUMENT_TITLE]: updateDocumentTitle,
  [ACTIONS.TOGGLE_SIDEBAR]: setShowSidebar,
  [ACTIONS.SET_IS_MOBILE_DEVICE]: updateIsMobileDevice,
};

export default createReducer(INITIAL_STATE, handlers);
