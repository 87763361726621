export const ACTIONS = {
  INIT_CONTAINER: 'PRODUCT_CREATOR__INIT_CONTAINER',

  DOWNLOAD_EDIT_TEMPLATE: 'PRODUCT_CREATOR__DOWNLOAD_EDIT_TEMPLATE',
  DOWNLOAD_EDIT_TEMPLATE_SUCCESS: 'PRODUCT_CREATOR__DOWNLOAD_EDIT_TEMPLATE_SUCCESS',
  DOWNLOAD_EDIT_TEMPLATE_ERROR: 'PRODUCT_CREATOR__DOWNLOAD_EDIT_TEMPLATE_ERROR',

  DOWNLOAD_ADD_TEMPLATE: 'PRODUCT_CREATOR__DOWNLOAD_ADD_TEMPLATE',
  DOWNLOAD_ADD_TEMPLATE_SUCCESS: 'PRODUCT_CREATOR__DOWNLOAD_ADD_TEMPLATE_SUCCESS',
  DOWNLOAD_ADD_TEMPLATE_ERROR: 'PRODUCT_CREATOR__DOWNLOAD_ADD_TEMPLATE_ERROR',

  DOWNLOAD_DELETE_TEMPLATE: 'PRODUCT_CREATOR__DOWNLOAD_DELETE_TEMPLATE',
  DOWNLOAD_DELETE_TEMPLATE_SUCCESS: 'PRODUCT_CREATOR__DOWNLOAD_DELETE_TEMPLATE_SUCCESS',
  DOWNLOAD_DELETE_TEMPLATE_ERROR: 'PRODUCT_CREATOR__DOWNLOAD_DELETE_TEMPLATE_ERROR',

  VALIDATE_UPLOAD_FILE: 'PRODUCT_CREATOR__VALIDATE_UPLOAD_FILE',
  VALIDATE_UPLOAD_FILE_SUCCESS: 'PRODUCT_CREATOR__VALIDATE_UPLOAD_FILE_SUCCESS',
  VALIDATE_UPLOAD_FILE_ERROR: 'PRODUCT_CREATOR__VALIDATE_UPLOAD_FILE_ERROR',

  SET_UPLOAD_FILE_IS_VALID: 'PRODUCT_CREATOR__SET_UPLOAD_FILE_IS_VALID',
  SET_UPLOAD_FILE_VALIDATION_ERRORS: 'PRODUCT_CREATOR__SET_UPLOAD_FILE_VALIDATION_ERRORS',

  RESET_VALIDATE_UPLOAD: 'PRODUCT_CREATOR__RESET_VALIDATE_UPLOAD',

  UPLOAD_FILE: 'PRODUCT_CREATOR__UPLOAD_FILE',
  UPLOAD_FILE_SUCCESS: 'PRODUCT_CREATOR__UPLOAD_FILE_SUCCESS',
  UPLOAD_FILE_ERROR: 'PRODUCT_CREATOR__UPLOAD_FILE_ERROR',

  LOAD_UPLOAD_LOGS: 'PRODUCT_CREATOR__LOAD_UPLOAD_LOGS',
  LOAD_UPLOAD_LOGS_SUCCESS: 'PRODUCT_CREATOR__LOAD_UPLOAD_LOGS_SUCCESS',
  LOAD_UPLOAD_LOGS_ERROR: 'PRODUCT_CREATOR__LOAD_UPLOAD_LOGS_ERROR',

  SET_UPLOAD_LOGS: 'PRODUCT_CREATOR__SET_UPLOAD_LOGS',

  SET_UPLOAD_TYPE: 'PRODUCT_CREATOR__SET_UPLOAD_TYPE',

  DOWNLOAD_ORIGINAL_FILE: 'PRODUCT_CREATOR__DOWNLOAD_ORIGINAL_FILE',
  DOWNLOAD_ORIGINAL_FILE_SUCCESS: 'PRODUCT_CREATOR__DOWNLOAD_ORIGINAL_FILE_SUCCESS',
  DOWNLOAD_ORIGINAL_FILE_ERROR: 'PRODUCT_CREATOR__DOWNLOAD_ORIGINAL_FILE_ERROR',

  CANCEL_UPLOAD_REQUEST: 'PRODUCT_CREATOR__CANCEL_UPLOAD_REQUEST',
  CANCEL_UPLOAD_REQUEST_SUCCESS: 'PRODUCT_CREATOR__CANCEL_UPLOAD_REQUEST_SUCCESS',
  CANCEL_UPLOAD_REQUEST_ERROR: 'PRODUCT_CREATOR__CANCEL_UPLOAD_REQUEST_ERROR',

  DOWNLOAD_ERROR_FILE: 'PRODUCT_CREATOR__DOWNLOAD_ERROR_FILE',
  DOWNLOAD_ERROR_FILE_SUCCESS: 'PRODUCT_CREATOR__DOWNLOAD_ERROR_FILE_SUCCESS',
  DOWNLOAD_ERROR_FILE_ERROR: 'PRODUCT_CREATOR__DOWNLOAD_ERROR_FILE_ERROR',

  RESET_STATE: 'PRODUCT_CREATOR__RESET_STATE',
};

export const UPLOAD_TYPES = {
  ADD_PRODUCTS: 'add',
  EDIT_PRODUCTS: 'update',
  DELETE_PRODUCTS: 'delete',
};

export const UPLOAD_ERROR_TYPE = {
  file_data: 'File Data',
  file_headers: 'File Headers',
  other: 'Other',
};

export const ERROR_FILE_NAME = originalFilename => `OMNI_Product_Error_File_${originalFilename}`;