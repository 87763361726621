import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _isNaN from 'lodash/isNaN';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Select from 'components/Mui/Select';
import Pagination from 'components/Pagination';
import { useIsMobile, usePrevious } from 'utils/react';
import { PAGINATION_ITEMS_PER_PAGE, PAGINATION_DAYS } from './constants';

const FeedPagination = ({
  daysRangeOptions,
  daysSettings,
  isFetchingFeed,
  itemsPerPage,
  itemsPerPageOptions,
  noRecords,
  onGoToPage,
  onSetDaysRange,
  onSetItemsPerPage,
  pageNumber,
  totalPages,
  totalRecords,
}) => {
  const [pageNumberValue, setPageNumberValue] = useState(0);
  const prevIsFetchingFeed = usePrevious(isFetchingFeed);
  const isMobile = useIsMobile();

  const validatePageNumberValue = newPageNumber => {
    if (newPageNumber === '') {
      return null;
    }

    let newPageNumberValue = Number(newPageNumber);
    if (newPageNumberValue > totalPages) {
      newPageNumberValue = totalPages;
    }
    if (newPageNumberValue < 1 || _isNaN(newPageNumberValue)) {
      newPageNumberValue = 1;
    }
    setPageNumberValue(newPageNumberValue);
    return newPageNumberValue;
  };

  const handleArrowClick = value => {
    const newPageNumberValue = validatePageNumberValue(value);
    if (newPageNumberValue) {
      onGoToPage(newPageNumberValue);
    }
  };

  const handlePageNumberSubmit = () => {
    const newPageNumberValue = validatePageNumberValue(pageNumberValue);
    if (newPageNumberValue) {
      onGoToPage(newPageNumberValue);
    }
  };

  useEffect(() => {
    if (prevIsFetchingFeed && !isFetchingFeed) {
      setPageNumberValue(pageNumber);
    }
  }, [prevIsFetchingFeed, isFetchingFeed, pageNumber]);

  return (
    <Box pt="10px" pb={1} px={1}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8} lg={9} xl={10}>
          {pageNumber && (
            <Box maxWidth="fit-content" mx={isMobile ? 'auto' : '0'}>
              <Pagination
                pageNumber={pageNumber}
                isLoading={isFetchingFeed}
                disabled={isFetchingFeed || noRecords}
                onArrowClick={handleArrowClick}
                onFormSubmit={handlePageNumberSubmit}
                fieldValue={noRecords ? totalPages : pageNumberValue}
                onFieldChange={setPageNumberValue}
                totalPages={totalPages}
                totalRecords={totalRecords}
              />
            </Box>
          )}
        </Grid>
        <Grid item xs={12} md={4} lg={3} xl={2}>
          <Box display="flex" justifyContent={isMobile ? 'space-between' : 'flex-end'} ml="auto">
            {daysRangeOptions && onSetDaysRange && (
              <Box maxWidth="10rem" width="100%" ml={isMobile ? '0' : 'auto'}>
                <Select
                  label="Days Range"
                  variant="outlined"
                  size="small"
                  options={daysRangeOptions}
                  value={daysSettings}
                  onChange={onSetDaysRange}
                  disabled={isFetchingFeed}
                  fullWidth
                />
              </Box>
            )}
            {itemsPerPageOptions && onSetItemsPerPage && (
              <Box maxWidth="7rem" minWidth="7rem" width="100%" ml={isMobile ? 'auto' : 1}>
                <Select
                  label="Items per page"
                  variant="outlined"
                  size="small"
                  options={itemsPerPageOptions}
                  value={itemsPerPage || itemsPerPageOptions[0].value}
                  onChange={onSetItemsPerPage}
                  disabled={isFetchingFeed}
                  fullWidth
                />
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

FeedPagination.propTypes = {
  itemsPerPage: PropTypes.number,
  daysSettings: PropTypes.string,
  onSetDaysRange: PropTypes.func,
  onSetItemsPerPage: PropTypes.func,
  pageNumber: PropTypes.number,
  totalRecords: PropTypes.number,
  totalPages: PropTypes.number,
  onGoToPage: PropTypes.func.isRequired,
  isFetchingFeed: PropTypes.bool.isRequired,
  noRecords: PropTypes.bool.isRequired,
  daysRangeOptions: PropTypes.arrayOf(PropTypes.shape()),
  itemsPerPageOptions: PropTypes.arrayOf(PropTypes.shape()),
};

FeedPagination.defaultProps = {
  pageNumber: null,
  totalRecords: null,
  totalPages: null,
  daysRangeOptions: null,
  itemsPerPageOptions: null,
  daysSettings: null,
  itemsPerPage: null,
  onSetDaysRange: null,
  onSetItemsPerPage: null,
};

export default FeedPagination;
export {
  PAGINATION_ITEMS_PER_PAGE,
  PAGINATION_DAYS,
};