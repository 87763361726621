import {
  all, takeEvery, put, call, select,
} from 'redux-saga/effects';
import { APP_FEATURES } from 'constants/global';
import { handleURLFilters } from 'features/sagaHelpers';
import { setDocumentTitle, setFeatureName } from 'containers/Dashboard/actions';
import {
  ACTIONS,
  ORDER_VIEW_COLUMN_FILTER_OPTIONS,
  ORDER_VIEW_FILTER_OPTIONS,
  ORDER_VIEW_QUICK_FILTER_OPTIONS,
  ORDER_VIEW_TYPE_OPTIONS,
} from '../constants';
import {
  loadOrderLines,
  setColumnFilterOptions,
  setFilterOptions,
  setFilters,
  setQuickFilterOptions,
  goToPage,
} from '../actions';
import { selectOrderViewType } from '../selectors';

function* changeFeatureName() {
  const orderViewType = yield select(selectOrderViewType);
  const orderViewTypeTitle = ORDER_VIEW_TYPE_OPTIONS[orderViewType].title;

  yield put(setFeatureName(`${APP_FEATURES.ORDER_VIEW.title} | ${orderViewTypeTitle}`));
}

function* changeFilters() {
  const orderViewType = yield select(selectOrderViewType);
  yield put(setFilterOptions(ORDER_VIEW_FILTER_OPTIONS[orderViewType]));
  yield put(setColumnFilterOptions(ORDER_VIEW_COLUMN_FILTER_OPTIONS[orderViewType]));
  yield put(setQuickFilterOptions(ORDER_VIEW_QUICK_FILTER_OPTIONS[orderViewType]));
}

function* setOrderViewTypeWatcher() {
  yield call(changeFeatureName);
  yield put(goToPage(1));
  yield call(changeFilters);
}

function* initContainerWatcher() {
  yield call(changeFeatureName);
  yield call(changeFilters);
  yield put(setDocumentTitle(APP_FEATURES.ORDER_VIEW.title));
  yield call(handleURLFilters, setFilters);
  yield put(loadOrderLines());
}

function* exitContainerWatcher() {
  yield put(setFeatureName());
}

export default function* containerSagas() {
  yield all([
    takeEvery(ACTIONS.INIT_CONTAINER, initContainerWatcher),
    takeEvery(ACTIONS.EXIT_CONTAINER, exitContainerWatcher),
    takeEvery(ACTIONS.SET_ORDER_VIEW_TYPE, setOrderViewTypeWatcher),
  ]);
}