import { createReducer } from 'redux-create-reducer';
import { ACTIONS } from './constants';

const INITIAL_STATE = {
  session: null,
};

const updateSession = (state, { session }) => ({
  ...state,
  session,
});

const handlers = {
  [ACTIONS.FIREBASE_SET_SESSION]: updateSession,
};

export default createReducer(INITIAL_STATE, handlers);
