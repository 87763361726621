import { lazy } from 'react';
import { createReducer } from 'redux-create-reducer';
import _get from 'lodash/get';
import { ACTIONS, BUSINESS_FIELDS } from './constants';

const LoadableCountries = lazy(() => import(/* webpackChunkName: "countries" */ 'constants/countries'));

export const INITIAL_STATE = {
  isSearchingZipCode: false,
  isLoadingBusinessInfo: false,
  isUploadingBusinessLogo: false,
  countryList: LoadableCountries,
  businessInfo: {
    [BUSINESS_FIELDS.BUSINESS_NAME]: '',
    [BUSINESS_FIELDS.FIRST_NAME]: '',
    [BUSINESS_FIELDS.LAST_NAME]: '',
    [BUSINESS_FIELDS.EMAIL_ADDRESS]: '',
    [BUSINESS_FIELDS.PHONE_NUMBER]: '',
    [BUSINESS_FIELDS.ADDRESS_1]: '',
    [BUSINESS_FIELDS.ADDRESS_2]: '',
    [BUSINESS_FIELDS.ADDRESS_3]: '',
    [BUSINESS_FIELDS.CITY]: '',
    [BUSINESS_FIELDS.STATE]: '',
    [BUSINESS_FIELDS.ZIP_CODE]: '',
    [BUSINESS_FIELDS.COUNTRY]: 'US',
  },
};

const resetState = () => ({
  ...INITIAL_STATE,
});

const setIsLoadingBusinessInfo = (state, isLoadingBusinessInfo) => ({
  ...state,
  isLoadingBusinessInfo,
});

const setIsUploadingBusinessLogo = (state, isUploadingBusinessLogo) => ({
  ...state,
  isUploadingBusinessLogo,
});

const setLoadBusinessInfoSuccess = (state, { payload }) => ({
  ...state,
  businessInfo: payload.business,
  isLoadingBusinessInfo: INITIAL_STATE.isLoadingBusinessInfo,
});

const setIsSearchingZipCode = (state, isSearchingZipCode) => ({
  ...state,
  isSearchingZipCode,
});

const setSearchZipCodeSuccess = (state, { payload }) => {
  const businessCity = _get(payload, 'search_results[0].ship_city', '');
  const businessState = _get(payload, 'search_results[0].state', '');
  return {
    ...state,
    isSearchingZipCode: INITIAL_STATE.isSearchingZipCode,
    businessInfo: {
      ...state.businessInfo,
      [BUSINESS_FIELDS.CITY]: businessCity,
      [BUSINESS_FIELDS.STATE]: businessState,
    },
  };
};

const updateFieldValue = (state, { fieldId, value }) => ({
  ...state,
  businessInfo: {
    ...state.businessInfo,
    [fieldId]: value,
  },
});

export const handlers = {
  [ACTIONS.LOAD_BUSINESS_INFO]: state => setIsLoadingBusinessInfo(state, true),
  [ACTIONS.LOAD_BUSINESS_INFO_SUCCESS]: setLoadBusinessInfoSuccess,
  [ACTIONS.LOAD_BUSINESS_INFO_ERROR]: state => setIsLoadingBusinessInfo(state, false),
  [ACTIONS.SEARCH_ZIP_CODE]: state => setIsSearchingZipCode(state, true),
  [ACTIONS.SEARCH_ZIP_CODE_SUCCESS]: setSearchZipCodeSuccess,
  [ACTIONS.SET_BUSINESS_INFO_FIELD_VALUE]: updateFieldValue,
  [ACTIONS.UPLOAD_BUSINESS_LOGO]: state => setIsUploadingBusinessLogo(state, true),
  [ACTIONS.UPLOAD_BUSINESS_LOGO_SUCCESS]: state => setIsUploadingBusinessLogo(state, false),
  [ACTIONS.UPLOAD_BUSINESS_LOGO_ERROR]: state => setIsUploadingBusinessLogo(state, false),
  [ACTIONS.RESET_STATE]: resetState,
};

export default createReducer(INITIAL_STATE, handlers);