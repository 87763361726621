import styled from 'styled-components';

export const LoaderWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  > p {
    font-size: 0.8rem;
  }
`;

export const ErrorWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  max-width: 500px;
  h1, p {
    margin-bottom: 1rem;
    text-align: center;
  }
  button {
    margin-top: 1rem;
  }
`;

export const ImageWrap = styled.div`
  > svg {
    height: auto;
    width: 100%;
  }
`;
