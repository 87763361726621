import MoneyContent from 'features/components/FeedGrid/MoneyContent';
import DescriptionContent from '../components/ReportTable/DescriptionContent';

export const ACTIONS = {
  LOAD_REPORT: 'ADJUSTED_INVENTORY__LOAD_REPORT',
  LOAD_REPORT_SUCCESS: 'ADJUSTED_INVENTORY__LOAD_REPORT_SUCCESS',
  LOAD_REPORT_ERROR: 'ADJUSTED_INVENTORY__LOAD_REPORT_ERROR',

  EXPORT_TO_FILE: 'ADJUSTED_INVENTORY__EXPORT_TO_FILE',
  EXPORT_TO_FILE_SUCCESS: 'ADJUSTED_INVENTORY__EXPORT_TO_FILE_SUCCESS',
  EXPORT_TO_FILE_ERROR: 'ADJUSTED_INVENTORY__EXPORT_TO_FILE_ERROR',

  RESET_STATE: 'ADJUSTED_INVENTORY__RESET_STATE',
};

export const REPORT_COLUMNS = {
  description: {
    id: 'description',
    label: 'Description',
    cellProps: {
      align: 'left',
    },
  },
  upc: {
    id: 'upc',
    label: 'UPC',
    cellProps: {
      minWidth: '8rem',
      maxWidth: '11rem',
      align: 'center',
    },
  },
  lead_time: {
    id: 'lead_time',
    label: 'Leadtime',
    cellProps: {
      minWidth: '3rem',
      maxWidth: '6rem',
      align: 'center',
    },
  },
  cost: {
    id: 'cost',
    label: 'Cost',
    cellProps: {
      minWidth: '5rem',
      maxWidth: '8rem',
      align: 'center',
    },
  },
  price: {
    id: 'price',
    label: 'Price',
    cellProps: {
      minWidth: '5rem',
      maxWidth: '8rem',
      align: 'center',
    },
  },
  qty: {
    id: 'qty',
    label: 'QTY',
    cellProps: {
      minWidth: '4rem',
      maxWidth: '7rem',
      align: 'center',
    },
  },
  qty_available: {
    id: 'qty_available',
    label: 'Available',
    cellProps: {
      minWidth: '4rem',
      maxWidth: '7rem',
      align: 'center',
    },
  },
};

export const REPORT_COLUMNS_DEFAULT_ORDER = [
  REPORT_COLUMNS.description.id,
  REPORT_COLUMNS.upc.id,
  REPORT_COLUMNS.qty.id,
  REPORT_COLUMNS.qty_available.id,
  REPORT_COLUMNS.cost.id,
  REPORT_COLUMNS.price.id,
  REPORT_COLUMNS.lead_time.id,
];

export const REPORT_CELL_COMPONENTS = {
  [REPORT_COLUMNS.description.id]: DescriptionContent,
  [REPORT_COLUMNS.cost.id]: MoneyContent,
  [REPORT_COLUMNS.price.id]: MoneyContent,
};