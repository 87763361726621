import {
  all, takeEvery, put, call, select,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import _get from 'lodash/get';
import ROUTES from 'constants/routes';
import { getRouterQuery } from 'app/router/selectors';
import { setForgotPassword } from 'services/auth/actions';
import {
  resetState, sendRecoveryLinkError, sendRecoveryLinkSuccess, setExpiredLink,
} from './actions';
import { ACTIONS } from './constants';

function* initContainerWatcher() {
  const urlQueryObj = yield select(getRouterQuery);
  const expired = _get(urlQueryObj, 'expired');
  if (expired === 'True') {
    yield put(setExpiredLink(true));
    yield put(push(ROUTES.LOGIN.RESET_PASSWORD));
  }
}

function* exitContainerWatcher() {
  yield put(resetState());
}

function* sendRecoveryLinkWatcher({ username }) {
  yield put(setForgotPassword(username, sendRecoveryLinkSuccess, sendRecoveryLinkError));
}

function* sendRecoveryLinkSuccessWatcher() {
  yield put(push(ROUTES.LOGIN.CHECK_EMAIL));
}

function* sendRecoveryLinkErrorWatcher({ errorMessage }) {
  yield call([toast, toast.error], errorMessage);
}

export default function* resetPasswordSagas() {
  yield all([
    takeEvery(ACTIONS.INIT_CONTAINER, initContainerWatcher),
    takeEvery(ACTIONS.EXIT_CONTAINER, exitContainerWatcher),
    takeEvery(ACTIONS.SEND_RECOVERY_LINK, sendRecoveryLinkWatcher),
    takeEvery(ACTIONS.SEND_RECOVERY_LINK_SUCCESS, sendRecoveryLinkSuccessWatcher),
    takeEvery(ACTIONS.SEND_RECOVERY_LINK_ERROR, sendRecoveryLinkErrorWatcher),
  ]);
}
