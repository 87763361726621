import axios from 'axios';
import _reduce from 'lodash/reduce';

export default class HttpRequest {
  constructor(url, endpoints) {
    this.baseUrl = url;
    this.endpoints = _reduce(endpoints, (result, value, key) => {
      const newResult = result;
      newResult[key.toLowerCase()] = value;
      return newResult;
    }, {});
    this.config = {};
  }

  call(url, passedConfig = {}, external = false) {
    const config = external
      ? ({ ...passedConfig })
      : ({ ...this.config, ...passedConfig });

    const requestUrl = config.pure ? url : `${this.baseUrl}${url}`;

    const promise = axios.request(requestUrl, config);
    const result = promise.then(response => {
      if (passedConfig.responseType === 'blob') {
        return response;
      }
      return response.data;
    });

    return result;
  }
}
