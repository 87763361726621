import { combineReducers } from 'redux';
import productsReducers from './Products/reducers';
import productCreatorReducers from './ProductCreator/reducers';
import brandSuppliersReducers from './BrandsSuppliers/reducers';
import { ACTIONS } from './constants';

const featureReducers = combineReducers({
  products: productsReducers,
  productCreator: productCreatorReducers,
  brandsSuppliers: brandSuppliersReducers,
});

const productManagementReducers = (state, action) => {
  let featureState = state;
  if (action.type === ACTIONS.RESET_FEATURE_REDUCERS) {
    featureState = undefined;
  }
  return featureReducers(featureState, action);
};

export default productManagementReducers;
