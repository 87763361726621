export const FIELD_VALUE_TYPES = {
  INTEGER: 'integer',
  LETTERS: 'letters',
  LETTERS_SPACE: 'letters_space',
  DECIMALS: 'decimals',
  PERCENTAGE: 'percentage',
  ANY: 'any',
  USERNAME: 'username',
  AWS_GROUP_NAME: 'aws_group_name',
  EMAIL: 'email',
  PLUS_SIGN_NUMBERS: 'plus_sign_numbers',
};

export const FIELD_TYPES = {
  TEXT: 'text',
  PASSWORD: 'password',
  NUMBER: 'number',
  EMAIL: 'email',
};