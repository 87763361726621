import { all, fork } from 'redux-saga/effects';
import exportToFileSagas from './exportToFileSagas';
import loadReportSagas from './loadReportSagas';

export default function* inventoryLocationsSagas(...args) {
  yield all([
    fork(loadReportSagas, ...args),
    fork(exportToFileSagas, ...args),
  ]);
}
