import { combineReducers } from 'redux';
import authReducers from './auth/reducers';
import inventoryReducers from './inventory/reducers';
import ordersReducers from './orders/reducers';
import firebaseReducers from './firebase/reducers';
import userManagementReducers from './userManagement/reducers';

export default combineReducers({
  auth: authReducers,
  inventory: inventoryReducers,
  orders: ordersReducers,
  firebase: firebaseReducers,
  userManagement: userManagementReducers,
});
