export const createTempFile = ({
  fileName,
  fileData,
  blobType,
}) => {
  const fData = new Blob([fileData], { type: blobType });
  const fileURL = navigator.msSaveBlob
    ? navigator.msSaveBlob(fData, fileName)
    : window.URL.createObjectURL(fData);

  return fileURL;
};