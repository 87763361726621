import {
  put, all, takeEvery, call, select,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { setZoneLock, setZoneUnlock, fetchHasLock } from 'services/inventory/actions';
import { INVENTORY_MANAGEMENT_ERRORS } from 'features/InventoryManagement/constants';
import { ACTIONS } from '../constants';
import {
  unlockZone,
  lockZone,
  lockZoneSuccess,
  lockZoneError,
  unlockZoneSuccess,
  unlockZoneError,
  reloadZone,
  reloadZoneLockSuccess,
  reloadZoneLockError,
} from '../actions';
import {
  getZoneName,
  getZoneLocked,
  getZoneLockNonce,
} from '../selectors';

function* toggleZoneLockWatcher() {
  const isZoneLocked = yield select(getZoneLocked);

  if (isZoneLocked) {
    yield put(unlockZone());
    return;
  }

  yield put(lockZone());
}

function* lockZoneWatcher() {
  const zoneName = yield select(getZoneName);
  yield put(setZoneLock(zoneName, lockZoneSuccess, lockZoneError));
}

function* unlockZoneWatcher() {
  const zoneName = yield select(getZoneName);
  const nonce = yield select(getZoneLockNonce);
  yield put(setZoneUnlock(zoneName, nonce, unlockZoneSuccess, unlockZoneError));
}

function* lockZoneErrorWatcher({ errorMessage }) {
  yield put(reloadZone());
  yield call([toast, toast.error], errorMessage || INVENTORY_MANAGEMENT_ERRORS.LOCKING_ZONE);
}

function* unlockZoneErrorWatcher({ errorMessage }) {
  yield put(reloadZone());
  yield call([toast, toast.error], errorMessage || INVENTORY_MANAGEMENT_ERRORS.UNLOCKING_ZONE);
}

function* reloadZoneLockWatcher() {
  const zoneName = yield select(getZoneName);
  yield put(fetchHasLock(zoneName, reloadZoneLockSuccess, reloadZoneLockError));
}

function* reloadZoneLockErrorWatcher({ errorMessage }) {
  yield call([toast, toast.error], errorMessage);
}

export default function* zoneLockSagas() {
  yield all([
    takeEvery(ACTIONS.TOGGLE_ZONE_LOCK, toggleZoneLockWatcher),
    takeEvery(ACTIONS.LOCK_ZONE, lockZoneWatcher),
    takeEvery(ACTIONS.LOCK_ZONE_ERROR, lockZoneErrorWatcher),
    takeEvery(ACTIONS.UNLOCK_ZONE, unlockZoneWatcher),
    takeEvery(ACTIONS.UNLOCK_ZONE_ERROR, unlockZoneErrorWatcher),
    takeEvery(ACTIONS.RELOAD_ZONE_LOCK, reloadZoneLockWatcher),
    takeEvery(ACTIONS.RELOAD_ZONE_LOCK_ERROR, reloadZoneLockErrorWatcher),
  ]);
}