export const ACTIONS = {
  INIT_CONTAINER: 'ROLES_MANAGEMENT__INIT_CONTAINER',
  INIT_CONTAINER_SUCCESS: 'ROLES_MANAGEMENT__INIT_CONTAINER_SUCCESS',
  INIT_CONTAINER_ERROR: 'ROLES_MANAGEMENT__INIT_CONTAINER_ERROR',
  EXIT_CONTAINER: 'ROLES_MANAGEMENT__EXIT_CONTAINER',

  SET_AUTHORIZATION_ERROR: 'ROLES_MANAGEMENT__SET_AUTHORIZATION_ERROR',

  LOAD_ROLES: 'ROLES_MANAGEMENT__LOAD_ROLES',
  LOAD_ROLES_SUCCESS: 'ROLES_MANAGEMENT__LOAD_ROLES_SUCCESS',
  LOAD_ROLES_ERROR: 'ROLES_MANAGEMENT__LOAD_ROLES_ERROR',

  SET_SHOW_ADD_ROLE_MODAL: 'ROLES_MANAGEMENT__SET_SHOW_ADD_ROLE_MODAL',
  SET_SHOW_DELETE_ROLE_MODAL: 'ROLES_MANAGEMENT__SET_SHOW_DELETE_ROLE_MODAL',
  SET_SHOW_EDIT_ROLE_MODAL: 'ROLES_MANAGEMENT__SET_SHOW_EDIT_ROLE_MODAL',

  SET_DELETE_SELECTED_ROLE: 'ROLES_MANAGEMENT__SET_DELETE_SELECTED_ROLE',
  SET_EDIT_SELECTED_ROLE: 'ROLES_MANAGEMENT__SET_EDIT_SELECTED_ROLE',

  ADD_ROLE: 'ROLES_MANAGEMENT__ADD_ROLE',
  ADD_ROLE_SUCCESS: 'ROLES_MANAGEMENT__ADD_ROLE_SUCCESS',
  ADD_ROLE_ERROR: 'ROLES_MANAGEMENT__ADD_ROLE_ERROR',

  EDIT_ROLE: 'ROLES_MANAGEMENT__EDIT_ROLE',
  EDIT_ROLE_SUCCESS: 'ROLES_MANAGEMENT__EDIT_ROLE_SUCCESS',
  EDIT_ROLE_ERROR: 'ROLES_MANAGEMENT__EDIT_ROLE_ERROR',

  DELETE_ROLE: 'ROLES_MANAGEMENT__DELETE_ROLE',
  DELETE_ROLE_SUCCESS: 'ROLES_MANAGEMENT__DELETE_ROLE_SUCCESS',
  DELETE_ROLE_ERROR: 'ROLES_MANAGEMENT__DELETE_ROLE_ERROR',

  RESET_STATE: 'ROLES_MANAGEMENT__RESET_STATE',
};