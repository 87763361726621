import { createReducer } from 'redux-create-reducer';
import { ACTIONS } from './constants';

const INITIAL_STATE = {
  isFetchingOrderFeed: false,
};

const setIsFetchingOrderFeed = (
  state, isFetchingOrderFeed = INITIAL_STATE.isFetchingOrderFeed,
) => ({
  ...state,
  isFetchingOrderFeed,
});

const handlers = {
  [ACTIONS.FETCH_ORDER_FEED]: state => setIsFetchingOrderFeed(state, true),
  [ACTIONS.FETCH_ORDER_FEED_SUCCESS]: state => setIsFetchingOrderFeed(state, false),
  [ACTIONS.FETCH_ORDER_FEED_ERROR]: state => setIsFetchingOrderFeed(state, false),
};

export default createReducer(INITIAL_STATE, handlers);
