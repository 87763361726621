import { all, fork } from 'redux-saga/effects';
import containerSagas from './containerSagas';
import loadFeedSagas from './loadFeedSagas';
import exportToFileSagas from './exportToFileSagas';
import managementSagas from './managementSagas';

export default function* purchaseOrdersSagas(...args) {
  yield all([
    fork(containerSagas, ...args),
    fork(loadFeedSagas, ...args),
    fork(exportToFileSagas, ...args),
    fork(managementSagas, ...args),
  ]);
}
