import {
  all, takeEvery, put, call, select,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import _isEmpty from 'lodash/isEmpty';
import _pick from 'lodash/pick';
import ROUTES from 'constants/routes';
import { fetchSearchOrderLineIds } from 'services/feeds/actions';
import { setShippingInfo, setReferringOrder } from 'features/OrderManagement/AddOrder/actions';
import { ACTIONS, SHIPPING_INFO_ATTRIBUTES, ORDER_VIEW_ERRORS } from '../constants';
import {
  confirmCreateOrderSuccess,
  confirmCreateOrderError,
  showCreateOrderModal,
  redirectToAddOrder,
  cancelCreateOrder,
} from '../actions';
import { getSalesOrderNumber } from '../selectors';

function* createOrderFromOrderLineWatcher() {
  yield put(showCreateOrderModal(true));
}

function* confirmCreateOrderWatcher({ guidOrderLine }) {
  yield put(
    fetchSearchOrderLineIds(
      guidOrderLine, confirmCreateOrderSuccess, confirmCreateOrderError,
    ),
  );
}

function* confirmCreateOrderSuccessWatcher({ payload }) {
  const { search_results: results } = payload;
  if (_isEmpty(results)) {
    const salesOrderNumber = yield select(getSalesOrderNumber);
    yield put(confirmCreateOrderError(ORDER_VIEW_ERRORS.NO_RESULTS_FOR_SO(salesOrderNumber)));
    return;
  }

  const info = results[0];
  const shippingInfo = _pick(info, SHIPPING_INFO_ATTRIBUTES);
  yield put(setShippingInfo(shippingInfo));
  yield put(setReferringOrder(info.sales_order_number));
  yield put(redirectToAddOrder());
}

function* confirmCreateOrderErrorWatcher({ errorMessage }) {
  yield put(cancelCreateOrder());
  yield call([toast, toast.error], errorMessage);
}

function* cancelCreateOrderWatcher() {
  yield put(showCreateOrderModal(false));
}

function* redirectToAddOrderWatcher() {
  yield put(push(ROUTES.ORDER_MANAGEMENT.ADD_ORDER));
  yield put(cancelCreateOrder());
}

export default function* createOrderFromOrderLineSagas() {
  yield all([
    takeEvery(ACTIONS.CREATE_ORDER_FROM_ORDER_LINE, createOrderFromOrderLineWatcher),
    takeEvery(ACTIONS.CONFIRM_CREATE_ORDER, confirmCreateOrderWatcher),
    takeEvery(ACTIONS.CONFIRM_CREATE_ORDER_SUCCESS, confirmCreateOrderSuccessWatcher),
    takeEvery(ACTIONS.CONFIRM_CREATE_ORDER_ERROR, confirmCreateOrderErrorWatcher),
    takeEvery(ACTIONS.CANCEL_CREATE_ORDER, cancelCreateOrderWatcher),
    takeEvery(ACTIONS.REDIRECT_TO_ADD_ORDER, redirectToAddOrderWatcher),
  ]);
}