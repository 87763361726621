import {
  all, takeEvery, select, put,
} from 'redux-saga/effects';
import _reduce from 'lodash/reduce';
import { ACTIONS, REPORT_COLUMNS } from '../constants';
import { getSettingsColumns } from '../selectors';
import { setColumns } from '../actions';

function* toggleColumnsEnabledWatcher({ enabled }) {
  const columns = yield select(getSettingsColumns);
  const settings = REPORT_COLUMNS;
  const updatedColumns = _reduce(columns, (result, value, key) => {
    const newResult = result;
    newResult[key] = {
      ...value,
      enabled: !settings[key].required ? enabled : value.enabled,
    };

    return newResult;
  }, {});

  yield put(setColumns(updatedColumns));
}

export default function* settingsSagas() {
  yield all([
    takeEvery(ACTIONS.TOGGLE_COLUMNS_ENABLED, toggleColumnsEnabledWatcher),
  ]);
}