import {
  all, takeEvery, put, call, select,
} from 'redux-saga/effects';
import _get from 'lodash/get';
import {
  addBrandSupplier,
  deleteBrandSupplier,
  updateBrandSupplier,
} from 'services/productManagement/actions';
import { errorToasterSaga, successToasterSaga } from 'services/sagaWorkers';
import {
  createBrandSupplierSuccess,
  createBrandSupplierError,
  refreshFeed,
  removeBrandSupplierSuccess,
  removeBrandSupplierError,
  editBrandSupplierSuccess,
  editBrandSupplierError,
} from '../actions';
import { ACTIONS } from '../constants';
import {
  selectSelectedItem,
} from '../selectors';

function* createBrandSupplierWatcher({ params }) {
  yield put(addBrandSupplier(params, createBrandSupplierSuccess, createBrandSupplierError));
}

function* createBrandSupplierSuccessWatcher() {
  yield call(successToasterSaga, { message: 'Brand/Supplier successfully created.' });
  yield put(refreshFeed());
}

function* removeBrandSupplierWatcher({ brandSupplierId }) {
  yield put(
    deleteBrandSupplier(brandSupplierId, removeBrandSupplierSuccess, removeBrandSupplierError),
  );
}

function* removeBrandSupplierSuccessWatcher() {
  yield call(successToasterSaga, { message: 'Brand/Supplier successfully deleted.' });
  yield put(refreshFeed());
}

function* editBrandSupplierWatcher({ params }) {
  const selectedItem = yield select(selectSelectedItem);
  const brandSupplierId = _get(selectedItem, 'brand_supplier_id');
  const requestParams = {
    brand_supplier_id: brandSupplierId,
    ...params,
  };
  yield put(
    updateBrandSupplier(requestParams, editBrandSupplierSuccess, editBrandSupplierError),
  );
}

function* editBrandSupplierSuccessWatcher() {
  yield call(successToasterSaga, { message: 'Brand/Supplier successfully updated.' });
  yield put(refreshFeed());
}

export default function* managementSagas() {
  yield all([
    takeEvery(ACTIONS.CREATE_BRAND_SUPPLIER, createBrandSupplierWatcher),
    takeEvery(ACTIONS.CREATE_BRAND_SUPPLIER_ERROR, errorToasterSaga),
    takeEvery(ACTIONS.CREATE_BRAND_SUPPLIER_SUCCESS, createBrandSupplierSuccessWatcher),

    takeEvery(ACTIONS.EDIT_BRAND_SUPPLIER, editBrandSupplierWatcher),
    takeEvery(ACTIONS.EDIT_BRAND_SUPPLIER_ERROR, errorToasterSaga),
    takeEvery(ACTIONS.EDIT_BRAND_SUPPLIER_SUCCESS, editBrandSupplierSuccessWatcher),

    takeEvery(ACTIONS.REMOVE_BRAND_SUPPLIER, removeBrandSupplierWatcher),
    takeEvery(ACTIONS.REMOVE_BRAND_SUPPLIER_ERROR, errorToasterSaga),
    takeEvery(ACTIONS.REMOVE_BRAND_SUPPLIER_SUCCESS, removeBrandSupplierSuccessWatcher),
  ]);
}