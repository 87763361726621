import { FaUserCircle, FaUsers } from 'react-icons/fa';
import { HiOutlineAdjustments } from 'react-icons/hi';
import { MdBusinessCenter, MdGroupWork } from 'react-icons/md';
import { BsPlug } from 'react-icons/bs';
import ROUTES from 'constants/routes';
import { isFeatureEnabled } from 'app/featureFlags';
import { FEATURE_FLAGS } from 'app/featureFlags/constants';

export const ACTIONS = {
  INIT_CONTAINER: 'ACCOUNT_SETTINGS__INIT_CONTAINER',
  EXIT_CONTAINER: 'ACCOUNT_SETTINGS__EXIT_CONTAINER',
  RESET_FEATURE_REDUCERS: 'ACCOUNT_SETTINGS__RESET_FEATURE_REDUCERS',
};

export const ACCOUNT_SETTINGS_SECTIONS = {
  PROFILE: {
    id: 'profile',
    title: 'Profile',
    route: ROUTES.ACCOUNT_SETTINGS.PROFILE,
    icon: FaUserCircle,
    disabled: false,
  },
  BUSINESS: {
    id: 'business',
    title: 'Business',
    route: ROUTES.ACCOUNT_SETTINGS.BUSINESS,
    icon: MdBusinessCenter,
    disabled: !isFeatureEnabled(FEATURE_FLAGS.ACCOUNT_SETTINGS_BUSINESS),
  },
  USERS_MANAGEMENT: {
    id: 'users-management',
    title: 'Users Management',
    route: ROUTES.ACCOUNT_SETTINGS.USERS_MANAGEMENT,
    icon: FaUsers,
    disabled: false,
  },
  ROLES_MANAGEMENT: {
    id: 'roles-management',
    title: 'Roles Management',
    route: ROUTES.ACCOUNT_SETTINGS.ROLES_MANAGEMENT,
    icon: MdGroupWork,
    disabled: false,
  },
  INTEGRATIONS: {
    id: 'integrations',
    title: 'Integrations',
    route: ROUTES.ACCOUNT_SETTINGS.INTEGRATIONS,
    icon: BsPlug,
    disabled: !isFeatureEnabled(FEATURE_FLAGS.ACCOUNT_SETTINGS_INTEGRATIONS),
  },
  SHIPPING_RULES: {
    id: 'shipping_rules',
    title: 'Shipping Rules',
    route: ROUTES.ACCOUNT_SETTINGS.SHIPPING_RULES(),
    icon: HiOutlineAdjustments,
    disabled: false,
  },
};