import {
  put, all, takeEvery, call, select,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { fetchZone } from 'services/inventory/actions';
import { INVENTORY_MANAGEMENT_ERRORS } from 'features/InventoryManagement/constants';
import { unsubscribeFromZoneUpdates, subscribeToZoneUpdates } from 'features/InventoryManagement/sagaWorkers';
import { ACTIONS } from '../constants';
import {
  scanZoneNameSuccess,
  scanZoneNameError,
  setChangedItems,
  scanZoneName,
  reloadZoneLock,
  reloadZone,
} from '../actions';
import { getZoneName } from '../selectors';

function* scanZoneNameWatcher({ zoneName }) {
  const prevZoneName = yield select(getZoneName);
  yield call(unsubscribeFromZoneUpdates, zoneName, prevZoneName);
  yield put(fetchZone(zoneName, scanZoneNameSuccess, scanZoneNameError));
}

function* scanZoneNameSuccessWatcher({ payload }) {
  const { items, location } = payload;
  yield put(setChangedItems(items));

  yield call(subscribeToZoneUpdates, location, reloadZoneLock, reloadZone);
}

function* scanZoneNameErrorWatcher({ errorMessage }) {
  yield call([toast, toast.error], errorMessage || INVENTORY_MANAGEMENT_ERRORS.SCANNING_ZONE_NAME);
}

function* reloadZoneWatcher() {
  const zoneName = yield select(getZoneName);
  yield put(scanZoneName(zoneName));
}

export default function* scanZoneNameSagas() {
  yield all([
    takeEvery(ACTIONS.SCAN_ZONE_NAME, scanZoneNameWatcher),
    takeEvery(ACTIONS.SCAN_ZONE_NAME_SUCCESS, scanZoneNameSuccessWatcher),
    takeEvery(ACTIONS.SCAN_ZONE_NAME_ERROR, scanZoneNameErrorWatcher),
    takeEvery(ACTIONS.RELOAD_ZONE, reloadZoneWatcher),
  ]);
}