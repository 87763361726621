export const numberWithCommas = (number, decimals) => {
  let formattedNumber = number;

  if (parseFloat(number) === number && decimals) {
    formattedNumber = String(parseFloat(number).toFixed(decimals));
  }

  const parts = formattedNumber.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  const num = parts.join('.');

  return num;
};

export const roundNumber = (value, maxDecimals = 2) => (
  +(`${Math.round(`${value}e+${maxDecimals}`)}e-${maxDecimals}`)
);