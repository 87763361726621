import { createAction } from 'redux-create-action';
import { ACTIONS } from './constants';

export const setSalesOrderNumbers = createAction(ACTIONS.SET_SALES_ORDER_NUMBERS, 'salesOrderNumbers');

export const generateTickets = createAction(ACTIONS.GENERATE_TICKETS);
export const generateTicketsSuccess = createAction(ACTIONS.GENERATE_TICKETS_SUCCESS, 'payload');
export const generateTicketsError = createAction(ACTIONS.GENERATE_TICKETS_ERROR, 'errorMessage', 'error');
export const setShowPickTicketsPopup = createAction(ACTIONS.SET_SHOW_POPUP, 'showPickTicketsPopup');
export const setShowPickTicketGeneratorModal = createAction(ACTIONS.SET_SHOW_PICK_TICKET_GENERATOR_MODAL, 'showPickTicketGeneratorModal');
export const resetPickTicketGeneratorState = createAction(ACTIONS.RESET_STATE);