import React from 'react';
import PropTypes from 'prop-types';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CrashBoundary from 'components/CrashBoundary';
import Routes from './routes';
import { AppWrapper } from './styles';
import { DEV_MODE } from './config';
import FeatureFlagsControl from './featureFlags/featureFlagsControl';

const App = ({ isDrawerOpen }) => (
  <CrashBoundary>
    <AppWrapper blockScroll={isDrawerOpen} data-testid="app-wrapper">
      <ToastContainer />
      <Routes />
    </AppWrapper>
    {DEV_MODE && <FeatureFlagsControl />}
  </CrashBoundary>
);

App.propTypes = {
  isDrawerOpen: PropTypes.bool,
};

App.defaultProps = {
  isDrawerOpen: false,
};

export default App;
