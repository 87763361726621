export const FEATURE_FLAGS = {
  APP_BRANDING: 'app_branding',
  ACCOUNT_SETTINGS_BUSINESS: 'account_settings__business',
  ACCOUNT_SETTINGS_INTEGRATIONS: 'account_settings__integrations',
  INVENTORY_MOVE_MOBILE_VERSION: 'inventory_move__mobile_version',
  INVENTORY_PICKING_MOBILE_VERSION: 'inventory_picking__mobile_version',
  ORDER_VIEW: 'order_view',
  PURCHASE_ORDER_MANAGEMENT_PURCHASE_ORDERS: 'purchase_order_management__purchase_orders',
  PRODUCT_MANAGEMENT: 'product_management',
  PRODUCT_MANAGEMENT_BRANDS_SUPPLIERS: 'product_management__brands_suppliers',
  PRODUCT_MANAGEMENT_PRODUCTS: 'product_management__products',
  PRODUCT_MANAGEMENT_PRODUCT_CREATOR: 'product_management__product_creator',
  REPORTS_INVENTORY_LOCATIONS_CHARTS: 'reports__inventory_locations_charts',
};