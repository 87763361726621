import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { FaSortUp, FaSortDown, FaSort } from 'react-icons/fa';
import { RiQuestionFill } from 'react-icons/ri';
import Box from '@material-ui/core/Box';
import IconButton, { circleButtonColors, circleButtonSizes } from 'components/CircleButton';
import Skeleton from '../Skeleton';
import { GridColumnWrap } from './styles';

export const GRID_COLUMN_SORT = {
  ASC: 'asc',
  DESC: 'desc',
};

const sortIcons = {
  [GRID_COLUMN_SORT.ASC]: FaSortUp,
  [GRID_COLUMN_SORT.DESC]: FaSortDown,
};

const GridColumn = memo(({
  children,
  forwardedRef,
  onSort,
  sortable,
  sorting,
  sortingDisabled,
  tooltip,
  ...styleProps
}) => {
  const { isLoading, columnHead } = styleProps;
  const SortIcon = sortIcons[sorting] || FaSort;
  const nextSortingDirection = sorting === GRID_COLUMN_SORT.ASC
    ? GRID_COLUMN_SORT.DESC
    : GRID_COLUMN_SORT.ASC;

  return (
    <GridColumnWrap ref={forwardedRef} {...styleProps}>
      <Box width="100%" display="flex" flexDirection="column">
        <Box mr={1} display="flex" alignItems="center">
          {children}
          {tooltip && columnHead && (
            <Box mx={1} display="flex" alignItems="center"><RiQuestionFill size="1rem" /></Box>
          )}
        </Box>
        {isLoading && !columnHead && <Skeleton />}
        {sortable && columnHead && (
          <IconButton
            color={circleButtonColors.ghost}
            size={circleButtonSizes.small}
            onClick={() => onSort(nextSortingDirection)}
            disabled={sortingDisabled}
            loading={sorting && isLoading}
          >
            <SortIcon />
          </IconButton>
        )}
      </Box>
    </GridColumnWrap>
  );
});

GridColumn.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  columnHead: PropTypes.bool,
  forwardedRef: PropTypes.func,
  onSort: PropTypes.func,
  sortable: PropTypes.bool,
  sorting: PropTypes.oneOf([...Object.values(GRID_COLUMN_SORT), false]),
  sortingDisabled: PropTypes.bool,
  tooltip: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

GridColumn.defaultProps = {
  children: null,
  columnHead: false,
  forwardedRef() {},
  onSort() {},
  sortable: false,
  sorting: null,
  sortingDisabled: false,
  tooltip: null,
};

export default GridColumn;