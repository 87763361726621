import _reduce from 'lodash/reduce';

export const formatSortByToObject = (columnId, direction) => {
  if (!columnId || !direction) {
    return null;
  }
  return { [columnId]: direction === 'desc' ? -1 : 1 };
};

export const formatFilterToObject = (operands = []) => {
  const formattedOperands2 = _reduce(operands, (result, group) => {
    if (group.operands.length === 1) {
      result.push(group.operands[0]);
    } else {
      result.push(group);
    }

    return result;
  }, []);

  const filterFormat = {
    operator: 'AND',
    operands: formattedOperands2,
  };

  return filterFormat;
};