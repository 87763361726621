import {
  put, all, takeEvery, takeLatest, call, select,
} from 'redux-saga/effects';
import { feedsApiSelector } from 'app/api/selectors';
import { getErrorMessage } from 'utils/error';
import { authRequired } from 'services/auth/sagas';
import { ACTIONS } from './constants';

function* fetchProductInfoWatcher({ upcOrSku, successActionCallback, errorActionCallback }) {
  const api = yield select(feedsApiSelector);
  try {
    const { data } = yield call([api, api.productInfo], upcOrSku);

    if (successActionCallback) {
      yield put(successActionCallback(data));
    }
    return data;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    if (errorActionCallback) {
      yield put(errorActionCallback(errorMessage, error));
    }
    return error;
  }
}

function* fetchShipCitiesWatcher({ zipCode, successActionCallback, errorActionCallback }) {
  const api = yield select(feedsApiSelector);
  try {
    const { data } = yield call([api, api.shipCities], zipCode);

    if (successActionCallback) {
      yield put(successActionCallback(data));
    }
    return data;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    if (errorActionCallback) {
      yield put(errorActionCallback(errorMessage, error));
    }
    return error;
  }
}

function* fetchSearchOrderLineIdsWatcher({
  guidOrderLine, successActionCallback, errorActionCallback,
}) {
  const api = yield select(feedsApiSelector);
  try {
    const { data } = yield call([api, api.searchOrderLineIds], guidOrderLine);

    if (successActionCallback) {
      yield put(successActionCallback(data));
    }
    return data;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    if (errorActionCallback) {
      yield put(errorActionCallback(errorMessage, error));
    }
    return error;
  }
}

function* fetchAdjustedInventoryWatcher({ type, successActionCallback, errorActionCallback }) {
  const format = type === ACTIONS.FETCH_ADJUSTED_INVENTORY_CSV ? '.csv' : '';
  const api = yield select(feedsApiSelector);
  try {
    const response = yield call([api, api.adjustedInventory], format);
    const result = response.data ? response.data : response;

    if (successActionCallback) {
      yield put(successActionCallback(result));
    }

    return result;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    if (errorActionCallback) {
      yield put(errorActionCallback(errorMessage, error));
    }

    return error;
  }
}

function* fetchAdjustedInventoryCostWatcher({
  type, successActionCallback, errorActionCallback,
}) {
  const format = type === ACTIONS.FETCH_ADJUSTED_INVENTORY_COST_CSV ? '.csv' : '';
  const api = yield select(feedsApiSelector);
  try {
    const response = yield call([api, api.adjustedInventoryCost], format);
    const result = response.data ? response.data : response;

    if (successActionCallback) {
      yield put(successActionCallback(result));
    }

    return result;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    if (errorActionCallback) {
      yield put(errorActionCallback(errorMessage, error));
    }

    return error;
  }
}

function* fetchInventoryLocationsWatcher({ type, successActionCallback, errorActionCallback }) {
  const format = type === ACTIONS.FETCH_INVENTORY_LOCATIONS_CSV ? '.csv' : '';
  const api = yield select(feedsApiSelector);
  try {
    const response = yield call([api, api.inventoryLocations], format);
    const result = response.data ? response.data : response;

    if (successActionCallback) {
      yield put(successActionCallback(result));
    }

    return result;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    if (errorActionCallback) {
      yield put(errorActionCallback(errorMessage, error));
    }

    return error;
  }
}

function* fetchInventoryQtysWatcher({ type, successActionCallback, errorActionCallback }) {
  const format = type === ACTIONS.FETCH_INVENTORY_QTYS_CSV ? '.csv' : '';
  const api = yield select(feedsApiSelector);
  try {
    const response = yield call([api, api.inventoryQtys], format);
    const result = response.data ? response.data : response;

    if (successActionCallback) {
      yield put(successActionCallback(result));
    }

    return result;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    if (errorActionCallback) {
      yield put(errorActionCallback(errorMessage, error));
    }

    return error;
  }
}

function* fetchSalesReportWatcher({
  type, params, successActionCallback, errorActionCallback,
}) {
  const format = type === ACTIONS.FETCH_SALES_REPORT_CSV ? '.csv' : '';
  const api = yield select(feedsApiSelector);
  try {
    const response = yield call([api, api.salesReport], format, params);
    const result = response.data ? response.data : response;

    if (successActionCallback) {
      yield put(successActionCallback(result));
    }

    return result;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    if (errorActionCallback) {
      yield put(errorActionCallback(errorMessage, error));
    }

    return error;
  }
}

function* fetchSalesReportSearchWatcher({
  params, successActionCallback, errorActionCallback,
}) {
  const api = yield select(feedsApiSelector);
  try {
    const response = yield call([api, api.salesReportSearch], params);
    const result = response.data ? response.data : response;

    if (successActionCallback) {
      yield put(successActionCallback(result));
    }

    return result;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    if (errorActionCallback) {
      yield put(errorActionCallback(errorMessage, error));
    }

    return error;
  }
}

export default function* feedsSagas() {
  yield all([
    takeLatest(ACTIONS.FETCH_PRODUCT_INFO, authRequired, fetchProductInfoWatcher),
    takeEvery(ACTIONS.FETCH_SHIP_CITIES, authRequired, fetchShipCitiesWatcher),
    takeEvery(ACTIONS.FETCH_SEARCH_ORDER_LINE_IDS, authRequired, fetchSearchOrderLineIdsWatcher),
    takeEvery([
      ACTIONS.FETCH_ADJUSTED_INVENTORY,
      ACTIONS.FETCH_ADJUSTED_INVENTORY_CSV,
    ],
    authRequired,
    fetchAdjustedInventoryWatcher),
    takeEvery([
      ACTIONS.FETCH_ADJUSTED_INVENTORY_COST,
      ACTIONS.FETCH_ADJUSTED_INVENTORY_COST_CSV,
    ],
    authRequired, fetchAdjustedInventoryCostWatcher),
    takeEvery([
      ACTIONS.FETCH_INVENTORY_LOCATIONS,
      ACTIONS.FETCH_INVENTORY_LOCATIONS_CSV,
    ],
    authRequired, fetchInventoryLocationsWatcher),
    takeEvery([
      ACTIONS.FETCH_INVENTORY_QTYS,
      ACTIONS.FETCH_INVENTORY_QTYS_CSV,
    ],
    authRequired, fetchInventoryQtysWatcher),
    takeEvery([
      ACTIONS.FETCH_SALES_REPORT,
      ACTIONS.FETCH_SALES_REPORT_CSV,
    ],
    authRequired, fetchSalesReportWatcher),
    takeEvery(ACTIONS.FETCH_SALES_REPORT_SEARCH, authRequired, fetchSalesReportSearchWatcher),
  ]);
}