import _isEmpty from 'lodash/isEmpty';
import _cloneDeep from 'lodash/cloneDeep';
import _reduce from 'lodash/reduce';
import { pickFormValuesFromProps } from 'utils/form';
import { FILTER_GROUP_ID_PREFIX } from 'features/components/FilterChipsRow/constants';

export const getFieldErrorMessage = (errorsList, fieldId) => {
  if (_isEmpty(errorsList) || _isEmpty(fieldId)) {
    return null;
  }
  return errorsList[fieldId] && errorsList[fieldId].message;
};

export const formatFieldError = (fieldId, message) => ({
  [fieldId]: {
    error: true,
    message,
  },
});

const formatNewFilterGroup = operands => ({
  operator: 'OR',
  operands,
});

export const getArrangedFilters = (destination, source, filters) => {
  if (!destination || !source) {
    return filters;
  }

  const destinationIndex = destination.droppableId.replace(FILTER_GROUP_ID_PREFIX, '');
  const destinationPosition = destination.index;
  const sourceIndex = source.droppableId.replace(FILTER_GROUP_ID_PREFIX, '');
  const sourcePosition = source.index;

  if (destinationIndex === sourceIndex) {
    return filters;
  }

  const updatedFilters = _cloneDeep(filters);
  const sourceFilter = updatedFilters[sourceIndex].operands[sourcePosition];
  const destinationFilterGroup = updatedFilters[destinationIndex];
  const sourceFilterGroup = updatedFilters[sourceIndex];
  if (!destinationFilterGroup) {
    updatedFilters.push(formatNewFilterGroup([sourceFilter]));
  } else {
    destinationFilterGroup.operands.splice(destinationPosition, 0, sourceFilter);
  }

  sourceFilterGroup.operands.splice(sourcePosition, 1);
  if (_isEmpty(sourceFilterGroup.operands)) {
    updatedFilters.splice(sourceIndex, 1);
  }

  return updatedFilters;
};

export const removeFilter = (groupIndex, filterIndex, filters) => {
  const updatedFilters = _cloneDeep(filters);
  const filterGroup = updatedFilters[groupIndex];

  if (!filterGroup) {
    return filters;
  }

  filterGroup.operands.splice(filterIndex, 1);

  if (_isEmpty(filterGroup.operands)) {
    updatedFilters.splice(groupIndex, 1);
  }

  return updatedFilters;
};

export const addFilter = (filter, filters) => {
  const updatedFilters = _cloneDeep(filters);
  updatedFilters.push(formatNewFilterGroup([filter]));
  return updatedFilters;
};

export const getRequiredFormFields = (
  fieldsArray, formValues,
) => pickFormValuesFromProps(fieldsArray)(formValues);

export const validateRequiredFields = (fieldsArray, formValues) => {
  const requiredFieldValues = getRequiredFormFields(fieldsArray, formValues);
  const errors = _reduce(requiredFieldValues, (result, field, key) => {
    if (_isEmpty(String(field))) {
      const fieldError = formatFieldError(key, 'Please enter a value.');
      return {
        ...result,
        ...fieldError,
      };
    }
    return result;
  }, {});

  return errors;
};