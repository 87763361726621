export const ACTIONS = {
  FETCH_AUTH_TEST: 'AUTH_SERVICE__FETCH_AUTH_TEST',
  FETCH_AUTH_TEST_SUCCESS: 'AUTH_SERVICE__FETCH_AUTH_TEST_SUCCESS',
  FETCH_AUTH_TEST_ERROR: 'AUTH_SERVICE__FETCH_AUTH_TEST_ERROR',
  SET_LOGIN: 'AUTH_SERVICE__SET_LOGIN',
  SET_LOGIN_SUCCESS: 'AUTH_SERVICE__SET_LOGIN_SUCCESS',
  SET_LOGIN_ERROR: 'AUTH_SERVICE__SET_LOGIN_ERROR',
  SET_LOGOUT: 'AUTH_SERVICE__SET_LOGOUT',
  SET_LOGOUT_SUCCESS: 'AUTH_SERVICE__SET_LOGOUT_SUCCESS',
  SET_LOGOUT_ERROR: 'AUTH_SERVICE__SET_LOGOUT_ERROR',
  SET_FORGOT_PASSWORD: 'AUTH_SERVICE__SET_FORGOT_PASSWORD',
  SET_VERIFY_PASSWORD_RESET: 'AUTH_SERVICE__SET_VERIFY_PASSWORD_RESET',
  SET_CHANGE_TEMPORARY_PASSWORD: 'AUTH_SERVICE__SET_CHANGE_TEMPORARY_PASSWORD',
};

export const AUTH_FLAGS = {
  PASSWORD_CHANGE_REQUIRED: 'password_change_required',
};

export const AUTH_ERRORS = {
  NOT_AUTHORIZED: 'Not authorized',
};