import _get from 'lodash/get';
import { createReducer } from 'redux-create-reducer';
import { ACTIONS } from '../constants';

export const INITIAL_STATE = {
  selectedItem: null,
  showModal: false,
  showDeleteModal: false,
  isSubmitting: false,
  isRemoving: false,
  errorMessage: null,
};

const updateSelectedItem = (state, { selectedItem }) => ({
  ...state,
  selectedItem,
});

const updateShowModal = (state, { showModal }) => ({
  ...state,
  showModal,
});

const updateShowDeleteModal = (state, { showDeleteModal }) => ({
  ...state,
  showDeleteModal,
});

const setIsRemoving = (state, isRemoving) => ({
  ...state,
  isRemoving,
});

const setIsSubmitting = (state, isSubmitting) => ({
  ...state,
  isSubmitting,
});

const setRemoveSuccess = state => ({
  ...state,
  isRemoving: INITIAL_STATE.isRemoving,
  showDeleteModal: INITIAL_STATE.showDeleteModal,
});

const setCreating = state => ({
  ...state,
  isSubmitting: true,
  errorMessage: INITIAL_STATE.errorMessage,
});

const setCreateSuccess = state => ({
  ...state,
  isSubmitting: INITIAL_STATE.isSubmitting,
  showModal: INITIAL_STATE.showModal,
});

const setCreateError = (state, { errorMessage }) => ({
  ...state,
  isSubmitting: INITIAL_STATE.isCreating,
  errorMessage: _get(JSON.parse(errorMessage), 'errors'),
});

const setEditSuccess = state => ({
  ...state,
  isSubmitting: INITIAL_STATE.isSubmitting,
  showModal: INITIAL_STATE.showModal,
});

export const resetState = () => ({
  ...INITIAL_STATE,
});

export const handlers = {
  [ACTIONS.SET_SELECTED_ITEM]: updateSelectedItem,
  [ACTIONS.SHOW_BRAND_SUPPLIER_MODAL]: updateShowModal,
  [ACTIONS.SHOW_DELETE_BRAND_SUPPLIER_MODAL]: updateShowDeleteModal,

  [ACTIONS.REMOVE_BRAND_SUPPLIER]: state => setIsRemoving(state, true),
  [ACTIONS.REMOVE_BRAND_SUPPLIER_ERROR]: state => setIsRemoving(state, false),
  [ACTIONS.REMOVE_BRAND_SUPPLIER_SUCCESS]: setRemoveSuccess,

  [ACTIONS.CREATE_BRAND_SUPPLIER]: setCreating,
  [ACTIONS.CREATE_BRAND_SUPPLIER_SUCCESS]: setCreateSuccess,
  [ACTIONS.CREATE_BRAND_SUPPLIER_ERROR]: setCreateError,

  [ACTIONS.EDIT_BRAND_SUPPLIER]: state => setIsSubmitting(state, true),
  [ACTIONS.EDIT_BRAND_SUPPLIER_SUCCESS]: setEditSuccess,
  [ACTIONS.EDIT_BRAND_SUPPLIER_ERROR]: state => setIsSubmitting(state, false),

  [ACTIONS.RESET_BRAND_SUPPLIER_MANAGEMENT_STATE]: resetState,
};

export default createReducer(INITIAL_STATE, handlers);