import { createAction } from 'redux-create-action';
import { ACTIONS } from './constants';

export const initContainer = createAction(ACTIONS.INIT_CONTAINER);
export const exitContainer = createAction(ACTIONS.EXIT_CONTAINER);

export const loadChannels = createAction(ACTIONS.LOAD_CHANNELS);
export const loadChannelsSuccess = createAction(ACTIONS.LOAD_CHANNELS_SUCCESS, 'payload');
export const loadChannelsError = createAction(ACTIONS.LOAD_CHANNELS_ERROR, 'errorMessage', 'error');

export const loadIntegrations = createAction(ACTIONS.LOAD_INTEGRATIONS);
export const loadIntegrationsSuccess = createAction(ACTIONS.LOAD_INTEGRATIONS_SUCCESS, 'payload');
export const loadIntegrationsError = createAction(ACTIONS.LOAD_INTEGRATIONS_ERROR, 'errorMessage', 'error');

export const setShowAddIntegrationModal = createAction(ACTIONS.SET_SHOW_ADD_INTEGRATION_MODAL, 'showAddIntegrationModal');
export const setShowModifyIntegrationModal = createAction(ACTIONS.SET_SHOW_MODIFY_INTEGRATION_MODAL, 'showModifyIntegrationModal');
export const setShowDeleteIntegrationModal = createAction(ACTIONS.SET_SHOW_DELETE_INTEGRATION_MODAL, 'showDeleteIntegrationModal');

export const setAddIntegrationId = createAction(ACTIONS.SET_ADD_INTEGRATION_ID, 'addIntegrationId');
export const setModifyIntegrationId = createAction(ACTIONS.SET_MODIFY_INTEGRATION_ID, 'modifyIntegrationId');
export const setDeleteIntegrationId = createAction(ACTIONS.SET_DELETE_INTEGRATION_ID, 'deleteIntegrationId');

export const resetState = createAction(ACTIONS.RESET_STATE);