import React from 'react';
import PropTypes from 'prop-types';
import { SpinnerCentered, spinnerThickness } from 'components/Spinner';
import Box from '@material-ui/core/Box';
import {
  ButtonStyled, buttonColors, buttonSizes, ButtonGroup,
} from './styles';

const Button = ({
  children,
  color,
  size,
  fullWidth,
  loading,
  disabled,
  startIcon,
  endIcon,
  ...restProps
}) => (
  <ButtonStyled
    color={color}
    size={size}
    fullWidth={fullWidth}
    disabled={disabled || loading}
    isLoading={loading}
    {...restProps}
  >
    {startIcon && (
      <Box mr={1}>{startIcon}</Box>
    )}
    {children}
    {endIcon && (
      <Box ml={1}>{endIcon}</Box>
    )}
    {loading && (<SpinnerCentered thickness={spinnerThickness.medium} />)}
  </ButtonStyled>
);

Button.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.oneOf(Object.keys(buttonColors)),
  size: PropTypes.oneOf(Object.keys(buttonSizes)),
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
};

Button.defaultProps = {
  color: buttonColors.default,
  size: buttonSizes.medium,
  fullWidth: false,
  disabled: false,
  loading: false,
  startIcon: null,
  endIcon: null,
};

export default Button;
export { buttonColors, buttonSizes, ButtonGroup };
