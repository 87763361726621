import { createAction } from 'redux-create-action';
import { ACTIONS } from './constants';

export const setImportFile = createAction(ACTIONS.SET_IMPORT_FILE, 'file', 'successActionCallback', 'errorActionCallback');
export const setValidateFile = createAction(ACTIONS.SET_VALIDATE_FILE, 'file', 'successActionCallback', 'errorActionCallback');
export const fetchImportTemplate = createAction(ACTIONS.FETCH_IMPORT_TEMPLATE, 'successActionCallback', 'errorActionCallback');

export const fetchPurchaseOrders = createAction(ACTIONS.FETCH_PURCHASE_ORDERS, 'params', 'successActionCallback', 'errorActionCallback');
export const fetchPurchaseOrdersCSV = createAction(ACTIONS.FETCH_PURCHASE_ORDERS_CSV, 'params', 'successActionCallback', 'errorActionCallback');

export const setRemovePurchaseOrder = createAction(ACTIONS.SET_REMOVE_PURCHASE_ORDER, 'purchaseOrderNumber', 'successActionCallback', 'errorActionCallback');
export const setUpdatePurchaseOrder = createAction(ACTIONS.SET_UPDATE_PURCHASE_ORDER, 'purchaseOrderData', 'successActionCallback', 'errorActionCallback');