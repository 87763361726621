import { createAction } from 'redux-create-action';
import { ACTIONS } from './constants';

export const fetchPermissions = createAction(ACTIONS.FETCH_PERMISSIONS, 'successActionCallback', 'errorActionCallback');
export const fetchPermissionsSuccess = createAction(ACTIONS.FETCH_PERMISSIONS_SUCCESS, 'payload');
export const fetchPermissionsError = createAction(ACTIONS.FETCH_PERMISSIONS_ERROR, 'errorMessage', 'error');

export const fetchUsers = createAction(ACTIONS.FETCH_USERS, 'successActionCallback', 'errorActionCallback');
export const fetchCheckUsernameAvailability = createAction(ACTIONS.FETCH_CHECK_USERNAME_AVAILABILITY, 'username', 'successActionCallback', 'errorActionCallback');
export const fetchRoles = createAction(ACTIONS.FETCH_ROLES, 'successActionCallback', 'errorActionCallback');

export const setAddUser = createAction(ACTIONS.SET_ADD_USER, 'userData', 'successActionCallback', 'errorActionCallback');
export const setDeleteUser = createAction(ACTIONS.SET_DELETE_USER, 'username', 'successActionCallback', 'errorActionCallback');
export const setEditUser = createAction(ACTIONS.SET_EDIT_USER, 'username', 'userData', 'successActionCallback', 'errorActionCallback');
export const setResetUserPassword = createAction(ACTIONS.SET_RESET_USER_PASSWORD, 'username', 'successActionCallback', 'errorActionCallback');
export const setResendInvite = createAction(ACTIONS.SET_RESEND_INVITE, 'username', 'successActionCallback', 'errorActionCallback');
export const setAddRole = createAction(ACTIONS.SET_ADD_ROLE, 'params', 'successActionCallback', 'errorActionCallback');
export const setEditRole = createAction(ACTIONS.SET_EDIT_ROLE, 'params', 'successActionCallback', 'errorActionCallback');
export const setDeleteRole = createAction(ACTIONS.SET_DELETE_ROLE, 'params', 'successActionCallback', 'errorActionCallback');