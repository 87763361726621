import {
  FaRegFilePowerpoint,
  FaQrcode,
  FaBoxes,
} from 'react-icons/fa';
import { ImBarcode } from 'react-icons/im';
import ROUTES from 'constants/routes';

export const ACTIONS = {
  INIT_CONTAINER: 'TOOLS__INIT_CONTAINER',
  EXIT_CONTAINER: 'TOOLS__EXIT_CONTAINER',
  RESET_FEATURE_REDUCERS: 'TOOLS__RESET_FEATURE_REDUCERS',
};

export const TOOLS_SECTIONS = {
  PICK_TICKET_GENERATOR: {
    id: 'pick_ticket_generator',
    title: 'Pick Ticket Generator',
    route: ROUTES.TOOLS.PICK_TICKET_GENERATOR,
    icon: FaRegFilePowerpoint,
    disabled: false,
  },
  INVENTORY_LABEL_GENERATOR: {
    id: 'inventory_label_generator',
    title: 'Inventory Label Generator',
    route: ROUTES.TOOLS.INVENTORY_LABEL_GENERATOR,
    icon: FaQrcode,
    disabled: false,
  },
  UPC_LABEL_GENERATOR: {
    id: 'upc_label_generator',
    title: 'UPC Label Generator',
    route: ROUTES.TOOLS.UPC_LABEL_GENERATOR,
    icon: ImBarcode,
    disabled: false,
  },
  BULK_MARK_SHIP: {
    id: 'bulk_mark_ship',
    title: 'Bulk Mark Ship',
    route: ROUTES.TOOLS.BULK_MARK_SHIP,
    icon: FaBoxes,
    disabled: false,
  },
  /* FULFILLMENT_WAREHOUSE: {
    id: 'fulfillment_warehouse',
    title: 'Set Fulfillment Warehouse',
    route: ROUTES.ORDER_MANAGEMENT.ADD_ORDER,
    icon: FaRegFileWord,
    disabled: true,
  }, */
};