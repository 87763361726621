import _isNaN from 'lodash/isNaN';
import _isEmpty from 'lodash/isEmpty';
import _isNumber from 'lodash/isNumber';
import { numberWithCommas } from '../formatNumber';

const currencySymbol = {
  USD: '$',
  EUR: '€',
  GBP: '£',
  JPY: '¥',
};

const formatMoney = (amount, currency = 'USD', otherOptions = {}) => {
  const amountNumber = Number(amount);

  if ((!_isNumber(amount) && _isEmpty(amount)) || _isNaN(amountNumber)) {
    return null;
  }

  const options = {
    decimals: 2,
    ...otherOptions,
  };

  const amountWithDecimals = parseFloat(amountNumber).toFixed(options.decimals);
  const amountWithCommas = numberWithCommas(amountWithDecimals);
  const defaultCurrency = currency ? ` ${currency}` : '';
  const moneySymbol = currencySymbol[currency] || '';

  return `${moneySymbol}${amountWithCommas}${!moneySymbol ? defaultCurrency : ''}`;
};

export default formatMoney;
