export const ACTIONS = {
  DOWNLOAD_SAMPLE: 'UPC_LABEL_GENERATOR__DOWNLOAD_SAMPLE',
  DOWNLOAD_SAMPLE_SUCCESS: 'UPC_LABEL_GENERATOR__DOWNLOAD_SAMPLE_SUCCESS',
  DOWNLOAD_SAMPLE_ERROR: 'UPC_LABEL_GENERATOR__DOWNLOAD_SAMPLE_ERROR',

  UPLOAD_FILE: 'UPC_LABEL_GENERATOR__UPLOAD_FILE',
  UPLOAD_FILE_SUCCESS: 'UPC_LABEL_GENERATOR__UPLOAD_FILE_SUCCESS',
  UPLOAD_FILE_ERROR: 'UPC_LABEL_GENERATOR__UPLOAD_FILE_ERROR',

  RESET_STATE: 'UPC_LABEL_GENERATOR__RESET_STATE',
};

export const UPC_LABEL_GENERATOR_SAMPLE_FILENAME = 'upc_label_sample.csv';