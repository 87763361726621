import {
  all, takeEvery, put, call, select,
} from 'redux-saga/effects';
import _isEmpty from 'lodash/isEmpty';
import _reject from 'lodash/reject';
import _findIndex from 'lodash/findIndex';
import _find from 'lodash/find';
import _isNil from 'lodash/isNil';
import _cloneDeep from 'lodash/cloneDeep';
import _map from 'lodash/map';
import { toast } from 'react-toastify';
import { fetchProductInfo } from 'services/feeds/actions';
import { ACTIONS, ADD_ORDER_ERRORS } from '../constants';
import {
  scanItemSuccess,
  scanItemError,
  setItem,
  setOrderItems,
  setItemQty,
} from '../actions';
import { getOrderItems } from '../selectors';
import {
  applyDiscountToPrice,
  getDiscountOfPrice,
  isEmptyQty,
  getTaxValueOfPrice,
  getPriceWithTax,
} from '../helpers';

function* scanItemWatcher({ upcOrSku }) {
  yield put(fetchProductInfo(upcOrSku, scanItemSuccess, scanItemError));
}

function* scanItemSuccessWatcher({ payload }) {
  const orderItems = yield select(getOrderItems);
  const { search_results: results } = payload;
  if (_isEmpty(results)) {
    yield put(scanItemError(ADD_ORDER_ERRORS.ITEM_NOT_FOUND, null));
    return;
  }
  const item = results[0];

  const existingItem = _find(orderItems, { sku: item.sku, upc: item.upc });

  if (existingItem) {
    const { qty_ordered: qtyOrdered, sku, upc } = existingItem;
    const newQty = String(Number(qtyOrdered) + 1);
    yield put(setItemQty(sku, upc, newQty));
    return;
  }

  yield put(setItem(item));
}

function* scanItemErrorWatcher({ errorMessage }) {
  yield call([toast, toast.error], errorMessage);
}

function* removeItemWatcher({ sku, upc }) {
  const orderItems = yield select(getOrderItems);
  const updatedOrderItems = _reject(orderItems, { sku, upc });

  yield put(setOrderItems(updatedOrderItems));
}

function* findItemIndex(sku, upc) {
  const orderItems = yield select(getOrderItems);
  return _findIndex(orderItems, { sku, upc });
}

function* cloneOrderItems() {
  const orderItems = yield select(getOrderItems);
  return _cloneDeep(orderItems);
}

function* setItemQtyWatcher({ sku, upc, qty }) {
  const itemIndex = yield call(findItemIndex, sku, upc);

  if (_isNil(itemIndex)) {
    return;
  }

  const updatedOrderItems = yield call(cloneOrderItems);
  const {
    original_price: originalPrice,
    sales_tax_percentage: taxPercentage,
    discount_percentage: discount,
  } = updatedOrderItems[itemIndex];

  const emptyQty = isEmptyQty(qty);
  const newSubtotal = emptyQty
    ? '0.00'
    : applyDiscountToPrice(originalPrice, qty, discount);

  const newTaxValue = getTaxValueOfPrice(taxPercentage, newSubtotal);

  updatedOrderItems[itemIndex].qty_ordered = qty;
  updatedOrderItems[itemIndex].price = newSubtotal;
  updatedOrderItems[itemIndex].price_with_tax = getPriceWithTax(newSubtotal, newTaxValue);
  updatedOrderItems[itemIndex].sales_tax_amount = newTaxValue;

  yield put(setOrderItems(updatedOrderItems));
}

function* setItemDiscountWatcher({ sku, upc, discount }) {
  const itemIndex = yield call(findItemIndex, sku, upc);

  if (_isNil(itemIndex)) {
    return;
  }

  const updatedOrderItems = yield call(cloneOrderItems);
  const {
    original_price: originalPrice,
    qty_ordered: qtyOrdered,
    sales_tax_percentage: taxPercentage,
  } = updatedOrderItems[itemIndex];

  const newDiscount = discount || '0.00';
  const newPrice = Number(qtyOrdered) === 0
    ? '0'
    : applyDiscountToPrice(originalPrice, qtyOrdered, newDiscount);
  const newTaxValue = getTaxValueOfPrice(taxPercentage, newPrice);

  updatedOrderItems[itemIndex].discount_percentage = discount;
  updatedOrderItems[itemIndex].price_with_tax = getPriceWithTax(newPrice, newTaxValue);
  updatedOrderItems[itemIndex].price = newPrice;
  updatedOrderItems[itemIndex].sales_tax_amount = newTaxValue;

  yield put(setOrderItems(updatedOrderItems));
}

function* setItemSubtotalWatcher({ sku, upc, subtotal }) {
  const itemIndex = yield call(findItemIndex, sku, upc);

  if (_isNil(itemIndex)) {
    return;
  }

  const updatedOrderItems = yield call(cloneOrderItems);
  const {
    original_price: originalPrice,
    qty_ordered: qtyOrdered,
    discount_percentage: discount,
    sales_tax_percentage: taxPercentage,
  } = updatedOrderItems[itemIndex];

  const newSubtotal = subtotal || '0.00';
  const newTaxValue = getTaxValueOfPrice(taxPercentage, newSubtotal);
  const newDiscount = Number(qtyOrdered) === 0
    ? (discount || '0.00')
    : getDiscountOfPrice(originalPrice, subtotal, qtyOrdered);

  updatedOrderItems[itemIndex].price = subtotal;
  updatedOrderItems[itemIndex].price_with_tax = getPriceWithTax(newSubtotal, newTaxValue);
  updatedOrderItems[itemIndex].discount_percentage = newDiscount;
  updatedOrderItems[itemIndex].sales_tax_amount = newTaxValue;

  yield put(setOrderItems(updatedOrderItems));
}

function* setItemTaxPercentageWatcher({ sku, upc, taxPercentage }) {
  const itemIndex = yield call(findItemIndex, sku, upc);

  if (_isNil(itemIndex)) {
    return;
  }

  const updatedOrderItems = yield call(cloneOrderItems);
  const {
    price,
  } = updatedOrderItems[itemIndex];

  const newTaxPercentage = taxPercentage || '0.00';
  const taxValue = getTaxValueOfPrice(newTaxPercentage, price);

  updatedOrderItems[itemIndex].price_with_tax = getPriceWithTax(price, taxValue);
  updatedOrderItems[itemIndex].sales_tax_percentage = taxPercentage;
  updatedOrderItems[itemIndex].sales_tax_amount = taxValue;

  yield put(setOrderItems(updatedOrderItems));
}

function* setAllItemsTaxPercentageWatcher({ taxPercentage }) {
  const orderItems = yield select(getOrderItems);
  const updatedOrderItems = _map(orderItems, item => {
    const newItem = { ...item };
    const { price } = newItem;
    const taxValue = getTaxValueOfPrice(taxPercentage, price);

    newItem.price_with_tax = getPriceWithTax(price, taxValue);
    newItem.sales_tax_percentage = taxPercentage;
    newItem.sales_tax_amount = taxValue;
    return newItem;
  });

  yield put(setOrderItems(updatedOrderItems));
}

export default function* addItemsSagas() {
  yield all([
    takeEvery(ACTIONS.SCAN_ITEM, scanItemWatcher),
    takeEvery(ACTIONS.SCAN_ITEM_SUCCESS, scanItemSuccessWatcher),
    takeEvery(ACTIONS.SCAN_ITEM_ERROR, scanItemErrorWatcher),

    takeEvery(ACTIONS.REMOVE_ITEM, removeItemWatcher),
    takeEvery(ACTIONS.SET_ITEM_QTY, setItemQtyWatcher),
    takeEvery(ACTIONS.SET_ITEM_DISCOUNT, setItemDiscountWatcher),
    takeEvery(ACTIONS.SET_ITEM_SUBTOTAL, setItemSubtotalWatcher),
    takeEvery(ACTIONS.SET_ITEM_TAX_PERCENTAGE, setItemTaxPercentageWatcher),
    takeEvery(ACTIONS.SET_ALL_ITEMS_TAX_PERCENTAGE, setAllItemsTaxPercentageWatcher),
  ]);
}