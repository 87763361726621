import {
  all, takeEvery, put, fork,
} from 'redux-saga/effects';
import { APP_FEATURES } from 'constants/global';
import { setFeatureName } from 'containers/Dashboard/actions';
import profileSagas from './Profile/sagas';
import usersManagementSagas from './UsersManagement/sagas';
import businessSagas from './Business/sagas';
import shippingRulesSagas from './ShippingRules/sagas';
import integrationsSagas from './Integrations/sagas';
import rolesManagementSagas from './RolesManagement/sagas';
import { ACTIONS } from './constants';
import { resetFeatureReducers } from './actions';

function* initContainerWatcher() {
  yield put(setFeatureName(APP_FEATURES.ACCOUNT_MANAGEMENT.title));
}

function* exitContainerWatcher() {
  yield put(setFeatureName());
  yield put(resetFeatureReducers());
}

export default function* accountSettingsSagas(...args) {
  yield all([
    takeEvery(ACTIONS.INIT_CONTAINER, initContainerWatcher),
    takeEvery(ACTIONS.EXIT_CONTAINER, exitContainerWatcher),
    fork(profileSagas, ...args),
    fork(usersManagementSagas, ...args),
    fork(businessSagas, ...args),
    fork(shippingRulesSagas, ...args),
    fork(integrationsSagas, ...args),
    fork(rolesManagementSagas, ...args),
  ]);
}
