import { createAction } from 'redux-create-action';
import { ACTIONS } from './constants';

export const scanSalesOrder = createAction(ACTIONS.SCAN_SALES_ORDER, 'salesOrderNumber');
export const scanSalesOrderSuccess = createAction(ACTIONS.SCAN_SALES_ORDER_SUCCESS, 'payload');
export const scanSalesOrderError = createAction(ACTIONS.SCAN_SALES_ORDER_ERROR, 'errorMessage', 'error');
export const setScannedItems = createAction(ACTIONS.SET_SCANNED_ITEMS, 'scannedItems');
export const setSalesOrderNumber = createAction(ACTIONS.SET_SALES_ORDER_NUMBER, 'salesOrderNumber');
export const confirmShipment = createAction(ACTIONS.CONFIRM_SHIPMENT, 'trackingNumber');
export const confirmShipmentSuccess = createAction(ACTIONS.CONFIRM_SHIPMENT_SUCCESS);
export const confirmShipmentError = createAction(ACTIONS.CONFIRM_SHIPMENT_ERROR, 'errorMessage', 'error');