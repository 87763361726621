import {
  put, all, takeEvery, call,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { fetchSearchItem, fetchZone } from 'services/inventory/actions';
import { INVENTORY_MANAGEMENT_ERRORS } from 'features/InventoryManagement/constants';
import { ACTIONS } from '../constants';
import {
  searchItemsSuccess,
  searchItemsError,
  searchItemsLoadZoneSuccess,
  searchItemsLoadZoneError,
  toggleShowSearchModal,
  setChangedItems,
} from '../actions';

function* searchItemsWatcher({ searchTerm }) {
  yield put(fetchSearchItem(
    searchTerm,
    searchItemsSuccess,
    searchItemsError,
  ));
}

function* searchItemsErrorWatcher({ errorMessage }) {
  yield call([toast, toast.error], errorMessage || INVENTORY_MANAGEMENT_ERRORS.SEARCHING_ITEM);
}

function* searchItemsLoadZoneWatcher({ zoneName }) {
  yield put(fetchZone(zoneName, searchItemsLoadZoneSuccess, searchItemsLoadZoneError));
}

function* searchItemsLoadZoneSuccessWatcher({ payload }) {
  const { items } = payload;
  yield put(setChangedItems(items));
  yield put(toggleShowSearchModal(false));
}

function* searchItemsLoadZoneErrorWatcher({ errorMessage }) {
  yield call(
    [toast, toast.error],
    errorMessage || INVENTORY_MANAGEMENT_ERRORS.LOADING_ZONE_SEARCH_ITEM,
  );
}

export default function* searchItemsSagas() {
  yield all([
    takeEvery(ACTIONS.SEARCH_ITEMS, searchItemsWatcher),
    takeEvery(ACTIONS.SEARCH_ITEMS_ERROR, searchItemsErrorWatcher),
    takeEvery(ACTIONS.SEARCH_ITEMS_LOAD_ZONE, searchItemsLoadZoneWatcher),
    takeEvery(ACTIONS.SEARCH_ITEMS_LOAD_ZONE_SUCCESS, searchItemsLoadZoneSuccessWatcher),
    takeEvery(ACTIONS.SEARCH_ITEMS_LOAD_ZONE_ERROR, searchItemsLoadZoneErrorWatcher),
  ]);
}