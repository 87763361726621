const urlParser = require('../utils/urlParser');

const BASE = '/';
const LOGIN = '/login';
const DASHBOARD = '/dashboard';
const ACCOUNT_SETTINGS = '/account-settings';
const INVENTORY = '/inventory';
const VIEW = '/view';
const MOVE = '/move';
const PICKING = '/picking';
const ORDER_MANAGEMENT = '/order-management';
const ORDER_VIEW = '/order-view';
const ADD_ORDER = '/add-order';
const SHIPPING = '/shipping';
const RECEIVING = '/receiving';
const TOOLS = '/tools';
const PICK_TICKET_GENERATOR = '/pick-ticket-generator';
const PROFILE = '/profile';
const USERS_MANAGEMENT = '/users-management';
const INTEGRATIONS = '/integrations';
const SHIPPING_RULES = '/shipping-rules';
const BUSINESS = '/business';
const REPORTS = '/reports';
const PRODUCT_MANAGEMENT = '/product-management';
const ADJUSTED_INVENTORY = '/adjusted-inventory';
const ADJUSTED_INVENTORY_COST = '/adjusted-inventory-cost';
const INVENTORY_QTYS = '/inventory-qtys';
const INVENTORY_LOCATIONS = '/inventory-locations';
const SALES = '/sales';
const PRODUCTS = '/products';
const PURCHASE_ORDER_CREATOR = '/purchase-order-creator';
const INVENTORY_LABEL_GENERATOR = '/inventory-label-generator';
const UPC_LABEL_GENERATOR = '/upc-label-generator';
const PASSWORD = '/password';
const NEW = '/new';
const RESET = '/reset';
const CHECK_EMAIL = '/check-email';
const ORDER_EXPORTER = '/order-exporter';
const PRODUCT_CREATOR = '/product-creator';
const ROLES_MANAGEMENT = '/roles-management';
const PURCHASE_ORDER_MANAGEMENT = '/po-management';
const PURCHASE_ORDERS = '/purchase-orders';
const BULK_MARK_SHIP = '/bulk-mark-ship';
const BRANDS_SUPPLIERS = '/brands-suppliers';

const ROUTES = {
  BASE,
  DASHBOARD,
  LOGIN: {
    BASE: LOGIN,
    RESET_PASSWORD: `${LOGIN}${PASSWORD}${RESET}`,
    NEW_PASSWORD: queryObj => `${LOGIN}${PASSWORD}${NEW}${urlParser.queryStringFromObj(queryObj)}`,
    CHECK_EMAIL: `${LOGIN}${PASSWORD}${CHECK_EMAIL}`,
  },
  INVENTORY: {
    BASE: `${DASHBOARD}${INVENTORY}`,
    VIEW: `${DASHBOARD}${INVENTORY}${VIEW}`,
    MOVE: `${DASHBOARD}${INVENTORY}${MOVE}`,
    PICKING: `${DASHBOARD}${INVENTORY}${PICKING}`,
  },
  ORDER_VIEW: {
    BASE: queryObj => `${DASHBOARD}${ORDER_VIEW}${urlParser.queryStringFromObj(queryObj)}`,
  },
  ORDER_MANAGEMENT: {
    BASE: `${DASHBOARD}${ORDER_MANAGEMENT}`,
    ADD_ORDER: `${DASHBOARD}${ORDER_MANAGEMENT}${ADD_ORDER}`,
    SHIPPING: `${DASHBOARD}${ORDER_MANAGEMENT}${SHIPPING}`,
    ORDER_EXPORTER: `${DASHBOARD}${ORDER_MANAGEMENT}${ORDER_EXPORTER}`,
  },
  TOOLS: {
    BASE: `${DASHBOARD}${TOOLS}`,
    PICK_TICKET_GENERATOR: `${DASHBOARD}${TOOLS}${PICK_TICKET_GENERATOR}`,
    INVENTORY_LABEL_GENERATOR: `${DASHBOARD}${TOOLS}${INVENTORY_LABEL_GENERATOR}`,
    UPC_LABEL_GENERATOR: `${DASHBOARD}${TOOLS}${UPC_LABEL_GENERATOR}`,
    BULK_MARK_SHIP: `${DASHBOARD}${TOOLS}${BULK_MARK_SHIP}`,
  },
  ACCOUNT_SETTINGS: {
    BASE: `${DASHBOARD}${ACCOUNT_SETTINGS}`,
    PROFILE: `${DASHBOARD}${ACCOUNT_SETTINGS}${PROFILE}`,
    USERS_MANAGEMENT: `${DASHBOARD}${ACCOUNT_SETTINGS}${USERS_MANAGEMENT}`,
    ROLES_MANAGEMENT: `${DASHBOARD}${ACCOUNT_SETTINGS}${ROLES_MANAGEMENT}`,
    INTEGRATIONS: `${DASHBOARD}${ACCOUNT_SETTINGS}${INTEGRATIONS}`,
    SHIPPING_RULES: queryObj => `${DASHBOARD}${ACCOUNT_SETTINGS}${SHIPPING_RULES}${urlParser.queryStringFromObj(queryObj)}`,
    BUSINESS: `${DASHBOARD}${ACCOUNT_SETTINGS}${BUSINESS}`,
  },
  REPORTS: {
    BASE: `${DASHBOARD}${REPORTS}`,
    ADJUSTED_INVENTORY: `${DASHBOARD}${REPORTS}${ADJUSTED_INVENTORY}`,
    ADJUSTED_INVENTORY_COST: `${DASHBOARD}${REPORTS}${ADJUSTED_INVENTORY_COST}`,
    INVENTORY_QTYS: `${DASHBOARD}${REPORTS}${INVENTORY_QTYS}`,
    INVENTORY_LOCATIONS: `${DASHBOARD}${REPORTS}${INVENTORY_LOCATIONS}`,
    SALES: `${DASHBOARD}${REPORTS}${SALES}`,
  },
  PRODUCT_MANAGEMENT: {
    BASE: `${DASHBOARD}${PRODUCT_MANAGEMENT}`,
    PRODUCTS: queryObj => `${DASHBOARD}${PRODUCT_MANAGEMENT}${PRODUCTS}${urlParser.queryStringFromObj(queryObj)}`,
    PRODUCT_CREATOR: `${DASHBOARD}${PRODUCT_MANAGEMENT}${PRODUCT_CREATOR}`,
    BRANDS_SUPPLIERS: queryObj => `${DASHBOARD}${PRODUCT_MANAGEMENT}${BRANDS_SUPPLIERS}${urlParser.queryStringFromObj(queryObj)}`,
  },
  PURCHASE_ORDER_MANAGEMENT: {
    BASE: `${DASHBOARD}${PURCHASE_ORDER_MANAGEMENT}`,
    PURCHASE_ORDERS: queryObj => `${DASHBOARD}${PURCHASE_ORDER_MANAGEMENT}${PURCHASE_ORDERS}${urlParser.queryStringFromObj(queryObj)}`,
    PURCHASE_ORDER_CREATOR: `${DASHBOARD}${PURCHASE_ORDER_MANAGEMENT}${PURCHASE_ORDER_CREATOR}`,
    RECEIVING: `${DASHBOARD}${PURCHASE_ORDER_MANAGEMENT}${RECEIVING}`,
  },
};
export default ROUTES;
