import {
  put, takeEvery, call, select, takeLeading,
} from 'redux-saga/effects';
import _reject from 'lodash/reject';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import { toast } from 'react-toastify';
import { unsubscribeFromZoneUpdates, subscribeToZoneUpdates } from 'features/InventoryManagement/sagaWorkers';
import { INVENTORY_MANAGEMENT_ERRORS, INVENTORY_MANAGEMENT_SUCCESS } from 'features/InventoryManagement/constants';
import { buildInventoryItem } from 'services/inventory/helpers';
import {
  fetchZone,
  setZoneLock,
  setZoneUnlock,
  setMoveReplaceDeltaQty,
  setMoveItems,
  fetchHasLock,
} from 'services/inventory/actions';
import { findSelectedItems } from './helpers';

export function* scanZoneNameWorker({
  zoneName, getZoneName, scanZoneNameSuccess, scanZoneNameError,
}) {
  const prevZoneName = yield select(getZoneName);
  yield call(unsubscribeFromZoneUpdates, zoneName, prevZoneName);
  yield put(fetchZone(zoneName, scanZoneNameSuccess, scanZoneNameError));
}

export function* scanZoneNameSuccessWorker({
  payload, reloadZoneLock, reloadZone, setChangedItems,
}) {
  const { items, location } = payload;
  yield put(setChangedItems(items));

  yield call(subscribeToZoneUpdates, location, reloadZoneLock, reloadZone);
}

export function* scanZoneNameErrorWorker() {
  yield call([toast, toast.error], INVENTORY_MANAGEMENT_ERRORS.SCANNING_ZONE_NAME);
}

export function* toggleZoneLockWorker({ getZoneLocked, unlockZone, lockZone }) {
  const isZoneLocked = yield select(getZoneLocked);

  if (isZoneLocked) {
    yield put(unlockZone());
    return;
  }

  yield put(lockZone());
}

export function* lockZoneWorker({ getZoneName, lockZoneSuccess, lockZoneError }) {
  const zoneName = yield select(getZoneName);
  yield put(setZoneLock(zoneName, lockZoneSuccess, lockZoneError));
}

export function* lockZoneErrorWorker({ errorMessage, reloadZone }) {
  yield call([toast, toast.error], errorMessage);
  yield put(reloadZone());
}

export function* unlockZoneWorker({
  getZoneName, getZoneLockNonce, unlockZoneSuccess, unlockZoneError,
}) {
  const zoneName = yield select(getZoneName);
  const nonce = yield select(getZoneLockNonce);
  yield put(setZoneUnlock(zoneName, nonce, unlockZoneSuccess, unlockZoneError));
}

export function* unlockZoneErrorWorker({ reloadZone, errorMessage }) {
  yield call([toast, toast.error], errorMessage);
  yield put(reloadZone());
}

export function* reloadZoneLockWorker({ getZoneName, reloadZoneLockSuccess, reloadZoneLockError }) {
  const zoneName = yield select(getZoneName);
  yield put(fetchHasLock(zoneName, reloadZoneLockSuccess, reloadZoneLockError));
}

export function* reloadZoneLockErrorWorker({ errorMessage }) {
  yield call([toast, toast.error], errorMessage);
}

export function* toggleSelectItemWorker({
  upc, isSelected, getChangedItems, updateSelectedItems,
}) {
  const changedItems = yield select(getChangedItems);
  const itemIndex = _findIndex(changedItems, { upc });
  const updatedChangedItems = _reject(changedItems, { upc });
  const updatedItem = {
    ..._find(changedItems, { upc }),
  };
  updatedItem.selected = isSelected;
  updatedChangedItems.splice(itemIndex, 0, updatedItem);

  yield put(updateSelectedItems(updatedChangedItems));
}

export function* toggleSelectAllItemsWorker({ selectAll, getChangedItems, updateSelectedItems }) {
  const changedItems = yield select(getChangedItems);
  const updatedChangedItems = changedItems.map(item => {
    const updatedItem = {
      ...item,
    };
    updatedItem.selected = selectAll;
    return updatedItem;
  });
  yield put(updateSelectedItems(updatedChangedItems));
}

export function* cancelChangedItemsWorker({ getZoneItems, setChangedItems }) {
  const zoneItems = yield select(getZoneItems);
  yield put(setChangedItems(zoneItems));
}

export function* scanUPCWorker({
  upc,
  findZoneItemByUPC,
  getZoneCoords,
  getZoneName,
  getOtherZoneCoords,
  getOtherZoneName,
  scanUPCSuccess,
  scanUPCError,
}) {
  const { qty: sourceQty } = yield select(findZoneItemByUPC(upc));
  const sourceZone = yield select(getZoneCoords);
  const sourceItem = yield call(buildInventoryItem, {
    qty: sourceQty - 1,
    upc,
    row: sourceZone.row,
    col: sourceZone.col,
    lvl: sourceZone.level,
  });
  const sourceCollection = [sourceItem];

  const targetZone = yield select(getOtherZoneCoords);
  const targetItem = yield call(buildInventoryItem, {
    qty: 1,
    upc,
    row: targetZone.row,
    col: targetZone.col,
    lvl: targetZone.level,
  });

  const targetCollection = [targetItem];
  const sourceZoneName = yield select(getZoneName);
  const targetZoneName = yield select(getOtherZoneName);

  yield put(setMoveReplaceDeltaQty(
    sourceZoneName,
    targetZoneName,
    sourceCollection,
    targetCollection,
    scanUPCSuccess,
    scanUPCError,
  ));
}

export function* scanUPCSuccessWorker() {
  yield call([toast, toast.success], INVENTORY_MANAGEMENT_SUCCESS.MOVING_ITEMS);
}

export function* scanUPCErrorWorker({ errorMessage, reloadZone, reloadOtherZone }) {
  yield call([toast, toast.error], errorMessage);
  yield put(reloadZone());
  yield put(reloadOtherZone());
}

export function* moveSelectedItemsWorker({
  getOtherZoneName,
  getZoneName,
  selectSelectedItems,
  getChangedItems,
  moveSelectedItemsSuccess,
  moveSelectedItemsError,
}) {
  const targetZoneName = yield select(getOtherZoneName);
  const sourceZoneName = yield select(getZoneName);
  const items = yield select(getChangedItems);
  const selectedItemIds = yield select(selectSelectedItems);
  const selectedItems = findSelectedItems(selectedItemIds, items);

  if (!selectedItems) {
    yield put(moveSelectedItemsError('Selected items not found in zone.'));
    return;
  }

  yield put(setMoveItems(
    targetZoneName,
    sourceZoneName,
    selectedItems,
    moveSelectedItemsSuccess,
    moveSelectedItemsError,
  ));
}

export function* moveSelectedItemsSuccessWorker({
  reloadZone,
  reloadOtherZone,
  setSelectedItems,
}) {
  yield put(reloadZone());
  yield put(reloadOtherZone());
  yield put(setSelectedItems([]));
  yield call([toast, toast.success], INVENTORY_MANAGEMENT_SUCCESS.MOVING_ITEMS);
}

export function* moveSelectedItemsErrorWorker({
  errorMessage,
  reloadZone,
  reloadOtherZone,
}) {
  yield put(reloadZone());
  yield put(reloadOtherZone());
  yield call([toast, toast.error], errorMessage || INVENTORY_MANAGEMENT_ERRORS.MOVING_ITEMS);
}

export function* reloadZoneWorker({ getZoneName, scanZoneName }) {
  const zoneName = yield select(getZoneName);
  yield put(scanZoneName(zoneName));
}

export function sagas(ACTIONS, watchers) {
  const {
    scanZoneNameWatcher,
    scanZoneNameSuccessWatcher,
    scanZoneNameErrorWatcher,
    toggleZoneLockWatcher,
    lockZoneWatcher,
    lockZoneErrorWatcher,
    unlockZoneWatcher,
    unlockZoneErrorWatcher,
    reloadZoneLockWatcher,
    reloadZoneLockErrorWatcher,
    toggleSelectItemWatcher,
    toggleSelectAllItemsWatcher,
    scanUPCWatcher,
    scanUPCSuccessWatcher,
    scanUPCErrorWatcher,
    moveSelectedItemsWatcher,
    moveSelectedItemsSuccessWatcher,
    moveSelectedItemsErrorWatcher,
    reloadZoneWatcher,
  } = watchers;

  return [
    takeLeading(ACTIONS.SCAN_ZONE_NAME, scanZoneNameWatcher),
    takeEvery(ACTIONS.SCAN_ZONE_NAME_SUCCESS, scanZoneNameSuccessWatcher),
    takeEvery(ACTIONS.SCAN_ZONE_NAME_ERROR, scanZoneNameErrorWatcher),

    takeEvery(ACTIONS.TOGGLE_ZONE_LOCK, toggleZoneLockWatcher),
    takeLeading(ACTIONS.LOCK_ZONE, lockZoneWatcher),
    takeEvery(ACTIONS.LOCK_ZONE_ERROR, lockZoneErrorWatcher),
    takeLeading(ACTIONS.UNLOCK_ZONE, unlockZoneWatcher),
    takeEvery(ACTIONS.UNLOCK_ZONE_ERROR, unlockZoneErrorWatcher),
    takeLeading(ACTIONS.RELOAD_ZONE_LOCK, reloadZoneLockWatcher),
    takeEvery(ACTIONS.RELOAD_ZONE_LOCK_ERROR, reloadZoneLockErrorWatcher),

    takeEvery(ACTIONS.TOGGLE_SELECT_ITEM, toggleSelectItemWatcher),
    takeEvery(ACTIONS.TOGGLE_SELECT_ALL_ITEMS, toggleSelectAllItemsWatcher),

    takeEvery(ACTIONS.SCAN_UPC, scanUPCWatcher),
    takeEvery(ACTIONS.SCAN_UPC_SUCCESS, scanUPCSuccessWatcher),
    takeEvery(ACTIONS.SCAN_UPC_ERROR, scanUPCErrorWatcher),

    takeLeading(ACTIONS.MOVE_SELECTED_ITEMS, moveSelectedItemsWatcher),
    takeEvery(ACTIONS.MOVE_SELECTED_ITEMS_SUCCESS, moveSelectedItemsSuccessWatcher),
    takeEvery(ACTIONS.MOVE_SELECTED_ITEMS_ERROR, moveSelectedItemsErrorWatcher),

    takeLeading(ACTIONS.RELOAD_ZONE, reloadZoneWatcher),
  ];
}