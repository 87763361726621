import {
  all, takeEvery, put, call,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { fetchUPCLabelGeneratorSample, setImportUPCLabelGeneratorFile } from 'services/utilities/actions';
import { fileSaverSaga } from 'services/sagaWorkers';
import { ACTIONS, UPC_LABEL_GENERATOR_SAMPLE_FILENAME } from './constants';
import {
  downloadSampleSuccess,
  downloadSampleError,
  uploadFileSuccess,
  uploadFileError,
} from './actions';

const QR_CODES_FILE_NAME = 'qr_codes.pdf';

function* downloadSampleWatcher() {
  yield put(fetchUPCLabelGeneratorSample(downloadSampleSuccess, downloadSampleError));
}

function* downloadSampleSuccessWatcher({ payload }) {
  yield call(fileSaverSaga, {
    fileName: UPC_LABEL_GENERATOR_SAMPLE_FILENAME,
    fileData: payload,
    blobType: 'text/csv;charset=utf-8;',
    errorActionCallback: downloadSampleError,
  });
}

function* downloadSampleErrorWatcher({ errorMessage }) {
  yield call([toast, toast.error], errorMessage);
}

function* uploadFileWatcher({ file, options }) {
  yield put(setImportUPCLabelGeneratorFile(file, options, uploadFileSuccess, uploadFileError));
}

function* uploadFileSuccessWatcher({ payload }) {
  yield call([toast, toast.success], 'File successfully imported.');
  yield call(fileSaverSaga, {
    fileName: QR_CODES_FILE_NAME,
    fileData: payload,
    blobType: 'application/pdf',
    errorActionCallback: uploadFileError,
  });
}

function* uploadFileErrorWatcher({ errorMessage }) {
  yield call([toast, toast.error], errorMessage);
}

export default function* UPCLabelGeneratorSagas() {
  yield all([
    takeEvery(ACTIONS.DOWNLOAD_SAMPLE, downloadSampleWatcher),
    takeEvery(ACTIONS.DOWNLOAD_SAMPLE_SUCCESS, downloadSampleSuccessWatcher),
    takeEvery(ACTIONS.DOWNLOAD_SAMPLE_ERROR, downloadSampleErrorWatcher),

    takeEvery(ACTIONS.UPLOAD_FILE, uploadFileWatcher),
    takeEvery(ACTIONS.UPLOAD_FILE_SUCCESS, uploadFileSuccessWatcher),
    takeEvery(ACTIONS.UPLOAD_FILE_ERROR, uploadFileErrorWatcher),
  ]);
}