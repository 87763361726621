import { createReducer } from 'redux-create-reducer';
import { ACTIONS } from '../constants';

export const INITIAL_STATE = {
  searchTerm: null,
  results: null,
  error: false,
  isSearching: false,
  showSearchOrdersModal: false,
  isSorting: false,
};

const setSearchOrders = state => ({
  ...state,
  searchTerm: INITIAL_STATE.searchTerm,
  results: INITIAL_STATE.results,
  error: INITIAL_STATE.error,
  isSearching: true,
});

const setSearchOrdersSuccess = (state, { payload }) => ({
  ...state,
  searchTerm: payload.searchTerm,
  results: payload.search_results,
  isSearching: false,
});

const setSearchOrdersError = (state, { errorMessage }) => ({
  ...state,
  error: errorMessage,
  isSearching: false,
});

const setShowSearchOrdersModal = (state, { showSearchOrdersModal }) => ({
  ...state,
  showSearchOrdersModal,
});

const setSortResults = state => ({
  ...state,
  isSorting: true,
});

const setSortResultsSuccess = (state, { results }) => ({
  ...state,
  results,
  isSorting: INITIAL_STATE.isSorting,
});

const resetState = () => ({
  ...INITIAL_STATE,
});

export const handlers = {
  [ACTIONS.SEARCH_ORDERS]: setSearchOrders,
  [ACTIONS.SEARCH_ORDERS_SUCCESS]: setSearchOrdersSuccess,
  [ACTIONS.SEARCH_ORDERS_ERROR]: setSearchOrdersError,
  [ACTIONS.SHOW_SEARCH_ORDERS_MODAL]: setShowSearchOrdersModal,
  [ACTIONS.SORT_SEARCH_ORDER_RESULTS]: setSortResults,
  [ACTIONS.SORT_SEARCH_ORDER_RESULTS_SUCCESS]: setSortResultsSuccess,
  [ACTIONS.RESET_SEARCH_ORDERS]: resetState,
};

export default createReducer(INITIAL_STATE, handlers);