import React from 'react';
import PropTypes from 'prop-types';
import { SpinnerCentered } from 'components/Spinner';

const LoadableSpinner = ({ pastDelay }) => {
  if (pastDelay) {
    return (
      <SpinnerCentered text="Loading bundle..." />
    );
  }

  return null;
};

LoadableSpinner.propTypes = {
  pastDelay: PropTypes.bool,
};
LoadableSpinner.defaultProps = {
  pastDelay: true,
};

export default LoadableSpinner;
