import { createReducer } from 'redux-create-reducer';
import { ACTIONS, ORDER_TYPES } from '../constants';

const INITIAL_STATE = {
  sales_channel: ORDER_TYPES[0].value,
};

const updateSalesChannel = (state, { salesChannel }) => ({
  ...state,
  sales_channel: salesChannel,
});

const handlers = {
  [ACTIONS.SET_SALES_CHANNEL]: updateSalesChannel,
};

export default createReducer(INITIAL_STATE, handlers);