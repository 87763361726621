import { queryStringFromObj } from 'utils/urlParser';
import _omit from 'lodash/omit';
import HttpRequest from '../HttpRequest';
import ENDPOINTS from './constants';

export default class DataVisualizationApi extends HttpRequest {
  getSalesSummary({ params }) {
    // startDate = date, endDate = date, selectedBrands = array, selectedVendors = array
    const queryString = queryStringFromObj(params);
    return this.call(`${ENDPOINTS.SALES_SUMMARY}${queryString}`);
  }

  getSalesChart({ params }) {
    const queryString = queryStringFromObj(_omit(params, ['timeline']));
    return this.call(`${ENDPOINTS.SALES_CHART}${queryString}`);
  }

  getInventoryLocationsSummary({ params }) {
    const queryString = queryStringFromObj(params);
    return this.call(`${ENDPOINTS.INVENTORY_LOCATIONS_SUMMARY}${queryString}`);
  }

  getInventoryLocationsChart({ params }) {
    const queryString = queryStringFromObj(params);
    return this.call(`${ENDPOINTS.INVENTORY_LOCATIONS_CHART}${queryString}`);
  }
}

export { ENDPOINTS };