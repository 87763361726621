import { createReducer } from 'redux-create-reducer';
import {
  ACTIONS,
  CHART_TYPE_OPTIONS,
  INDEX_BY_OPTIONS,
  SORT_BY_OPTIONS,
  LIMIT_OPTIONS,
} from '../constants';

export const INITIAL_STATE = {
  isGenerating: false,
  isFetchingChartData: false,
  isFetchingSummaryData: false,
  summaryData: null,
  chartData: [],
  chartSettings: {
    chartType: CHART_TYPE_OPTIONS[0].value,
    indexBy: INDEX_BY_OPTIONS[0].value,
    limit: LIMIT_OPTIONS[0].value,
    sortBy: SORT_BY_OPTIONS[0].value,
  },
};

const updateChartSettings = (state, { updatedProps }) => {
  const { chartType } = updatedProps;
  const salesChartData = chartType ? [] : state.salesChartData;
  return {
    ...state,
    salesChartData,
    chartSettings: {
      ...state.chartSettings,
      ...updatedProps,
    },
  };
};

const setIsGenerating = (state, isGenerating) => ({
  ...state,
  isGenerating,
});

const setIsFetchingChartData = (state, isFetchingChartData) => ({
  ...state,
  isFetchingChartData,
});

const setIsFetchingSummaryData = (state, isFetchingSummaryData) => ({
  ...state,
  isFetchingSummaryData,
});

const updateChartData = (state, { payload }) => ({
  ...state,
  chartData: payload.results,
  isFetchingChartData: INITIAL_STATE.isFetchingChartData,
});

const updateSummaryData = (state, { payload }) => ({
  ...state,
  summaryData: payload.summary,
  isFetchingSummaryData: INITIAL_STATE.isFetchingSummaryData,
});

export const handlers = {
  [ACTIONS.GENERATE_VISUALIZATION]: state => setIsGenerating(state, true),
  [ACTIONS.GENERATE_VISUALIZATION_SUCCESS]: state => setIsGenerating(state, false),
  [ACTIONS.GENERATE_VISUALIZATION_ERROR]: state => setIsGenerating(state, false),

  [ACTIONS.FETCH_SUMMARY_DATA]: state => setIsFetchingSummaryData(state, true),
  [ACTIONS.FETCH_SUMMARY_DATA_SUCCESS]: updateSummaryData,
  [ACTIONS.FETCH_SUMMARY_DATA_ERROR]: state => setIsFetchingSummaryData(state, false),

  [ACTIONS.FETCH_CHART_DATA]: state => setIsFetchingChartData(state, true),
  [ACTIONS.FETCH_CHART_DATA_SUCCESS]: updateChartData,
  [ACTIONS.FETCH_CHART_DATA_ERROR]: state => setIsFetchingChartData(state, false),

  [ACTIONS.SET_CHART_SETTINGS]: updateChartSettings,
};

export default createReducer(INITIAL_STATE, handlers);