import { combineReducers } from 'redux';
import orderTypeReducers from './orderType';
import shippingAddressReducers from './shippingAddress';
import addItemsReducers from './addItems';
import completeOrderReducers from './completeOrder';
import { ACTIONS } from '../constants';

const featureReducers = combineReducers({
  orderType: orderTypeReducers,
  shippingAddress: shippingAddressReducers,
  addItems: addItemsReducers,
  completeOrder: completeOrderReducers,
});

const addOrderReducers = (state, action) => {
  let featureState = state;
  if (action.type === ACTIONS.RESET_FEATURE_REDUCERS) {
    featureState = undefined;
  }
  return featureReducers(featureState, action);
};

export default addOrderReducers;
