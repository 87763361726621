import {
  put, all, takeEvery, call, select,
} from 'redux-saga/effects';
import { integrationsApiSelector } from 'app/api/selectors';
import { getErrorMessage } from 'utils/error';
import { authRequired } from 'services/auth/sagas';
import { ACTIONS } from './constants';

function* fetchChannelsWatcher({ successActionCallback, errorActionCallback }) {
  const api = yield select(integrationsApiSelector);
  try {
    const response = yield call([api, api.getChannels]);
    const result = response.data ? response.data : response;

    if (successActionCallback) {
      yield put(successActionCallback(result));
    }

    return result;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    if (errorActionCallback) {
      yield put(errorActionCallback(errorMessage, error));
    }

    return error;
  }
}

function* fetchInstalledWatcher({ successActionCallback, errorActionCallback }) {
  const api = yield select(integrationsApiSelector);
  try {
    const response = yield call([api, api.getIntegrations]);
    const result = response.data ? response.data : response;

    if (successActionCallback) {
      yield put(successActionCallback(result));
    }

    return result;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    if (errorActionCallback) {
      yield put(errorActionCallback(errorMessage, error));
    }

    return error;
  }
}

export default function* inventoryServiceSagas() {
  yield all([
    takeEvery(ACTIONS.FETCH_CHANNELS, authRequired, fetchChannelsWatcher),
    takeEvery(ACTIONS.FETCH_INSTALLED, authRequired, fetchInstalledWatcher),
  ]);
}
