import { queryStringFromObj } from 'utils/urlParser';
import HttpRequest from '../HttpRequest';
import ENDPOINTS from './constants';

export default class FeedsApi extends HttpRequest {
  productInfo(upcOrSku) {
    const queryString = queryStringFromObj({ q: upcOrSku });
    return this.call(`${ENDPOINTS.PRODUCT_INFO}${queryString}`);
  }

  shipCities(zipCode) {
    const queryString = queryStringFromObj({ zip_code: zipCode });
    return this.call(`${ENDPOINTS.SHIP_CITIES}${queryString}`);
  }

  searchOrderLineIds(guidOrderLine) {
    const queryString = queryStringFromObj({ q: guidOrderLine });
    return this.call(`${ENDPOINTS.SEARCH_ORDER_LINE_IDS}${queryString}`);
  }

  adjustedInventory(format = '') {
    return this.call(`${ENDPOINTS.ADJUSTED_INVENTORY}${format}`);
  }

  adjustedInventoryCost(format = '') {
    return this.call(`${ENDPOINTS.ADJUSTED_INVENTORY_COST}${format}`);
  }

  inventoryLocations(format = '') {
    return this.call(`${ENDPOINTS.INVENTORY_LOCATIONS}${format}`);
  }

  inventoryQtys(format = '') {
    return this.call(`${ENDPOINTS.INVENTORY_QTYS}${format}`);
  }

  salesReportSearch(params) {
    // params = vendor, brand;
    const queryString = queryStringFromObj(params);
    return this.call(`${ENDPOINTS.SALES_REPORT_SEARCH}${queryString}`);
  }

  salesReport(format = '', params) {
    const {
      startDate, endDate, selectedCols = [], selectedBrands = [], selectedVendors = [],
    } = params;
    // Dates format 2020-11-01 00:00:00

    const queryString = queryStringFromObj({
      startDate,
      endDate,
      selectedCols: selectedCols.join(','),
      selectedBrands: JSON.stringify(selectedBrands),
      selectedVendors: JSON.stringify(selectedVendors),
    });
    return this.call(`${ENDPOINTS.SALES_REPORT}${format}${queryString}`);
  }
}

export { ENDPOINTS };