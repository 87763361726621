import { createReducer } from 'redux-create-reducer';
import { ACTIONS } from './constants';

const INITIAL_STATE = {
  isFetchingShippingZoneName: false,
  shippingZoneName: null,
  personalZoneName: null,
  fetchShippingZoneError: null,
};

const setIsFetchingShippingZoneName = (
  state, isFetchingShippingZoneName = INITIAL_STATE.isFetchingShippingZoneName,
) => ({
  ...state,
  isFetchingShippingZoneName,
});

const setShippingZoneNameSuccess = (state, { shippingZoneName, personalZoneName }) => ({
  ...state,
  shippingZoneName,
  personalZoneName,
  isFetchingShippingZoneName: INITIAL_STATE.isFetchingShippingZoneName,
});

const setShippingZoneNameError = (state, { errorMessage }) => ({
  ...state,
  shippingZoneName: INITIAL_STATE.shippingZoneName,
  personalZoneName: INITIAL_STATE.personalZoneName,
  fetchShippingZoneError: errorMessage,
  isFetchingShippingZoneName: INITIAL_STATE.isFetchingShippingZoneName,
});

const handlers = {
  [ACTIONS.FETCH_SHIPPING_ZONE_NAME]: state => setIsFetchingShippingZoneName(state, true),
  [ACTIONS.FETCH_SHIPPING_ZONE_NAME_SUCCESS]: setShippingZoneNameSuccess,
  [ACTIONS.FETCH_SHIPPING_ZONE_NAME_ERROR]: setShippingZoneNameError,
};

export default createReducer(INITIAL_STATE, handlers);
