import _forEach from 'lodash/forEach';
import HttpRequest from '../HttpRequest';
import ENDPOINTS from './constants';

export default class UtilitiesApi extends HttpRequest {
  importInventoryLabelGeneratorFile(csvFile) {
    const bodyFormData = new FormData();
    bodyFormData.append('qr_codes', csvFile);

    return this.call(ENDPOINTS.INVENTORY_LABEL_GENERATOR, {
      method: 'POST',
      data: bodyFormData,
    });
  }

  getInventoryLabelGeneratorSample() {
    return this.call(ENDPOINTS.INVENTORY_LABEL_GENERATOR);
  }

  importUPCLabelGeneratorFile(csvFile, options) {
    const bodyFormData = new FormData();
    bodyFormData.append('fileData', csvFile);

    _forEach(options, (value, key) => {
      bodyFormData.append(key, value);
    });

    return this.call(ENDPOINTS.UPC_LABEL_GENERATOR, {
      method: 'POST',
      data: bodyFormData,
    });
  }

  getUPCLabelGeneratorSample() {
    return this.call(ENDPOINTS.UPC_LABEL_GENERATOR);
  }
}

export { ENDPOINTS };