import {
  all, takeEvery, put, call, select,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';
import ROUTES from 'constants/routes';
import { getArrangedFilters } from 'features/helpers';
import { formatSortByToObject, formatFilterToObject } from 'app/api/helpers';
import { hashFiltersToURL } from 'features/sagaHelpers';
import { fetchPurchaseOrders } from 'services/purchaseOrders/actions';
import { ACTIONS } from '../constants';
import {
  loadFeed,
  loadPurchaseOrders,
  loadPurchaseOrdersError,
  loadPurchaseOrdersSuccess,
  setFilters,
} from '../actions';
import {
  getSettingsItemsPerPage,
  getSettingsSorting,
  getLastPage,
  getFilters,
} from '../selectors';

function* requestFeed(otherParams = {}) {
  const itemsPerPage = yield select(getSettingsItemsPerPage);
  const { columnId, direction } = yield select(getSettingsSorting);
  const page = yield select(getLastPage);
  const sortBy = yield call(formatSortByToObject, columnId, direction);
  const filters = yield select(getFilters);
  const filter = yield call(formatFilterToObject, filters);

  yield call(hashFiltersToURL, filters, ROUTES.PURCHASE_ORDER_MANAGEMENT.PURCHASE_ORDERS);

  const params = {
    itemsPerPage,
    page,
    sortBy,
    filter,
    ...otherParams,
  };

  yield put(loadFeed());
  yield put(fetchPurchaseOrders(params, loadPurchaseOrdersSuccess, loadPurchaseOrdersError));
}

export function* loadPurchaseOrdersWatcher() {
  yield call(requestFeed);
}

function* loadPurchaseOrdersErrorWatcher({ errorMessage }) {
  yield call([toast, toast.error], errorMessage);
}

function* arrangeFiltersWatcher({ destination, source }) {
  const filters = yield select(getFilters);
  const updatedFilters = yield call(getArrangedFilters, destination, source, filters);
  yield put(setFilters(updatedFilters));
  if (JSON.stringify(filters) !== JSON.stringify(updatedFilters)) {
    yield put(loadPurchaseOrders());
  }
}

export default function* loadFeedSagas() {
  yield all([
    takeEvery([
      ACTIONS.LOAD_PURCHASE_ORDERS,
      ACTIONS.REFRESH_FEED,
      ACTIONS.SORT_FEED,
      ACTIONS.SET_FILTER_OPERAND,
      ACTIONS.UNSET_FILTER_OPERAND,
      ACTIONS.CLEAR_FILTERS,
      ACTIONS.GO_TO_PAGE,
      ACTIONS.SET_ITEMS_PER_PAGE,
    ], loadPurchaseOrdersWatcher),
    takeEvery(ACTIONS.LOAD_PURCHASE_ORDERS_ERROR, loadPurchaseOrdersErrorWatcher),
    takeEvery(ACTIONS.ARRANGE_FILTERS, arrangeFiltersWatcher),
  ]);
}