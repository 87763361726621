import MoneyContent from 'features/components/FeedGrid/MoneyContent';
import formatMoney from 'utils/formatMoney';
import { numberWithCommas } from 'utils/formatNumber';
import DescriptionContent from './components/DescriptionContent';

export const ACTIONS = {
  EXPORT_TO_FILE: 'INVENTORY_LOCATIONS__EXPORT_TO_FILE',
  EXPORT_TO_FILE_SUCCESS: 'INVENTORY_LOCATIONS__EXPORT_TO_FILE_SUCCESS',
  EXPORT_TO_FILE_ERROR: 'INVENTORY_LOCATIONS__EXPORT_TO_FILE_ERROR',

  GENERATE_VISUALIZATION: 'INVENTORY_LOCATIONS_REPORT__GENERATE_VISUALIZATION',
  GENERATE_VISUALIZATION_SUCCESS: 'INVENTORY_LOCATIONS_REPORT__GENERATE_VISUALIZATION_SUCCESS',
  GENERATE_VISUALIZATION_ERROR: 'INVENTORY_LOCATIONS_REPORT__GENERATE_VISUALIZATION_ERROR',

  FETCH_SUMMARY_DATA: 'INVENTORY_LOCATIONS__FETCH_SUMMARY_DATA',
  FETCH_SUMMARY_DATA_SUCCESS: 'INVENTORY_LOCATIONS__FETCH_SUMMARY_DATA_SUCCESS',
  FETCH_SUMMARY_DATA_ERROR: 'INVENTORY_LOCATIONS__FETCH_SUMMARY_DATA_ERROR',

  FETCH_CHART_DATA: 'INVENTORY_LOCATIONS_REPORT__FETCH_CHART_DATA',
  FETCH_CHART_DATA_SUCCESS: 'INVENTORY_LOCATIONS_REPORT__FETCH_CHART_DATA_SUCCESS',
  FETCH_CHART_DATA_ERROR: 'INVENTORY_LOCATIONS_REPORT__FETCH_CHART_DATA_ERROR',
  SET_CHART_SETTINGS: 'INVENTORY_LOCATIONS_REPORT__SET_CHART_SETTINGS',

  RESET_STATE: 'INVENTORY_LOCATIONS__RESET_STATE',
};

export const REPORT_COLUMNS = {
  description: {
    id: 'description',
    label: 'Description',
    cellProps: {
      align: 'left',
    },
  },
  upc: {
    id: 'upc',
    label: 'UPC',
    cellProps: {
      minWidth: '8rem',
      maxWidth: '11rem',
      align: 'center',
    },
  },
  total_cost: {
    id: 'total_cost',
    label: 'Total Cost',
    cellProps: {
      minWidth: '6rem',
      maxWidth: '9rem',
      align: 'center',
    },
  },
  total_list_price: {
    id: 'total_list_price',
    label: 'Total Price',
    cellProps: {
      minWidth: '6rem',
      maxWidth: '9rem',
      align: 'center',
    },
  },
  qty: {
    id: 'qty',
    label: 'QTY',
    cellProps: {
      minWidth: '4rem',
      maxWidth: '7rem',
      align: 'center',
    },
  },
  location: {
    id: 'location',
    label: 'Location',
    cellProps: {
      minWidth: '7rem',
      maxWidth: '10rem',
      align: 'center',
    },
  },
};

export const REPORT_COLUMNS_DEFAULT_ORDER = [
  REPORT_COLUMNS.location.id,
  REPORT_COLUMNS.description.id,
  REPORT_COLUMNS.upc.id,
  REPORT_COLUMNS.qty.id,
  REPORT_COLUMNS.total_cost.id,
  REPORT_COLUMNS.total_list_price.id,
];

export const REPORT_CELL_COMPONENTS = {
  [REPORT_COLUMNS.description.id]: DescriptionContent,
  [REPORT_COLUMNS.total_cost.id]: MoneyContent,
  [REPORT_COLUMNS.total_list_price.id]: MoneyContent,
};

export const SORT_BY_OPTIONS = [
  {
    label: 'Items',
    value: 'items',
    labelFormat: '',
    axisLeftFormat: '',
    valueFormatter: numberWithCommas,
  },
  {
    label: 'Revenue',
    value: 'revenue',
    labelFormat: '$,.2f',
    axisLeftFormat: '.2s',
    valueFormatter: formatMoney,
  },
  {
    label: 'Cost',
    value: 'cost',
    labelFormat: '$,.2f',
    axisLeftFormat: '.2s',
    valueFormatter: formatMoney,
  },
];

export const LIMIT_OPTIONS = [
  {
    label: 'Top 25',
    value: 25,
  },
  {
    label: 'Top 50',
    value: 50,
  },
  {
    label: 'Top 100',
    value: 100,
  },
];

export const INDEX_BY_OPTIONS = [
  { label: 'Locations', value: 'location' },
];

export const CHART_TYPE_OPTIONS = [
  { label: 'Horizontal Bar Chart', value: 'horizontal_bar' },
  // { label: 'Vertical Bar Chart', value: 'vertical_bar' },
];