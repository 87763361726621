import HttpRequest from '../HttpRequest';
import ENDPOINTS from './constants';

export default class SuppliersApi extends HttpRequest {
  listSuppliers() {
    return this.call(`${ENDPOINTS.LIST_SUPPLIERS}`);
  }

  addSupplier({ params = {} }) {
    const {
      name,
      active = true,
      image = '',
    } = params;
    const bodyFormData = new FormData();
    bodyFormData.set('name', name);
    bodyFormData.set('active', active);
    bodyFormData.set('image', image);

    return this.call(ENDPOINTS.ADD_SUPPLIER, {
      method: 'POST',
      data: bodyFormData,
    });
  }

  updateSupplier({ supplierId, params = {} }) {
    const bodyFormData = new FormData();
    bodyFormData.set('supplier_id', supplierId);
    bodyFormData.set('attributes', JSON.stringify(params));

    return this.call(ENDPOINTS.UPDATE_SUPPLIER, {
      method: 'POST',
      data: bodyFormData,
    });
  }

  deleteSupplier({ supplierId }) {
    const bodyFormData = new FormData();
    bodyFormData.set('supplier_id', supplierId);

    return this.call(ENDPOINTS.DELETE_SUPPLIER, {
      method: 'POST',
      data: bodyFormData,
    });
  }
}

export { ENDPOINTS };
