import HttpRequest from '../HttpRequest';
import ENDPOINTS from './constants';

export default class SearchOrdersApi extends HttpRequest {
  searchOrders(searchTerm) {
    return this.call(`${ENDPOINTS.SEARCH_ORDERS}${searchTerm}`);
  }
}

export { ENDPOINTS };
