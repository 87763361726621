import {
  all, fork,
} from 'redux-saga/effects';
import authServiceSagas from './auth/sagas';
import inventoryServiceSagas from './inventory/sagas';
import integrationsServiceSagas from './integrations/sagas';
import ordersServiceSagas from './orders/sagas';
import feedsSagas from './feeds/sagas';
import firebaseSagas from './firebase/sagas';
import shipmentsSagas from './shipments/sagas';
import tomSagas from './tom/sagas';
import accountSagas from './account/sagas';
import userManagementSagas from './userManagement/sagas';
import productManagementSagas from './productManagement/sagas';
import purchaseOrdersSagas from './purchaseOrders/sagas';
import utilitiesSagas from './utilities/sagas';
import dataVisualizationSagas from './dataVisualization/sagas';
import brandsSagas from './brands/sagas';
import suppliersSagas from './suppliers/sagas';

export default function* servicesSagas(...args) {
  yield all([
    fork(authServiceSagas, ...args),
    fork(inventoryServiceSagas, ...args),
    fork(integrationsServiceSagas, ...args),
    fork(ordersServiceSagas, ...args),
    fork(feedsSagas, ...args),
    fork(firebaseSagas, ...args),
    fork(shipmentsSagas, ...args),
    fork(tomSagas, ...args),
    fork(accountSagas, ...args),
    fork(userManagementSagas, ...args),
    fork(productManagementSagas, ...args),
    fork(purchaseOrdersSagas, ...args),
    fork(utilitiesSagas, ...args),
    fork(dataVisualizationSagas, ...args),
    fork(brandsSagas, ...args),
    fork(suppliersSagas, ...args),
  ]);
}
