import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _get from 'lodash/get';

export const TextContentWrap = styled.p`
  word-break: break-all;
  font-size: 0.9rem;
`;

const TextContent = ({ item, columnId }) => {
  const content = _get(item, columnId);
  return (
    <TextContentWrap>{content}</TextContentWrap>
  );
};

TextContent.propTypes = {
  item: PropTypes.shape(),
  columnId: PropTypes.string,
};

TextContent.defaultProps = {
  item: {},
  columnId: null,
};

export default TextContent;