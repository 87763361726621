import { createReducer } from 'redux-create-reducer';
import {
  ACTIONS,
  CHART_TYPE_OPTIONS,
  INDEX_BY_OPTIONS,
  LIMIT_OPTIONS,
  SORT_BY_OPTIONS,
  LINE_CHART_INTERVAL_OPTIONS,
} from '../constants';

export const INITIAL_STATE = {
  isGenerating: false,
  isFetchingSalesSummary: false,
  isFetchingSalesChart: false,
  salesSummaryData: null,
  salesChartData: [],
  chartSettings: {
    chartType: CHART_TYPE_OPTIONS[0].value,
    limit: LIMIT_OPTIONS[0].value,
    indexBy: INDEX_BY_OPTIONS[0].value,
    sortBy: SORT_BY_OPTIONS[0].value,
    interval: LINE_CHART_INTERVAL_OPTIONS[0].value,
  },
};

const updateChartSettings = (state, { updatedProps }) => {
  const { chartType } = updatedProps;
  const salesChartData = chartType ? [] : state.salesChartData;
  return {
    ...state,
    salesChartData,
    chartSettings: {
      ...state.chartSettings,
      ...updatedProps,
    },
  };
};

const setIsGenerating = (state, isGenerating) => ({
  ...state,
  isGenerating,
});

const setIsFetchingSalesSummary = (state, isFetchingSalesSummary) => ({
  ...state,
  isFetchingSalesSummary,
});

const setIsFetchingSalesChart = (state, isFetchingSalesChart) => ({
  ...state,
  isFetchingSalesChart,
});

const updateSalesSummary = (state, { payload }) => ({
  ...state,
  salesSummaryData: payload.summary,
  isFetchingSalesSummary: INITIAL_STATE.isFetchingSalesSummary,
});

const updateSalesChart = (state, { payload }) => ({
  ...state,
  salesChartData: payload.results,
  isFetchingSalesChart: INITIAL_STATE.isFetchingSalesChart,
});

export const handlers = {
  [ACTIONS.GENERATE_VISUALIZATION]: state => setIsGenerating(state, true),
  [ACTIONS.GENERATE_VISUALIZATION_SUCCESS]: state => setIsGenerating(state, false),
  [ACTIONS.GENERATE_VISUALIZATION_ERROR]: state => setIsGenerating(state, false),

  [ACTIONS.FETCH_SALES_SUMMARY]: state => setIsFetchingSalesSummary(state, true),
  [ACTIONS.FETCH_SALES_SUMMARY_SUCCESS]: updateSalesSummary,
  [ACTIONS.FETCH_SALES_SUMMARY_ERROR]: state => setIsFetchingSalesSummary(state, false),

  [ACTIONS.FETCH_SALES_CHART]: state => setIsFetchingSalesChart(state, true),
  [ACTIONS.FETCH_SALES_CHART_SUCCESS]: updateSalesChart,
  [ACTIONS.FETCH_SALES_CHART_ERROR]: state => setIsFetchingSalesChart(state, false),

  [ACTIONS.SET_CHART_SETTINGS]: updateChartSettings,
};

export default createReducer(INITIAL_STATE, handlers);