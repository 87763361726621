import { createAction } from 'redux-create-action';
import { ACTIONS } from './constants';

export const resetAddOrderReducers = createAction(ACTIONS.RESET_FEATURE_REDUCERS);

// Order Type
export const setSalesChannel = createAction(ACTIONS.SET_SALES_CHANNEL, 'salesChannel');

// Shipping Address
export const setShippingAddressFieldValue = createAction(ACTIONS.SET_SHIPPING_ADDRESS_FIELD_VALUE, 'fieldId', 'value');
export const searchZipCode = createAction(ACTIONS.SEARCH_ZIP_CODE, 'zipCode');
export const searchZipCodeSuccess = createAction(ACTIONS.SEARCH_ZIP_CODE_SUCCESS, 'payload');
export const setShippingInfo = createAction(ACTIONS.SET_SHIPPING_INFO, 'shippingInfo');

// Add Items
export const scanItem = createAction(ACTIONS.SCAN_ITEM, 'upcOrSku');
export const scanItemSuccess = createAction(ACTIONS.SCAN_ITEM_SUCCESS, 'payload');
export const scanItemError = createAction(ACTIONS.SCAN_ITEM_ERROR, 'errorMessage', 'error');

export const setItem = createAction(ACTIONS.SET_ITEM, 'item');
export const setOrderItems = createAction(ACTIONS.SET_ORDER_ITEMS, 'orderItems');
export const removeItem = createAction(ACTIONS.REMOVE_ITEM, 'sku', 'upc');
export const setItemQty = createAction(ACTIONS.SET_ITEM_QTY, 'sku', 'upc', 'qty');
export const setItemDiscount = createAction(ACTIONS.SET_ITEM_DISCOUNT, 'sku', 'upc', 'discount');
export const setItemSubtotal = createAction(ACTIONS.SET_ITEM_SUBTOTAL, 'sku', 'upc', 'subtotal');
export const setItemTaxPercentage = createAction(ACTIONS.SET_ITEM_TAX_PERCENTAGE, 'sku', 'upc', 'taxPercentage');
export const setAllItemsTaxPercentage = createAction(ACTIONS.SET_ALL_ITEMS_TAX_PERCENTAGE, 'taxPercentage');

// Complete Order
export const setOrderId = createAction(ACTIONS.SET_ORDER_ID, 'orderId');
export const setPurchaseDate = createAction(ACTIONS.SET_ORDER_ID, 'purchaseDate');
export const setReferringOrder = createAction(ACTIONS.SET_REFERRING_ORDER, 'referringOrder');
export const setOrderNotes = createAction(ACTIONS.SET_ORDER_NOTES, 'orderNotes');
export const setShippingItem = createAction(ACTIONS.SET_SHIPPING_ITEM, 'shippingItem');
export const setShippingItemSubtotal = createAction(ACTIONS.SET_SHIPPING_ITEM_SUBTOTAL, 'subtotal');
export const removeShippingItem = createAction(ACTIONS.REMOVE_SHIPPING_ITEM);

export const setShowConfirmPaymentModal = createAction(ACTIONS.SET_SHOW_CONFIRM_PAYMENT_MODAL, 'showConfirmPaymentModal');
export const setShowCancelOrderModal = createAction(ACTIONS.SET_SHOW_CANCEL_ORDER_MODAL, 'showCancelOrderModal');
export const confirmPayment = createAction(ACTIONS.CONFIRM_PAYMENT, 'paymentType', 'transactionIds');
export const confirmPaymentSuccess = createAction(ACTIONS.CONFIRM_PAYMENT_SUCCESS, 'payload');
export const generateOrderId = createAction(ACTIONS.GENERATE_ORDER_ID);

export const confirmOrder = createAction(ACTIONS.CONFIRM_ORDER);
export const confirmOrderSuccess = createAction(ACTIONS.CONFIRM_ORDER_SUCCESS, 'payload');
export const confirmOrderError = createAction(ACTIONS.CONFIRM_ORDER_ERROR, 'errorMessage', 'error');
export const cancelOrder = createAction(ACTIONS.CANCEL_ORDER);
export const restartOrder = createAction(ACTIONS.RESTART_ORDER);