import {
  all, takeEvery, put, call, select,
} from 'redux-saga/effects';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import _pick from 'lodash/pick';
import { getOrderLineNotes } from 'services/orders/actions';
import { openNotesPopup } from 'features/components/helpers/notesPopup';
import { getSalesOrderItems } from 'features/OrderManagement/Shipping/selectors';
import { ACTIONS } from '../constants';
import {
  fetchOrderLineNotesError,
  fetchOrderLineNotesSuccess,
} from '../actions';
import { selectOrderFeedItems } from '../selectors';

function* fetchOrderLineNotesWatcher({ guidOrderLineId }) {
  yield put(
    getOrderLineNotes(guidOrderLineId, fetchOrderLineNotesSuccess, fetchOrderLineNotesError),
  );
}

function* fetchOrderLineNotesSuccessWatcher({ payload }) {
  let data = { noData: true };
  if (!_isEmpty(payload)) {
    const fetchedData = payload[0];
    const orderLinesOrderView = yield select(selectOrderFeedItems);
    const orderLinesShipping = yield select(getSalesOrderItems);
    const orderLines = orderLinesOrderView || orderLinesShipping;
    const orderLine = _find(orderLines, { guid_order_line: fetchedData.guid_order_line }) || {};
    const orderLineProps = _pick(orderLine, ['order_id', 'sku', 'upc', 'sales_order_number']);
    data = {
      ...payload[0],
      ...orderLineProps,
    };
  }
  yield call(openNotesPopup, {
    data,
  });
}

export default function* orderLinesSagas() {
  yield all([
    takeEvery(ACTIONS.FETCH_ORDER_LINE_NOTES, fetchOrderLineNotesWatcher),
    takeEvery(ACTIONS.FETCH_ORDER_LINE_NOTES_SUCCESS, fetchOrderLineNotesSuccessWatcher),
  ]);
}