export default {
  apiKey: 'AIzaSyD0KJovIinU4LdFM2-1jI8ckqum5nhsSTM',
  authDomain: 'omni-warehouse.firebaseapp.com',
  databaseURL: 'https://omni-warehouse.firebaseio.com',
  projectId: 'omni-warehouse',
  storageBucket: 'omni-warehouse.appspot.com',
  messagingSenderId: '393545728444',
  appId: '1:393545728444:web:b7ff09dc40f82e9a38ebb9',
  measurementId: 'G-Q77GMP4WPP',
};

// Initialize Firebase
// firebase.initializeApp(firebaseConfig);