import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import LoadableError from 'components/LoadableError';
import { CrashBoundaryWrapper } from './styles';

class CrashBoundary extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback TESTID_COMPONENT.
    return { hasError: true };
  }

  componentDidCatch() {
    // You can also log the error to an error reporting service
    // logErrorService(error, errorInfo);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <CrashBoundaryWrapper>
          <LoadableError
            data-testid="crash-boundary"
            title="Oops! App crashed!"
          />
        </CrashBoundaryWrapper>
      );
    }

    return children;
  }
}

CrashBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
};

export default CrashBoundary;
