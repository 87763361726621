import { combineReducers } from 'redux';
import inventoryViewReducers from './InventoryView/reducers';
import inventoryMoveReducers from './InventoryMove/reducers';
import pickingReducers from './Picking/reducers';
import { ACTIONS } from './constants';

const featureReducers = combineReducers({
  inventoryView: inventoryViewReducers,
  inventoryMove: inventoryMoveReducers,
  picking: pickingReducers,
});

const inventoryManagementReducers = (state, action) => {
  let featureState = state;
  if (action.type === ACTIONS.RESET_FEATURE_REDUCERS) {
    featureState = undefined;
  }
  return featureReducers(featureState, action);
};

export default inventoryManagementReducers;
