import { createReducer } from 'redux-create-reducer';
import { ACTIONS } from './constants';

const today = new Date();

export const INITIAL_STATE = {
  currentDate: today,
  selectedDate: today,
  selectedOrderType: null,
  selectedTimestamp: null,
  isGettingReports: false,
  isDownloading: false,
  isExporting: false,
  availableTimestamps: null,
  pendingExportedCount: null,
};

const setGetReports = state => ({
  ...state,
  isGettingReports: true,
  availableTimestamps: INITIAL_STATE.availableTimestamps,
  pendingExportedCount: INITIAL_STATE.pendingExportedCount,
});

const setGetReportsSuccess = (state, { payload }) => ({
  ...state,
  isGettingReports: INITIAL_STATE.isGettingReports,
  availableTimestamps: payload.log,
  pendingExportedCount: payload.pending_exported_count,
});

const setGetReportsError = state => ({
  ...state,
  isGettingReports: INITIAL_STATE.isGettingReports,
  availableTimestamps: INITIAL_STATE.availableTimestamps,
  pendingExportedCount: INITIAL_STATE.pendingExportedCount,
});

const updateSelectedDate = (state, { selectedDate }) => ({
  ...state,
  selectedDate,
  availableTimestamps: INITIAL_STATE.availableTimestamps,
  pendingExportedCount: INITIAL_STATE.pendingExportedCount,
});

const updateSelectedOrderType = (state, { selectedOrderType }) => ({
  ...state,
  selectedOrderType,
  availableTimestamps: INITIAL_STATE.availableTimestamps,
  pendingExportedCount: INITIAL_STATE.pendingExportedCount,
});

const updateSelectedTimestamp = (state, { selectedTimestamp }) => ({
  ...state,
  selectedTimestamp,
});

const setIsDownloading = (state, isDownloading) => ({
  ...state,
  isDownloading,
});

const setIsExporting = (state, isExporting) => ({
  ...state,
  isExporting,
});

const resetState = () => ({
  ...INITIAL_STATE,
});

export const handlers = {
  [ACTIONS.GET_REPORTS]: setGetReports,
  [ACTIONS.GET_REPORTS_SUCCESS]: setGetReportsSuccess,
  [ACTIONS.GET_REPORTS_ERROR]: setGetReportsError,

  [ACTIONS.SET_SELECTED_DATE]: updateSelectedDate,
  [ACTIONS.SET_SELECTED_ORDER_TYPE]: updateSelectedOrderType,
  [ACTIONS.SET_SELECTED_TIMESTAMP]: updateSelectedTimestamp,

  [ACTIONS.DOWNLOAD_FILE]: state => setIsDownloading(state, true),
  [ACTIONS.DOWNLOAD_FILE_SUCCESS]: state => setIsDownloading(state, false),
  [ACTIONS.DOWNLOAD_FILE_ERROR]: state => setIsDownloading(state, false),

  [ACTIONS.EXPORT_DOWNLOAD]: state => setIsExporting(state, true),
  [ACTIONS.EXPORT_DOWNLOAD_SUCCESS]: state => setIsExporting(state, false),
  [ACTIONS.EXPORT_DOWNLOAD_ERROR]: state => setIsExporting(state, false),

  [ACTIONS.RESET_STATE]: resetState,
};

export default createReducer(INITIAL_STATE, handlers);