import React from 'react';
import PropTypes from 'prop-types';

const Form = ({ onSubmit, children }) => {
  const handleOnSubmit = e => {
    e.preventDefault();
    if (onSubmit) {
      onSubmit();
    }
  };

  return (
    <form onSubmit={handleOnSubmit}>
      {children}
    </form>
  );
};

Form.propTypes = {
  onSubmit: PropTypes.func,
  children: PropTypes.node,
};

Form.defaultProps = {
  onSubmit: null,
  children: null,
};

export default Form;