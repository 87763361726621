import { createReducer } from 'redux-create-reducer';
import { ACTIONS } from './constants';

const INITIAL_STATE = {
  isLoadingData: false,
  isExporting: false,
  data: [],
};

const setLoadReportSuccess = (state, { payload }) => ({
  ...state,
  data: payload.results,
  isLoadingData: INITIAL_STATE.isLoadingData,
});

const setIsLoadingData = (state, isLoadingData) => ({
  ...state,
  isLoadingData,
});

const setIsExporting = (state, isExporting) => ({
  ...state,
  isExporting,
});

const resetState = () => ({
  ...INITIAL_STATE,
});

const handlers = {
  [ACTIONS.LOAD_REPORT]: state => setIsLoadingData(state, true),
  [ACTIONS.LOAD_REPORT_SUCCESS]: setLoadReportSuccess,
  [ACTIONS.LOAD_REPORT_ERROR]: state => setIsLoadingData(state, false),
  [ACTIONS.EXPORT_TO_FILE]: state => setIsExporting(state, true),
  [ACTIONS.EXPORT_TO_FILE_SUCCESS]: state => setIsExporting(state, false),
  [ACTIONS.EXPORT_TO_FILE_ERROR]: state => setIsExporting(state, false),
  [ACTIONS.RESET_STATE]: resetState,
};

export default createReducer(INITIAL_STATE, handlers);