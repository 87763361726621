import { DEV_MODE } from 'app/config';
import { isFeatureEnabled } from 'app/featureFlags';
import { FEATURE_FLAGS } from 'app/featureFlags/constants';
import ROUTES from './routes';

export const FIELD_DEBOUNCE = DEV_MODE ? 300 : 0;
export const APP_MIN_WIDTH = '45rem';
export const APP_FEATURES = {
  INVENTORY_MANAGEMENT: {
    id: 'inventory_management',
    title: 'Inventory Management',
    short_title: 'Inventory',
    route: ROUTES.INVENTORY.BASE,
  },
  ACCOUNT_MANAGEMENT: {
    id: 'account_management',
    title: 'Account Settings',
    short_title: 'Account',
    route: ROUTES.ACCOUNT_SETTINGS.BASE,
  },
  ORDER_VIEW: {
    id: 'order_view',
    title: isFeatureEnabled(FEATURE_FLAGS.ORDER_VIEW) ? 'Order View' : 'Order Line View',
    short_title: 'Order View',
    route: ROUTES.ORDER_VIEW.BASE(),
  },
  ORDER_MANAGEMENT: {
    id: 'order_management',
    title: 'Order Management',
    short_title: 'Orders',
    route: ROUTES.ORDER_MANAGEMENT.BASE,
  },
  PURCHASE_ORDER_MANAGEMENT: {
    id: 'purchase_order_management',
    title: 'PO Management',
    short_title: 'PO\'s',
    route: ROUTES.PURCHASE_ORDER_MANAGEMENT.BASE,
  },
  PRODUCT_MANAGEMENT: {
    id: 'product_management',
    title: 'Product Management',
    short_title: 'Products',
    route: ROUTES.PRODUCT_MANAGEMENT.BASE,
  },
  TOOLS: {
    id: 'tools',
    title: 'Tools',
    short_title: 'Tools',
    route: ROUTES.TOOLS.BASE,
  },
  REPORTS: {
    id: 'reports',
    title: 'Reports',
    short_title: 'Reports',
    route: ROUTES.REPORTS.BASE,
  },
};