import HttpRequest from '../HttpRequest';
import ENDPOINTS from './constants';

export default class BrandsApi extends HttpRequest {
  listBrands() {
    return this.call(`${ENDPOINTS.LIST_BRANDS}`);
  }

  addBrand({ params = {} }) {
    const {
      name,
      active = true,
      image = '',
      urlSlug = '',
    } = params;
    const bodyFormData = new FormData();
    bodyFormData.set('name', name);
    bodyFormData.set('active', active);
    bodyFormData.set('image', image);
    bodyFormData.set('url_slug', urlSlug);

    return this.call(ENDPOINTS.ADD_BRAND, {
      method: 'POST',
      data: bodyFormData,
    });
  }

  updateBrand({ brandId, params = {} }) {
    const bodyFormData = new FormData();
    bodyFormData.set('brand_id', brandId);
    bodyFormData.set('attributes', JSON.stringify(params));

    return this.call(ENDPOINTS.UPDATE_BRAND, {
      method: 'POST',
      data: bodyFormData,
    });
  }

  deleteBrand({ brandId }) {
    const bodyFormData = new FormData();
    bodyFormData.set('brand_id', brandId);

    return this.call(ENDPOINTS.DELETE_BRAND, {
      method: 'POST',
      data: bodyFormData,
    });
  }
}

export { ENDPOINTS };
