import { createAction } from 'redux-create-action';
import { ACTIONS } from './constants';

export const downloadTemplate = createAction(ACTIONS.DOWNLOAD_TEMPLATE);
export const downloadTemplateSuccess = createAction(ACTIONS.DOWNLOAD_TEMPLATE_SUCCESS, 'payload');
export const downloadTemplateError = createAction(ACTIONS.DOWNLOAD_TEMPLATE_ERROR, 'errorMessage', 'error');

export const uploadFile = createAction(ACTIONS.UPLOAD_FILE, 'file');
export const uploadFileSuccess = createAction(ACTIONS.UPLOAD_FILE_SUCCESS, 'payload');
export const uploadFileError = createAction(ACTIONS.UPLOAD_FILE_ERROR, 'errorMessage', 'error');

export const resetState = createAction(ACTIONS.RESET_STATE);